import { React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ColoredLogo, DropDownIc, FbIcon, InstagramIcon, LinkesIcon, TwitterIcon, LawyerdLogo } from "../../../assets/img/imagesh";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { COMMON_PATHS } from "../../../config/Owner-And-NewList_Paths";
import { advanceFilter } from "../../../Redux/ActionTypes";
import moment from "moment";
const Footer = () => {
  const [isActive, productActive] = useState(false);
  const [isCompanyActive, companyActive] = useState(false);
  const [isSupportActive, supportActive] = useState(false);
  const [isSocialActive, socialActive] = useState(false);
  const dispatch = useDispatch();


  const productToggle = () => {
    productActive(!isActive);
  };
  const companyToggle = () => {
    companyActive(!isCompanyActive);
  };
  const supportToggle = () => {
    supportActive(!isSupportActive);
  };
  const socialToggle = () => {
    socialActive(!isSocialActive);
  };
  const cleanFilter =()=>{
    dispatch({type:advanceFilter.resetFilter})
   }
  return (
    <>
      {/* Footer Section */}
      <footer className="footer-sec">
        <Container>
          <Row>
            <Col md={3}>
              <div className="foot-item">
                <div className="j-align-center" onClick={cleanFilter}>
                  <Link to={COMMON_PATHS.HOME}>
                    {" "}
                    <img src={ColoredLogo} alt="Logo" />{" "}
                  </Link>
                  {/* <a className="lawyerd-logo" href="http://lawyerd.net" title="Lawyerd! Protection Status"> <img src ={LawyerdLogo}  alt="Lawyerd! Protection Status" /></a> */}
                </div>
                <p>
                  {" "}
                  &copy; 2021-{moment().format('YYYY')} NursesBnB Inc. <br /> All rights reserved.{" "}
                </p>
              </div>
            </Col>
            <Col md={2}>
              <div className="foot-item">
                <h4 onClick={productToggle} className={isActive ? " " : "active"}>
                  {" "}
                  Product{" "}
                  <img
                    className="show-in-mobile drop-icon"
                    src={DropDownIc}
                    alt="Dropdown Icon"
                  />
                </h4>
                <ul className={isActive ? " " : "active"}>
                  <li>
                    {" "}
                    <Link to={COMMON_PATHS.RENTER_PAGE}> Renter </Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to={COMMON_PATHS.HOMEOWNER_PAGE}> Homeowner </Link>{" "}
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={3}>
              <div className="foot-item">
                <h4  onClick={companyToggle} className={isCompanyActive ? " " : "active"}>
                  {" "}
                  Company{" "}
                  <img 
                    className="show-in-mobile drop-icon"
                    src={DropDownIc}
                    alt="Dropdown Icon"
                  />
                </h4>
                <ul className={isCompanyActive ? " " : "active"}>
                  <li>
                    {" "}
                    <Link to={COMMON_PATHS.ABOUT_US}>
                      {" "}
                      About NursesBnB{" "}
                    </Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to={COMMON_PATHS.CONTACT_US}> Contact Us </Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to={COMMON_PATHS.TRAVEL_TIPS}> Travel Tips</Link>
                  </li>
                  <li >
                    {" "}
                    <Link to={COMMON_PATHS.OUR_TEAM}> Our Team  </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to={COMMON_PATHS.BLOG}>  Blog  </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={2}>
              <div className="foot-item">
                <h4  onClick={supportToggle} className={isSupportActive ? " " : "active"}>
                  {" "}
                  Support{" "}
                  <img 
                    className="show-in-mobile drop-icon"
                    src={DropDownIc}
                    alt="Dropdown Icon"
                  />
                </h4>
                <ul className={isSupportActive ? " " : "active"}>
                  <li>
                    {" "}
                    <Link to={COMMON_PATHS.TERMS_AGREEMENT}>
                      {" "}
                      Terms & Conditions{" "}
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to={COMMON_PATHS.PRIVACY_POLICY}>
                      {" "}
                      Privacy Policy{" "}
                    </Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to={COMMON_PATHS.FAQ}> FAQ </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to={COMMON_PATHS.PRESS_PAGE}> Press </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={2} className="flex-end">
              <div className="foot-item">
                <h4 onClick={socialToggle} className={isSocialActive ? " " : "active"}>
                  {" "}
                  Follow Us{" "}
                  <img 
                    className="show-in-mobile drop-icon"
                    src={DropDownIc}
                    alt="Dropdown Icon"
                  />
                </h4>
                <ul className={isSocialActive ? "social-links" : "social-links active"}>
                  <li>
                    {" "}
                    <a href="https://www.facebook.com/nursesbnb" target="_blank"> <img src={FbIcon} alt="Facebook Icon" /> </a>
                  </li>
                  <li>
                    {" "}
                    <a href="https://twitter.com/nursesbnb" target="_blank"> <img src={TwitterIcon} alt="Twitter Icon" />  </a>
                  </li>
                  <li>
                    {" "}
                    <a href="https://www.instagram.com/nursesbnb" target="_blank"> <img src={InstagramIcon} alt="Instagram Icon" /> </a>
                  </li>
                  <li>
                    {" "}
                    <a href="https://www.linkedin.com/company/nursesbnb-inc" target="_blank"> <img src={LinkesIcon} alt="LinkedIn Icon" />  </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};
export default Footer;
