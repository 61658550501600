import { call, put, takeEvery } from "redux-saga/effects";
import {
    requestIcalLink,
} from "../../Requests/owner/Notification-owner";
import {fetchIcalLink} from "../../../ActionTypes";
import {
    icallink_request_details_success,
    icallink_request_details_error,
} from "../../../Actions/Owner/IcalLink";

import { request_loader } from "../../../Actions/loader";
import { set_message } from "../../../Actions/message";
import { take } from "lodash";

export function* handleIcalLink({ payload }) {
    try {
      yield put(request_loader({ load: true }));
      const response = yield call(requestIcalLink, payload);
      if (response) {
        yield put(request_loader({ load: false }));
        const { data, status } = response;
        if (status === window.code["200"].code) {
          yield put(icallink_request_details_success(data));
        } else {
          yield put(icallink_request_details_error(data));
        }
      }
    } catch (error) {
    }
  }
  
  export function* watchOwnerIcalLink() {
    yield takeEvery(fetchIcalLink.load, handleIcalLink);
  }