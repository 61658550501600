import { createUserDetails } from "../ActionTypes";
const data = {
  loading: true,
  user: [],
  error: "",
  isSocialLogin:false
};
const user_details = (state = data, action) => {
  switch (action.type) {
    case createUserDetails.load:
      return { ...state, loading: true };
    case createUserDetails.success:
      return { ...state, loading: false, user: action.payload };
    case createUserDetails.failure:
      return { ...state, loading: false, error: action.payload };
    case createUserDetails.fetchSuccess:
      return { ...action.payload };  
    case createUserDetails.stripeVarify:
      state.user.is_stripe_connect_account_verified=action.payload;
      return { ...state }; 
    case createUserDetails.socialLogin:
    return {...state, loading: false, user: action.payload,isSocialLogin:true };  
    default:
    return { ...state };
  }
};

export default user_details;
