import { all } from "redux-saga/effects";
import watchProductList from "./Handlers/ProductList";
import AdminSaga from "./Watchers/admin";
import OwnerSaga from "./Watchers/Owner";
import {
  watchBasicInfoUploadImages,
  watchDeletePropertyImages,
} from "./Handlers/BasicInfoImagesUpload";
import watchCreateBasicInfo from "./Handlers/BasicInfo";
import {
  watchAddSingleBankInfo,
  watchVerifyBankInfo,
  watchUpdateRepresentative,
} from "./Handlers/BankInfo";
import {
  watchCreatePropertyInfo,
  watchLoadCities,
  watchLoadCountries,
  watchLoadPropertyInfo,
  watchLoadStates,
  watchSearchRequestLisitng,
} from "./Handlers/PropertyInfo";
import {
  watchCreateLeaseSignor,
  handleLoadCountriesWithFlag,
  watchDeleteLeaseSignor,
  watchResendInvite,
  watchLoadExistLss,
} from "./Handlers/LeaseSignor";
import {
  watchLoadReviewDetails,
  watchSaveReviewDetails,
} from "./Handlers/ReviewDetails";
import { watchLoadHomePage, watchLoadSearchPage } from "./Handlers/HomePage";
import {
  watchCreateUserDetails,
  watchFetchUserDetails,
  watchUpdateUserDetails,
  watchUpdateUserProfileImage,
  watchChangePassword,
  watchSwitchRole,
} from "./Handlers/UserDetails";
import watchUserLogin from "./Handlers/Auth";
import watchLoader from "./Handlers/Loader";
import {
  watchOtpCheck,
  watchOtpResend,
  watchOtpLeaseSignorSend,
} from "./Handlers/OtpCheck";
import watchCompleteProfile from "./Handlers/CompleteProfile";
import {
  watchFetchOwnerListing,
  watchDeleteOwnerListing,
} from "./Handlers/Owner/OwnerListing";
import {
  watchOwnerCreateStripeBankToken,
  watchOwnerCreateLinkToken,
} from "./Handlers/Owner/PlaidLink";
import StripeSaga from "./Watchers/stripe";
import {
  watchDeleteListingRequest,
  watchListingRequestDetails,
  watchLoadListingRequest,
  watchSaveRatingRequest,
} from "./Handlers/Admin/ListingRequest";
import watchFilterRecord from "./Handlers/FilterRecord";
import watchHeaderLanguage from "./Handlers/HeaderLanguage";
import { watchUpdateTac, watchFetchStaticContent } from "./Handlers/Cms";
import {
  watchCreateFaq,
  watchFetchFaq,
  watchUpdateFaq,
  watchDeleteFaq,
} from "./Handlers/Faq";
import { watchDashBoardReportsRequest } from "./Handlers/Admin/DashboardSaga";

import {
  watchLeaseSignorDocs,
  watchListSignorDocs,
  watchDeleteLeaseDoc,
  watchUploadMedia,
  watchUploadDoc,
} from "./Handlers/UploadActn";
import {
  watchCreateBusinessAdmin,
  watchLoadBusinessAdmins,
  watchUpdateBusinessAdminStatus,
} from "./Handlers/Admin/BusinessAdmins";

import {
  watchBookingInitiate,
  watchBookingOccupantsAdd,
  watchBookingAdminList,
  watchBookingOwnerList,
  watchBookingOwnerStatusUpdate,
  watchBookingDetail,
  watchBookingCancelRenter,
  watchBookingApplyPromocode,
  watchCancelOwnerBooking,
  watchBookingApplyListingPayoutPromocode,
  watchBookingApplyListingHotelPromocode,
  watchDeleteListingPromocode,
} from "./Handlers/BookingHandle";
import { watchCheckinDetail } from "./Handlers/CheckinHandle";
import { watchSendMessage } from "./Handlers/Renter/Messages";
import {
  watchPaymentListOwner,
  watchPaymentListRenter,
} from "./Handlers/PaymentHandle";
import {
  watchEvictionDetail,
  watchTerminationDetail,
} from "./Handlers/EvictionHandle";
import { watchPayPenalty } from "./Handlers/Renter/PayPenalty";
import watchRvCreatePropertyInfo from "./Handlers/CreateRvProperty";
import { watchLoadBackgroundCheckList } from "./Handlers/Admin/BackgroundCheck";
import { watchLoadhandMessageCount } from "./Handlers/messageCount";

export default function* rootSaga() {
  yield all([
    ...AdminSaga,
    ...StripeSaga,
    ...OwnerSaga,
    watchProductList(),
    watchBasicInfoUploadImages(),
    watchDeletePropertyImages(),
    watchCreateBasicInfo(),
    watchLoadPropertyInfo(),
    watchCreatePropertyInfo(),
    watchCreateLeaseSignor(),
    watchLoadReviewDetails(),
    watchLoadHomePage(),
    watchLoadSearchPage(),
    watchCreateUserDetails(),
    watchFetchUserDetails(),
    watchUpdateUserDetails(),
    watchUpdateUserProfileImage(),
    watchChangePassword(),
    watchSwitchRole(),
    watchUserLogin(),
    watchOtpCheck(),
    watchOtpResend(),
    watchOtpLeaseSignorSend(),
    watchLoader(),
    watchCompleteProfile(),
    watchFetchOwnerListing(),
    watchDeleteOwnerListing(),
    watchAddSingleBankInfo(),
    watchVerifyBankInfo(),
    watchUpdateRepresentative(),
    watchLoadListingRequest(),
    watchDeleteListingRequest(),
    watchListingRequestDetails(),
    watchSaveRatingRequest(),
    watchSaveReviewDetails(),
    watchFilterRecord(),
    watchHeaderLanguage(),
    watchLoadCountries(),
    watchLoadStates(),
    handleLoadCountriesWithFlag(),
    watchLoadCities(),
    watchFetchStaticContent(),
    watchFetchFaq(),
    watchCreateFaq(),
    watchUpdateFaq(),
    watchDeleteFaq(),
    watchUpdateTac(),
    watchLeaseSignorDocs(),
    watchListSignorDocs(),
    watchDeleteLeaseDoc(),
    watchSearchRequestLisitng(),
    watchDeleteLeaseSignor(),
    watchLoadBusinessAdmins(),
    watchCreateBusinessAdmin(),
    watchUpdateBusinessAdminStatus(),
    watchBookingInitiate(),
    watchBookingOccupantsAdd(),
    watchBookingAdminList(),
    watchBookingOwnerList(),
    watchBookingOwnerStatusUpdate(),
    watchBookingDetail(),
    watchBookingCancelRenter(),
    watchResendInvite(),
    watchBookingApplyPromocode(),
    watchCancelOwnerBooking(),
    watchLoadExistLss(),
    watchSendMessage(),
    watchCheckinDetail(),
    watchUploadMedia(),
    watchPaymentListOwner(),
    watchPaymentListRenter(),
    watchUploadDoc(),
    watchEvictionDetail(),
    watchOwnerCreateLinkToken(),
    watchOwnerCreateStripeBankToken(),

    watchPayPenalty(),
    watchBookingApplyListingPayoutPromocode(),
    watchBookingApplyListingHotelPromocode(),
    watchDeleteListingPromocode(),
    watchRvCreatePropertyInfo(),
    watchDashBoardReportsRequest(),
    watchTerminationDetail(),
    watchLoadBackgroundCheckList(),
    watchLoadhandMessageCount(),
  ]);
}
