import { filterAction } from "../ActionTypes";
const data = {
  coordinate: [],
  defaultpoint:[],
  isloading:'loading'
};
const map_coordinate = (state = data, action) => {
  switch (action.type) {
    case filterAction.setCoordinate:
      const initial = state.coordinate;
      const newarr = action.payload.data;
      const final = initial.concat(newarr);
      return { coordinate:final,defaultpoint:newarr[0],isloading:action.payload.status };
    case filterAction.resetCoordinate:
      return  {
        ...state,
        coordinate: [],
        defaultpoint:[]
      };
    default:
      return { ...state };
  }
};

export default map_coordinate;
