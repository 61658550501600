import { messageCount } from "../ActionTypes";

export const allMessageCountLoad = (data) => ({
  type: messageCount.load,
  payload: data,
});

export const allMessageCoutSuccess = (data) => {
  return {
    type: messageCount.success,
    payload: data,
  };
};

export const allMessageCoutFail = (data) => ({
  type: messageCount.failure,
  payload: data,
});
export const allMessageCoutReset = (data) => ({
  type: messageCount.reset,
  payload: 0,
});
