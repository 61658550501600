import { listAction } from "../../ActionTypes/admin";
import { initial_state } from "../../../Constants/Common";

const proplistadmin = (state = initial_state, action) => {
  switch (action.type) {
    case listAction.displayProp:
      return { ...state, ...action.payload };
    case listAction.updatePropStatus:
      return { ...state, status: action.payload };
    default:
      return { ...state };
  }
};
export default proplistadmin;
