import axios from "axios";
import { apiConstants } from "../../../../Constants/api_constants";

export const requestLoadLeaseSignorDetails = (id) => {
  return axios.get(`${apiConstants.LEASE_SIGNORS_GET_ALL}${id}`);
};


export const requestLoadLeaseSignorDetailsById = (id) => {
  return axios.get(`${apiConstants.LEASE_SIGNORS_GET_BY_ID}${id}`);
};