import { put, takeEvery } from "redux-saga/effects";
import { loaderAction } from "../../ActionTypes";
import { set_loader } from '../../Actions/loader';

export function* handleLoader({ payload }) {
  let { load } = payload;
  yield put(set_loader(payload));
}

export default function* watchLoader() {
  yield takeEvery(loaderAction.load, handleLoader);
}
