import { call, put, takeEvery } from "redux-saga/effects";
import { payHotelFee } from "../../../ActionTypes";
import { request_loader } from "../../../Actions/loader";
import {
  COMMON_PATHS,
  CREATE_NEW_LIST_PATHS,
  OWNER_PATHS,
} from "../../../../config/Owner-And-NewList_Paths";
import { requestPayHotelFee } from "../../Requests/create-new-list";
import error_messages from "../../../../Constants/error-messages";
import { set_message } from "../../../Actions/message";

export function* handlePropertyHotelPaymentAmount({ payload }) {
  try {
    const { values, navigate } = payload;
    yield put(request_loader({ load: true }));
    const response = yield call(requestPayHotelFee, values);
    yield put(request_loader({ load: false }));
    if (response) {
      const { status } = response;
      if (status === window.code["200"].code) {
        let module = {
          error_code: error_messages["hotel_pay_success"],
          code: "success",
          heading: "Success",
        };
        yield put(set_message(module));
        const p_id = localStorage.getItem("p_id");
        //yield put(setPropertyFeatureAmount(data))
        navigate(
          `${
            CREATE_NEW_LIST_PATHS.LAYOUT_PATH +
            CREATE_NEW_LIST_PATHS.REVIEW_DETAILS_ONLY +
            p_id
          }`
        );
        // window.location.reload();
        localStorage.setItem("paid", true);
        localStorage.setItem("paymentHotel", true);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchPropertyHotelPaymentAmount() {
  yield takeEvery(payHotelFee.load, handlePropertyHotelPaymentAmount);
}
