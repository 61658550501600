import {
  fetchOwnerListing,
  deleteOwnerListing,
  propertyFeatureAmount,
  featureAmountPayment,
  loadLeaseSignorDetails,
  fetchLinkToken,
  fetchStripeBankToken
} from "../../ActionTypes";
//Fetch Listing
export const fetch_owner_listing = (param) => ({
  type: fetchOwnerListing.load,
  payload: param,
});
export const fetch_owner_listing_success = (data) => ({
  type: fetchOwnerListing.success,
  payload: data,
});
export const fetch_owner_listing_error = (data) => ({
  type: fetchOwnerListing.failure,
  payload: data,
});

//Delete Listing
export const delete_owner_listing = (id, isPublished, contactData, s_type) => ({
  type: deleteOwnerListing.load,
  payload: { id, isPublished, contactData, s_type },
});
export const delete_owner_listing_success = (data) => ({
  type: deleteOwnerListing.success,
  payload: data,
});
export const delete_owner_listing_error = (data) => ({
  type: deleteOwnerListing.failure,
  payload: data,
});

//Feature a list
export const getPropertyFeatureAmount = (data) => ({
  type: propertyFeatureAmount.load,
  payload: data,
});

export const setPropertyFeatureAmount = (data) => ({
  type: propertyFeatureAmount.success,
  payload: data,
});

export const payPropertyFeatureAmount = (data) => ({
  type: featureAmountPayment.load,
  payload: data,
});

// lease Signor Details //
export const load_lease_signor_details = (_id) => ({
  type: loadLeaseSignorDetails.load,
  payload: _id,
});
export const load_lease_signor_details_success = (data) => ({
  type: loadLeaseSignorDetails.success,
  payload: data,
});
export const load_lease_signor_details_error = (data) => ({
  type: loadLeaseSignorDetails.failure,
  payload: data,
});

// create-token 

export const create_link_token_details = (param) => ({
  type: fetchLinkToken.load,
  payload: param,
});
export const create_link_token_success = (data) => ({
  type: fetchLinkToken.success,
  payload: data,
});
export const create_link_token_error = (data) => ({
  type: fetchLinkToken.failure,
  payload: data,
});

// create bank stripe

export const create_stripe_bank_token_details = (param, dispatch) => ({
  type: fetchStripeBankToken.load,
  payload: {param, dispatch},
});
export const create_stripe_bank_token_success = (data) => ({
  type: fetchStripeBankToken.success,
  payload: data,
});
export const create_stripe_bank_token_error = (data) => ({
  type: fetchStripeBankToken.failure,
  payload: data,
});
