import { fetchNotifications } from "../../../Redux/ActionTypes/index";
const data = {
  loading: true,
  notificationInfo: [],
  error: "",
  notificationInfoFinished: false,
};
const notification_info = (state = data, action) => {
  switch (action.type) {
    case fetchNotifications.success:
      return {
        ...state,
        loading: false,
        notificationInfo: action.payload,
      };
    case fetchNotifications.failure:
      return {
        ...state,
        loading: false,
        notificationInfoFinished: false,
        error: action.payload,
      };
    default:
      return { ...state };
  }
};

export default notification_info;
