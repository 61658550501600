import axios from "axios";
import { store } from "../Redux/Store";
import { set_message } from "../Redux/Actions/message";
import { SESSION_TIME } from "../Constants/Common";
const { dispatch } = store;
axios.interceptors.request.use(
  (config) => {
    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["Access-Control-Allow-Methods"] = "POST";
    let startTime = localStorage.getItem("loginTimer");
    let now = Date.now();
    let diff = (now - startTime) / (1000 * 60);
    if (diff > SESSION_TIME) {
      localStorage.setItem("accessToken", "");
    } else {
      localStorage.setItem("loginTimer", Date.now());
    }

    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    } else if (config.url.includes(process.env.REACT_APP_STRIPE_API_URL)) {
      config.headers.Authorization =
        "Bearer " + process.env.REACT_APP_STRIPE_PUB_KEY;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response) {
      let { status, data } = response;
      if (status === window.code["422"].code) {
        dispatch(
          set_message({
            error_code: data.errors[0].msg,
            code: "warning",
            heading: window.code["422"].header,
          })
        );
      }

      if (status === window.code["401"].code) {
        localStorage.clear();
        dispatch(
          set_message({
            error_code: "Please log in to continue.",
            code: "warning",
            heading: window.code[status].header,
          })
        );
        localStorage.setItem("history_url", window.location.pathname);
        setTimeout(() => {
          window.location.replace("/login");
        }, 3000);
      }

      if (!window.diff.includes(status)) {
        let msg = data.message;
        // handled case of jwt expired
        if (
          status === window.code["400"].code &&
          data.message === "jwt expired"
        ) {
          msg = "Token expired. Redirecting to login.";
          dispatch(
            set_message({
              error_code: msg,
              code: "warning",
              heading: window.code[status].header,
            })
          );
          setTimeout(() => {
            window.location.replace("/login");
          }, 3000);
        } else {
          dispatch(
            set_message({
              error_code: msg,
              code: "warning",
              heading: window.code[status].header,
            })
          );
        }
      }
      return response;
    } else {
      return null;
    }
  },
  (error) => {
    if (error.message.split(" ").includes("401" || 401)) {
      localStorage.clear();
      sessionStorage.clear();
      dispatch(
        set_message({
          error_code: "Please log in to continue.",
          code: "warning",
          heading: "",
        })
      );
      localStorage.setItem("history_url", window.location.pathname);
      setTimeout(() => {
        window.location.replace("/login");
      }, 2000);
    } else {
      throw error.response;
    }
  }
);
export default axios;
