import { call, put, takeEvery } from "redux-saga/effects";
import { ADMINPATHS } from "../../../config/webPathAdmin";
import { constantTexts } from "../../../Constants/Common";
import { storeStaticContent, update_cm_success } from "../../Actions/Cms";
import { request_loader } from "../../Actions/loader";
import { set_message } from "../../Actions/message";
import { loadStaticContent, updateTac } from "../../ActionTypes";
import {
  requestFetchStaticContent,
  requestUpdateContents,
} from "../Requests/Cms";

export function* handleFetchStaticContent({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(requestFetchStaticContent);

    if (response) {
      const { data, status } = response;
      if (status === window.code["200"].code) {
        yield put(storeStaticContent(data.data));
      }
      yield put(request_loader({ load: false }));
    }
  } catch (error) {
    yield put(request_loader({ load: false }));
    console.log(error);
  }
}

export function* watchFetchStaticContent() {
  yield takeEvery(loadStaticContent.load, handleFetchStaticContent);
}

export function* handleUpdateCm({ payload }) {
  const { u_cm, navigate } = payload;
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(requestUpdateContents, u_cm);
    yield put(request_loader({ load: false }));
    if (response) {
      const { data, status } = response;
      if (status === window.code["200"].code) {
        yield put(update_cm_success(data.data));
        navigate(ADMINPATHS.APPPATH + ADMINPATHS.CONTENT_MANAGEMENT);
      }
      yield put(
        set_message({
          error_code: data?.msg,
          code: constantTexts.SUCCESS,
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchUpdateTac() {
  yield takeEvery(updateTac.load, handleUpdateCm);
}
