import proplistadmin from "./proplist";
import userlistadmin from "./userlist";
import settingadmin from "./setting";
import listing_request from "./listing-request";
import LeaseDocAdmin from './LeaseDocAdmin';
import business_admins from "./BusinessAdmins";
import featured_history from "./FeaturedHistory";
import background_check from "./BackgroundCheck";

const adminReducer = {
  proplistadmin,
  userlistadmin,
  settingadmin,
  listing_request,
  LeaseDocAdmin,
  business_admins,
  featured_history,
  background_check
};

export default adminReducer;
