import { allLeaseSignors } from "../../ActionTypes";
const data = {
    loading: true,
    leaseSignors: [],
    error: "",
  };
const profileData = (state, action) => {
    switch (action.type) {
        case allLeaseSignors.success:
            return {...state, loading: false, leaseSignors:action.payload }; 
        default:
            return {...state}
    }
}
export default profileData;