import { propertyFeatureAmount } from "../../ActionTypes";
const data = {
    loading: true,
    featuredProperty : [],
    error: "",
  };

const propertyFeatured = (state = data, action) => {
    switch (action.type) {
        // case propertyFeatureAmount.load:
        //     return {...state, ...action.payload}
        case propertyFeatureAmount.success:
            return {...state, ...action.payload}
        default:
            return {...state}
    }
};
export default propertyFeatured;