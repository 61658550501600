import { call, put, takeEvery } from "redux-saga/effects";
import error_messages from "../../../../Constants/error-messages";
import { request_loader } from "../../../Actions/loader";
import { set_message } from "../../../Actions/message";
import { fetch_stripe_payment_methods, fetch_stripe_payment_methods_success } from "../../../Actions/stripe/bank-info";
import { stripePaymentMethods } from "../../../ActionTypes";
import { cardAction } from "../../../ActionTypes/stripe";
import { getPaymentMethods, requestDeleteSource } from "../../Requests/stripe/card";

export function* handleFetchStripePaymentMethods() {
  try {
    yield put(request_loader({ load: true }));
    const user_data = JSON.parse(localStorage.getItem("all_user_data"));
    if (user_data.stripe_customer_account) {
      const response = yield call(getPaymentMethods, user_data);
      if (response.data) {
        localStorage.setItem('cardAdded', true)
        yield put(fetch_stripe_payment_methods_success(response.data.data));
        yield put(request_loader({ load: false }));
      }
    } else {
      yield put(fetch_stripe_payment_methods_success([]));
    }
    yield put(request_loader({ load: false }));
  } catch (error) {
    yield put(request_loader({ load: false }));
    // yield put( fetch_stripe_payment_methods_success([]));
    // yield put(create_bank_infoError(error));
  }
}

export default function* watchFetchStripePaymentMethods() {
  yield takeEvery(stripePaymentMethods.load, handleFetchStripePaymentMethods);
}

export function* handleDeleteSource({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const { dispatch, source_type, source_id } = payload;
    const response = yield call(requestDeleteSource, { source_type, source_id });
    yield put(request_loader({ load: false }));
    if (response) {
      let { status } = response;
      if (status === window.code["200"].code) {
        yield put(
          set_message({
            error_code: source_type.charAt(0).toUpperCase() + source_type.replace("_", " ").slice(1) + error_messages["source_deleted_successfully"],
            code: "success",
            heading: window.code["200"].header,
          })
        );
        dispatch(fetch_stripe_payment_methods());
      }
    } else {
      yield put(
        set_message({
          error_code: "",
          code: "warning",
          heading: "Invalid Value",
        })
      );
    }
  }
  catch (error) {
    console.log(error);
  }
}

export function* watchDeleteSource() {
  yield takeEvery(cardAction.deleteSource, handleDeleteSource);
}

