import { call, put, takeEvery } from "redux-saga/effects";
import {
  listAction,
  userAction,
  fetchRenterInfo,
} from "../../../ActionTypes/admin";
import error_messages from "../../../../Constants/error-messages";

import {
  requestUserList,
  requestUserUpdateStatusAdmin,
  requestgetCurrentRenterinfoAdmin,
  requestPublishApproveProperty,
} from "../../Requests/admin/userlist";
import { display_user_list } from "../../../Actions/admin/userlist";
import { request_loader } from "../../../Actions/loader";
import { set_message } from "../../../Actions/message";
import { fetch_user_detail_success } from "../../../Actions/create-user-details";
import { load_review_details } from "../../../Actions/create-new-list";
export function* handleUserList({ payload }) {
  try {
    const {
      initial,
      fetchPage,
      page,
      lists,
      type,
      search_by,
      search,
      iso_code,
    } = payload;

    let req = {};
    if (type && type !== "US") req.type = type;
    if (page) req.page = page;
    if (search_by && search) req[search_by] = search;
    if (search_by === "mobile") req["iso_code"] = iso_code;

    const response = yield call(requestUserList, req);
    if (response) {
      const { status } = response;
      if (status === window.code["200"].code) {
        const { data, options } = response.data;
        const params = {
          initial: false,
          lists: lists ? lists.concat(data) : data,
        };

        if (type !== "") params.type = type;
        if (search_by) params.search_by = search_by;
        if (search) params.search = search;
        if (search_by === "mobile") params.iso_code = iso_code;
        if (fetchPage === true) params.totalPages = options.totalDocs;
        if (options && options.nextPage) params.page = options.nextPage;
        yield put(display_user_list(params));
      }
    }
  } catch (error) {
    console.log(error);
  }
}
export function* watchUserListAdmin() {
  yield takeEvery(listAction.loadUser, handleUserList);
}
export function* handleUserUpdateStatusAdmin({ payload }) {
  try {
    const { user_id, _id, status, orginalDetails } = payload;
    yield put(request_loader({ load: true }));
    const response = yield call(requestUserUpdateStatusAdmin, {
      _id: user_id ? user_id : _id,
      status: status,
    });
    yield put(request_loader({ load: false }));
    if (response) {
      const { status, data } = response;
      if (status === window.code["200"].code) {
        let module = {
          error_code: data.msg,
          code: "success",
          heading: "success",
        };
        yield put(set_message(module));
        if (orginalDetails) {
          orginalDetails.user.status = data.data.status;
          yield put(fetch_user_detail_success(orginalDetails));
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handlerenterInfo({ payload }) {
  try {
    const { _id } = payload;
    yield put(request_loader({ load: true }));
    const response = yield call(requestgetCurrentRenterinfoAdmin, {});
    yield put(request_loader({ load: false }));
    if (response) {
      const { status, data } = response;
      if (status === window.code["200"].code) {
        let module = {
          error_code: data.msg,
          code: "success",
          heading: "success",
        };
        // yield put(set_message(module));
        // if(orginalDetails) {

        //   orginalDetails.user.status = data.data.status;
        //   yield put(fetch_user_detail_success(orginalDetails));
        // }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

const setMessageFunc = () => {
  return set_message({
    error_code: error_messages["publish_error"],
    code: "warning",
  })
};

export function* handlePublishApprovePropertyAdmin({ payload }) {
  try {
    const { property_id } = payload;
    yield put(request_loader({ load: true }));
    const response = yield call(requestPublishApproveProperty, {
      property_id: property_id,
    });
    yield put(request_loader({ load: false }));
    if (response) {
      const { status, data } = response;
      if (status === window.code["200"].code) {
        let module = {
          error_code: data.msg,
          code: "success",
          heading: "success",
        };
        yield put(set_message(module));
        if (property_id) {
          yield put(load_review_details(property_id));
        }
      }
    }
    else {
      yield put(setMessageFunc());
    }
  } catch (error) {
    yield put(request_loader({ load: false }));
    yield put(setMessageFunc());
  }
}

export function* watchUserUpdateStatusAdmin() {
  yield takeEvery(userAction.updateStatus, handleUserUpdateStatusAdmin);
}
export function* watchTogetCurentRenterAdmin() {
  yield takeEvery(fetchRenterInfo.load, handleUserUpdateStatusAdmin);
}
export function* watchTogetPublishApprovePropAdmin() {
  yield takeEvery(userAction.publishApproveProp, handlePublishApprovePropertyAdmin);
}
