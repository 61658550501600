import { call, put, takeEvery } from "redux-saga/effects";
import {
  requestPropertyFeaturedAmount,
  requestPayPropertyFeaturedAmount,
} from "../../Requests/owner/owner-listing";
import { featureAmountPayment, propertyFeatureAmount } from "../../../ActionTypes";
import {
  setPropertyFeatureAmount
} from "../../../Actions/Owner/owner-listing";
import { request_loader } from "../../../Actions/loader";
import { COMMON_PATHS, OWNER_PATHS } from "../../../../config/Owner-And-NewList_Paths";

export function* handlePropertyFeaturedAmount({ payload }) {
  try {
    const {values, navigate} = payload;
    yield put(request_loader({ load: true }));
    const response = yield call(requestPropertyFeaturedAmount, values);
    yield put(request_loader({ load: false }));
    if (response) {
      const {data,status} = response;
      if(status===window.code['200'].code){
        yield put(setPropertyFeatureAmount(data))
        navigate(`${COMMON_PATHS.APP1+OWNER_PATHS.FEATURE_LISTING_PAY+values.property_id}`);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchPropertyFeaturedAmount() {
  yield takeEvery(propertyFeatureAmount.load, handlePropertyFeaturedAmount);
}

export function* handlePayPropertyFeaturedAmount({ payload }) {
  try {
    const {values, navigate} = payload;
    yield put(request_loader({ load: true }));
    const response = yield call(requestPayPropertyFeaturedAmount, values);
    yield put(request_loader({ load: false }));
    if (response) {
      if(response?.data?.data?.paymentStatus==='pending'|| response?.data?.data?.paymentStatus==='success'|| response?.data?.data?.paymentStatus==='succeeded'){
        localStorage.setItem('featuredCardPayment',true)
      }
      const {data,status} = response;
      if(status===window.code['200'].code){
        yield put(setPropertyFeatureAmount(data))
        navigate(`${COMMON_PATHS.APP1+OWNER_PATHS.LISTING_FEATURED+values.property_id}`);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchPayPropertyFeaturedAmount() {
  yield takeEvery(featureAmountPayment.load, handlePayPropertyFeaturedAmount);
}