import axios from "axios";
import { apiConstants } from "../../../Constants/api_constants";

export const requestFetchStaticContent = () => {
  return axios.get(apiConstants.GET_STATIC_CONTENTS);
};

export const requestFetchFaq = () => {
  return axios.get(apiConstants.GET_FAQ);
};
//update contents
export const requestUpdateContents = (data) => {
  let body = {
    title: data.title,
    content: data.content,
  };
  return axios.put(`${apiConstants.UPDATE_CONTENT}${data.slug}/`, { ...body });
};

//create Faq
export const requestCreateFaq = (data) => {
  return axios.post(`${apiConstants.CREATE_FAQ}`, { ...data });
};

//update Faq
export const requestUpdateFaq = (_id, data) => {
  return axios.put(`${apiConstants.UPDATE_FAQ}${_id}`, { ...data });
};

//delete Faq
export const requestDeleteFaq = (id) => {
  return axios.delete(`${apiConstants.DELETE_FAQ}${id}`);
};
