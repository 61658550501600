import { call, put, takeEvery } from "redux-saga/effects";
import {
  listAction,
  listFeaturedHistory,
  propAction,
} from "../../../ActionTypes/admin";
import {
  requestFeaturedHistory,
  requestPropertyList,
  requestPropStatusApprove,
  requestPropStatusReject,
} from "../../Requests/admin/proplist";
import {
  display_prop_list,
  store_featured_history,
} from "../../../Actions/admin/proplist";
import { request_loader } from "../../../Actions/loader";
import { set_message } from "../../../Actions/message";
import error_messages from "../../../../Constants/error-messages";
import { ADMINPATHS } from "../../../../config/webPathAdmin";

export function* handlePropList({ payload }) {
  try {
    const {
      initial,
      fetchPage,
      lists,
      page,
      user_id,
      displayStatusMessage,
      typeOne,
    } = payload;
    let req = {};
    if (page) req.page = page;
    if (user_id !== "reset") req.user_id = user_id;
    if (typeOne && !["deleted", "featured"].includes(typeOne))
      req.status = typeOne;
    if (typeOne && ["deleted", "featured"].includes(typeOne)) req[typeOne] = 1;
    const response = yield call(requestPropertyList, req);

    if (response) {
      const { status } = response;
      if (status === window.code["200"].code) {
        const { data, options } = response.data;
        const params = {
          initial: false,
          page,
          lists: lists ? lists.concat(data) : data,
        };
        if (user_id && user_id !== "rest") params.user_id = user_id;
        if (user_id === "rest") params.user_id = "";
        if (fetchPage === true) params.totalPages = options.totalDocs;
        if (options && options.nextPage) params.page = options.nextPage;
        yield put(display_prop_list(params));
        if (displayStatusMessage) {
          yield put(
            set_message({
              error_code: error_messages[displayStatusMessage.msg],
              code: displayStatusMessage.code,
              heading: payload.status,
            })
          );
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}
export function* watchPropListAdmin() {
  yield takeEvery(listAction.loadProp, handlePropList);
}

export function* handlePropApproveAdmin({ payload }) {
  try {
    const propStatus=window.location.search.split("&")[0];
    const { property_id, from, navigate } = payload;
    yield put(request_loader({ load: true }));
    const req = {
      property_id: property_id,
    };

    const response = yield call(requestPropStatusApprove, req);
    yield put(request_loader({ load: false }));

    if (response) {
      const { status } = response;
      if (status === window.code["200"].code) {
        yield put(
          set_message({
            error_code: "Property status updated successfully.",
            code: "200",
            heading: "property_update_status",
          })
        );
      }
    }
    if (from && from === "listingDeatils") {
      return window.location.reload();
    }

    navigate(ADMINPATHS.APPPATH + ADMINPATHS.LISTING+propStatus + "&approve=1");
    return window.location.reload();
  } catch (error) {
    console.log(error);
  }
}
export function* watchPropApproveAdmin() {
  yield takeEvery(propAction.propApr, handlePropApproveAdmin);
}

export function* handlePropRejectAdmin({ payload }) {
  try {
    const propStatus=window.location.search.split("&")[0];
    const { req } = payload;
    const { navigate } = req;
    yield put(request_loader({ load: true }));
    const response = yield call(requestPropStatusReject, req);
    yield put(request_loader({ load: false }));
    if (response) {
      const { status } = response;
      if (status === window.code["200"].code) {
        yield put(
          set_message({
            error_code: "Property status updated successfully.",
            code: "200",
            heading: "property_update_status",
          })
        );
      }
    }
    if (req.from && req.from === "listingDeatils") {
      return window.location.reload();
    }
    navigate(ADMINPATHS.APPPATH + ADMINPATHS.LISTING+propStatus + "&reject=1");
    return window.location.reload();
  } catch (error) {
    console.log(error);
  }
}
export function* watchPropRejectAdmin() {
  yield takeEvery(propAction.propRej, handlePropRejectAdmin);
}

export function* handleFeaturedHistory({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(requestFeaturedHistory, payload);
    yield put(request_loader({ load: false }));
    if (response) {
      const { data, status } = response;
      if (status === window.code["200"].code) {
        yield put(store_featured_history(data.data.docs));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchFeaturedHistory() {
  yield takeEvery(listFeaturedHistory.load, handleFeaturedHistory);
}
