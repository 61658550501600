import { put, takeEvery } from "redux-saga/effects";
import { filterAction } from "../../ActionTypes";
import {advance_query_redirector} from '../../../helpers/CommonHelper';
import { request_loader } from "../../Actions/loader";

export function* handleFilterRecord({payload}){
 const {location,navigate,url,request} = payload;
  advance_query_redirector(location,navigate,url,{...request});
  yield put(request_loader({ load: false }));
}

export default function* watchFilterRecord() {
  yield takeEvery(filterAction.executeFilter, handleFilterRecord);
}
