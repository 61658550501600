import { authAction } from "../ActionTypes";
let init = localStorage.getItem('role')||"common";
const data = {
  user:[],
  role:init,
  userBackgroundInfo:{}
};
const auth = (state = data, action) => {
  switch (action.type) {
    case authAction.reset:
      return { user:[],role:init };
    case authAction.success:
      return {...action.payload};
    case authAction.getBackGroundInfoReset:
      return {...state,userBackgroundInfo:{}};  
    case authAction.getBackGroundInfo:
      return {...state,userBackgroundInfo:action.payload};   
    default:
      return { ...state };
  }
};
export default auth;
