import axios from "axios";
import { apiConstantsAdmin } from "../../../../Constants/api_constant_admin";

export function requestUserList(data) {
  return axios({
    method: 'get',
    url: apiConstantsAdmin.LOAD_USER_ADMIN,
    params:{...data}
  });
}

export function requestUserUpdateStatusAdmin(data) {
  return axios({
    method: 'PUT',
    url: apiConstantsAdmin.UPDATE_USER_STATUS_ADMIN,
    data:{...data},
    validateStatus: () => true
  });
}
export function requestPublishApproveProperty(data) {
  return axios({
    method: 'PUT',
    url: apiConstantsAdmin.PUBLISH_APPROVED_PROPERTY,
    data: data
  });
}
export function requestgetCurrentRenterinfoAdmin(data) {
  return axios({
    method: 'GET',
    url: apiConstantsAdmin.LOAD_PROPERTY_CURRENT_RENTER+data,
    validateStatus: () => true
  });
}

