import axios from 'axios';
import { apiConstants } from '../../../Constants/api_constants';

export function requestUploadAction(data) {
    return axios({
      method: 'POST',
      url: apiConstants.ADMIN_MANAGE_LEASE_DOCUMENT,
      data: data,
      validateStatus: () => true
    });
 }

 export function requestListSignorDocs(data,country) {
  return axios({
    method: 'GET',
    url: apiConstants.ADMIN_MANAGE_LEASE_DOCUMENT+country,
    params: {...data},
    validateStatus: () => true
  });
}

export function requestDeleteSignorDocs(data) {
  return axios({
    method: 'DELETE',
    url: apiConstants.ADMIN_MANAGE_LEASE_DOCUMENT+data,
    validateStatus: () => true
  });
}

export function requestUploadMedia(data, url) {
  return axios({
    method: 'POST',
    url: url,
    data: data,
    validateStatus: () => true
  });
}