import { bookingAction, promocodeActions } from "../ActionTypes";

export const bookingInitiate = (data) => ({
    type: bookingAction.create,
    payload: data,
  });

  export const bookingAddOccupants = (data) => ({
    type: bookingAction.occupants,
    payload: data,
  });

  export const bookingListOwner = (data) =>({
    type: bookingAction.listOwner,
    payload: data,
  });

  export const bookingUpdateStatusOwner = (data) =>({
    type: bookingAction.updateStatusOwner,
    payload: data,
  })
  
  export const bookingCancelRenter = (data) =>({
    type: bookingAction.cancelBooking,
    payload: data,
  })

  export const bookingListAdmin = (data) =>({
    type: bookingAction.listAdmin,
    payload: data,
  });

  export const bookingListAdminDisplay = (data) =>({
    type: bookingAction.displayListAdmin,
    payload: data,
  });

  export const bookingListOwnerDisplay = (data) =>({
    type: bookingAction.displayListOwner,
    payload: data,
  })

  export const bookingUpdateUnique = (data) =>({
    type: bookingAction.listOnlyUpdate,
    payload: data,
  })

  export const bookingDetail = (data) =>({
    type: bookingAction.loadDetail,
    payload: data,
  });

  export const bookingDetailDisplay = (data) =>({
    type: bookingAction.displayDetail,
    payload: data
  })

  export const bookingApplyPromocode = (data) => ({
    type: promocodeActions.apply,
    payload: data
  })

  export const bookingRemovePromocode = (data) => ({
    type: promocodeActions.remove,
    payload: data
  })


  export const bookingPromocodeApplied = (data) => ({
    type: promocodeActions.applied,
    payload: data
  })

  export const bookingCancelHomeOwner = (data) =>({
    type: bookingAction.cancelBookingHomeOwner,
    payload: data,
  })