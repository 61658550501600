import { call, put, takeEvery } from "redux-saga/effects";
import { request_loader } from "../../../Actions/loader";
import {
  create_leaving_review_error,
  create_leaving_review_success,
} from "../../../Actions/Owner/LeavingReview";
import { createLeavingReview } from "../../../ActionTypes";
import { requestCreateLeavigReviews } from "../../Requests/owner/LeavingReview";

export function* handleCreateLeavingReview({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(requestCreateLeavigReviews, payload);
    if (response) {
      yield put(request_loader({ load: false }));
      const { data, status } = response;
      if (status === window.code["200"].code) {
        yield put(create_leaving_review_success(data.data));
      } else {
        yield put(create_leaving_review_error(data.data));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchCreateLeavingReview() {
  yield takeEvery(createLeavingReview.load, handleCreateLeavingReview);
}
