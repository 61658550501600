import {
    fetchBackgroundCheckList,
  } from "../../ActionTypes/admin";
  
  export const load_backgroundCheck = (param) => ({
    type: fetchBackgroundCheckList.load,
    payload: param,
  });
  export const load_backgroundCheck_success = (data) => ({
    type: fetchBackgroundCheckList.success,
    payload: data,
  });
  export const load_backgroundCheck_error = (data) => ({
    type: fetchBackgroundCheckList.failure,
    payload: data,
  });