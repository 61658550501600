import {
  createLeaseSignor,
  deleteLeaseSignor,
  fetchExistLeaseSignors,
  loadCountriesWithFlag,
} from "../ActionTypes";
const data = {
  loading: true,
  leaseSignor: [],
  error: "",
  leaseSignorInfoFinished: false,
};

const lease_signor = (state = data, action) => {
  switch (action.type) {
    case createLeaseSignor.load:
      return { ...state, loading: true, leaseSignorInfoFinished: false };
    case createLeaseSignor.success:
      return {
        ...state,
        loading: false,
        leaseSignorInfoFinished: true,
        leaseSignor: action.payload,
      };
    case createLeaseSignor.failure:
      return {
        ...state,
        loading: false,
        leaseSignorInfoFinished: false,
        error: action.payload,
      };
    // delete leas signor
    case deleteLeaseSignor.load:
      return { ...state, loading: true, lsDeleted: false };
    case deleteLeaseSignor.success:
      return {
        ...state,
        loading: false,
        lsDeleted: true,
        leaseSignor: action.payload,
      };
    case deleteLeaseSignor.failure:
      return {
        ...state,
        loading: false,
        lsDeleted: false,
        error: action.payload,
      };
    //load country with flag
    case loadCountriesWithFlag.load:
      return { ...state, loading: true };
    case loadCountriesWithFlag.success:
      return {
        ...state,
        loading: false,
        countriesWithFlag: action.payload,
      };
    case loadCountriesWithFlag.failure:
      return {
        ...state,
        loading: false,
        error: action.payload,
        err: "",
      };

    //fetch exist Lease signors
    case fetchExistLeaseSignors.load:
      return { ...state, loading: true };
    case fetchExistLeaseSignors.success:
      return {
        ...state,
        loading: false,
        existLss: action.payload,
      };
    case fetchExistLeaseSignors.failure:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return { ...state };
  }
};

export default lease_signor;
