import axios from "axios";
import { apiConstants } from "../../../Constants/api_constants";

export function getEvictionDetails(booking_id) {
  return axios({
    method: "GET",
    url: apiConstants.GET_EVICTION_DETAILS + booking_id,
    validateStatus: () => true,
  });
}

export function getEvictionAdminDetails(eviction_id) {
  return axios({
    method: "GET",
    url: apiConstants.GET_ADMIN_EVICTION_DETAILS + eviction_id,
    validateStatus: () => true,
  });
}

export function evictionList(data) {
  return axios({
    method: 'get',
    url: apiConstants.LIST_EVICTION,
    params:{...data}
  });
}

export function getTerminationDetailsRequest(booking_id) {
  return axios({
    method: "GET",
    url: apiConstants.GET_TERMINATION_DETAILS + booking_id,
    validateStatus: () => true,
  });
}