import {
  deleteListingRequest,
  listingRequestDetails,
  loadListingRequest,
} from "../../ActionTypes/admin";
const data = {
  loading: true,
  listingRequest: [],
  error: "",
};
const getItemIndex = (array, file) => {
  return array.findIndex((d) => d._id === file._id);
};
const listing_request = (state = data, action) => {
  switch (action.type) {
    case loadListingRequest.load:
      return { ...state, loading: true };
    case loadListingRequest.success:
      return { ...state, loading: false, listingRequest: action.payload };
    case loadListingRequest.failure:
      return { ...state, loading: false, error: action.payload };
    case deleteListingRequest.load:
      return { ...state, loading: true, listingReqestDeleted: false };
    case deleteListingRequest.success:
      let index = getItemIndex(state.listingRequest.lists, action.payload);
      state.listingRequest.lists.splice(index, 1);
      return {
        ...state,
        loading: false,
        listingRequest: state.listingRequest,
        listingReqestDeleted: true,
      };
    case deleteListingRequest.failure:
      return {
        ...state,
        loading: false,
        listingReqestDeleted: false,
        error: action.payload,
      };
    case listingRequestDetails.load:
      return { ...state, loading: true };
    case listingRequestDetails.success:
      return {
        ...state,
        loading: false,
        listingRequestDetails: action.payload,
      };
    case listingRequestDetails.failure:
      return { ...state, loading: false, error: action.payload };
    default:
      return { ...state };
  }
};

export default listing_request;
