import {
  createBusinessAdmin,
  loadBusinessAdmins,
  updateStatusBusinessAdmin,
} from "../../ActionTypes/admin";
const data = {
  loading: true,
  businessAdmins: [],
  error: "",
};
const business_admins = (state = data, action) => {
  switch (action.type) {
    case loadBusinessAdmins.load:
      return { ...state, loading: true };
    case loadBusinessAdmins.success:
      return { ...state, loading: false, ...action.payload };
    case loadBusinessAdmins.failure:
      return { ...state, loading: false, error: action.payload };

    case createBusinessAdmin.load:
      return { ...state, loading: true };
    case createBusinessAdmin.success:
      return {
        ...state,
        loading: false,
        newAdminCreated: true,
        lists: [action.payload, ...state.lists],
      };
    case createBusinessAdmin.failure:
      return {
        ...state,
        loading: false,
        newAdminCreated: false,
        error: action.payload,
      };

    case updateStatusBusinessAdmin.load:
      return { ...state, loading: true };
    case updateStatusBusinessAdmin.success:
      return { ...state, loading: false };
    case updateStatusBusinessAdmin.failure:
      return { ...state, loading: false, error: action.payload };
    default:
      return { ...state };
  }
};

export default business_admins;
