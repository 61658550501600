import { terminationActions } from "../ActionTypes";
import { initial_state } from "../../Constants/Common";

const data = {
  terminationDetails: {}
}
const Termination = (state = data, action) => {
  switch (action.type) {
    case terminationActions.store:
      return { ...state, terminationDetails: action.payload }
    case terminationActions.display:
      return { ...state, ...action.payload }
    default:
      return { ...state };
  }
};
export default Termination;
