import axios from "axios";
import { apiConstants } from "../../../../Constants/api_constants";

export const requestCreateLeavigReviews = (data) => {
  return axios.post(apiConstants.CREATE_LEAVING_REVIEW, data);
};

export const requestGetAllReviews = (data) => {
  return axios.post(apiConstants.GET_ALL_REVIEWS, { params: data });
};
