import { call, put, takeEvery } from "redux-saga/effects";
import { setCheckinDetails } from "../../Actions/Checkin";
import { request_loader } from "../../Actions/loader";
import { checkInActions } from "../../ActionTypes";
import { getCheckinDetails } from "../Requests/checkinRequest";


export function* handleCheckinDetail({ payload }) {
  try {
    const { id } = payload;
    yield put(request_loader({ load: true }));
    const response = yield call(getCheckinDetails, id);
   
    if (response) {
      const { status, data } = response;
      if (status === window.code["200"].code) {
        yield put(setCheckinDetails(data.data));
        yield put(request_loader({ load: false }));
      }
    }else{
      yield put(request_loader({ load: false }));
    }
  } catch (error) {
    console.log(error);
    yield put(request_loader({ load: false }));
  }
}

export function* watchCheckinDetail() {
  yield takeEvery(checkInActions.fetch, handleCheckinDetail);
}