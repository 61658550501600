import axios from "axios";
import { apiConstants } from "../../../Constants/api_constants";

export function promocodeCreate(data) {
  return axios({
    method: "POST",
    url: apiConstants.CREATE_PROMOCODE,
    data: data,
    validateStatus: () => true,
  });
}


export function promocodeList(data) {
  return axios({
    method: 'get',
    url: apiConstants.LIST_PROMOCODE,
    params:{...data}
  });
}

export function promocodeUpdate(data,id) {
  return axios({
    method: "PUT",
    url: apiConstants.PROMOCODE_ACTION+id,
    data: data,
    validateStatus: () => true,
  });
}

export function promocodeStatusUpdate(id,status) {
  return axios({
    method: "PUT",
    url: apiConstants.PROMOCODE_ACTION+id+'/status',
    data: {status:status},
    validateStatus: () => true,
  });
}

export function promocodeDelete(id) {
  return axios({
    method: "DELETE",
    url: apiConstants.PROMOCODE_ACTION+id,
    validateStatus: () => true,
  });
}