// send message
export const sendMessage={
    load: "SEND_MESSAGE",
    success: "SEND_MESSAGE_SUCCESS",
    failure: "SEND_MESSAGE_ERROR",
  }

  export const payPenalty={
    load: "PAY_PENALTY",
    success: "PAY_PENALTY_SUCCESS",
    failure: "PAY_PENALTY_ERROR",
  }