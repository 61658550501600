import { call, put, takeEvery } from "redux-saga/effects";
import { bookingAction, promocodeActions } from "../../ActionTypes";
import {
  bookingInitiateRequest,
  bookingAddOccupants,
  bookProperty,
  bookingAdminList,
  bookingOwnerList,
  bookingOwnerStatusUpdate,
  bookingDetail,
  bookingCancelRenter,
  applyPromocodeRequest,
  payOwnerCancellatonCharge,
  cancelOwnerBooking,
  applyListingPayoutPromocodeRequest,
  applyListingHotelPromocodeRequest,
  deleteListingPromocodeRequest,
} from "../Requests/bookingRequest";
import {
  bookingListAdminDisplay,
  bookingDetailDisplay,
  bookingPromocodeApplied,
} from "../../Actions/booking";
import {
  COMMON_PATHS,
  OWNER_PATHS,
} from "../../../config/Owner-And-NewList_Paths";
import { request_loader } from "../../Actions/loader";
import { set_message } from "../../Actions/message";
import error_messages from "../../../Constants/error-messages";
import { differenceBetweenTwoDates } from "../../../helpers/CommonHelper";
import { listingPromocodeApplied } from "../../Actions/promocode";

export function* handleBookingInitiate({ payload }) {
  try {
    const { request, navigate } = payload;
    yield put(request_loader({ load: true }));
    const response = yield call(bookingInitiateRequest, request);
    yield put(request_loader({ load: false }));
    if (response) {
      const { status, data } = response;
      if (status === window.code["200"].code) {
        window.parent.setToken({
          booking_detail: JSON.stringify({
            ...data.data,
            isbackgroundapplied: request.isbackgroundcheck,
          }),
        });
        let daysBookedFor = differenceBetweenTwoDates(
          data.data.check_in_date,
          data.data.check_out_date
        );
        if (daysBookedFor >= 30) {
          navigate(COMMON_PATHS.BOOKING_LEASE_AGREEMENT);
        } else {
          navigate(COMMON_PATHS.BOOKING_PAYMENT);
      }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchBookingInitiate() {
  yield takeEvery(bookingAction.create, handleBookingInitiate);
}

export function* handleBookingOccupantsAdd({ payload }) {
  try {
    const {
      request,
      id,
      sourceId,
      appliedforcheck,
      navigate,
      candidate_id,
      invitation_id,
      skip_background_check
    } = payload;
    yield put(request_loader({ load: true }));
    const response = yield call(bookingAddOccupants, request, id);

    if (response) {
      const { status, data } = response;
      if (status === window.code["200"].code) {
        let req = {};
        req.property_token = data.data.property_token;
        req.booking_token = data.data.booking_token;
        req.source_id = sourceId;
        req.candidate_id = candidate_id;
        req.invitation_id = invitation_id;
        req.skip_background_check = skip_background_check;
        const result = yield call(bookProperty, req);
        if (result.status === window.code["200"].code) {
          let details = localStorage.getItem("booking_detail");
          let record = JSON.parse(details);
          record.number_of_occupants =
            parseInt(data.data.name.length) +
            parseInt(data.data.number_of_children) +
            parseInt(data.data.number_of_infants) +
            parseInt(data.data.number_of_pets);
          window.parent.setToken({ booking_detail: JSON.stringify(record) }, [
            "isbackgroundapplied",
          ]);
          navigate(
            COMMON_PATHS.PROPERTY_BOOKED
            // code commented to not redirect user to checkr invitation page to complete background check
            // appliedforcheck === true
            //   ? COMMON_PATHS.PROPERTY_BOOKED
            //   : COMMON_PATHS.REACTIFRAME +
            //       "/" +
            //       result.data.data.booking.booking_id +
            //       "/" +
            //       invitation_id
          );
        }
      }
    }
    yield put(request_loader({ load: false }));
  } catch (error) {
    console.log(error);
  }
}

export function* watchBookingOccupantsAdd() {
  yield takeEvery(bookingAction.occupants, handleBookingOccupantsAdd);
}

export function* handleBookingAdminList({ payload }) {
  try {
    const { select, initial, fetchPage, page, user_id, owner_id, lists } =
      payload;
    let req = {};
    if (select) req.status = select;

    if (page) req.page = page;
    if (user_id) req.user_id = user_id;
    if (owner_id) req.owner_id = owner_id;
    const response = yield call(bookingAdminList, req);
    if (response) {
      const { status } = response;
      if (status === window.code["200"].code) {
        const { data, options } = response.data;

        const params = {
          select,
          initial: false,
          lists: lists ? lists.concat(data) : data,
        };

        if (fetchPage === true) params.totalPages = options.totalDocs;
        if (options && options.nextPage) params.page = options.nextPage;
        if (user_id) params.user_id = user_id;

        yield put(bookingListAdminDisplay(params));
      }
    }
  } catch (error) {}
}

export function* watchBookingAdminList() {
  yield takeEvery(bookingAction.listAdmin, handleBookingAdminList);
}

export function* handleBookingOwnerList({ payload }) {
  try {
    const { select, initial, fetchPage, page, lists } = payload;
    yield put(request_loader({ load: initial }));
    let req = {};

    if (select) req.status = select;
    if (page) req.page = page;

    const response = yield call(bookingOwnerList, req);

    yield put(request_loader({ load: false }));
    if (response) {
      const { status } = response;

      if (status === window.code["200"].code) {
        const { data, options } = response.data;

        const params = {
          select,
          initial: false,
          lists: lists ? lists.concat(data) : data,
        };

        if (fetchPage === true) params.totalPages = options.totalDocs;
        if (options && options.nextPage) params.page = options.nextPage;

        yield put(bookingListAdminDisplay(params));
      }
    }
  } catch (error) {}
}

export function* watchBookingOwnerList() {
  yield takeEvery(bookingAction.listOwner, handleBookingOwnerList);
}

export function* handleBookingOwnerStatusUpdate({ payload }) {
  try {
    const { bookingId, bookingStatus, comment } = payload;
    yield put(request_loader({ load: true }));

    const response = yield call(
      bookingOwnerStatusUpdate,
      bookingId,
      bookingStatus,
      comment
    );

    yield put(request_loader({ load: false }));
    if (response) {
      const { status, data } = response;
      if (status === window.code["200"].code) {
        let module = {
          error_code: data.msg,
          code: "success",
          heading: "success",
          updated_data: data.data,
        };
        yield put(set_message(module));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchBookingOwnerStatusUpdate() {
  yield takeEvery(
    bookingAction.updateStatusOwner,
    handleBookingOwnerStatusUpdate
  );
}

export function* handleBookingDetail({ payload }) {
  try {
    const { id } = payload;
    yield put(request_loader({ load: true }));
    const response = yield call(bookingDetail, id);
    if (response) {
      const { status, data } = response;
      if (status === window.code["200"].code) {
        yield put(bookingDetailDisplay(data.data));
      }
      yield put(request_loader({ load: false }));
    }
  } catch (error) {
    yield put(request_loader({ load: false }));
    console.log(error);
  }
}

export function* watchBookingDetail() {
  yield takeEvery(bookingAction.loadDetail, handleBookingDetail);
}

export function* handleBookingCancelRenter({ payload }) {
  try {
    const {
      bookingId,
      bookingStatus,
      comment,
      dispatch,
      actn,
      search,
      page,
      order_by,
      order,
      select,
    } = payload;
    yield put(request_loader({ load: true }));

    const response = yield call(
      bookingCancelRenter,
      bookingId,
      bookingStatus,
      comment
    );

    yield put(request_loader({ load: false }));
    if (response) {
      const { status, data } = response;
      if (status === window.code["200"].code) {
        let module = {
          error_code: data.msg,
          code: "success",
          heading: "success",
        };
        yield put(set_message(module));
        if (dispatch) {
          dispatch(
            actn({
              search,
              page: parseInt(page),
              order_by,
              order,
              select,
              initial: false,
            })
          );
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchBookingCancelRenter() {
  yield takeEvery(bookingAction.cancelBooking, handleBookingCancelRenter);
}

export function* handleBookingApplyPromocode({ payload }) {
  try {
    const { coupon } = payload;
    yield put(request_loader({ load: true }));
    const response = yield call(applyPromocodeRequest, coupon);
    yield put(request_loader({ load: false }));
    if (response) {
      const { status, data } = response;
      if (status === window.code["200"].code) {
        yield put(bookingPromocodeApplied(data.data));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchBookingApplyPromocode() {
  yield takeEvery(promocodeActions.apply, handleBookingApplyPromocode);
}

export function* handleCancelOwnerBooking({ payload }) {
  try {
    const { values, comment, booking_id, navigate } = payload;
    yield put(request_loader({ load: true }));
    const response = yield call(payOwnerCancellatonCharge, values);
    if (response) {
      const { status, data } = response;
      if (status === window.code["200"].code) {
        const response2 = yield call(
          cancelOwnerBooking,
          booking_id,
          comment,
          data.data
        );
        if (response2 && response2.status === window.code["200"].code) {
          let module = {
            error_code: error_messages.booking_cancel_owner,
            code: "success",
            heading: "success",
          };
          yield put(set_message(module));
          yield put(request_loader({ load: false }));
          const redirectUrl =
            COMMON_PATHS.APP1 + OWNER_PATHS.OWNER_BOOKINGS + "/" + booking_id;
          localStorage.removeItem("owner_cancel_obj");
          navigate(redirectUrl);
        }
      }
    }
    yield put(request_loader({ load: false }));
  } catch (error) {
    console.log(error);
  }
}

export function* watchCancelOwnerBooking() {
  yield takeEvery(
    bookingAction.cancelBookingHomeOwner,
    handleCancelOwnerBooking
  );
}

export function* handleApplyListingPayoutPromocode({ payload }) {
  try {
    const { coupon, property_id } = payload;
    yield put(request_loader({ load: true }));
    const response = yield call(
      applyListingPayoutPromocodeRequest,
      coupon,
      property_id
    );
    yield put(request_loader({ load: false }));
    if (response) {
      const { status, data } = response;
      if (status === window.code["200"].code) {
        yield put(listingPromocodeApplied(data.data));
        let module = {
          error_code: error_messages["promocode_applied"],
          code: "success",
          heading: "success",
        };
        yield put(set_message(module));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchBookingApplyListingPayoutPromocode() {
  yield takeEvery(
    promocodeActions.apply_listing_payout,
    handleApplyListingPayoutPromocode
  );
}

export function* handleApplyListingHotelPromocode({ payload }) {
  try {
    const { coupon, property_id } = payload;
    yield put(request_loader({ load: true }));
    const response = yield call(
      applyListingHotelPromocodeRequest,
      coupon,
      property_id
    );
    yield put(request_loader({ load: false }));
    if (response) {
      const { status, data } = response;
      if (status === window.code["200"].code) {
        yield put(listingPromocodeApplied(data.data));
        let module = {
          error_code: error_messages["promocode_applied"],
          code: "success",
          heading: "success",
        };
        yield put(set_message(module));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchBookingApplyListingHotelPromocode() {
  yield takeEvery(
    promocodeActions.apply_listing_hotel,
    handleApplyListingHotelPromocode
  );
}

export function* handleDeleteListingPromocode({ payload }) {
  try {
    if (payload !== undefined) {
      const { property_id } = payload;

      yield put(request_loader({ load: true }));
      const response = yield call(deleteListingPromocodeRequest, property_id);

      if (response) {
        const { status, data } = response;
        if (status === window.code["200"].code) {
          let module = {
            error_code: error_messages["promocode_deleted"],
            code: "success",
            heading: "success",
          };
          yield put(set_message(module));
        }
        yield put(request_loader({ load: false }));
      }
    }
  } catch (error) {
    yield put(request_loader({ load: false }));
    console.log(error);
  }
}

export function* watchDeleteListingPromocode() {
  yield takeEvery(
    promocodeActions.remove_listing_promocode,
    handleDeleteListingPromocode
  );
}
