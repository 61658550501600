import axios from "axios";
import { apiConstantsAdmin } from "../../../../Constants/api_constant_admin";

export function requestPropertyList(data) {
  return axios({
    method: 'get',
    url: apiConstantsAdmin.LOAD_PROPERTY_ADMIN,
    params:{...data}
  });
}

export function requestPropStatusApprove(data) {
  return axios({
    method: 'PUT',
    url: apiConstantsAdmin.APPROVE_PROP_ADMIN,
    data:{...data},
    validateStatus: () => true
  });
}

export function requestPropStatusReject(data) {
  return axios({
    method: 'POST',
    url: apiConstantsAdmin.REJECT_PROP_ADMIN,
    data:data,
    validateStatus: () => true
  });
}

export function requestFeaturedHistory(data) {
  return axios({
    method: 'get',
    url: apiConstantsAdmin.GET_FEATURED_LIST +'/'+ data +'/index'
  })
}
