import { call, put, takeEvery } from "redux-saga/effects";
import { requestLoadHomePage, requestLoadSearchPage } from "../Requests/create-new-list";
import { loadHomePage } from "../../ActionTypes";
import {
  load_home_page_error,
  load_home_page_success,
  load_search_page_success,
  reset_search_page
} from "../../Actions/create-new-list";
import { request_loader } from "../../Actions/loader";

export function* handleLoadHomePage() {
  try {
    const response = yield call(requestLoadHomePage);
    const { data } = response;
    if (data) {
      yield put(load_home_page_success(data.data));
    } else {
      yield put(load_home_page_error(data));
    }
  } catch (error) {
    yield put(load_home_page_error(error));
  }
}
export function* handleLoadSearchPage({ payload }) {
  try {
    const {
      initial,
      fetchPage,
      page,
      lists,
      filterparams
    } = payload;
    let req = {};
    let params = {}
    if (page) req.page = page;
    for (let key in filterparams) {
      if (filterparams[key]) {
        req[key] = filterparams[key];
        params[key] = filterparams[key];
      }
    }
    if (req?.checkin === 'NaN-NaN-NaN' || req?.checkin === undefined) {
      delete req?.checkin
    } if (req?.checkout === 'NaN-NaN-NaN' || req?.checkout === undefined) {
      delete req?.checkout
    }
    if(req?.property_type) {
      delete req?.is_pet_allowed
    }
    if(req?.is_pet_allowed === '1') {
      delete req?.property_type
    }

    yield put(request_loader({ load: initial }));

    const response = yield call(requestLoadSearchPage, req);
    yield put(request_loader({ load: false }));
    if (response) {
      const { status } = response;
      if (status === window.code["200"].code) {
        const { data, options } = response.data;

        params.initial = false;
        params.lists = lists ? lists.concat(data) : data;

        params.totalPages = options.totalDocs;
        if (options && options.nextPage) params.page = options.nextPage;

        yield put(load_search_page_success(params));
      }
    }
  }
  catch (error) {
    console.log(error);
  }
}
export function* watchLoadHomePage() {
  yield takeEvery(loadHomePage.load, handleLoadHomePage);
}

export function* watchLoadSearchPage() {
  yield takeEvery(loadHomePage.searchLoad, handleLoadSearchPage);
}