import { call, put, takeEvery } from "redux-saga/effects";
import { request_loader } from "../../../Actions/loader";
import { set_message } from "../../../Actions/message";
import {
  requestCreateCardStripe,
  requestSetDefaultCard,
} from "../../Requests/stripe/card";
import {
  requestCreateBankStripe,
  requestCreateConnectAccount,
  requestSaveBankAccount,
} from "../../Requests/stripe/bank";
import { cardAction, bankAction } from "../../../ActionTypes/stripe";
import error_messages from "../../../../Constants/error-messages";
import { fetch_stripe_payment_methods } from "../../../Actions/stripe/bank-info";
import { getBackGroundInfo } from "../../../Actions/auth";

export function* handleCreateCard({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const { dispatch, card_token } = payload;
    const response = yield call(requestCreateCardStripe, { card_token });
    yield put(request_loader({ load: false }));
    if (response) {
      let { status } = response;
      if (status === window.code["200"].code) {
        yield put(
          set_message({
            error_code: error_messages["card_added_successfully"],
            code: "success",
            heading: window.code["200"].header,
          })
        );
        dispatch(fetch_stripe_payment_methods());
      }
    } else {
      yield put(
        set_message({
          error_code: "",
          code: "warning",
          heading: "Invalid Value",
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchCreateCard() {
  yield takeEvery(cardAction.createCard, handleCreateCard);
}

export function* handleCreateBank({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const { dispatch, bank_token } = payload;
    const response = yield call(requestCreateBankStripe, { bank_token });
    yield put(request_loader({ load: false }));
    if (response) {
      let { status } = response;
      if (status === window.code["200"].code) {
        yield put(
          set_message({
            error_code: error_messages["bank_added_successfully"],
            code: "success",
            heading: window.code["200"].header,
          })
        );
        dispatch(fetch_stripe_payment_methods());
      }
    } else {
      yield put(
        set_message({
          error_code: "",
          code: "warning",
          heading: "Invalid Value",
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchCreateBank() {
  yield takeEvery(bankAction.createBank, handleCreateBank);
}

export function* handlecreateConnect({ payload }) {
  try {
    let countryName = ''
    const { request, bankinfo, dispatch, onHide, open, showCanadaPopup } = payload;
    countryName = request?.country
    yield put(request_loader({ load: true }));
    const response = yield call(requestCreateConnectAccount, request);
    if (response) {
      yield put(request_loader({ load: false }));
      let { status, data } = response;
      if (response.status === window.code["200"].code) {
        // let { status,data } = response;
        //update all_user_data with new connect_account
        let userLocalStorage = JSON.parse(
          localStorage.getItem("all_user_data")
        );
        userLocalStorage["stripe_connect_account"] = data.data.id;
        localStorage.setItem("all_user_data", JSON.stringify(userLocalStorage));
        if (countryName !== '' && countryName === 'CA') {
          showCanadaPopup()
          return
        }
        onHide();
        open();

      }
      if (response.status === window.code["400"].code) {
        // const acc = data.data.id;
        // bankinfo.stripe_connect_account = acc;
        // yield put(getBackGroundInfo(bankinfo));

        yield put(
          set_message({
            error_code: data.data.message,
            code: "warning",
            heading: window.code["400"].header,
          })
        );
        dispatch(fetch_stripe_payment_methods());
      }
    } else {
      yield put(request_loader({ load: false }));
      yield put(
        set_message({
          error_code: "",
          code: "warning",
          heading: "Invalid Value",
        })
      );
    }
  } catch (error) {
    console.log(error);
    yield put(request_loader({ load: false }));
  }
}

export function* watchcreateConnect() {
  yield takeEvery(bankAction.createConnect, handlecreateConnect);
}

export function* handleSetDefaultCard({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const { dispatch, card_id } = payload;
    const response = yield call(requestSetDefaultCard, { card_id });
    yield put(request_loader({ load: false }));
    if (response) {
      let { status } = response;
      if (status === window.code["200"].code) {
        yield put(
          set_message({
            error_code: error_messages["set_default_card_successfully"],
            code: "success",
            heading: window.code["200"].header,
          })
        );
        dispatch(fetch_stripe_payment_methods());
      }
    } else {
      yield put(
        set_message({
          error_code: "",
          code: "warning",
          heading: "Invalid Value",
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchSetDefaultCard() {
  yield takeEvery(cardAction.setDefault, handleSetDefaultCard);
}
