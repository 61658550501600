import {Modal,Button} from 'react-bootstrap';
const LogoutModel = props =>{
  const {show,onHide,onCloseClick,onClick} = props;
  return(
    <Modal
      className="reject--list-pop"
      show={show}
      onHide={onHide}
    >
      <div className="modal-box">
        <div className="header">
          <h3 className="mr-b-20"> Sign out </h3>
          <p className="mr-b-10">
            {" "}
            Are you sure you want to sign out from the <br /> NursesBnB?
          </p>
        </div>
        <div className="body">
          <div className="button-wrp">
            <Button
              className="button"
              variant="secondary"
              onClick={onCloseClick}
            >
              {" "}
              Cancel{" "}
            </Button>
            <Button
              className="button mr-l-15"
              variant="primary"
              onClick={onClick}
            >
              {" "}
              Sign Out{" "}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default LogoutModel;
