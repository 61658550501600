import {
    fetchAdminDashboardReports
     } from "../../ActionTypes/admin";

export const fetch_admin_dashboard_reports = () => ({
    type: fetchAdminDashboardReports.load
  });
export const fetch_admin_dashboard_reports_success = (data) => ({
    type: fetchAdminDashboardReports.success,
    payload: data,
  });
export const fetch_admin_dashboard_reports_error = (data) => ({
    type: fetchAdminDashboardReports.failure,
    payload: data,
  });