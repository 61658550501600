export const apiEndPoint =
  process.env.REACT_APP_API_URL || "http://localhost:3000";
export const apiEndPointPropertyService =
  process.env.REACT_APP_API_URL || "http://localhost:3201/";
export const apiEndPointDefaultService =
  process.env.REACT_APP_API_URL || "http://localhost:3001/";
export const apiEndPointLeaseSignorService =
  process.env.REACT_APP_API_URL || "http://localhost:3003/";
export const apiEndPointPaymentService =
  process.env.REACT_APP_API_URL || "http://localhost:3004/";
export const apiEndPointStaticContentService =
  process.env.REACT_APP_API_URL || "http://localhost:3006/";
export const apiEndPointUserService =
  process.env.REACT_APP_API_URL || "http://localhost:3002/";
export const apiEndPointDocumentService =
  process.env.REACT_APP_API_URL || "http://localhost:3008/";
export const apiEndPointBookingService =
  process.env.REACT_APP_API_URL || "http://localhost:3006/";
export const apiEndPointSettingService =
  process.env.REACT_APP_API_URL || "http://localhost:3005/";
export const stripeApiEndPoint =
  process.env.REACT_APP_STRIPE_API_URL || "https://api.stripe.com/v1/";
export const COUNTRY_CODE = process.env.REACT_APP_COUNTRY_CODE;
// "https://property-service-dot-extreme-pixel-330416.uc.r.appspot.com";
export const apiEndPointPromocodeService =
  process.env.REACT_APP_API_URL || "http://localhost:3007/";
export const apiEndPointReviewService =
  process.env.REACT_APP_API_URL || "http://localhost:3203/";

export const apiEndPointCheckinService =
  process.env.REACT_APP_API_URL || "http://localhost:3010/";
export const apiEndPointEvictionService =
  process.env.REACT_APP_API_URL || "http://localhost:3011/";

export const apiConstants = {
  login_otp_send: `${apiEndPointUserService}user/login_with_otp`,
  UPLOAD_PROPERTY_IMAGES: `${apiEndPointPropertyService}property/uploadfile`,
  CREATE_BASIC_INFO: `${apiEndPointPropertyService}property/`,
  GET_PROPERTY_COUNT: `${apiEndPointPropertyService}property/get_property_count`,
  DELETE_OWNER_LISTING: `${apiEndPointPropertyService}property/`,
  UPDATE_BASIC_INFO: `${apiEndPointPropertyService}property/updateBasicInfo`,
  CREATE_PROPERTY_INFO: `${apiEndPointPropertyService}property/`,
  LOAD_PROPERTY_INFO1: `${apiEndPointPropertyService}property/getallitems`,
  LOAD_PROPERTY_INFO: `${apiEndPointDefaultService}default/property/pre_defines`,
  LOAD_COUNTRIES: `${apiEndPointDefaultService}default/common/countries`,
  LOAD_STATES: `${apiEndPointDefaultService}default/common/states`,
  LOAD_CITIES: `${apiEndPointDefaultService}default/common/cities`,
  CREATE_LEASE_SIGNOR: `${apiEndPointLeaseSignorService}leasesignor/`,
  DELETE_LEASE_SIGNOR: `${apiEndPointLeaseSignorService}/property/delete_leasesignor`,
  CREATE_BANK_INFO: `${apiEndPointPaymentService}payment/accounts/create`,
  OWNER_CANCELLATION_CHARGE: `${apiEndPointPaymentService}payment/booking/cancel`,
  ADD_SINGLE_BANK_INFO: `${apiEndPointPropertyService}property/bankAccountSave`,
  LOAD_PREVIEW_LIST: `${apiEndPointPropertyService}property/`,
  LOAD_PREVIEW_LIST_PROTECTED: `${apiEndPointPropertyService}property/get_property_details/`,
  CREATE_USER_DETAILS: `${apiEndPointUserService}user/register`,
  RESET_CHECKR_FIELD: `${apiEndPointUserService}user/checkr/candidate/delete`,
  RESET_CHECKR_USER_RECORD: `${apiEndPointUserService}user/checkr/delete_checkr_request_with_booking`,
  USER_LOGIN_CHECK: `${apiEndPointUserService}user/login`,
  USER_CHECKR_VERIFY: `${apiEndPointUserService}user/checkr/candidate/`,
  USER_BACKGROUND_INFO: `${apiEndPointUserService}user/auth/user_info`,
  CREATE_CONNECT_ACCOUNT: `${apiEndPointUserService}user/create_connect_account`,
  USER_LOGIN_BY_OTP: `${apiEndPointUserService}user/send_otp_for_login`,
  CHECK_OTP: `${apiEndPointUserService}user/verify/otp`,
  RESEND_OTP: `${apiEndPointUserService}user/resend_otp`,
  VERIFY_USER: `${apiEndPointUserService}user/otp_verification`,
  LEASESIGNOR_VERIFY: `${apiEndPointLeaseSignorService}leasesignor/mobile-verify`,
  FORGET_PASSWORD_EMAIL_VARIFY: {
    forgotpassword: `${apiEndPointUserService}user/verify`,
    newuser: `${apiEndPointUserService}user/verify`,
    leasesignor: `${apiEndPointLeaseSignorService}leasesignor/verify`,
    leasesignor_document: `${apiEndPointPropertyService}property/get_document`,
    newBusinessAdmin: `${apiEndPointPropertyService}user/admin/get_busniess_details`,
  },
  LOAD_HOME_PAGE: `${apiEndPointPropertyService}property/recently_added`,
  FETCH_USER_DETAIL: `${apiEndPointUserService}user/profile`,
  VERIFY_LOGIN_RECAPTCHA: `${apiEndPointUserService}user/verify_captcha`,
  SWITCH_ROLE: `${apiEndPointUserService}/user/switch_user`,
  FORGET_PASSWORD_OTP: `${apiEndPointUserService}user/forgotpassword/sendotp`,
  UPDATE_PASSWORD: `${apiEndPointUserService}user/change_password`,
  RESET_PASSWORD: `${apiEndPointUserService}user/forgot_password`,
  USER_UPDATE: `${apiEndPointUserService}user/update`,
  GET_STRIPE_BANK_ACCOUNT: `${stripeApiEndPoint}accounts`,
  NEW_PASSWORD: `${apiEndPointUserService}user/forgot_password`,
  FETCH_PROPERTY_LIST: `${apiEndPointPropertyService}property/`,
  LEASE_SIGNORS_GET_BY_PROPERTY_ID: `${apiEndPointLeaseSignorService}leasesignor/get_by_property_id/`,
  FETCH_LIST_FRONTED: `${apiEndPointPropertyService}property/browse`,
  DEFAULT_ELEMENTS: `${apiEndPointDefaultService}default/property/pre_defines`,
  DEFAULT_COUNTRIES: `${apiEndPointDefaultService}default/common/countries`,
  COUNTRY_STATES: `${apiEndPointDefaultService}default/common/states`,
  COUNTRY_CITIES: `${apiEndPointDefaultService}default/common/cities`,
  GET_ISO_CODES: `${apiEndPointDefaultService}default/common/phonecodes`,

  BACKGROUND_CHECK_STATUS: `${apiEndPointSettingService}setting/background/check`,

  LEASESIGNOR_SENDOTP: `${apiEndPointLeaseSignorService}leasesignor/sendotp`,
  SAVE_REVIEW_DETAILS: `${apiEndPointPropertyService}property/completed`,
  PROPERTY_FEATURED_AMOUNT: `${apiEndPointPropertyService}property/featured/get_amount`,
  PAY_PROPERTY_FEATURED_AMOUNT: `${apiEndPointPaymentService}payment/property_featured`,
  LEASE_SIGNORS_GET_ALL: `${apiEndPointPropertyService}property/get_leasesignor_by_user_id/`,
  LEASE_SIGNORS_GET_BY_ID: `${apiEndPointPropertyService}property/get_leasesignor_by_id/`,
  GET_STATIC_CONTENTS: `${apiEndPointStaticContentService}static-content/getAllData`,
  UPDATE_CONTENT: `${apiEndPointStaticContentService}static-content/update/`,
  GET_FAQ: `${apiEndPointStaticContentService}static-content/faq/getall`,
  CREATE_FAQ: `${apiEndPointStaticContentService}static-content/faq/save`,
  UPDATE_FAQ: `${apiEndPointStaticContentService}static-content/faq/`,
  DELETE_FAQ: `${apiEndPointStaticContentService}static-content/faq/`,
  ADMIN_MANAGE_LEASE_DOCUMENT: `${apiEndPointDocumentService}document/`,
  EDIT_LIVE_LISTING: `${apiEndPointPropertyService}property/edit_live_listing/`,
  DELETE_LIVE_LISTING: `${apiEndPointPropertyService}property/delete_live_listing`,
  LISTING_REQUEST: `${apiEndPointPropertyService}property/listing_request`,
  LEASESIGON_VERIFY_DOC: `${apiEndPointLeaseSignorService}property/signed_document`,
  PAY_HOTEL_FEE_AMOUNT: `${apiEndPointPaymentService}payment/list_hotel`,
  CREATE_BOOKING: `${apiEndPointBookingService}booking/`,
  BUSINESS_ADMIN_CREATE_PASSWORD: `${apiEndPointBookingService}user/admin/business_admin_set_password`,

  INITIATE: "store",
  OCCUPANTS: "/occupants",
  STATUS: "/status",
  BOOK_PROPERTY: `${apiEndPointPaymentService}payment/booking`,
  PAYMENT_LIST: `${apiEndPointPaymentService}payment/index`,
  PAYOUTS: `${apiEndPointPaymentService}payment/payouts`,
  PAYOUT_RENTER: `${apiEndPointPaymentService}payment/index`,
  SAVE_BANK_ACCOUNT: `${apiEndPointPaymentService}payment/accounts/save_bank_account`,
  GET_CONNECT_ACCOUNT: `${apiEndPointPaymentService}payment/connect_accounts/get_connect_account`,
  GET_CONNECT_ACCOUNT_IS_VERIFIED: `${apiEndPointPaymentService}payment/connect_accounts/is_connect_account_data_submitted`,
  ACCOUNT_VERIFICATION_START: `${apiEndPointPaymentService}payment/connect_accounts/update_connect_account`,
  UPDATE_REP_OWNER: `${apiEndPointPaymentService}payment/connect_accounts/update_rep_owner`,
  LIST_BOOKING_ADMIN: `${apiEndPointBookingService}booking/admin/index`,
  LIST_BOOKING_DETAIL: `${apiEndPointBookingService}booking/`,
  LIST_BOOKING_DETAIL_ADMIN: `${apiEndPointBookingService}booking/admin/`,
  LIST_BOOKING_OWNER: `${apiEndPointBookingService}booking/index`,
  LEASE_SIGNOR_RESEND_INVITE: `${apiEndPointPropertyService}property/resend_invite`,
  // GET_ALL_LEASE_SIGNORS: `${apiEndPointPropertyService}property/get_leasesignor_by_user_id/`,
  GET_ALL_LEASE_SIGNORS: `${apiEndPointLeaseSignorService}leasesignor/`,
  GET_EXIST_LEASE_SIGNOR: `${apiEndPointLeaseSignorService}leasesignor/`,
  GET_LIKE_UNLIKE_PROPERTY: `${apiEndPointLeaseSignorService}/property/like_dislike_property`,
  APPLY_PROMOCODE: `${apiEndPointBookingService}booking/promocode`,
  CREATE_PROMOCODE: `${apiEndPointPromocodeService}promo_code/save`,
  LIST_PROMOCODE: `${apiEndPointPromocodeService}promo_code/get_all_promocde`,
  PROMOCODE_ACTION: `${apiEndPointPromocodeService}promo_code/`,
  READY_TO_CHECKIN: `${apiEndPointCheckinService}check-in/status_change/`,
  GET_ALL_MESSAGES: `${apiEndPointCheckinService}messages/threads`,
  SEND_MESSAGE: `${apiEndPointCheckinService}messages/new`,
  SEND_MESSAGE_FOR_EXIST_THREAD: `${apiEndPointCheckinService}messages/add`,
  GET_ONE_THREAD_MESSAGE: `${apiEndPointCheckinService}messages/threads/`,

  GET_CHECKIN_DETAILS: `${apiEndPointCheckinService}check-in/get_checkin_details/`,
  UPLOAD_CHECKIN_MEDIA: `${apiEndPointCheckinService}check-in/uploadfile/`,
  CHECKIN_SAVE_DETAILS: `${apiEndPointCheckinService}check-in/save_details/`,
  CHECKIN_ADMIN_ADD_COMMENT: `${apiEndPointCheckinService}check-in/admin/add_comment/`,
  CHECKIN_ADMIN_SETTLE_DISPUTE: `${apiEndPointCheckinService}check-in/admin/settle_dispute/`,
  RESEND_EMAIL_VERIFICATION: `${apiEndPointUserService}user/resend_invite`,
  UPLOAD_EVICTION_MEDIA: `${apiEndPointEvictionService}eviction/uploadfile/`,
  UPLOAD_TERMINATION_MEDIA: `${apiEndPointEvictionService}eviction/termination/uploadfile/`,
  EVICTION_SAVE_DETAILS: `${apiEndPointEvictionService}eviction/save_details/`,
  EVICTION_UPDATE_DETAILS: `${apiEndPointEvictionService}eviction/update_details/`,
  GET_ALREADY_REVIEWED: `${apiEndPointReviewService}reviews/review_available`,
  CREATE_LEAVING_REVIEW: `${apiEndPointReviewService}reviews/add`,
  GET_ALL_REVIEWS: `${apiEndPointReviewService}reviews/`,
  GET_EVICTION_DETAILS: `${apiEndPointEvictionService}eviction/exists/`,

  GET_ADMIN_EVICTION_DETAILS: `${apiEndPointEvictionService}eviction/admin/`,
  LIST_EVICTION: `${apiEndPointEvictionService}eviction/admin/list`,

  //Notification
  GET_NOTIFICATIONS: `${apiEndPointDefaultService}notification/user/notification`,
  CLEAR_NOTIFICATIONS: `${apiEndPointDefaultService}notification/user/clear_notification`,
  PAY_PENALTY: `${apiEndPointEvictionService}payment/penalty`,
  GET_NOTIFICATION: `${apiEndPointEvictionService}notification/user/notification`,
  APPLY_LISTING_PAYOUT_PROMOCODE: `${apiEndPointPropertyService}property/promocode/apply`,
  APPLY_LISTING_HOTEL_PROMOCODE: `${apiEndPointPaymentService}payment/promocode`,
  DELETE_LISTING_PROMOCODE: `${apiEndPointPropertyService}property/promocode/delete`,
  UPLOAD_USER_DOCUMENT: `${apiEndPointUserService}user/checkr/upload_user_document`,

  FETCH_DATE_FROM_ICAL: `${apiEndPointPropertyService}property/get_events/`,
  SAVE_CHECKR_DETAILS: `${apiEndPointUserService}user/checkr/create_candidate/`,
  ICAL_LINK: `${apiEndPointUserService}/property/get_export_link/`,
  EVICTION_ADMIN_SETTLE_DISPUTE: `${apiEndPointEvictionService}eviction/admin/settle_dispute/`,

  // termination related
  TERMINATION_SAVE_DETAILS: `${apiEndPointEvictionService}eviction/save_termination/`,
  GET_TERMINATION_DETAILS: `${apiEndPointEvictionService}eviction/termination_exists/`,
  TERMINATION_ADMIN_SETTLE_DISPUTE: `${apiEndPointEvictionService}eviction/admin/settle_termination/`,

  // contact us form
  CONTACT_US: `${apiEndPointStaticContentService}notification/send_enquiry/`,
  LOAD_PROPERTY_CURRENT_RENTER: `${apiEndPointPropertyService}user/profile?user_id=`,

  // for count messages
  COUNT_MESSAGES: `${apiEndPointDefaultService}messages/unread-message-count`,
  BOOKING_HISTORY: `${apiEndPointDefaultService}booking/penalty `,
  TOTAL_EVCITION_FEE: `${apiEndPointDefaultService}eviction/admin/dashboard/get_total_evicition_fee`,
  OWNER_REMAINING_EVICITIONS: `${apiEndPointDefaultService}eviction/get_owner_unpaid_evictions`,
  OWNER_PAYMENT_EVICTION: `${apiEndPointDefaultService}payment/eviction_charge`,
  CANADA_PAYMENT_ACCOUNT_ADD: `${apiEndPointDefaultService}payment/accounts/create_bank_token_canada`
};
