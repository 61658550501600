import { basicInfoUploadImages, deletePropertyImage } from "../ActionTypes";
export const basic_info_upload_images = (data) => ({
  type: basicInfoUploadImages.load,
  payload: data,
});
export const basic_info_upload_images_success = (data) => ({
  type: basicInfoUploadImages.success,
  payload: data,
});
export const basic_info_upload_images_error = (data) => ({
  type: basicInfoUploadImages.failure,
  payload: data,
});

export const delete_Image = (data) => ({
  type: deletePropertyImage.load,
  payload: data,
});
export const delete_Image_success = (data) => ({
  type: deletePropertyImage.success,
  payload: data,
});
export const delete_Image_error = (data) => ({
  type: deletePropertyImage.failure,
  payload: data,
});
