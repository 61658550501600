import { call, put, takeEvery } from "redux-saga/effects";
import {
  requestCreateLeaseSignor,
  requestDeleteLeaseSignor,
  requestFetchLss,
  requestLoadCountriesWithflag,
  requestResendInvite,
} from "../Requests/create-new-list";
import {
  createLeaseSignor,
  deleteLeaseSignor,
  fetchExistLeaseSignors,
  leaseSignorAction,
  loadCountriesWithFlag,
} from "../../ActionTypes";
import {
  create_lease_signor_error,
  create_lease_signor_success,
  delete_lease_signor_error,
  delete_lease_signor_success,
  fetch_exist_lease_signors_error,
  fetch_exist_lease_signors_success,
  load_countries_with_flag_error,
  load_countries_with_flag_success,
} from "../../Actions/create-new-list";
import { CREATE_NEW_LIST_PATHS } from "../../../config/Owner-And-NewList_Paths";
import { constantTexts } from "../../../Constants/Common";
import { request_loader } from "../../Actions/loader";
import { set_message } from "../../Actions/message";
import { fetchLeaseSignors } from "../../Actions/Owner/Profile";
import error_messages from "../../../Constants/error-messages";

export function* handleCreateLeaseSignor({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const {
      leaseSignor,
      leaseSignor: { property_id },
      navigate,
    } = payload;
    leaseSignor.property_id = property_id;
    const response = yield call(requestCreateLeaseSignor, leaseSignor);
    if (response?.data || response?.status === 204) {
      const { data } = response;
      yield put(create_lease_signor_success(data ? data[0] : data));
      navigate(
        `${CREATE_NEW_LIST_PATHS.LAYOUT_PATH}${CREATE_NEW_LIST_PATHS.BANK_INFO_ONLY}${leaseSignor.property_id}`
      );
      yield put(
        set_message({
          error_code: data?.msg,
          code: constantTexts.SUCCESS,
        })
      );
      yield put(request_loader({ load: false }));
    } else {
      yield put(
        set_message({
          error_code: response?.data?.msg,
          code: constantTexts.WARNING,
        })
      );
      yield put(request_loader({ load: false }));
      yield put(create_lease_signor_error(response?.data));
    }
  } catch (error) {
    yield put(request_loader({ load: false }));
    yield put(
      set_message({
        error_code: error_messages.lease_signor_already_exist,
        code: "warning",
        heading: "Invalid Value",
      })
    );
    yield put(create_lease_signor_error(error));
  }
}

export function* watchCreateLeaseSignor() {
  yield takeEvery(createLeaseSignor.load, handleCreateLeaseSignor);
}

//delete lease signor
export function* handleDeleteLeaseSignor({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const { id } = payload;
    const response = yield call(requestDeleteLeaseSignor, payload);
    const { data } = response;
    if (data) {
      yield put(delete_lease_signor_success(id));
      yield put(
        set_message({
          error_code: data?.msg,
          code: constantTexts.SUCCESS,
        })
      );
      yield put(request_loader({ load: false }));
    } else {
      yield put(delete_lease_signor_error(data));
    }
  } catch (error) {
    yield put(delete_lease_signor_error(error));
  }
}

export function* watchDeleteLeaseSignor() {
  yield takeEvery(deleteLeaseSignor.load, handleDeleteLeaseSignor);
}

//load country with flag

export function* handleLoadCountriesWithFlag() {
  try {
    const response = yield call(requestLoadCountriesWithflag);
    const { data } = response;
    if (data) {
      yield put(load_countries_with_flag_success(data.data));
    } else {
      yield put(load_countries_with_flag_error(data));
    }
  } catch (error) {
    yield put(load_countries_with_flag_error(error));
  }
}

export function* watchLoadCoutriesWithFlag() {
  yield takeEvery(loadCountriesWithFlag.load, handleLoadCountriesWithFlag);
}

export function* handleResendInvite({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const { dispatch, id } = payload;
    const response = yield call(requestResendInvite, { _id: id });
    yield put(request_loader({ load: false }));
    if (response) {
      let { status } = response;
      if (status === window.code["200"].code) {
        yield put(
          set_message({
            error_code:
              error_messages["lease_signor_resend_invite_successfull"],
            code: "success",
            heading: window.code["200"].header,
          })
        );
        dispatch(fetchLeaseSignors());
      }
    } else {
      yield put(
        set_message({
          error_code: "",
          code: "warning",
          heading: "Invalid Value",
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchResendInvite() {
  yield takeEvery(leaseSignorAction.resendInvite, handleResendInvite);
}

//Fetch Exist LEase Signor
export function* handleFecthLss({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(requestFetchLss, payload);
    const { data } = response;
    if (data) {
      yield put(request_loader({ load: false }));
      yield put(fetch_exist_lease_signors_success(data.data));
    } else {
      yield put(fetch_exist_lease_signors_error(data));
    }
  } catch (error) {
    yield put(fetch_exist_lease_signors_error(error));
  }
}

export function* watchLoadExistLss() {
  yield takeEvery(fetchExistLeaseSignors.load, handleFecthLss);
}
