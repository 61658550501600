import { call, put, takeEvery } from "redux-saga/effects";
import {
  requestBasicInfoUploadImages,
  requestDeletePropertyImage,
} from "../Requests/basic-info-images-upload";
import { basicInfoUploadImages, deletePropertyImage } from "../../ActionTypes";
import {
  basic_info_upload_images_error,
  basic_info_upload_images_success,
  delete_Image_success,
  delete_Image_error,
} from "../../Actions/basic-info-upload-images";
import { request_loader } from "../../Actions/loader";

export function* handleBasicInfoUploadImages(action) {
  try {
    yield put(request_loader({ load: true }));
    const { payload } = action;
    const response = yield call(requestBasicInfoUploadImages, payload);
    const { data } = response;
    if (data) {
      yield put(basic_info_upload_images_success(data.data));
      yield put(request_loader({ load: false }));
    } else {
      yield put(request_loader({ load: false }));
      yield put(basic_info_upload_images_error(data));
    }
  } catch (error) {
    yield put(request_loader({ load: false }));
    yield put(basic_info_upload_images_error(error));
  }
}

export function* watchBasicInfoUploadImages() {
  yield takeEvery(basicInfoUploadImages.load, handleBasicInfoUploadImages);
}

export function* handleDeletePropertyImages(action) {
  try {
    const { payload } = action;
    const response = yield call(requestDeletePropertyImage);
    const { data } = response;
    if (data) {
      yield put(delete_Image_success(payload));
    } else {
      yield put(delete_Image_error(data));
    }
  } catch (error) {
    yield put(delete_Image_error(error));
  }
}

export function* watchDeletePropertyImages() {
  yield takeEvery(deletePropertyImage.load, handleDeletePropertyImages);
}
