export const authAction = {
  load: "USER_LOGIN_CHECK",
  success: "USER_LOGIN_SUCCESS",
  failure: "USER_LOGIN_FAIL",
  reset: "RESET_FORM_SUBMISSION",
  logout: "USER_LOGOUT",
  otpRequest: "REQUEST_OTP_CHANGE",
  passwordReset: "RESET_USER_PASSWORD",
  emailVerify: "VERIFY_EMAIL_THROUGH_LINK",
  leaseSignDoc: "SIGN_LEASE_DOCUMENT",
  getBackGroundInfo: "GET_BACKGROUND_INFO",
  getBackGroundInfoReset: "GET_BACKGROUND_INFO_RESET",
};

export const filterAction = {
  executeFilter: "EXECUTE_FILTER_HOME",
  setCoordinate: "SET_MAP_COORDINATE",
  resetCoordinate: "RESET_MAP_COORDINATE",
  setAddress: "SET_AUTOCOMPLETE_ADDRESS",
  resetAddress: "RESET_AUTOCOMPLETE_ADDRESS",
};

export const loaderAction = {
  load: "LOADER_INITIATE",
  success: "LOADER_SUCCESS",
};

export const listProduct = {
  get: "GET_PRODUCT_LIST",
  set: "SET_PRODUCT_LIST",
};

//image Upload//
export const basicInfoUploadImages = {
  load: "BASIC_INFO_UPLOAD_IMAGES",
  success: "BASIC_INFO_UPLOAD_IMAGES_SUCCESS",
  failure: "BASIC_INFO_UPLOAD_IMAGES_ERROR",
  reset: "BASIC_INFO_UPLOAD_IMAGES_RESET",
};

//create basic info//
export const createBasicInfo = {
  load: "CREATE_BASIC_INFO",
  success: "CREATE_BASIC_INFO_SUCCESS",
  failure: "CREATE_BASIC_INFO_ERROR",
};

//create property info//
export const createPropertyInfo = {
  load: "CREATE_PROPERTY_INFO",
  success: "CREATE_PROPERTY_INFO_SUCCESS",
  failure: "CREATE_PROPERTY_INFO_ERROR",
};

//create lease signor//
export const createLeaseSignor = {
  load: "CREATE_LEASE_SIGNOR",
  success: "CREATE_LEASE_SIGNOR_SUCCESS",
  failure: "CREATE_LEASE_SIGNOR_ERROR",
};

//delete lease signor//
export const deleteLeaseSignor = {
  load: "DELETE_LEASE_SIGNOR",
  success: "DELETE_LEASE_SIGNOR_SUCCESS",
  failure: "DELETE_LEASE_SIGNOR_ERROR",
};

export const fetchExistLeaseSignors = {
  load: "FETCH_EXIST_LEASE_SIGNOR",
  success: "FETCH_EXIST_LEASE_SIGNOR_SUCCESS",
  failure: "FETCH_EXIST_LEASE_SIGNOR_ERROR",
};

//load property info//
export const loadPropertyInfo = {
  load: "LOAD_PROPERTY_INFO",
  success: "LOAD_PROPERTY_INFO_SUCCESS",
  failure: "LOAD_PROPERTY_INFO_ERROR",
  listRequest: "SEARCH_REQUEST_LIST",
};

//load Review details//
export const loadReviewDetails = {
  load: "LOAD_REVIEW_DETAILS",
  success: "LOAD_REVIEW_DETAILS_SUCCESS",
  failure: "LOAD_REVIEW_DETAILS_ERROR",
  reset: "LOAD_REVIEW_DETAILS_RESET",
};

export const addSingleBankInfo = {
  load: "ADD_SINGLE_BANK_ACCOUNT_INFO",
  success: "ADD_SINGLE_BANK_ACCOUNT_INFO_SUCCESS",
  failure: "ADD_SINGLE_BANK_ACCOUNT_INFO_ERROR",
  verifyAccount: "VERIFY_INDIVIDUAL_BANK_ACCOUNT",
  updateRepresentative: "UPDATE_REPRESENTATIVE_STRIPE",
};

//load home page//
export const loadHomePage = {
  load: "LOAD_HOME_PAGE",
  success: "LOAD_HOME_PAGE_SUCCESS",
  failure: "LOAD_HOME_PAGE_ERROR",
  searchLoad: "LOAD_SEARCH_PAGE",
  searchLoadReset: "RESET_SEARCH_RESULT",
  searchLoadSuccess: "LOAD_SEARCH_PAGE_SUCCESS",
};

export const createUserDetails = {
  load: "CREATE_USER_DETAILS",
  success: "CREATE_USER_DETAILS_SUCCESS",
  failure: "CREATE_USER_DETAILS_ERROR",
  fetchRequest: "FETCH_USER_DETAIL",
  fetchSuccess: "FETCH_USER_DETAIL_SUCCESS",
  update: "UPDATE_USER_PROFILE",
  updateSuccess: "UPDATE_USER_PROFILE_SUCCESS",
  updateImage: "UPDATE_USER_PROFILE_IMAGE",
  updateImageSuccess: "UPDATE_USER_PROFILE_IMAGE_SUCCESS",
  changePass: "CHANGE_USER_PASSWORD",
  switchRole: "SWITCH_PROFILE",
  stripeVarify: "Varify_STRIPE_CONNECT",
  socialLogin: "SOCIAL_LOGIN",
};

export const otpAction = {
  load: "OTP_CHECK",
  success: "OTP_SUCCESS",
  failure: "OTP_FAIL",
  resend: "OTP_RESEND",
  otpSignorSend: "SEND_LEASE_SIGNOR_OTP",
};

export const completeProfileAction = {
  load: "COMPLETE_PROFILE",
  success: "COMPLETE_PROFILE_SUCCESS",
  failure: "COMPLETE_PROFILE_FAIL",
};

export const displayMessage = {
  reset: "RESET_MESSAGE",
  success: "DISPLAY_MESSAGE",
};

export const stripePaymentMethods = {
  load: "FETCH_STRIPE_PAYMENT_METHODS",
  success: "FETCH_STRIPE_PAYMENT_METHODS_SUCCESS",
};

//delete Property Images
export const deletePropertyImage = {
  load: "DELETE_PROPERTY_IMAGE",
  success: "DELETE_PROPERTY_IMAGE_SUCCESS",
  failure: "DELETE_PROPERTY_IMAGE_ERROR",
};

//owner listing
export const fetchOwnerListing = {
  load: "FETCH_OWNER_LISTING",
  success: "FETCH_OWNER_LISTING_SUCCESS",
  failure: "FETCH_OWNER_LISTING_ERROR",
};

export const deleteOwnerListing = {
  load: "DELETE_OWNER_LISTING",
  success: "DELETE_OWNER_LISTING_SUCCESS",
  failure: "DELETE_OWNER_LISTING_ERROR",
};

export const saveReviewDetails = {
  load: "SAVE_REVIEW_DETAILS",
  success: "SAVE_REVIEW_DETAILS_SUCCESS",
  failure: "SAVE_REVIEW_DETAILS_ERROR",
};

export const headerLanguage = {
  load: "LOAD_LANGUAGE_TOGGLE",
  success: "SET_LANGUAGE_TOGGLE",
};

//countries
export const loadCountries = {
  load: "LOAD_COUNTRIES",
  success: "LOAD_COUNTRIES_SUCCESS",
  failure: "LOAD_COUNTRIES_ERROR",
};
// states
export const loadStates = {
  load: "LOAD_STATES",
  success: "LOAD_STATES_SUCCESS",
  failure: "LOAD_STATES_ERROR",
};
//cities
export const loadCities = {
  load: "LOAD_CITIES",
  success: "LOAD_CITIES_SUCCESS",
  failure: "LOAD_CITIES_ERROR",
};

//countries with flag
export const loadCountriesWithFlag = {
  load: "LOAD_COUNTRIES_WITH_FLAG",
  success: "LOAD_COUNTRIES_WITH_FLAG_SUCCESS",
  failure: "LOAD_COUNTRIES_WITH_FLAG_ERROR",
};
//feature a list
export const propertyFeatureAmount = {
  load: "PROPERTY_FEATURE_AMOUNT",
  success: "PROPERTY_FEATURE_AMOUNT_SUCCESS",
};

export const featureAmountPayment = {
  load: "FEATURE_AMOUNT_PAYMENT",
};

export const uploadAction = {
  setMessage: "SET_UPLOAD_ALERT",
  upload: "PROCEED_FILE_UPLOAD",
  upload_reset: "RESET_UPLOAD_REQUEST",
  uploadDoc: "ADMIN_UPLOAD_DOCUMENT",
  listDoc: "LIST_LEASESIGNOR_DOCS",
  listDocDisplay: "LIST_LEASESIGNOR_DOCS_DISPLAY",
  displayLeaseDoc: "DISPLAY_LEASESIGNOR_DOCS",
  deleteDoc: "ADMIN_DELETE_LEASESIGNOR",
};
//load Review details//
export const allLeaseSignors = {
  load: "LOAD_ALL_LEASE_SIGNORS",
  success: "LOAD_ALL_LEASE_SIGNORS_SUCCESS",
  failure: "LOAD_ALL_LEASE_SIGNORS_ERROR",
};

//load Lease Signor details//
export const loadLeaseSignorDetails = {
  load: "LOAD_LEASE_SIGNOR_DETAILS",
  success: "LOAD_LEASE_SIGNOR_DETAILS_SUCCESS",
  failure: "LOAD_LEASE_SIGNOR_DETAILS_ERROR",
};

//get static contents
export const loadStaticContent = {
  load: "LOAD_STATIC_CONTENT",
  success: "LOAD_STATIC_CONTENT_SUCCESS",
  failure: "LOAD_STATIC_CONTENT_ERROR",
};
//update Terms and condition
export const updateTac = {
  load: "UPDATE_TERMS_AND_CONDITION",
  success: "UPDATE_TERMS_AND_CONDITION_SUCCESS",
  failure: "UPDATE_TERMS_AND_CONDITION_ERROR",
};

//update Faq
export const updateFaq = {
  load: "UPDATE_FAQ",
  success: "UPDATE_FAQ_SUCCESS",
  failure: "UPDATE_FAQ_ERROR",
};
//update Faq
export const createFaq = {
  load: "CREATE_FAQ",
  success: "CREATE_FAQ_SUCCESS",
  failure: "CREATE_FAQ_ERROR",
};

//delete Faq
export const deleteFaq = {
  load: "DELETE_FAQ",
  success: "DELETE_FAQ_SUCCESS",
  failure: "DELETE_FAQ_ERROR",
};

//get faq contents
export const loadFaq = {
  load: "LOAD_FAQ",
  success: "LOAD_FAQ_SUCCESS",
  failure: "LOAD_FAQ_ERROR",
};

export const bookingAction = {
  create: "CREATE_BOOKING",
  occupants: "ADD_BOOKING_OCCUPANTS",
  listAdmin: "ADMIN_BOOKING_LIST",
  listOwner: "OWNER_BOOKING_LIST",
  displayListAdmin: "ADMIN_BOOKING_LIST_DISPLAY",
  updateStatusOwner: "OWNER_UPDATE_BOOKING_STATUS",
  displayListOwner: "OWNER_BOOKING_LIST_DISPLAY",
  loadDetail: "FETCH_BOOKING_DETAIL_ADMIN",
  displayDetail: "DISPLAY_BOOKING_ADMIN_DETAIL",
  cancelBooking: "RENTER_CANCEL_BOOKING",
  cancelBookingHomeOwner: "HOME_OWNER_CANCEL_BOOKING",
  listOnlyUpdate: "UPDATE_UNIQUE_BOOKING",
  updateStatusBooking: "UPDATED_DETAIL_STATUS",
};

//pay hotel one time fee
export const payHotelFee = {
  load: "PAY_HOTEL_FEE",
};

export const leaseSignorAction = {
  resendInvite: "RESEND_INVITE",
};

export const promocodeActions = {
  apply: "APPLY_PROMOCODE",
  remove: "REMOVE_PROMOCODE",
  applied: "PROMOCODE_APPLIED",
  create: "CREATE_PROMOCODE",
  list: "LIST_PROMOCODE",
  display: "DISPLAY_PROMOCODE_LIST",
  update: "UPDATE_PROMOCODE",
  statusupdate: "UPDATE_PROMO_CODE_STATUS",
  delete: "DELETE_PROMOCODE",
  updateOnly: "UPDATE_ONLY_PROMOCODE",
  apply_listing_payout: "APPLY_LISTING_PAYOUT_PROMOCODE",
  listing_promocode_applied: "LISTING_PROMOCODE_APPLIED",
  apply_listing_hotel: "APPLY_LISTING_HOTEL_PROMOCODE",
  remove_listing_promocode: "REMOVE_LISTING_PROMOCODE",
};

export const checkInActions = {
  fetch: "GET_CHECKIN_DETAILS",
  store: "STORE_CHECKIN_DETAILS",
};

export const uploadMedia = {
  load: "UPLOAD_MEDIA",
  store: "STORE_MEDIA",
  delete: "DELETE_MEDIA",
};

export const uploadDoc = {
  load: "UPLOAD_DOC",
  store: "STORE_DOC",
  delete: "DELETE_DOC",
};

export const paymentActn = {
  load: "FETCH_PAYMENT_HOMEOWNER",
  loadRenter: "FETCH_PAYMENT_RENTER",
  display: "DISPLAY_PAYMENT_HOMEOWNER",
};
//update Faq
export const createLeavingReview = {
  load: "CREATE_LEAVING_REVIEW",
  success: "CREATE_LEAVING_REVIEW_SUCCESS",
  failure: "CREATE_LEAVING_REVIEW_ERROR",
};

export const evictionActions = {
  fetch: "GET_EVICTION_DETAILS",
  store: "STORE_EVICTION_DETAILS",
  list: "LIST_EVICTION",
  display: "DISPLAY_EVICTION_LIST",
  displayDetail: "DISPLAY_EVICTION_LIST_DETAIL",
};

export const terminationActions = {
  fetch: "GET_TERMINATION_DETAILS",
  store: "STORE_TERMINATION_DETAILS",
};
// notification
export const fetchNotifications = {
  load: "FETCH_NOTIFICATION_DETAILS",
  success: "FETCH_NOTIFICATION_DETAILS_SUCCESS",
  failure: "FETCH_NOTIFICATION_DETAILS_ERROR",
};

// clear notification
export const clearNotifications = {
  load: "CLEAR_NOTIFICATION_DETAILS",
  success: "CLEAR_NOTIFICATION_DETAILS_SUCCESS",
  failure: "CLEAR_NOTIFICATION_DETAILS_ERROR",
};
// Create RV Property
export const createRvProperty = {
  load: "CREATE_RV_PROPERTY",
  success: "CREATE_RV_PROPERTY_SUCCESS",
  failure: "CREATE_RV_PROPERTY_ERROR",
};

//token

export const fetchLinkToken = {
  load: "FETCH_LINK_TOKEN",
  success: "FETCH_LINK_TOKEN_SUCCESS",
  failure: "FETCH_LINK_TOKEN_ERROR",
};

export const fetchStripeBankToken = {
  load: "FETCH_STRIPE_BANK_TOKEN",
  success: "FETCH_STRIPE_BANK_TOKEN_SUCCESS",
  failure: "FETCH_STRIPE_BANK_TOKEN_ERROR",
};

export const fetchIcalLink = {
  load: "FETCH_ICAL_LINK",
  success: "FETCH_ICAL_LINK_SUCCESS",
  failure: "FETCH_ICAL_LINK_ERROR",
};

export const advanceFilter = {
  updateBed: "UPDATE_BEDROOM_COUNT",
  updateBath: "UPDATE_BATHROOM_COUNT",
  resetFilter: "RESET_FILTER",
};

export const messageCount = {
  load: "FETCH_MESSAGE_COUNTS",
  success: "FETCH_MESSAGE_COUNTS_SUCCESS",
  failure: "FETCH_MESSAGE_COUNTS_ERROR",
  reset: "RESET_MESSAGE_COUNTS",
};

// Modal open and close action types for handling common modal state.
export const modalActionTypes = {
  OPEN: 'MODAL_OPEN',
  RESET: 'MODAL_RESET'
}
