import { call, put, takeEvery } from "redux-saga/effects";
import { getAllMessagesCount } from "../Requests/create-new-list";
import { messageCount } from "../../ActionTypes";
import { request_loader } from "../../Actions/loader";

import {
  allMessageCoutFail,
  allMessageCoutSuccess,
} from "../../Actions/messageCount";

export function* handlMessageCount({}) {
  try {
    // yield put(request_loader({ load: true }));
    const response = yield call(getAllMessagesCount);
    const { data } = response?.data;
    if (data) {
      yield put(
        allMessageCoutSuccess({
          data: data,
        })
      );
      //yield put(request_loader({ load: false }));
    } else {
      yield put(allMessageCoutFail(data));
    }
  } catch (error) {
    yield put(allMessageCoutFail(error));
  }
}

export function* watchLoadhandMessageCount() {
  yield takeEvery(messageCount.load, handlMessageCount);
}
