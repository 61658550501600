import { listAction,listFeaturedHistory,propAction } from '../../ActionTypes/admin';

export const get_prop_list = (data) => ({
  type: listAction.loadProp,
  payload: data,
});

export const display_prop_list = (data) => ({
  type: listAction.displayProp,
  payload: data,
});

export const update_prop_status = (data) =>({
  type: listAction.updatePropStatus,
  payload: data,
})

export const approve_prop_list = (data) => ({
  type: propAction.propApr,
  payload: data,
});

export const reject_prop_list = (data) => ({
  type: propAction.propRej,
  payload: data,
});

export const load_featured_history = (data) => ({
  type: listFeaturedHistory.load,
  payload: data
});

export const store_featured_history = (data) => ({
  type: listFeaturedHistory.success,
  payload: data
});