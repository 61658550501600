import { bookingAction } from "../ActionTypes";
import { initial_state } from "../../Constants/Common";

const Booking = (state = initial_state, action) => {
  switch (action.type) {
    case bookingAction.displayListAdmin:
     return {...state,...action.payload}
    case bookingAction.displayDetail:
      return {...action.payload} 
    case bookingAction.updateStatusBooking:
      return {...state,status:action.payload};  
    case bookingAction.listOnlyUpdate:
      return {...state,lists:action.payload}  
    default:
      return { ...state };
  }
};
export default Booking;
