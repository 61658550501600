import { createRvProperty } from "../ActionTypes";
const data = {
  loading: true,
  rv_property_info: [],
  error: "",
  propertyInfoFinished: false,
};
const rv_property_info = (state = data, action) => {
  switch (action.type) {
    case createRvProperty.load:
      return { ...state, propertyInfoFinished: false, loading: true };
    case createRvProperty.success:
      return {
        ...state,
        loading: false,
        propertyInfoFinished: true,
        propertyInfo: action.payload,
      };
    case createRvProperty.failure:
      return {
        ...state,
        loading: false,
        propertyInfoFinished: false,
        error: action.payload,
      };
    default:
      return { ...state };
  }
};

export default rv_property_info;
