import {statusCodes,diffStructure} from "../Constants/Common";
import error_messages from "../Constants/error-messages";
window.code = statusCodes;
window.diff = diffStructure;
window.msgs = error_messages;

window.setToken = function(setArr={},removeArr=[]){
    for (var key in setArr) {
        localStorage.setItem(key,setArr[key]);
    }
    let i = 0;
    for(;i<removeArr.length;i++){
        localStorage.removeItem(removeArr[i]);
    }
  }