import { paymentActn } from "../ActionTypes";

export const homeownerPaymentLoad = (data) => ({
  type: paymentActn.load,
  payload: data,
});

export const renterPaymentLoad = (data) => {
  return {
    type: paymentActn.loadRenter,
    payload: data,
  };
};

export const homeownerPaymentDisplay = (data) => ({
  type: paymentActn.display,
  payload: data,
});
