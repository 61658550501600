import { sendMessage } from "../../ActionTypes/Renter";
const data = {
  loading: true,
  messages: [],
  error: "",
};
const messages = (state = data, action) => {
  switch (action.type) {
    case sendMessage.load:
      return { ...state, messageSent: false, loading: true };
    case sendMessage.success:
      return { ...state, loading: false, messageSent: true, ...action.payload };
    case sendMessage.failure:
      return {
        ...state,
        messageSent: false,
        loading: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default messages;
