import axios from "axios";
import { apiConstantsAdmin } from "../../../../Constants/api_constant_admin";

export const requestLoadBusinessAdmins = (param) => {
  return axios.get(
    apiConstantsAdmin.LOAD_BUSINESS_ADMINS,
    {
    params: { ...param },
    }
  );
};

export const requestCreateBusinessAdmins = (param) => {
  return axios({
    method: "POST",
    url: apiConstantsAdmin.CREATE_BUSINESS_ADMINS,
    data: {
      ...param,
    },
    validateStatus: (status) => {
      return status === 200 || status === 422;
    },
  });
};

export const requestUpdateStatusBusinessAdmins = (param) => {
  return axios({
    method: "PUT",
    url: apiConstantsAdmin.UPDATE_USER_STATUS_ADMIN,
    data:param,
    validateStatus: (status) => {
      return status === 200 || status === 422;
    },
  });
};
