import { call, put, takeEvery } from "redux-saga/effects";
import { requestCreateBasicInfo } from "../Requests/create-new-list";
import { createBasicInfo } from "../../ActionTypes";
import {
  create_basic_info_error,
  create_basic_info_success,
} from "../../Actions/create-new-list";
import {
  CREATE_NEW_LIST_PATHS,
  OWNER_PATHS,
} from "../../../config/Owner-And-NewList_Paths";
import { constantTexts } from "../../../Constants/Common";
import { set_message } from "../../Actions/message";
import { request_loader } from "../../Actions/loader";
const p_id = localStorage.getItem("p_id");

export function* handleCreateBasicInfo({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const { basicInfo, navigate, status } = payload;

    basicInfo.basicInformation.no_of_guest =
      +basicInfo.basicInformation.no_of_guest;
    const response = yield call(requestCreateBasicInfo, basicInfo, status);
    const { data } = response;
    if (data) {
      localStorage.setItem("p_id", data.data.property_id);
      yield put(
        set_message({
          error_code: data?.msg,
          code: constantTexts.SUCCESS,
        })
      );
      yield put(create_basic_info_success(data.data));
      if (!p_id) {
        localStorage.setItem("p_id", data.data.property_id);
      }
      if (status?.isRejected) navigate(`${OWNER_PATHS.REDIRECT}`);
      else if( status?.isPublished) navigate(
        `${CREATE_NEW_LIST_PATHS.LAYOUT_PATH}${
          CREATE_NEW_LIST_PATHS.PROPERTY_INFO_ONLY
        }${data.data.property_id ? data.data.property_id : p_id}`
      );
      else if (
        basicInfo?.basicInformation?.property_type === "rv-parking-site"
      ) {
        navigate(
          `${CREATE_NEW_LIST_PATHS.LAYOUT_PATH}${
            CREATE_NEW_LIST_PATHS.RV_PROPERTY_INFO_ONLY
          }${data.data.property_id ? data.data.property_id : p_id}`
        );
        localStorage.setItem(
          "state",
          data.data.basicInformation.fulladdress.state
        );
      } else
        navigate(
          `${CREATE_NEW_LIST_PATHS.LAYOUT_PATH}${
            CREATE_NEW_LIST_PATHS.PROPERTY_INFO_ONLY
          }${data.data.property_id ? data.data.property_id : p_id}`
        );
      localStorage.setItem(
        "state",
        data.data.basicInformation.fulladdress.state
      );

      yield put(request_loader({ load: false }));
    } else yield put(create_basic_info_error(data));
  } catch (error) {
    yield put(create_basic_info_error(error));
  }
}

export default function* watchCreateBasicInfo() {
  yield takeEvery(createBasicInfo.load, handleCreateBasicInfo);
}
