import { ADMINPATHS } from "./webPathAdmin";

export const OWNER_PATHS = {
  REDIRECT: "/app/owner-listing",
  OWNER_LISTING: "/owner-listing",
  OWNER_FEATURE_LISTING: "/owner-listing/feature-listing",
  OWNER_BOOKINGS: "/owner-bookings",
  OWNER_BOOKINGS_DETAIL: "/owner-bookings/:id",
  OWNER_PAYMENT_MANAGEMENT: "/owner-payment-managements",
  OWNER_PAYMENT_MANAGEMENT_ROUTE:
    "/owner-payment-managements?year=" + new Date().getFullYear(),
  OWNER_MESSAGES: "/owner-messages",
  OWNER_MESSAGES_DETAIL: "/messages-detail/:type/:id",
  OWNER_MESSAGES_DETAIL_ONLY: "/messages-detail/",
  OWNER_NOTIFICATION: "/owner-notifications",
  OWNER_PROFILE: "/owner-profile",
  OWNER_PROFILE_EDIT: "/profile/edit",
  FEATURE_YOUR_LISTING: "/feature-your-listing/:property_id",
  FEATURE_LISTING_PAY: "/feature-your-listing/pay/",
  FEATURE_LISTING_PAY_WITH_ID: "/feature-your-listing/pay/:property_id",
  EVICTION_PAY_WITH_ID: 'listing-eviction/pay',
  LISTING_FEATURED: "/listing-featured-successfully/",
  LISTING_FEATURED_WITH_ID: "/listing-featured-successfully/:property_id",
  LISTING_DETAILS_ONLY: "/listing-details/",
  LISTING_DETAILS_WITH_ID: "/listing-details/:property_id",
  LEASE_SIGNOR_DOC_ONLY: "/lease-signor-document/",
  FEATURE_YOUR_LISTING_ONLY: "/feature-your-listing/",
  LEASE_SIGNOR_DOCUMENT: "/lease-signor-document/:ls_id",
  OWNER_PAY_CANCELLATION_FEE: "/owner-pay-cancellation-fees",
  OWNER_EVICTION_DETAIL: "/owner-eviction/:id",
  OWNER_EVICTION: "/owner-eviction",
};
export const RENTER_PATHS = {
  REDIRECT: "/app/renter-bookings",
  RENTER_BOOKINGS: "/renter-bookings",
  RENTER_BOOKING_DETAIL: "/renter-bookings/:id",
  RENTER_MESSAGES: "/renter-messages",
  RENTER_MESSAGES_DETAIL: "/messages-detail/:type/:id",
  RENTER_MESSAGES_DETAIL_ONLY: "/messages-detail/",
  RENTER_NOTIFICATION: "/renter-notifications",
  RENTER_PROFILE: "/renter-profile",
  RENTER_EDIT_PROFILE: "/renter-edit-profile",
  RENTER_PAYMENT_MANAGEMENT: "/renter-payment-managements",
  RENTER_PAYMENT_MANAGEMENT_ROUTE:
    "/renter-payment-managements?year=" + new Date().getFullYear(),
  RENTER_PAYMENTS: "/renter-payments",
  // RENTER_EVICTION: "/proceed-with-eviction",
  RENTER_EVICTION_DETAIL: "/renter-eviction/:id",
  RENTER_EVICTION: "/renter-eviction",
  RENETR_PENALTY_PAY: "/pay-penalty/:property_id/:booking_id",
  RENETR_PENALTY_PAY_ONLY: "/pay-penalty/",
  BACKGROUNDCHECKDETAIL: "/renter/background-check/detail/:id",
  BACKGROUNDCHECKDETAILONLY: "/renter/background-check/detail/",
};

export const CREATE_NEW_LIST_PATHS = {
  REDIRECT: "/app/create-new-list/basic-info",
  CREATE_NEW_LIST: "/create-new-list/*",
  BASIC_INFO: "/basic-info",
  BASIC_INFO_WITH_ID: "/basic-info/:property_id",
  LEASE_SIGNOR: "/lease-signor/:state/:property_id",
  LEASE_SIGNOR_DOC: "/lease-signor/:ls_id",
  PROPERTY_INFO: "/property-info/:property_id",
  RV_PROPERTY_INFO: "/rv-property-info/:property_id",
  BANK_INFO: "/bank-ac-info/:property_id",
  REVIEW_DETAILS: "/review-details/:property_id",
  CALLBACK_TO: "/owner-listing",
  LAYOUT_PATH: "/app/create-new-list",
  LEASE_SIGNOR_ONLY: "/lease-signor/",
  PROPERTY_INFO_ONLY: "/property-info/",
  RV_PROPERTY_INFO_ONLY: "/rv-property-info/",
  BASIC_INFO_ONLY: "/basic-info/",
  BANK_INFO_ONLY: "/bank-ac-info/",
  REVIEW_DETAILS_ONLY: "/review-details/",
  HOTEL_LISTING_PAY: "/hotel-pay/",
  HOTEL_LISTING_PAY_WITH_ID: "/hotel-pay/:property_id",
};

export const COMMON_PATHS = {
  HOME: "/home",
  APP: "/app/*",
  APP1: "/app",
  LOGIN: "/login",
  LOGIN_USING_OTP: "/login-with-otp",
  CREATE_NEW_PASSWORD: "/create-new-password",
  FORGOT_PASSWORD: "/forgot-password",
  FORGOT_PASSWORD_SUCCESS: "/password-update-success",
  EMAIL_LINK_SEND: "/email-varification-link/sent",
  VERIFY_USER_EMAIL_SUCCESS: "/user-email/verify/success",
  EMAIL_LINK_FORGOT_PASSWORD_VERIFY: "/verify",
  CHECKING_BACKGROUND_VERIFICATION: "/check-background/:booking_id",
  CHECKINGBACKGROUNDVERIFICATION: "/check-background",
  DEMOMAP: "/demo-map",
  OTP: "/otp",
  OTP_SEND: "/otp/login_otp_send",
  SIGNUP: "/signup",
  CHOOSE_ROLE: "/choose-role",
  SIGNUP_USER_DETAILS: "/signup/user-details",
  SIGNUP_DETAILS: "/login",
  VERIFY_LEASE_SIGNOR_DETAILS: "/leasesignor/verify/mobile",
  VERIFY_LEASE_SIGNOR_SUCCESS: "/leasesignor/verify/success",
  LEASE_SIGNOR_GET_DOCUMENT: "/leasesignor/get-document",
  LEASE_SIGNOR_DOCUMENT_SIGNES: "/leasesignor/get-document/success",
  COMPLETE_PROFILE: "/signup/complete-profile",
  SIGNUP_SUCCESS_MESSAGE: "/signup/success",
  SEARCH_RESULT: "/search-result",
  PROPERTY_DETAIL: "/property-detail/:id",
  PROPERTYDETAIL: "/property-detail/",
  ABOUT_US: "/about-us",
  OUR_TEAM: "/our-team",
  PRESS_PAGE: "/press",
  BLOG: "/blog",
  BLOG_DETAIL: "/blog/how-to-be-a-great-host",
  BLOG_DETAIL_2: "/blog/how-to-offer-a-welcoming-space-to-guests",
  BLOG_DETAIL_3: "/blog/travel-nurse-contracts",
  BLOG_DETAIL_4: "/blog/travel-nurse-rental-guide",
  CONTACT_US: "/contact-us",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_AGREEMENT: "/terms-of-agreement",
  TRAVEL_TIPS: "/travel-tips",
  RENTER_PAGE: "/Renter",
  HOMEOWNER_PAGE: "/homeOwner",
  TERMS_AGREEMENT_RENTER: "/terms-of-agreement-renter",
  FAQ: "/faq",
  BOOKING_LEASE_AGREEMENT: "/lease-agreement",
  BACKGROUND_CHECK: "/background-check",
  BOOKING_PAYMENT: "/booking-payment",
  PROPERTY_BOOKED: "/property-booked",
  PROPERTY_CANCELLED: "/booking-cancelled",
  DEFAULT: "/",
  CALLBACK: "*",
  CHECKIN_DETAILS: "/checkin-checkout-details",
  CHECKIN_DETAILS_WITH_ID: "/checkin-checkout-details/:id",
  ICAL_LINK_WITH_ID: "/ical-link/:id",
  REACT_IFRAME: "/verify-background-check/:booking_id/:invitation_id",
  REACTIFRAME: "/verify-background-check",
};

export const REDIRECTIONS = {
  default_redirect: COMMON_PATHS.HOME,
  owner_redirect: OWNER_PATHS.REDIRECT,
  renter_redirect: RENTER_PATHS.REDIRECT,
  admin_redirect: ADMINPATHS.REDIRECT,
  "bus-admin_redirect": ADMINPATHS.REDIRECT,
  "bus-admin_success_redirect": COMMON_PATHS.LOGIN,
  lost_pass_redirect: COMMON_PATHS.OTP,
  VERIFY_USER_200: COMMON_PATHS.COMPLETE_PROFILE,
  VERIFY_USER_redirect: COMMON_PATHS.SIGNUP_USER_DETAILS,
  VERIFY_USER_redirect_force: COMMON_PATHS.SIGNUP,
  CHECK_OTP_200: COMMON_PATHS.CREATE_NEW_PASSWORD,
  CHECK_OTP_redirect: COMMON_PATHS.FORGOT_PASSWORD,
  leasesignor_faild_redirect: COMMON_PATHS.LOGIN,
  forgotpassword_faild_redirect: COMMON_PATHS.FORGOT_PASSWORD,
  forgotpassword_success_redirect: COMMON_PATHS.CREATE_NEW_PASSWORD,
  leasesignor_success_redirect: COMMON_PATHS.VERIFY_LEASE_SIGNOR_DETAILS,
  login_otp_send_redirect: COMMON_PATHS.LOGIN_USING_OTP,
  leasesignor_document_success_redirect: COMMON_PATHS.LEASE_SIGNOR_GET_DOCUMENT,
  newBusinessAdmin_success_redirect: COMMON_PATHS.CREATE_NEW_PASSWORD,
};
