import { watchPayPropertyFeaturedAmount, watchPropertyFeaturedAmount } from "../Handlers/Owner/FeatureProperty";
import watchLoadLeaseSignorDetails from "../Handlers/Owner/LeaseSignorDetails";
import { watchCreateLeavingReview } from "../Handlers/Owner/LeavingReviews";
import { watchFetchAllLeaseSignors } from "../Handlers/Owner/Profile";
import { watchPropertyHotelPaymentAmount } from "../Handlers/Owner/PropertyHotelPay";
import {watchOwnerNotifications,
        watchClearNotifications} 
        from "../Handlers/Owner/NotificationOwner";
import {watchOwnerIcalLink,} 
            from "../Handlers/Owner/IcalLinkSaga";
        

const OwnerSaga = [
    watchPropertyFeaturedAmount(),
    watchPayPropertyFeaturedAmount(),
    watchFetchAllLeaseSignors(),
    watchLoadLeaseSignorDetails(),
    watchPropertyHotelPaymentAmount(),
    watchCreateLeavingReview(),
    watchOwnerNotifications(),
    watchClearNotifications(),
    watchOwnerIcalLink(),
]

export default OwnerSaga;