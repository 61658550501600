import { call, put, takeEvery } from "redux-saga/effects";
import {
  COMMON_PATHS,
  RENTER_PATHS,
} from "../../../../config/Owner-And-NewList_Paths";
import { constantTexts } from "../../../../Constants/Common";
import { request_loader } from "../../../Actions/loader";
import { set_message } from "../../../Actions/message";
import {
  send_message_error,
  send_message_success,
} from "../../../Actions/Renter/Messages";
import { sendMessage } from "../../../ActionTypes/Renter";
import { requestSendMessage } from "../../Requests/Renter/Message";

export function* handleSendMessage({ payload }) {
  const { msg, type, navigate, isNavigation } = payload;
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(requestSendMessage, msg, isNavigation);
    const { data } = response;
    if (data) {
      yield put(send_message_success(data.data));
      if (isNavigation)
        navigate(
          `${COMMON_PATHS.APP1}${RENTER_PATHS.RENTER_MESSAGES_DETAIL_ONLY}${type}/${data?.data?.threadId}`
        );
      yield put(
        set_message({
          error_code: data?.msg,
          code: constantTexts.SUCCESS,
        })
      );
      yield put(request_loader({ load: false }));
    } else {
      yield put(send_message_error(data));
    }
  } catch (error) {
    yield put(send_message_error(error));
  }
}

export function* watchSendMessage() {
  yield takeEvery(sendMessage.load, handleSendMessage);
}
