import {
    fetchNotifications,clearNotifications
  } from "../../ActionTypes";
  
  //Notification
  export const notification_request_details = (param) => ({
    type: fetchNotifications.load,
    payload: param,
  });
  export const notification_request_details_success = (data) => ({
    type: fetchNotifications.success,
    payload: data,
  });
  export const notification_request_details_error = (data) => ({
    type: fetchNotifications.failure,
    payload: data,
  });

  export const clear_notification_request_details = (param) => ({
    type: clearNotifications.load,
    payload: param,
  });
  export const clear_notification_request_details_success = (data) => ({
    type: clearNotifications.success,
    payload: data,
  });
  export const clear_notification_request_details_error = (data) => ({
    type: clearNotifications.failure,
    payload: data,
  });