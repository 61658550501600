import {
  CanadaFlagIcon,
  UsaFlagIcon,
  EditRedIcon,
  GreenCheckIcon,
  CheckIcon,
  RedCross,
  BookingCancel,
  TvIcon,
  WashingMachineIcon,
  DryerIcon,
  AirConditionIcon,
  GymIcon,
  HairDryerIcon,
  UnPublishedIcon,
} from "../assets/img/imagesh";
import queryString from "query-string";
import { ADMINPATHS } from "../config/webPathAdmin";
import axios from "axios";
import { apiConstants } from "./api_constants";

import { GA4React } from "ga-4-react";
const ga4react = new GA4React(process.env.REACT_APP_measurementId).initialize();

export { ga4react };

export const facilities = {
  tv: { icon: TvIcon, name: "TV" },
  "washing machine": { icon: WashingMachineIcon, name: "Washing Machine" },
  dryer: { icon: DryerIcon, name: "Dryer" },
  "air conditioner": { icon: AirConditionIcon, name: "Air conditioning" },
  gym: { icon: GymIcon, name: "Gym" },
  "hair dryer": { icon: HairDryerIcon, name: "Hair dryer" },
};
export const rvSite = "rv-parking-site";
export const utilities = {
  water: { name: "Water" },
  electricity: { name: "Electricity" },
  cable: { name: "Cable" },
  internet: { name: "Internet" },
};
export const iCalError = ["Link name required", "Link required"];
export const forgetPasswordLabel = {
  mobile: {
    key: "mobile",
    label: "Mobile Number",
    placeholder: "Enter Mobile Number.",
    buttonLabel: "Send Passcode",
  },
  email: {
    key: "email",
    label: "Email Address",
    placeholder: "Enter Email Address.",
    buttonLabel: "Email Verification Link",
  },
};

export const typeSwitch = [
  {
    type: "radio",
    value: "mobile",
    name: "type",
    label: "Mobile Number",
  },
  {
    type: "radio",
    value: "email",
    name: "type",
    label: "Email Address",
  },
];

export const SignupSuccess = {
  heading: "Verification email sent",
  subheading:
    "A verification link has been sent to your email account. Please click on the link to verify your email account.",
  buttontitle: "Proceed to dashboard",
};

export const SignedDocumentSuccess = {
  heading: "Document Verified Successfully!",
  subheading: "You`ve successfully verified document for the below property!",
  buttontitle: "GO TO HOME",
};

export const ForgotPasswordEmailSuccess = {
  heading: "Verification email sent",
  subheading:
    "A verification link has been sent to your email account. Please click on the link to verify your email account.",
  buttontitle: "Back to Sign in",
};

export const LeaseSignorVerifySuccess = {
  heading: "Lease Signor Verified Successfully",
  subheading: "You are successfully verified as a lease signor.",
  buttontitle: "GO TO HOME",
};

export const NewUserVerifySuccess = {
  heading: "Email Verified Successfully",
  subheading: "You have successfully verified your email address.",
  buttontitle: "GO TO HOME",
};

export const statusCodes = {
  200: {
    code: 200,
    header: "Success",
  },
  422: {
    code: 422,
    header: "Validation Error",
  },
  404: {
    code: 404,
    header: "Not Found",
  },
  400: {
    code: 400,
    header: "Invalid Credentials",
  },
  403: {
    code: 403,
    header: "Authorization Error",
  },
  204: {
    code: 204,
    header: "Not Exists",
  },
  401: {
    code: 401,
    header: "Unauthorized",
  },
  201: {
    code: 201,
    header: "SuccessFully Created",
  },
  500: {
    code: 500,
    header: "Undefined Error",
  },
};

export const diffStructure = [201, 200, 422, 401];
export const verify = {
  forgotpassword: "forgotpassword",
  leasesignor: "leasesignor",
  leasevarify: "LEASESIGNOR_VERIFY",
  leasedocument: "leasesignor_document",
  newuser: "newuser",
  user_otp_login: "login_otp_send",
  newBusinessAdmin: `newBusinessAdmin`,
};

export const constantTexts = {
  CHECK_OTP: "CHECK_OTP",
  VERIFY_USER: "VERIFY_USER",
  CREATE_NEW_LIST_LAYOUT: "CREATE_NEW_LIST_LAYOUT",
  OWNER_LAYOUT: "OWNER_LAYOUT",
  RENTER_LAYOUT: "RENTER_LAYOUT",
  CREATE_NEW_LIST: "create-new-list",
  REVIEW_DETAILS: "Review Details",
  PROPERTY_INFO: "Property Information",
  BASIC_INFO: "Basic Information",
  LEASE_SIGNOR: "Lease Signor",
  BANK_AC_INFO: "Bank Account Information",
  Payout_info: 'Payout Account Information',
  DOLLAR: "$",
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  PHONE_NUMBER: "Mobile Number",
  EMAIL_ADDRESS: "Email Address",
  BED_ROOMS: "Bedroom(s)",
  BATH_ROOMS: "Bathroom(s)",
  KITCHEN: "Kitchen",
  CLOSET: "Closet",
  ENTERANCE: "Entrance",
  PROPERTY_TYPE: "Property Type",
  NUMBER_OF_GUESTS: "Number of Guests",
  CHECK_IN_TIME: "Check-In Time",
  CHECK_OUT_TIME: "Check-Out Time",
  SECURITY_DEPOSIT_AMOUNT: "Security Deposit Amount",
  CLEANING_FEE: "Cleaning Fee",
  PARKING_FEE: "Parking Fee($)",
  RENTEL_PERIOD: "Rental Period",
  UTILITIES_INCLUDED: "Utilities Included",
  RENTEL_SPACE: "Rental space (in Sq. Ft.)",
  PROPERTY_SPACE: "Property space (in Sq. Ft.)",
  SAFE_AND_SUITABLE_CHILDREN: "Safe/Suitable for children",
  SAFE_AND_SUITABLE_INFANTS: "Safe/Suitable for infants",
  ALLOW_INSTANT_BOOKINGS: "Allow Instant Booking",
  BLACK_OUT_DATES: "Block-Out Dates",
  ROOMS: "Rooms",
  HOUSE_RULES: "House Rules",
  WEEKLY_RENT: "Weekly Rent",
  MONTHLY_RENT: "Monthly Rent",
  WEEKLY: "(Weekly)",
  MONTHLY: "(Monthly)",
  OPTIONAL: "Optional",
  PROCEED: "Proceed",
  CANCEL: "cancel",
  REJECTED: "rejected",
  PENDING: "pending",
  PUBLISHED: "published",
  PROPERTY_IMAGES_FIELD_TEXT: "Add property image(s)",
  PROPERTY_IMAGES_FIELD_TEXT2:
    "Upload images from your system in .png, .jpg, .gif format.",
  UPLOAD_DOC_HELP_TEXT:
    "Upload files from your system in .png, .jpg, .doc, .docx, .pdf format.",
  PROP_IMAGE_COMMON_ERROR_FOR_ID_DETAILS:
    "One or more images/videos uploaded did not match the criteria. These have not been uploaded.",
  PROP_IMAGE_COMMON_ERROR_FOR_MAX_LIMIT:
    "Maximum images/videos upload limit exceeded.",
  FLEXIBLE: "flexable",
  EMPTY_STRING: "",
  STARS: "**********",
  FILE: "file",
  SUB_HEAD: "Please fill in all the details for your listing.",
  ONELINE_SUB_HEAD:
    "Lease Signors are the authorised representatives for your listing who will approve the lease. Please list all the representatives. Request the Lease signor to check the Junk folder as well.",
  FIVE_MB: 5.2 * 1024 * 1024,
  EIGHT_MB: 8e6,
  TEN_MB: 10e6,
  FIVE_MB_ERROR: "File is too big, File size exceeds 5MB",
  EIGHT_MB_ERROR: "File is too big, File size exceeds 8MB",
  IMAGE_PIXEL_TOO_SMALL: "Pixel size is too small",
  IMAGE_PIXEL_TOO_BIG: "Pixel size is too Big",
  PROP_IMAGE_COMMON_ERROR:
    "One or more images/videos uploaded did not match the criteria. These have not been uploaded.",
  WARNING: "warning",
  SUCCESS: "success",
  SAVED: "saved",
  P_ID: "p_id",
  STATE: "state",
  CONGRATS: "congrats",
  DELETE: "delete",
  NAVIGATE: "navigate",
  OWNERLISTING: "ownerListing",
  FAQ: "FAQ",
  CONGRATULATION: "Congratulations!",
  PROPERTY_SUCCESS_MESSAGE:
    "Congratulations! Your listing has been sent for review. You will receive a notification once the listing is approved.",
  cleaingFeeErr: "Must be less than ",
  IMAGES_REQUIRED: "Images Required.",
  MIN_EIGHT_IMAGES_REQUIRED: "Minimum 8 images required.",
  MAX_TWENTY_IMAGES_REQUIRED: "Maximum 20 images required.",
  OWNER: "owner",
  RENTER: "renter",
  TWENTY_COUNT_ERROR: "Maximum 20 images can upload",
  MAX_IMAGE_UPLOAD_LIMIT: "Maximum images/video upload limit exceeded",
  UNPUBLISHED: "unpublished",
  LEASESIGNOR: "ls",
  IMAGE_MIN_WIDTH: 1024,
  IMAGE_MIN_HEIGHT: 683,
  IMAGE_MAX_WIDTH: 6000,
  IMAGE_MAX_HEIGHT: 4000,
  pleaseWait: "Loading. Please Wait...",
  twoHundred: 200,
  ZERO: 0,
  ONE: 1,
  HUNDRED: 100,
  TITLE: 'NursesBnB',
  LEASOR_SIGNOR_SUBHEADER: 'All your monthly renters will be required to sign this lease. For your protection, please read and pre-sign this lease that is in accordance with your state and local laws.',
  PROP_INFO_SUBHEAD: 'Next steps: For your protection, you will review and pre-sign a lease agreement that your monthly Renters will be required to sign at booking.'
};
export const classNames = {
  FORM_CONTROL: "form-control",
  MRB30: "mr-b-30",
  ERROR: "error-message",
};
export const fieldControls = {
  INPUT: "input",
  TEXT_AREA: "textArea",
  SELECT: "select",
  RADIO: "radio",
  CHECK_BOX: "checkbox",
  refer_array: ["one", "two", "three", "four", "five", "six"],
};
export const propertyTypes = [
  { key: "Apartment", value: "apartment" },
  { key: "Condo", value: "Condo" },
  { key: "Single-Family Home", value: "Single-Family Home" },
  { key: "Townhome", value: "Townhome" },
  { key: "Shared Home", value: "Shared Home" },
  { key: "Hotel", value: "Hotel" },
  { key: "Cabin", value: "Cabin" },
  { key: "RV Parking Site", value: "RV Parking Site" },
  { key: "Corporate Housing", value: "Corporate Housing" },
];
export const countries = ["usa", "cannada"];
export const pets = ["dog", "cat", "other"];

export const changeCase = (text, isHyphenAllowed) => {
  if (text === "1-3 month" || text === "3-6 month" || text === "6 month") {
    text = text + "s";
  }
  if (isHyphenAllowed) {
    text = text?.replace(/[_\s.]+(.)?/g, (_, c) =>
      c ? " " + c?.toUpperCase() : " "
    );
  } else {
    if (text === "corporate-housing") {
      let p = text.replace(
        "corporate-housing",
        "Corporate Housing/Property Management"
      );
      text = p;
    }
    text = text?.replace(/[-_\s.]+(.)?/g, (_, c) =>
      c ? " " + c?.toUpperCase() : " "
    );
  }
  return text?.substr(0, 1).toUpperCase() + text?.substr(1);
};

export const handlePropertyTypeCase = (text, isHyphenAllowed) => {
  if (isHyphenAllowed) {
    if (text === "corporate-housing") {
      let p = text.replace(
        "corporate-housing",
        "Corporate Housing/Property Management"
      );
      text = p;
    }
    text = text?.replace(/[_\s.]+(.)?/g, (_, c) =>
      c ? " " + c?.toUpperCase() : " "
    );
  } else {
    if (text === "corporate-housing") {
      let p = text.replace(
        "corporate-housing",
        "Corporate Housing/Property Management"
      );
      text = p;
    }
    text = text?.replace(/[-_\s.]+(.)?/g, (_, c) =>
      c ? " " + c?.toUpperCase() : " "
    );
  }
  return text?.substr(0, 1).toUpperCase() + text?.substr(1);
};

export const loadPropertyItems = (data, isHyphenAllowed = false) => {
  if (data) {
    return data?.map((elem) => {
      return {
        key:
          elem === countries[0]
            ? elem.toUpperCase()
            : changeCase(elem, isHyphenAllowed),
        value: elem,
      };
    });
  } else return [];
};

export const loadConstantItems = (data) => {
  let records = [];
  if (data) {
    for (var elem in data) {
      records.push({ key: data[elem]?.name || "Deleted", value: elem });
    }
  }
  return records;
};

export const loadCountriesStatesCities = (data) => {
  if (data) {
    return data?.map((elem) => {
      return {
        key: elem?.name,
        value: elem?.isoCode,
      };
    });
  } else return [];
};
export const loadStatesAndCities = (data) => {
  if (data) {
    return data?.map((elem) => {
      return {
        key: elem?.name,
        value: elem?.name,
      };
    });
  } else return [];
};

export const roomsTypes = [
  { key: "Private", value: "Private" },
  { key: "Sharing", value: "Sharing" },
];

export const ownerListingFilter = [
  { key: "Published", value: "published" },
  { key: "Pending", value: "pending" },
  { key: "Rejected", value: "rejected" },
  { key: "Featured", value: "featured" },
];

export const phoneRegExp = /\d{10}/;

export const positiveValue = /^[0]?[1-9]+\d*$/;

export const numberOnly = /^[+-]?\d*(?:[.,]\d*)?$/;

export const numberFormat = /^\d+$/;

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.\d*)(?=.*[!@#\$%\^&\*])(?=.{8,})/;

export const alphaRegex = /^\w*$/;
export const alphaWithFewChars = /^[aA-zZ0-9$+:;=@#|'<>^*()%\s.,&?!\-\b"]+$/;
export const aplhaSpecial = /^[A-Z @~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]*$/i;

export const emailRegex = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const charsRegex = /[`~,.<>;':"\/\[\]\|{}()=_+-]/;
export const emailRegex2 = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const emailRegex3 =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const canadaCodeRegex =
  /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
export const canadaCodeRegex2 = /^(?:[A-Z]\d[A-Z][ -]?\d[A-Z]\d)$/i;

export const usCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
export const blankSpaceregex = /^\S*$/;
export const onlyAlphabets = /^[aA-zZ\s]+$/;
export const alphaNumeric = /^[a-z0-9]+$/i;
export const dontAllowesSplChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
export const removeWhiteSpace = /^\S/;
export const decimalUpToTwo = /^(?:\d*\.\d{1,2}|\d+)$/;
export const urlValid =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

export const actions = {
  uvarify: "VERIFY_USER",
  otp: "CHECK_OTP",
  leasevarify: "LEASESIGNOR_VERIFY",
  user_otp_login: "login_otp_send",
};

export const linkPattern = new RegExp(
  "^(https?:\\/\\/)?" + // protocol
  "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
  "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
  "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
  "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
  "(\\#[-a-z\\d_]*)?$",
  "i"
); // fragment locator

export const headerFlag = {
  default: { key: "US", flagicon: UsaFlagIcon, alt: "USA Flag", title: "USA" },
  US: {
    key: "US",
    flagicon: UsaFlagIcon,
    alt: "USA Flag",
    title: "USA",
    defaultstate: "AL",
  },
  CA: {
    key: "CA",
    flagicon: CanadaFlagIcon,
    alt: "CANADA FLAG",
    title: "Canada",
    defaultstate: "AB",
    smalltitle: "Canada",
  },
};

export const numbers = 10;

export const numberValues = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
};

export const defaultNation = {
  isoCode: "US",
  name: "United States",
  phonecode: "1",
  flag: "🇺🇸",
};

export const homeFormErr = {
  goingErr: "Enter The OnBoarding Location.",
  checkinErr: "Enter Check-in Date.",
  checkoutErr: "Checkout Date Should Be Greater Check-in Date.",
  guestsErr: "Select The Number Of Guests.",
};

export const default_map_coordinate = {
  CA: {
    lat: 54.1792404,
    lng: -104.4181401,
  },
  US: {
    lat: 38.9807225,
    lng: -107.7941139,
  },
};

export const allNumbers = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  TWENTY: 20,
  HUNDRED: 100,
};

export const statuscode = {
  pending: {
    class: "pending",
    title: "Pending",
  },
  to_admin: {
    class: "to_admin",
    title: "To Admin",
  },
  background_check_pending: {
    class: "background_check_pending",
    title: "Background Check Pending",
  },
  eviction: {
    class: "eviction",
    title: "Eviction",
  },
  published: {
    class: "published",
    title: "Published",
  },
  rejected: {
    class: "rejected",
    title: "Rejected",
    bookingstatustitle: "Rejection Reason",
  },
  canceled: {
    class: "canceled",
    title: "Cancelled",
    bookingstatustitle: "Cancellation Reason",
  },
  featured: {
    class: "featured",
    title: "Featured",
  },
  approved: {
    class: "approved",
    title: "Approved",
  },
  apporved: {
    class: "approved",
    title: "Approved",
  },
  deleted: {
    class: "deleted",
    title: "Deleted",
  },
  is_deleted: {
    class: "deleted",
    title: "Deleted",
  },
  renter: {
    class: "renter",
    title: "Renter",
  },
  owner: {
    class: "homeowner",
    title: "Homeowner",
  },
  admin: {
    class: "admin",
    title: "Admin",
  },
  "bus-admin": {
    class: "business_admin",
    title: "Business Admin",
  },
  both: {
    class: "",
    title: "Both",
  },
  0: {
    class: "inactive",
    title: "Inactive",
  },
  1: {
    class: "active",
    title: "Active",
  },
  2: {
    class: "inactive",
    title: "Expired",
  },
  3: {
    class: "active",
    title: "Invited",
  },
  invited: {
    class: "active",
    title: "Invited",
  },
  ongoing_booking: {
    class: "status-btn active btn btn-primary",
    title: "ON-GOING",
    key: "ongoing_booking",
  },
  completed: {
    class: "completed",
    title: "COMPLETED",
    key: "completed",
  },
  completed_booking: {
    class: "completed",
    title: "COMPLETED",
    key: "completed_booking",
  },
  upcoming_booking: {
    class: "status-btn featured btn btn-primary",
    title: "UPCOMING",
    key: "upcoming_booking",
  },
  auto_rejected: {
    class: "auto_rejected",
    title: "AUTO REJECTED",
  },
  unpublished: {
    class: "unpublished",
    title: "unpublished",
  },
  expired: {
    class: "expired",
    title: "EXPIRED",
  },
  complete: {
    class: "completed",
    title: "COMPLETED",
    key: "completed",
  },
};

export const propFilterSelector = [
  { label: "Published", value: "published" },
  { label: "Featured", value: "featured" },
  { label: "Pending", value: "pending" },
  { label: "Rejected", value: "rejected" },
  { label: "Deleted", value: "deleted" },
];

export const propAllSelector = [
  { label: "Pending", value: "pending" },
  { label: "Approved", value: "approved" },
  { label: "Published", value: "published" },
  { label: "Unpublished", value: "unpublished" },
  { label: "Featured", value: "featured" },
  { label: "Rejected", value: "rejected" },
  { label: "Deleted", value: "deleted" },
  { label: "Changes Pending", value: "changes_pending" },
];

export const propOwnerSelector = [
  { label: "All", value: "all" },
  { label: "Pending", value: "pending" },
  { label: "Approved", value: "approved" },
  { label: "Published", value: "published" },
  { label: "Unpublished", value: "unpublished" },
  { label: "Featured", value: "featured" },
  { label: "Rejected", value: "rejected" },
];

export const messageFilterSelector = [
  { label: "All", value: "all" },
  { label: "Listing Details", value: "Listing Details" },
  { label: "Check-in date / time", value: "Check-in date / time" },
  { label: "Check-out date / time", value: "Check-out date / time" },
  { label: "House rules", value: "House rules" },
  { label: "Location", value: "Location" },
  { label: "Special Accommodations", value: "Special Accommodations" },
  { label: "General Information", value: "General Information" },
];

export const userFilterSelector = [
  { label: "Renter", value: "renter" },
  { label: "Homeowner", value: "owner" },
];

export const bookingFilterSelector = [
  { label: "All", value: "" },
  { label: "Pending", value: "pending" },
  { label: "Approved", value: "approved" },
  { label: "Completed", value: "completed" },
  { label: "Rejected", value: "rejected" },
  { label: "Cancelled", value: "canceled" },
  { label: "Background Check Pending", value: "background_check_pending" },
  { label: "Auto Rejected", value: "auto_rejected" },
  { label: "To Admin", value: "to_admin" },
  { label: "Eviction", value: "eviction" },
];

export const ownerpropFilterSelector = [
  { label: "Published", value: "published" },
  { label: "Featured", value: "featured" },
  { label: "Pending", value: "pending" },
  { label: "Rejected", value: "rejected" },
];

export const userStatusSwitch = {
  0: {
    class: "green-color",
    image: GreenCheckIcon,
    text: "Mark as Active",
    for: "active",
  },
  1: {
    class: "red-color",
    image: EditRedIcon,
    text: "Mark as Inactive",
    for: "inactive",
  },
  3: {
    class: "red-color",
    image: EditRedIcon,
    text: "Mark as Inactive",
    for: "inactive",
  },
};

export const backgroundCheckuserSwitch = {
  0: {
    class: "green-color",
    image: GreenCheckIcon,
    text: "Approve Background Check",
    for: "active",
  },
  1: {
    class: "green-color",
    image: GreenCheckIcon,
    text: "Approve Background Check",
    for: "active",
  },
  2: {
    class: "red-color",
    image: RedCross,
    text: "Reject Background Check",
    for: "inactive",
  },
};

export const propStatusSwitch = {
  1: {
    key: "approved",
    class: "",
    image: CheckIcon,
    text: "Approve Request",
  },
  2: {
    key: "rejected",
    class: "red-color",
    image: RedCross,
    text: "Reject Request",
  },
  3: {
    key: "cancel",
    class: "red-color",
    image: BookingCancel,
    text: "Cancel Booking",
  },
  4: {
    key: "cancel",
    class: "red-color",
    image: BookingCancel,
    text: "Cancel Request",
  },
  5: {
    key: "published",
    class: "red-color",
    image: UnPublishedIcon,
    text: "Unpublish",
  },
  6: {
    key: "unpublished",
    class: "red-color-no",
    image: CheckIcon,
    text: "Publish",
  },
};

export const keyselector = {
  published: ["5"],
  unpublished: ["6"],
  pending: ["1", "2"],
  rejected: ["1"],
  approved: ["2"],
  apporved: ["2"],
  active: ["1"],
  invited: ["0"],
  inactive: ["0"],
  deleted: ["1"],
  cancel: ["3"],
  canceled: ["3"],
  auto_rejected: ["3"],
  background_check_pending: ["3"],
  completed: ["1"],
  to_admin: ["1"],
  eviction: ["1"],
  expired: ["1"],
};

export const propStatus = {
  1: "approved",
  2: "rejected",
};

export const DEFAULTFILTER = {
  order_by: "",
  order: "desc",
  search: "",
  select: [],
  count: 1,
  initial: true,
  page: 0,
};

export const orderBy = {
  updated_At: "created_At",
  updatedAt: "createdAt",
};

export const initial_state = {
  lists: [],
  search: "",
  select: [],
  initial: "",
  totalPages: 0,
  page: 0,
  order_by: "",
  order: "",
};

export const initial_state_lease = {
  lists: [],
  initial: "",
  order_by: "",
  order: "",
};

export const DOCUMENT_LISTER = [
  {
    url: ADMINPATHS.APPPATH + ADMINPATHS.MANAGEDOCUMENTDETAIL,
    flag: UsaFlagIcon,
    title: "USA",
    endPoint: "US",
  },
  {
    url: ADMINPATHS.APPPATH + ADMINPATHS.MANAGEDOCUMENTDETAIL,
    flag: CanadaFlagIcon,
    title: "Canada",
    endPoint: "CA",
  },
];

export const CONFIGURATION = {
  mobileFirst: true,
  responsive: [
    {
      breakpoint: 200000,
      settings: "unslick",
    },
    {
      breakpoint: 1600,
      settings: "unslick",
    },
    {
      breakpoint: 1024,
      settings: "unslick",
    },
    {
      breakpoint: 767,
      settings: {
        dots: false,
        nav: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        dots: false,
        nav: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const DEFAULT_NO_RECORD = "-";

export const CONTACT_US_INFO = {
  phone1: "+1 470-502-4178",
  website: "https://nursesbnb.com",
  info_email: "support@nursesbnb.com",
  address: "-",
};

export const OTP_FIELDS = [
  {
    type: "text",
    maxLength: "1",
    name: "otp.one",
    className: "input-gray-bg",
    next: "two",
    prev: "",
    refer: "one",
  },
  {
    type: "text",
    maxLength: "1",
    name: "otp.two",
    className: "input-gray-bg",
    next: "three",
    prev: "one",
    refer: "two",
  },
  {
    type: "text",
    maxLength: "1",
    name: "otp.three",
    className: "input-gray-bg",
    next: "four",
    prev: "two",
    refer: "three",
  },
  {
    type: "text",
    maxLength: "1",
    name: "otp.four",
    className: "input-gray-bg",
    next: "five",
    prev: "three",
    refer: "four",
  },
  {
    type: "text",
    maxLength: "1",
    name: "otp.five",
    className: "input-gray-bg",
    next: "six",
    prev: "four",
    refer: "five",
  },
  {
    type: "text",
    maxLength: "1",
    name: "otp.six",
    className: "input-gray-bg",
    next: "",
    prev: "five",
    refer: "six",
  },
];

export const getDaysForList = (days) => {
  let month = [];
  for (let index = 1; index <= days; index++) {
    let day = {
      key: index,
      value: parseInt(index),
    };
    month.push(day);
  }
  return month;
};

export const bookingVariable = {
  cleaning: {
    key: "cleaning",
    value: 1,
    max: 2,
    min: 1,
  },
  parking: {
    key: "parking",
    value: 0,
    max: 10,
    min: 0,
  },
};

export const bookingVariables = {
  children: {
    min: 0,
    max: 11,
  },
  pets: {
    min: 0,
    max: 11,
  },
  infants: {
    min: 0,
    max: 11,
  },
};
export const quickPayingApartmentTypes = ["hotel", "corporate-housing"];
export const electricity = ["30Amp", "50Amp", "Other"];
export const countryCodes = ["US", "CA"];
export const SESSION_TIME = 240; // 4 hours => 60 * 4 = 240 min
export const autoFillSearchFilter = {
  labelClass: "home_label",
  label: "Going To <i> (Hospital or City and State) </i>",
  name: "location",
  nameLabel: "Search locations",
  placeholder: "Miami, Fort Lauderdale/ Broward, Miami",
  autocomplete: "off",
};

export const autoFillChecker = {
  labelClass: "home_label",
  label: "Please enter then select address from dropdown list",
  labelFor: "floatingInput",
  name: "location",
  nameLabel: "Search locations",
  placeholder: "Miami, Fort Lauderdale/ Broward, Miami",
  inputClass: "form-control",
  inputId: "floatingInput",
  autocomplete: "off",
};

export const defaultBookingVariables = {
  monthlyJob: 0,
  weeklyJob: 1,
  monthDays: 30,
  weekDays: 7,
};

export const userRoles = {
  owner: "Owner",
  admin: "Admin",
  renter: "Renter",
  _owner: "owner",
  _admin: "admin",
  _renter: "renter",
  _bus_admin: "bus-admin",
  homeowner: "Homeowner",
};

export const DefaultModalConfig = {
  rejectModal: {
    heading: "Reject Request",
    desc1: "Are you sure you want to reject the ",
    desc2: "booking from the system?",
  },
  cancelBooking: {
    heading: "Cancel Booking",
    heading1: "Cancel Request",
    desc1: "Please read the cancellation policy before cancelling the booking.",
    desc2: "Are you sure you want to cancel the ",
    desc3: "booking from the system?",
  },
  promocodeCreate: {
    heading: "Create promo code",
    desc1: "Fill in the details for creating a promo code.",
  },
  promocodeDelete: {
    heading: "Delete Promo code",
    desc1: "Are you sure you want to delete ",
    desc2: "promo code from the system?",
  },
  verifyaccount: {
    heading: "Verify You Account",
    desc1: "Proceed Your Account Verification ",
  },
  repdetails: {
    heading: "Add Representative Details",
    desc1: "Please add your further details to finalize the process.",
  },
  earlyTermination: {
    heading: "Early Termination of stay",
    heading1: "Cancel Request",
    desc1: "Please mark if the request is legitimate or illegitimate.",
    desc2: "Are you sure you want to cancel the ",
    desc3: "booking from the system?",
  },
  adminEarlyTermination: {
    heading: "Settle Early Termination Request",
    desc1: "Please mark if the request is legitimate or illegitimate.",
  },
};

export const rentalPeriods = {
  "2 weeks+": {
    min: process.env.REACT_APP_TWO_WEEKS_MIN,
    max: process.env.REACT_APP_TWO_WEEKS_MAX,
  },
  "1-3 month": {
    min: process.env.REACT_APP_ONE_THREE_MONTH_MIN,
    max: process.env.REACT_APP_ONE_THREE_MONTH_MAX,
  },
  "3-6 month": {
    min: process.env.REACT_APP_THREE_SIX_MONTH_MIN,
    max: process.env.REACT_APP_THREE_SIX_MONTH_MAX,
  },
  "6 month": {
    min: process.env.REACT_APP_SIX_MONTHS_MIN,
    max: process.env.REACT_APP_SIX_MONTHS_MAX,
  },
  "1 year": {
    min: process.env.REACT_APP_ONE_YEAR_MIN,
    max: process.env.REACT_APP_ONE_YEAR_MAX,
  },
};

export const preDefinedPeriods = [
  "2 weeks+",
  "1-3 month",
  "3-6 month",
  "6 month",
  "1 year",
];

export const deleteResons = [
  "Sold",
  "No longer responsible representative",
  "Under Contract for Sale",
  "No longer interested in renting",
  "Listing on other platform(s)",
  "Other",
];
export const deleteLiveListingReasons = [
  "Sold",
  "No longer responsible representative",
  "Under Contract for Sale",
  "Seasonal listing",
  "No longer interested in renting",
  "Listing on other platform(s)",
  "Other",
];
export const contactDetailsSchema = {
  contactDetails: {
    firstName: "",
    lastName: "",
    company: "",
    emailAddress: "",
    mobileNumber: "",
  },
};
export const scrolltoSpot = (reference) => {
  reference &&
    reference.current.scrollIntoView({
      behavior: "instant",
      block: "nearest",
      inline: "start",
    });
};

export const promo_code_type = {
  renter_fee: "Rent Amount",
  service_fee: "Service Fees",
  renter_service_fee: "Renter Service Fee",
  owner_service_fee: "Homeowner Service Fee",
  hotel_sign_up_fee: "Corporate Fee",
};

export const chargetitle = {
  background_check_fee: "Application Fee",
  renter_service_fee: "Service Fee",
  cancellation_charge: "Cancellation Fee",
  application_fee: "Application Fees",
  cleaning_fee: "Cleaning Fee",
  parking_fee: "Parking Fee",
  rent: "Accommodation",
  rent_amount: "Accommodation Amount",
  security_deposit: "Security Deposit",
  discount: "Discount",
  owner_processing_fees: "Homeowner Processing Fees",
  owner_processing_fees_discount: "Homeowner Processing Fees Discount",
};

export const statusCodesForDelete = ["pending", "approved", "rejected"];

export const messageType = ["INQUIRY", "BOOKING"];

export const subTypes = [
  "Listing Details",
  "Check-in date / time",
  "Check-out date / time",
  "House rules",
  "Location",
  "Special Accommodations",
  "General Information",
];

export const property_condition = {
  no_concern: "Property has no concerns",
  visable_damages: "Property has Visible Damages",
  not_cleaned: "Property has not been cleaned",
  not_expected: "Property is not as expected",
};

export const property_condition_homeowner = {
  no_concern: "Property has no concerns",
  visable_damages: "Property has new visible damages",
  not_cleaned: "Property needs to be painted due to Renter",
  not_expected: "Property needs additional deep cleaning due to Renter",
};

export const property_condition_admin = {
  no_concern: "Property has no concerns",
  not_expected: "Property has not met check-in/check-out requirements",
};

export const default_property_concern = "no_concern";
export const rejectedReasons = [
  "Listed homeowners do not match with the State,country,city,local municipal or property appraiser records",
  "Photos do not meet our acceptable use policy for marketing or text embedded within images",
  "Photos do not meet our acceptable use policy for quality",
];
export const rejaction_reasons = {
  state_not_matched:
    "Listed homeowners do not match with the State,country,city,local municipal or property appraiser records",
  text_embedded_within_image:
    "Photos do not meet our acceptable use policy for marketing or text embedded within images",
  policy_not_match: "Photos do not meet our acceptable use policy for quality",
};

export const ready_check_in = {
  ready_for_check_in:
    "Property ready for check in. Now images and videos can be uploaded for check-in. ",
  renter_gained_access: "Renter gained access.",
};

export const professions = [
  { key: "Healthcare", value: "Healthcare" },
  { key: "Education", value: "Education" },
  { key: "Finance", value: "Finance" },
  { key: "Telecommunication", value: "Telecommunication" },
  { key: "Others", value: "Others" },
];

// days at which the checking criterias will be enabled
export const defaultCheckinVariables = {
  readyForCheckin: 1, // 1 day before checking date
  renterGainedAccess: 0, // same day of checkin date
  renterCheckin: -2, // 2 days (48 hrs) from checkin date
  renterCheckout: 0, // check-out
  homeOwnerCheckin: 1, //1 day before checkin date
  homeOwnerCheckout: -2, // 2 days (48 hrs) from checkout date
};

export const appIds = {
  GOOGLE_API_ID: process.env.REACT_APP_GOOGLE_API_ID,
  FACEBOOK_API_ID: process.env.REACT_APP_FACEBOOK_API_ID,
  LINKEDIN_API_ID: process.env.REACT_APP_LINKEDIN_API_ID,
};

export const magicNumbers = {
  ZERO: 0,
  ONE: 1,
};

export const RVPropertContents1 = [
  {
    headerH: "Full Concrete Parking",
    isTrue: false,
  },
  {
    headerH: "Grass Parking",
    isTrue: false,
  },
  {
    headerH: "Concrete Patio",
    isTrue: false,
  },
  {
    headerH: "Grass Patio",
    isTrue: false,
  },
];
export const RVPropertContents2 = [
  {
    headerH: "Water",
    isTrue: false,
  },
  {
    headerH: "Internet",
    isTrue: false,
  },
  {
    headerH: "Sewer",
    isTrue: false,
  },
  {
    headerH: "Laundry Facility",
    isTrue: false,
  },
];
export const RVPropertContents3 = [
  {
    headerH: "Accomodate RV with slide out",
    isTrue: false,
  },
];
export const imageExtensions = [
  "gif",
  "jpeg",
  "png",
  "jpg",
  "JPG",
  "PNG",
  "JPEG",
  "GIF",
];
export const videoExtensions = ["mp4", "mov"];
export const docExtensions = ["pdf", "doc", "docx"];
export const getCurentREnterDetailes = async (id) => {
  if (id) {
    return await axios.get(apiConstants.LOAD_PROPERTY_CURRENT_RENTER + id);
  }
};
export const getLastBookingHistory = async () => {
  return await axios.get(apiConstants.BOOKING_HISTORY);
};

export const getEvictionHistory = async () => {
  return await axios.get(apiConstants.OWNER_REMAINING_EVICITIONS);
};

const QueryStringValue = (query, setDefaultCountry = true) => {
  const search = window.location.search;
  let code = "";
  if (setDefaultCountry) {
    code = query === "country" ? "US" : "";
  }
  if (search) {
    let parser = queryString.parse(search);
    if (query === "search") {
      parser = queryString.parse(search, { decode: false });
    }
    code = parser[query];
  }
  return code;
};
export const getTotalEvicitionFee = async () => {
  let queryData = {}
  if (QueryStringValue('year') != "")
    queryData.year = QueryStringValue('year');
  if (QueryStringValue('month') != "")
    queryData.month = QueryStringValue('month');
  if (QueryStringValue('country', false) != "")
    queryData.country = QueryStringValue('country', false);
  if (QueryStringValue('state') != "")
    queryData.state = QueryStringValue('state');
  if (QueryStringValue('city') != "")
    queryData.city = QueryStringValue('city');
  return await axios.get(apiConstants.TOTAL_EVCITION_FEE, { params: { ...queryData } });
  // return await axios.get(apiConstants.TOTAL_EVCITION_FEE);
};


export const incudedContriesCode2l = ["gu", "pr", "vi"];
export const restrictedCountries = ["gu", "pr", "vi", "us", "ca"];
//export const restrictedCountries = ["gu", "pr", "vi", "us"];
const NursesBnB = 'NursesBnB ::';

export const routeTypes = [
  { key: "app/create-new-list/basic-info", value: `${NursesBnB} Create New Listing | Basic Info` },
  { key: "app/create-new-list/property-info", value: `${NursesBnB} Create New Listing | Property Info` },
  { key: "app/create-new-list/lease-signor", value: `${NursesBnB} Create New Listing | Lease Signor` },
  { key: "app/create-new-list/bank-ac-info", value: `${NursesBnB} Create New Listing | Payout Account Info` },
  { key: "app/create-new-list/review-details", value: `${NursesBnB} Create New Listing | Review Details` },
  { key: "app/create-new-list/rv-property-info", value: `${NursesBnB} Create New Listing | Property Info` },

  // For common user
  { key: "login", value: `${NursesBnB} Login` },
  { key: "signup", value: `${NursesBnB} Signup` },
  { key: "forgot-password", value: `${NursesBnB} Forgot Password` },
  { key: "login-with-otp", value: `${NursesBnB} Login with mobile number` },

  //Public pages
  { key: "signup/user-details", value: `${NursesBnB} Signup | User Details` },
  { key: "terms-of-agreement-renter", value: `${NursesBnB} Terms Of Agreement Renter` },
  { key: "privacy-policy", value: `${NursesBnB} Privacy Policy` },
  { key: "contact-us", value: `${NursesBnB} Contact Us` },
  { key: "search-result", value: `${NursesBnB} Search Result` },
  { key: "property-detail", value: `${NursesBnB} Property Details` },
  { key: "Renter", value: `${NursesBnB} Renter` },
  { key: "homeOwner", value: `${NursesBnB} Homeowner` },
  { key: "about-us", value: `${NursesBnB} About Us` },
  { key: "travel-tips", value: `${NursesBnB} Travel Tips` },
  { key: "our-team", value: `${NursesBnB} Our Team` },
  { key: "blog", value: `${NursesBnB} Blog` },
  { key: "terms-of-agreement", value: `${NursesBnB} Terms of Agreement` },
  { key: "faq", value: `${NursesBnB} FAQ` },
  { key: "press", value: `${NursesBnB} Press` },
  { key: "home", value: `${NursesBnB} Home` },

  // Rental pages
  { key: "app/renter-payment-managements", value: `${NursesBnB} Renter Payments Management` },
  { key: "app/renter-bookings", value: `${NursesBnB} My Bookings` },
  { key: "app/renter-messages", value: `${NursesBnB} Renter Messages` },
  { key: "app/renter-notifications", value: `${NursesBnB} Renter Notifications` },
  { key: "app/renter-profile", value: `${NursesBnB} Renter Profile` },
  { key: "app/profile/edit", value: `${NursesBnB} Profile | Update` },
  { key: "app/messages-detail/BOOKING", value: `${NursesBnB} Message Details | Booking` },
  { key: "app/messages-detail/INQUIRY", value: `${NursesBnB} Message Details | Inquiry` },
  { key: "app/checkin-checkout-details", value: `${NursesBnB} Check-in Check-out Details` },
  { key: "app/renter-bookings/", value: `${NursesBnB} Renter Booking Details` },
  { key: "booking-payment", value: `${NursesBnB} Booking Payment` },
  { key: "lease-agreement", value: `${NursesBnB} Lease Agreement` },
  { key: "app/renter/background-check/detail", value: `${NursesBnB} Background Check Detail` },


  // Owner Pages
  { key: "app/owner-listing", value: `${NursesBnB} Owner Listings` },
  { key: "app/owner-bookings", value: `${NursesBnB} Owner Bookings` },
  { key: "app/owner-bookings/", value: `${NursesBnB} Owner Booking Detail` },
  { key: "app/owner-payment-managements", value: `${NursesBnB} Owner Payment Management` },
  { key: "app/owner-messages", value: `${NursesBnB} Owner Messages` },
  { key: "app/owner-notifications", value: `${NursesBnB} Owner Notifications` },
  { key: "app/owner-profile", value: `${NursesBnB} Owner Profile` },
  { key: "app/listing-details", value: `${NursesBnB} Listing Detail` },
  { key: "app/lease-signor-document", value: `${NursesBnB} Lease Signor Document` },
  { key: "app/owner-eviction/", value: `${NursesBnB} Proceed With Eviction` },
  { key: "app/feature-your-listing/", value: `${NursesBnB} Feature Your Listing` },
  { key: "app/owner-pay-cancellation-fees", value: `${NursesBnB} Owner Pay Cancellation Fees` },
  { key: "app/listing-eviction/pay", value: `${NursesBnB} Owner Eviction Pay` },

  // Admin Pages
  { key: "app/admin/dashboard", value: `${NursesBnB} Dashboard` },
  { key: "app/admin/user-management", value: `${NursesBnB} User Management` },
  { key: "app/admin/listing", value: `${NursesBnB} Listing Management` },
  { key: "app/admin/bookings", value: `${NursesBnB} Bookings` },
  { key: "app/admin/business-admin", value: `${NursesBnB} Business Admins` },
  { key: "app/admin/background-check", value: `${NursesBnB} Background Check` },
  { key: "app/admin/eviction-request", value: `${NursesBnB} Eviction Requests` },
  { key: "app/admin/promo-code-management", value: `${NursesBnB} Promo Codes Management` },
  { key: "app/admin/lease-documents", value: `${NursesBnB} Lease Documents` },
  { key: "app/admin/content-management", value: `${NursesBnB} Content Management` },
  { key: "app/admin/notification", value: `${NursesBnB} Notifications` },
  { key: "app/admin/dashboard/setting", value: `${NursesBnB} Dashboard | Settings` },
  { key: "app/admin/user-management/details/homeowner", value: `${NursesBnB} User Management | Homeowner Details` },
  { key: "app/admin/user-management/details/renter", value: `${NursesBnB} User Management | Renter Details` },
  { key: "app/admin/bookings/detail", value: `${NursesBnB} Bookings | Booking Detail` },
  { key: "app/admin/background-check/detail", value: `${NursesBnB} Background Check Detail` },
  { key: "app/admin/eviction-request/detail", value: `${NursesBnB} Eviction Request | Eviction Request Detail ` },
  { key: "app/admin/lease-document", value: `${NursesBnB} Manage Documents` },
  { key: "app/admin/edit-content", value: `${NursesBnB} Content Management | Edit Content` },
  { key: "app/admin/messages", value: `${NursesBnB} Messages` },
  { key: "app/admin/listing/detail", value: `${NursesBnB} Listings Management | Listing Detail` },
  { key: "admin/listing/listing-requests", value: `${NursesBnB} Listing Management | Listing Requests` }
];
