import {watchCreateBank, watchCreateCard, watchSetDefaultCard,watchcreateConnect}  from "../Handlers/stripe/card"
import watchFetchStripePaymentMethods, { watchDeleteSource } from "../Handlers/stripe/stripe-payment-methods";

const StripeSaga = [
    watchCreateCard(),
    watchCreateBank(),
    watchFetchStripePaymentMethods(),
    watchSetDefaultCard(),
    watchDeleteSource(),
    watchcreateConnect()
]

export default StripeSaga;