import { promocodeActions } from "../ActionTypes";

const data = {
  promocodeApplied: {},
  listingPromocodeApplied: {}
}
const Promocode = (state=data , action) => {
  switch (action.type) {
    case promocodeActions.applied:
      return {promocodeApplied:action.payload}
    case promocodeActions.remove:
      return {promocodeApplied:{} }
    case promocodeActions.display:
      return {...state,...action.payload} 
    case promocodeActions.updateOnly:
      return {...state,lists:state.lists.unshift(action.payload)}   
    case promocodeActions.listing_promocode_applied:
      return {listingPromocodeApplied: action.payload}
    case promocodeActions.remove_listing_promocode:
      return {listingPromocodeApplied:{} }
    default:
      return { ...state };
  }
};
export default Promocode;
