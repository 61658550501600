import { settings } from "../../ActionTypes/admin";
const data = {
  loading: true,
  setting: [],
  error: "",
};
const settingadmin = (state = data, action) => {
  switch (action.type) {
    case settings.storeAllSetting:
      return { ...state, ...action.payload };
    case settings.createNewCleaningFee:
      return { ...state, ...action.payload };
    case settings.createNewCleaningFeeSuccess:
      return {
        ...state,
        allCleaningFee: [...state.allCleaningFee, action.payload],
      };
    case settings.deleteCleaningFee:
      return { ...state, ...action.payload };
    case settings.deleteCleaningFeeSuccess:
      state.allCleaningFee.splice(action.payload, 1);
      return {
        ...state,
      };
    default:
      return { ...state };
  }
};
export default settingadmin;
