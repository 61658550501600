import {
    fetchIcalLink,
  } from "../../ActionTypes";
  
  export const icallink_request_details = (param) => ({
    type: fetchIcalLink.load,
    payload: param,
  });
  export const icallink_request_details_success = (data) => ({
    type: fetchIcalLink.success,
    payload: data,
  });
  export const icallink_request_details_error = (data) => ({
    type: fetchIcalLink.failure,
    payload: data,
  });