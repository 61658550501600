import {
    fetchAdminDashboardReports
  } from "../../ActionTypes/admin";
  
  const data = {
    loading: true,
    DashBoardRequest: {},
    error: "",
  };
 
  const dashboard_request = (state = data, action) => {
    switch (action.type) {
      case fetchAdminDashboardReports.load:
        return { ...state, loading: true };
      case fetchAdminDashboardReports.success:
        return { ...state, loading: false, DashBoardRequest: {...action.payload} };
      case fetchAdminDashboardReports.failure:
        return { ...state, loading: false, error: action.payload };
      default:
        return { ...state };
    }
  };
  
  export default dashboard_request;