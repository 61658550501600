import { call, put, takeEvery } from "redux-saga/effects";
import {
    requestFetchNotifications,
    requestDeleteNotifications
} from "../../Requests/owner/Notification-owner";
import {fetchNotifications,clearNotifications } from "../../../ActionTypes";
import {
  notification_request_details_success,
  notification_request_details_error,
  clear_notification_request_details_success,
  clear_notification_request_details_error,notification_request_details
} from "../../../Actions/Owner/Notification";
import { request_loader } from "../../../Actions/loader";
import { set_message } from "../../../Actions/message";
import { take } from "lodash";

export function* handleNotifications({ payload }) {
    try {
      yield put(request_loader({ load: true }));
      const response = yield call(requestFetchNotifications, payload);
      if (response) {
        yield put(request_loader({ load: false }));
        const { data, status } = response;
        if (status === window.code["200"].code) {
          yield put(notification_request_details_success(data.data));
        } else {
          yield put(notification_request_details_error(data.data));
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  export function* watchOwnerNotifications() {
    yield takeEvery(fetchNotifications.load, handleNotifications);
  }

  export function* clearNotificationsRequest({ payload }) {

    try {
      yield put(request_loader({ load: true }));
      const response = yield call(requestDeleteNotifications, payload);
      if (response) {
        yield put(request_loader({ load: false }));
        const { data, status } = response;
        if (status === window.code["200"].code) {
          yield take(clear_notification_request_details_success(data.data));
          payload?.dispatch(notification_request_details())
        } else {
          yield put(clear_notification_request_details_error(data.data));
        }
        yield put(
            set_message({
              error_code: data?.msg,
            })
          );
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  export function* watchClearNotifications() {
    yield takeEvery(clearNotifications.load, clearNotificationsRequest);
  }