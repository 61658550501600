const error_messages = {
  "Incorrect user or password":"Incorrect user or Password.",
  "non_refunded_booking":"This booking will be non-refundable.",
  "Invalid email email":"Email entered is incorrect.",
  "Invalid value mobile":"Please enter a valid mobile number.",
  "Invalid value first_name":"Please enter a valid first name.",
  "Invalid value last_name":"Please enter a valid last name.",
  "Invalid value token":"Unauthorized request please try again.",
  "Invalid value role":"Please select the role.",
  "Invalid value password":"Password entered is incorrect.",
  "invalid_mobile_number":"Please enter a valid 10 digit Mobile Number.",
  "This mobile number already in use" : "This mobile number already in use",
  "Image update successfully":"Profile picture updated successfully.",
  "user_not_found":"User doesn't exists. Please check credential again.",
  "otp sent successfully":"Passcode is sent successfully on mobile number. ",
  "This mobile number already in use mobile": "This mobile number already in use.",
  "invalid otp" : "Invalid Passcode",
  "Mobile verified successfully.":"Mobile Number verified successfully.",
  "mobile verifiled suceesfully": "Mobile Number verified successfully.",
  "Invalid value _id": "Unauthorized request. Please verified your mobile number.",
  "server_error": "Encountered server error",
  "password_update_success": "Password updated successfully.",
  "Mobile length should be minimum 10": "Please enter a valid mobile number." ,
  "Please enter a valid mobile": "Please enter a valid mobile number.",
  "This Email already in use email": "This email address is already taken.",
  "user_create_later": "Unable to fetch user please try again.",
  "Password Updated Successfully": "Password updated successfully",
  "request_time_out": "Request time out please try again.",
  "email_varified_successfully": "Email address is verified successfully.",
  "user_status_update_success": "User status updated successfully.",
  "Property Approved successfully": "Property approved successfully.",
  "prop_reject_success": "Property rejected successfully.",

  "profile_updated_successfully":"Profile updated successfully.",
  "unauth_action": "Unauthorized action. Please login and check back.",
  "fee_update_success": "Fees updated successfully.",
  "background_update_success": "Background Setting updated successfully.",
  "processing_fee_update_success": "Processing fees updated successfully.",
  "notification_update_success": "Notifications updated successfully.",
  "old_pass_does_not_match": "Old password does not match",
  "card_added_successfully" : "Card added successfully.",
  "set_default_card_successfully" : "Default card changed successfully",
  "bank_added_successfully" : "Bank added successfully",
  "property_featured_successfully" : "Property featured successfully",
  "admin_lease_empty_doc":'Please select the document to upload.',
  "admin_lease_state_not_selected":'Please select the state to proceed.',
  "admin_lease_wrong_state_selected":'Please select valid country state to proceed.',
  "booking_checkout_date_error": 'Checkout date must be atleast 14 days ahead of check-in date.',
  "booking_occupants_error": 'Please fill in all the fields.',
  "booking_occupants_exceeded": 'Number of occupants exceeds the guest limit.',
  "booking_occupants_duplicate": 'Emergency contact name must not match with occupants name.',
  "booking_emergancy_name_err":"Please enter valid emergency contact's name.",
  "booking_emergancy_mobile_err":"Please enter valid 10 digit mobile number.",
  "booking_cancel_owner":"Booking cancelled successfully",
  "hotel_pay_success": "One time payment for corporate done successfully.",
  "penalty_pay_success": "One time payment is done successfully.",
  "lease_signor_resend_invite_successfull": "Invite sent successfully to the lease signor.",
  "source_deleted_successfully" : " deleted successfully",
  "cancel_booking_comment" : "Please enter the cancellation remark.",
  "promo_code_created_successfully" : "Promo code created successfully.",
  "promo_code_updated_successfully" : "Promo code updated successfully.",
  "promo_code_status_updated_successfully":"Promocode status updated successfully.",
  "promo_code_deleted_successfully":"Promocode deleted successfully.",
  "cancellation_fee_charged":"Cancellation fee will be charged",
  "ready_to_checkin_done": "The property is now ready for check in. Please fill the check-in details.",
  "renter_grant_access_done": "Renter has been granted access",
  "checkin_details_filled": "Check-in/Check-out details filled.",
  "checkin_details_already_exist": "Check-in/Check-out details already exists.",
  "eviction_details_filled": "Eviction details filled.",
  "early_termination_details_filled": "Early termination of stay details filled.",
  "additional_comment_added": "Additional comment added.",
  "verification_email_resent": "Verification mail sent successfully. Please check your email.",
  "business_admin_success": "Profile updated successfully. Please login to access your account.",
  "stripe_connect_inprogress":"Stripe Connect verification is under progress.",
  "promocode_applied": "Promocode applied successfully",
  "promocode_deleted": "Promocode removed successfully",
  "email_verification_required":"You can list a property only after verifying your email address.",
  "eviction_submitted": "Eviction settled successfully.",
  "terminate_booking_comment" : "Please enter the early termination remark.",
  "termination_submitted": "Early termination settled successfully.",
  "contact_us": "Enquiry sent successfully.",
  "rental_period_error":"You can only select rental period in a sequence.",
  "lease_signor_already_exist": "This lease signor has already been added to your profile. Please select this lease signor from the dropdown list above.",
  "redirect_unpublished_listing": "Property does not exist. Redirected to homepage.",
  "publish_error": "This property can't published due to some of the lease signor has not signed the lease documents."
};
export default error_messages;
