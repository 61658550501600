import { paymentActn } from "../ActionTypes";
import { initial_state } from "../../Constants/Common";

const PaymentManagement = (state = initial_state, action) => {
  switch (action.type) {
    case paymentActn.display:
     return {...state,...action.payload}
    default:
      return { ...state };
  }
};
export default PaymentManagement;
