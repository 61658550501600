import { combineReducers } from "redux";
import adminReducer from "./admin";
import productList from "./productList";
import ownerReducer from "./owner";
import basicInfoImages_upload from "./basic-info-upload-images";
import basic_info from "./basic-info";
import property_info from "./property-info";
import lease_signor from "./lease-signor";
import review_details from "./review-details";
import bank_info from "./bank-info";
import home_page from "./home-page";
import user_details from "./user-details";
import loader from "./loader";
import auth from "./auth";
import otp from "./otp";
import completeProfile from "./complete_profile";
import message from "./message";
import stripeReducer from "./stripe";
import headlang from "./head-lang";
import map_coordinate from "./map-coordinate";
import propertyFeatured from "./owner/PropertyFeatured";
import staticContent from "./StaticContent";
import faq from "./Faq";
import UploadActn from "./UploadActn";
import Booking from "./Booking";
import SearchAutoFill from "./SearchAutoFill";
import Promocode from "./Promocode";
import RenterReducer from "./Renter";
import Checkin from "./Checkin";
import Eviction from "./Eviction";
import Notification from "./Notification/notification";
import Dashboard from "../Reducers/admin/DashboardReducers";
import rv_property_info from "./RvPropertyInfo";
import ical_info from "./IcalLink";
import Termination from "./Termination";
import fetchFilterReducer from "./fetchFilterReducer";
import AdvanceFilter from "./AdvanceFilter";
import messageAllcount from "./messageCount";
import modalReducer from "./modaReducer";
export default combineReducers({
  ...adminReducer,
  ...stripeReducer,
  ...ownerReducer,
  ...RenterReducer,
  AdvanceFilter,
  productList,
  basicInfoImages_upload,
  basic_info,
  property_info,
  bank_info,
  loader,
  headlang,
  auth,
  lease_signor,
  review_details,
  home_page,
  user_details,
  otp,
  completeProfile,
  message,
  map_coordinate,
  propertyFeatured,
  staticContent,
  faq,
  UploadActn,
  Booking,
  SearchAutoFill,
  Promocode,
  Checkin,
  Eviction,
  Notification,
  Dashboard,
  rv_property_info,
  ical_info,
  Termination,
  fetchFilterReducer,
  messageAllcount,
  modalReducer
});
