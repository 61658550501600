import { addSingleBankInfo } from "../ActionTypes";
const data = {
  loading: true,
  bankInfo: [],
  error: "",
  bankInfoFinished: false,
};
const bank_info = (state = data, action) => {
  switch (action.type) {
    case addSingleBankInfo.load:
      return { ...state, loading: true, bankInfoFinished: false };
    case addSingleBankInfo.success:
      return {
        ...state,
        loading: false,
        bankInfoFinished: true,
      };
    case addSingleBankInfo.failure:
      return {
        ...state,
        loading: false,
        bankInfoFinished: false,
        error: action.payload,
      };
    default:
      return { ...state };
  }
};

export default bank_info;
