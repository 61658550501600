import axios from "axios";
import { apiConstants } from "../../../Constants/api_constants";
import { numberFormat } from "../../../Constants/Common";

export function requestUserLogin(data) {
  const { email, password, recaptcha_token } = data;
  const req = {
    email: numberFormat.test(email) === true ? parseInt(email) : email,
    password: password,
    recaptcha_token: recaptcha_token
  };
  return axios({
    method: "POST",
    url: apiConstants.USER_LOGIN_CHECK,
    data: req,
    validateStatus: (status) => {
      return status === 200 || status === 403 || status === 404;
    },
  });
}

export function requestForgetPassOtp(data) {
  return axios({
    method: "POST",
    url: apiConstants.FORGET_PASSWORD_OTP,
    data: data,
    validateStatus: () => true,
  });
}

export function requestResetPassword(data) {
  return axios({
    method: "PUT",
    url: apiConstants.RESET_PASSWORD,
    data: data,
    validateStatus: () => true,
  });
}

export function requestEmailVarify(data, actn) {
  const url = apiConstants.FORGET_PASSWORD_EMAIL_VARIFY[actn] + "/" + data;
  return axios({
    method: "GET",
    url: url,
    validateStatus: () => true,
  });
}

export function requestLeaseDocSign(data) {
  return axios({
    method: "POST",
    url: apiConstants.LEASESIGON_VERIFY_DOC,
    data: data,
    validateStatus: () => true,
  });
}
