import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { COMMON_PATHS } from "../config/Owner-And-NewList_Paths";
import { userRoles } from "../Constants/Common";
import BlogDetail3 from "../Pages/PublicPages/BlogDetail3";
import BlogDetail4 from "../Pages/PublicPages/BlogDetail4";

const Home = React.lazy(() => import("../Pages/Home/Home"));
const ReactIframe = React.lazy(() =>
  import("../components/Common/ReactIframe")
);
const Signup = React.lazy(() => import("../Containers/Auth/signup"));
const Login = React.lazy(() => import("../Containers/Auth/login/Login"));
const AdminApp = React.lazy(() => import("../Pages/AdminApp/index"));
const OwnerApp = React.lazy(() => import("../Pages/OwnerApp/index"));
const RenterApp = React.lazy(() => import("../Pages/RenterApp/index"));
const Otp = React.lazy(() => import("../Containers/Auth/signup/Otp"));
const Infinite = React.lazy(() => import("../Containers/Infinite"));
const OtpSignIn = React.lazy(() =>
  import("../Containers/Auth/login/LoginWithOtp")
);
const CheckingBackgroundVerification = React.lazy(() =>
  import("../Containers/Renter/BackgroundCheckResponse")
);
const ForgotPassword = React.lazy(() =>
  import("../Containers/Auth/forgot-password/Forgot-password")
);

const IcalLink = React.lazy(() =>
  import("../components/CreateNewList/PropertyInfo/IcalLink")
);
const CreateNewPassword = React.lazy(() =>
  import("../Containers/Auth/forgot-password/Create-new-password")
);
const ForgotPasswordSuccess = React.lazy(() =>
  import("../Containers/Auth/forgot-password/Forgot-password-success")
);
const NewUserEmailSuccess = React.lazy(() =>
  import("../Containers/Auth/forgot-password/NewUserEmailSuccess")
);

const ForgotPasswordEmailVarify = React.lazy(() =>
  import("../Containers/Auth/forgot-password/verify-final")
);
const UserDetails = React.lazy(() =>
  import("../Containers/Auth/signup/User-details")
);
const CompleteProfile = React.lazy(() =>
  import("../Containers/Auth/signup/Complete-Profile")
);
const SuccessMessage = React.lazy(() =>
  import("../Containers/Auth/signup/Success-message")
);
const EmailVarificationLinkSent = React.lazy(() =>
  import("../Containers/Auth/forgot-password/email-success-message")
);

const VerifyLeaseSignor = React.lazy(() =>
  import("../Containers/Auth/signup/Verify-lease-signor")
);

const VerifyLeaseSignorSuccess = React.lazy(() =>
  import("../Containers/Auth/signup/Verify-lease-signor-success")
);

const LeaseSignDocument = React.lazy(() =>
  import("../Containers/Auth/signup/LeaseSignDocument")
);

const LeaseSignDocumentSuccess = React.lazy(() =>
  import("../Containers/Auth/signup/LeaseSignDocumentSuccess")
);

const SearchResult = React.lazy(() => import("../Pages/Home/SearchResult"));
const PropertyDetail = React.lazy(() =>
  import("../Pages/PublicPages/PropertyDetail")
);
const AboutUs = React.lazy(() => import("../Pages/PublicPages/AboutUs"));
const OurTeam = React.lazy(() => import("../Pages/PublicPages/OurTeam"));
const Press = React.lazy(() => import("../Pages/PublicPages/Press"));
const Blog = React.lazy(() => import("../Pages/PublicPages/Blog"));
const BlogDetail = React.lazy(() => import("../Pages/PublicPages/BlogDetail"));
const BlogDetail2 = React.lazy(() =>
  import("../Pages/PublicPages/BlogDetail2")
);
const ContactUs = React.lazy(() => import("../Pages/PublicPages/ContactUs"));
const TravelTips = React.lazy(() => import("../Pages/PublicPages/TravelTips"));
const Renter = React.lazy(() => import("../Pages/PublicPages/Renter"));
const HomeOwner = React.lazy(() => import("../Pages/PublicPages/HomeOwner"));
const PrivacyPolicy = React.lazy(() =>
  import("../Pages/PublicPages/PrivacyPolicy")
);
const TermsAgreement = React.lazy(() =>
  import("../Pages/PublicPages/TermsAndAgreement")
);

const TermsAgreementRenter = React.lazy(() =>
  import("../Pages/PublicPages/TermsAndAgreementRenter")
);
const Faq = React.lazy(() => import("../Pages/PublicPages/Faq"));

const BookingLeaseAgreement = React.lazy(() =>
  import("../Pages/PublicPages/BookingLeaseAgreement")
);
const BackgroundCheck = React.lazy(() =>
  import("../Pages/PublicPages/BackgroundCheck")
);
const BookingPayment = React.lazy(() =>
  import("../Pages/PublicPages/BookingPayment")
);
const PropertyBooked = React.lazy(() =>
  import("../Pages/PublicPages/PropertyBooked")
);

const PropertyCancelled = React.lazy(() =>
  import("../Pages/PublicPages/PropertyCancelled")
);
const MapDemo = React.lazy(() => import("../Pages/Components/MapDemo"));

const AuthRoutes = () => {
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const loginUser = auth.role;
  if (loginUser === userRoles._owner) {
    return <OwnerApp navigate={useNavigate} />;
  } else if (loginUser === userRoles._renter) {
    return <RenterApp />;
  } else if (
    loginUser === userRoles._admin ||
    loginUser === userRoles._bus_admin
  ) {
    return <AdminApp />;
  } else {
    return (
      <Navigate to={COMMON_PATHS.HOME} state={{ path: location.pathname }} />
    );
  }
};

const commonRoutes = [
  { path: COMMON_PATHS.DEMOMAP, element: <MapDemo /> },
  { path: COMMON_PATHS.APP, element: <AuthRoutes /> },
  { path: COMMON_PATHS.HOME, element: <Home /> },
  { path: COMMON_PATHS.LOGIN, element: <Login /> },
  { path: COMMON_PATHS.LOGIN_USING_OTP, element: <OtpSignIn /> },
  { path: COMMON_PATHS.SIGNUP, element: <Signup /> },
  { path: COMMON_PATHS.OTP + "/:action", element: <Otp /> },
  { path: COMMON_PATHS.FORGOT_PASSWORD, element: <ForgotPassword /> },
  { path: COMMON_PATHS.ICAL_LINK_WITH_ID, element: <IcalLink /> },
  {
    path: COMMON_PATHS.CHECKING_BACKGROUND_VERIFICATION,
    element: <CheckingBackgroundVerification />,
  },
  {
    path: COMMON_PATHS.REACT_IFRAME,
    element: <ReactIframe />,
  },
  {
    path: COMMON_PATHS.EMAIL_LINK_SEND,
    element: <EmailVarificationLinkSent />,
  },
  {
    path: COMMON_PATHS.EMAIL_LINK_FORGOT_PASSWORD_VERIFY,
    element: <ForgotPasswordEmailVarify />,
  },
  {
    path: COMMON_PATHS.VERIFY_USER_EMAIL_SUCCESS,
    element: <NewUserEmailSuccess />,
  },
  {
    path: COMMON_PATHS.CREATE_NEW_PASSWORD,
    element: <CreateNewPassword />,
  },
  {
    path: COMMON_PATHS.FORGOT_PASSWORD_SUCCESS,
    element: <ForgotPasswordSuccess />,
  },
  { path: COMMON_PATHS.SIGNUP_USER_DETAILS, element: <UserDetails /> },
  {
    path: COMMON_PATHS.VERIFY_LEASE_SIGNOR_DETAILS,
    element: <VerifyLeaseSignor />,
  },
  {
    path: COMMON_PATHS.VERIFY_LEASE_SIGNOR_SUCCESS,
    element: <VerifyLeaseSignorSuccess />,
  },
  {
    path: COMMON_PATHS.LEASE_SIGNOR_GET_DOCUMENT,
    element: <LeaseSignDocument />,
  },
  {
    path: COMMON_PATHS.LEASE_SIGNOR_DOCUMENT_SIGNES,
    element: <LeaseSignDocumentSuccess />,
  },
  { path: "/inifinite-scroll", element: <Infinite /> },
  { path: COMMON_PATHS.COMPLETE_PROFILE, element: <CompleteProfile /> },
  { path: COMMON_PATHS.SIGNUP_SUCCESS_MESSAGE, element: <SuccessMessage /> },
  { path: COMMON_PATHS.SEARCH_RESULT, element: <SearchResult /> },
  { path: COMMON_PATHS.PROPERTY_DETAIL, element: <PropertyDetail /> },
  { path: COMMON_PATHS.ABOUT_US, element: <AboutUs /> },
  { path: COMMON_PATHS.OUR_TEAM, element: <OurTeam /> },
  { path: COMMON_PATHS.PRESS_PAGE, element: <Press /> },
  { path: COMMON_PATHS.BLOG, element: <Blog /> },
  { path: COMMON_PATHS.BLOG_DETAIL, element: <BlogDetail /> },
  { path: COMMON_PATHS.BLOG_DETAIL_2, element: <BlogDetail2 /> },
  { path: COMMON_PATHS.BLOG_DETAIL_3, element: <BlogDetail3 /> },
  { path: COMMON_PATHS.BLOG_DETAIL_4, element: <BlogDetail4 /> },
  { path: COMMON_PATHS.CONTACT_US, element: <ContactUs /> },
  { path: COMMON_PATHS.PRIVACY_POLICY, element: <PrivacyPolicy /> },
  { path: COMMON_PATHS.TRAVEL_TIPS, element: <TravelTips /> },
  { path: COMMON_PATHS.RENTER_PAGE, element: <Renter /> },
  { path: COMMON_PATHS.HOMEOWNER_PAGE, element: <HomeOwner /> },
  { path: COMMON_PATHS.TERMS_AGREEMENT, element: <TermsAgreement /> },
  {
    path: COMMON_PATHS.TERMS_AGREEMENT_RENTER,
    element: <TermsAgreementRenter />,
  },
  { path: COMMON_PATHS.FAQ, element: <Faq /> },
  {
    path: COMMON_PATHS.BOOKING_LEASE_AGREEMENT,
    element: <BookingLeaseAgreement />,
  },
  // { path: RENTER_PATHS.RENTER_MESSAGES_DETAIL, element: <RenterMessagesDetails /> },

  { path: COMMON_PATHS.BACKGROUND_CHECK, element: <BackgroundCheck /> },
  { path: COMMON_PATHS.BOOKING_PAYMENT, element: <BookingPayment /> },
  { path: COMMON_PATHS.PROPERTY_BOOKED, element: <PropertyBooked /> },
  { path: COMMON_PATHS.PROPERTY_CANCELLED, element: <PropertyCancelled /> },
  { path: COMMON_PATHS.DEFAULT, element: <Navigate to={COMMON_PATHS.APP1} /> },
  { path: COMMON_PATHS.CALLBACK, element: <Navigate to={COMMON_PATHS.HOME} /> },
];
export default commonRoutes;
