import { checkInActions } from "../ActionTypes";

export const getCheckinDetails = (data) => ({
    type: checkInActions.fetch,
    payload: data,
});

export const setCheckinDetails = (data) => ({
    type: checkInActions.store,
    payload: data,
})