import { call, put, takeEvery } from "redux-saga/effects";
import {
  requestOtpCheck,
  requestOtpResend,
  requestOtpLeaseSignorSend
 } from "../Requests/create-user-details";
import {  otpAction } from "../../ActionTypes";
import { set_message } from "../../Actions/message";
import { request_loader } from "../../Actions/loader";
import {actions,verify} from "../../../Constants/Common";
import { login_success } from "../../Actions/auth";

import { COMMON_PATHS,REDIRECTIONS } from "../../../config/Owner-And-NewList_Paths";


export function* handleOtpCheck({ payload }) {
  try {

    const { request,navigate } = payload;
    let token = localStorage.getItem('token')||"";
     yield put(request_loader({load:true}));

     const req = {
       code:request.code,
       token:token,
       iso_code:localStorage.getItem('countryCode')
     };

     if(request.id===verify.leasevarify||request.id===verify.user_otp_login){
       req.mobile = parseInt(request.mobile);
     }
     const response = yield call(requestOtpCheck,req,request.id);
     yield put(request_loader({load:false}));
     if(response){
       const { data,status } = response;

       if(status===window.code['200'].code){
           localStorage.removeItem('user_phone');
           if(request.id!==verify.leasevarify&&request.id!==verify.user_otp_login){
             yield put(
               set_message({
                 error_code: data.msg,
                 code: "success",
                 heading: "Passcode Verified Successfully",
                 })
               );
           } 
           let link = REDIRECTIONS[request.id+'_'+status];
           if(request.id===actions.otp){
            link = link+'?token='+data.data.token;
           }
           if(request.id===actions.uvarify){
            localStorage.setItem('token',data.data.token);
           }

          if(request.id===actions.leasevarify){
            localStorage.clear();
            link = COMMON_PATHS.EMAIL_LINK_FORGOT_PASSWORD_VERIFY+'?action=leasesignor_document&token='+data.data;
          }

          if(request.id===actions.user_otp_login){
            let da = data.data;
            const role = da.role[0];
            localStorage.removeItem('user_phone');
            localStorage.removeItem('iso_code');
            localStorage.removeItem('token');
            localStorage.setItem('accessToken',da.accessToken);
            localStorage.setItem('loginTimer',new Date());
            localStorage.setItem('all_user_data',JSON.stringify(da));
            localStorage.setItem('role',role);
            link = localStorage.getItem('history_url')
           // link = REDIRECTIONS[role+'_redirect'];
            yield put(login_success({ role: role, user: da }));
          }
          navigate(link);
       }
    }
  } catch (error) {

    yield put(request_loader({load:false}));
     yield put(set_message(
       {
         error_code:"invalid otp",
         code:"warning",
         heading:"Invalid Value"
       }
     ));
  }
}

export function* watchOtpCheck() {
  yield takeEvery(otpAction.load, handleOtpCheck);
}

export function* handleOtpResend({ payload }) {
  try{
  const { mobile,segment,navigate } = payload;
    let token = localStorage.getItem('token')||'';

    if(token===''){
      yield put(set_message(
        {
          error_code:"Invalid value token",
          code:"warning",
          heading:"Invalid Value"
        }
      ));
      navigate(`${REDIRECTIONS[segment+'_redirect_force']}`);
    }
    yield put(request_loader({load:true}));
    const response = yield call(requestOtpResend,mobile,token,localStorage.getItem('countryCode'),segment);
    yield put(request_loader({load:false}));
    if(response){
      const { status,data } = response;
    if(status===window.code['200'].code){
        yield put(
        set_message(
          {
            error_code: data.msg,
            code: "success",
            heading: window.code['200'].header,
          }
        ));
      }
    }
  }catch(error){
    console.log(error);
  }
}

export function* watchOtpResend() {
  yield takeEvery(otpAction.resend, handleOtpResend);
}

export function* handleOtpLeaseSignorSend({ payload }) {
  try{
    const {records,actn,navigate,delete_cookie} = payload;
    yield put(request_loader({load:true}));
    const response = yield call(requestOtpLeaseSignorSend,{property_id:records.property_id, mobile:records.mobile,iso_code:records.iso_code,recaptcha_token:records.recaptcha_token,address:records.address,file:records.file,property_description:records.property_description,property_title:records.property_title, id: records.id});

    yield put(request_loader({load:false}));
    if(response){
      const {data,status} = response;
      if(status===window.code['200'].code){
        window.parent.setToken({
          token:data.data,
          user_phone:records.mobile,
          countryCode:records.iso_code
        });

         yield put(set_message(
          {
            error_code:data.msg,
            code:"success",
            heading:"Success"
          }
        ));
        delete_cookie(actn+'_is_disable');
        delete_cookie(actn+'_is_disabled_for');
        delete_cookie(actn+'_hit_counter');
        navigate(COMMON_PATHS.OTP+'/'+actn);
      }else{
        yield put(request_loader({load:'auth_error'}));
      }
    }

  }catch(error){
    console.log(error);
  }
}

export function* watchOtpLeaseSignorSend() {
  yield takeEvery(otpAction.otpSignorSend, handleOtpLeaseSignorSend);
}
