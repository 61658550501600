import { checkInActions } from "../ActionTypes";
import { initial_state } from "../../Constants/Common";

const data = {
  checkingDetails: {}
}
const Checkin = (state = data, action) => {
  switch (action.type) {
    case checkInActions.store:
     return {...state,checkingDetails:action.payload}
    default:
      return { ...state };
  }
};
export default Checkin;
