import axios from "axios";
import { apiConstantsAdmin } from "../../../../Constants/api_constant_admin";

export async function requestGetAllSetting() {
  const apis = [
    axios.get(apiConstantsAdmin.GET_ALL_SETTING),
    axios.get(apiConstantsAdmin.GET_CLEANING_FEE),
  ];
  const d = await Promise.all(apis);
  return d;
}

export function requestUpdateFeeSetting(data) {
  return axios({
    method: "PUT",
    url: apiConstantsAdmin.UPDATE_FEE_SETTING,
    data: { ...data },
    validateStatus: () => true,
  });
}

export function requestUpdateBackgroundSetting(data) {
  return axios({
    method: "PUT",
    url: apiConstantsAdmin.UPDATE_BACKGROUND_SETTING,
    data: { ...data },
    validateStatus: () => true,
  });
}


export async function requestUpdateProcessingFeeSetting(
  processingfeeSetting,
  cleaning_service_rate_city_state
) {
  const apis = [
    axios.put(`${apiConstantsAdmin.UPDATE_PROCESSING_FEE_SETTING}`, {
      processingfeeSetting,
    }),
    axios.post(`${apiConstantsAdmin.SAVE_CLEANING_FEE}`, [
      ...cleaning_service_rate_city_state,
    ]),
  ];
  let d = await Promise.all(apis);
  return d;
}
// SAVE_CLEANING_FEE
export function requestUpdateNotificationSetting(data) {
  return axios({
    method: "PUT",
    url: apiConstantsAdmin.UPDATE_NOTIFICATION_FEE_SETTING,
    data: { ...data },
    validateStatus: () => true,
  });
}
