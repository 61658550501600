import { stripePaymentMethods } from "../../ActionTypes";
const data = {
  loading: true,
  paymentMethods: [],
  error: "",
};
const StripePaymentMethods = (state = data, action) => {
  switch (action.type) {
    case stripePaymentMethods.success:
      return { ...state, loading: false, paymentMethods: action.payload };
    default:
      return { ...state };
  }
};

export default StripePaymentMethods;
