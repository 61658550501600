import error_messages from "../../../../Constants/error-messages";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  COMMON_PATHS,
  RENTER_PATHS,
} from "../../../../config/Owner-And-NewList_Paths";
import { constantTexts } from "../../../../Constants/Common";
import { request_loader } from "../../../Actions/loader";
import { set_message } from "../../../Actions/message";

import {
  pay_penalty_error,
  pay_penalty_success,
} from "../../../Actions/Renter/PayPenalty";
import { payPenalty } from "../../../ActionTypes/Renter";
import { requestPayPenalty } from "../../Requests/Renter/PayPenalty";

export function* handlePayPenalty({ payload }) {
  const { values, id, navigate } = payload;
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(requestPayPenalty, values);
    const { data } = response;
    if (data) {
      yield put(pay_penalty_success(data.data));
      yield put(
        set_message({
          error_code: error_messages['penalty_pay_success'],
          code: constantTexts.SUCCESS,
        })
      );
      navigate(`${RENTER_PATHS.REDIRECT}/${id}`);
      yield put(request_loader({ load: false }));
    } else {
      yield put(pay_penalty_error(data));
    }
  } catch (error) {
    yield put(pay_penalty_error(error));
  }
}

export function* watchPayPenalty() {
  yield takeEvery(payPenalty.load, handlePayPenalty);
}
