import { authAction } from "../ActionTypes";

export const check_login = (data) => ({
  type: authAction.load ,
  payload: data,
});

export const error_login = (data) => ({
  type: authAction.failure ,
  payload: data,
});

export const login_success = (data) => ({
  type: authAction.success,
  payload: data,
});

export const reset_data = (data) => ({
  type: authAction.reset ,
  payload: data,
});

export const user_logout = (data) => ({
  type: authAction.logout,
  payload: data,
});

export const request_otp = (data) => ({
  type: authAction.otpRequest,
  payload: data,
});

export const reset_password = (data) =>({
  type: authAction.passwordReset,
  payload: data,
});

export const verify_email = (data) =>({
  type: authAction.emailVerify,
  payload: data,
})
 
export const verify_signed_doc = (data) =>({
  type: authAction.leaseSignDoc,
  payload: data,
});

export const getBackGroundInfo = (data) =>({
  type: authAction.getBackGroundInfo,
  payload: data,
});

export const getBackGroundInfoReset = () =>({
  type: authAction.getBackGroundInfoReset,
});