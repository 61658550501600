import { call, put, takeEvery } from "redux-saga/effects";
import {
    fetchAdminDashboardReports   
} from "../../../ActionTypes/admin";
import {
    fetch_admin_dashboard_reports_success
} from "../../../Actions/admin/dashboard";
import {
  requestfetchDashboardRevenue,
  requestfetchDashboardPayout,
  requestfetchDashboardProfit,
  requestfetchDashboardDeposit,
  requestfetchDashboardCancelation,
  requestFetchDashboardTotalSearches

} from "../../Requests/admin/DashboardRequest";
import { request_loader } from "../../../Actions/loader";
import { set_message } from "../../../Actions/message";
import { getQueryStringValue } from "../../../../helpers/CommonHelper";

export function* handleFetchDashboardReports({payload}) {
    
    try {
      //year=2021&month=2&country=US&state=NY&city=New%20York
      let queryData = {}
      if(getQueryStringValue('year')!="" )
        queryData.year =getQueryStringValue('year');
      if(getQueryStringValue('month')!="" )
        queryData.month =getQueryStringValue('month');
      if(getQueryStringValue('country', false)!="" )
        queryData.country =getQueryStringValue('country', false);
      if(getQueryStringValue('state')!="" )
        queryData.state =getQueryStringValue('state');
      if(getQueryStringValue('city')!="" )
        queryData.city =getQueryStringValue('city');


      // yield put(request_loader({ load: true }));

      let combinedData = {};

      const response = yield call(requestfetchDashboardRevenue, queryData);
      combinedData.dashboardRevenue = response.data.data;
      yield put(fetch_admin_dashboard_reports_success(combinedData));

      const responseTotalSearches = yield call(requestFetchDashboardTotalSearches, queryData);
      combinedData.responseTotalSearches = responseTotalSearches.data;
      yield put(fetch_admin_dashboard_reports_success(combinedData));

      const responsePayout = yield call(requestfetchDashboardPayout, queryData);
      combinedData.responsePayout = responsePayout.data;
      yield put(fetch_admin_dashboard_reports_success(combinedData));

      const responseProfit = yield call(requestfetchDashboardProfit, queryData);
      combinedData.responseProfit = responseProfit.data;
      yield put(fetch_admin_dashboard_reports_success(combinedData));

      const responseDeposit = yield call(requestfetchDashboardDeposit, queryData);
      combinedData.responseDeposit = responseDeposit.data;
      yield put(fetch_admin_dashboard_reports_success(combinedData));

      const responseCancelation = yield call(requestfetchDashboardCancelation, queryData);
      combinedData.responseCancelation = responseCancelation.data;
      yield put(fetch_admin_dashboard_reports_success(combinedData));


      // yield put(request_loader({ load: false }));
      yield put(
        set_message({
          error_code: response.data.msg,
        })
      );
      
      
      // if (response) {
      //   const { data,msg, status } = response;
      //   if (status === window.code["200"].code) {
      //     yield put(fetch_admin_dashboard_reports_success(combinedData));
      //     yield put(
      //       set_message({
      //         error_code: response.data.msg,
      //       })
      //     );
      //   }  
      // }
    } catch (error) {
      console.log(error);
    }
  }
  export function* watchDashBoardReportsRequest() {
    yield takeEvery(fetchAdminDashboardReports.load, handleFetchDashboardReports);
  }