import { call, put, takeEvery } from "redux-saga/effects";
import {
  createBusinessAdmin,
  loadBusinessAdmins,
  updateStatusBusinessAdmin,
} from "../../../ActionTypes/admin";
import { request_loader } from "../../../Actions/loader";
import {
  requestCreateBusinessAdmins,
  requestLoadBusinessAdmins,
  requestUpdateStatusBusinessAdmins,
} from "../../Requests/admin/BusinessAdmins";
import {
  create_business_admin_error,
  create_business_admin_success,
  load_business_admins,
  load_business_admins_success,
  update_status_business_admin_error,
  update_status_business_admin_success,
} from "../../../Actions/admin/BusinessAdmins";
import { set_message } from "../../../Actions/message";
import { constantTexts } from "../../../../Constants/Common";

export function* handleLoadBusinessAdmins({ payload }) {
  try {
    const { search, initial, fetchPage, page, lists } = payload;

    let req = {};
    if (page) req.page = page;
    if (search) req.search = search;
    yield put(request_loader({ load: initial }));
    const response = yield call(requestLoadBusinessAdmins, req);
    yield put(request_loader({ load: false }));
    if (response) {
      const { status } = response;

      if (status === window.code["200"].code) {
        const { data, options } = response.data;
        const params = {
          initial: false,
          lists: lists ? lists.concat(data) : data,
        };
        if (fetchPage === true) params.totalPages = options.totalDocs;
        if (options && options.nextPage) params.page = options.nextPage;
        if (search) params.search = search;

        yield put(load_business_admins_success(params));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchLoadBusinessAdmins() {
  yield takeEvery(loadBusinessAdmins.load, handleLoadBusinessAdmins);
}

//create business admin
export function* handleCreateBusinessAdmin({ payload }) {
  const { param } = payload;
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(requestCreateBusinessAdmins, param);
    yield put(request_loader({ load: false }));
    if (response) {
      const { data, status } = response;
      if (status === 200) {
        yield put(create_business_admin_success(data.data));
        yield put(
          set_message({
            error_code: data.msg,
            code: constantTexts.SUCCESS,
          })
        );
      }
    }
  } catch (error) {
    console.log(error);
    yield put(request_loader({ load: false }));
    yield put(
      set_message({
        error_code: "This email already in use",
        code: constantTexts.WARNING,
      })
    );
    yield put(create_business_admin_error(error));
  }
}

export function* watchCreateBusinessAdmin() {
  yield takeEvery(createBusinessAdmin.load, handleCreateBusinessAdmin);
}

// change status business admin
export function* handleUpdateBusinessAdminStatus({ payload }) {
  const { stat, id } = payload;
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(requestUpdateStatusBusinessAdmins, {
      _id: id,
      status: stat ? 0 : 1,
    });
    yield put(request_loader({ load: false }));
    if (response) {
      const { data, status } = response;
      if (status === window.code["200"].code) {
        yield put(update_status_business_admin_success(data));
        yield put(
          set_message({
            error_code: data.msg,
            code: constantTexts.SUCCESS,
          })
        );
      }
    }
  } catch (error) {
    yield put(update_status_business_admin_error(error));
  }
}

export function* watchUpdateBusinessAdminStatus() {
  yield takeEvery(
    updateStatusBusinessAdmin.load,
    handleUpdateBusinessAdminStatus
  );
}
