export const cardAction = {
    createCard : "CREATE_CARD",
    setDefault : "SET_DEFAULT_CARD",
    deleteSource: "DELETE_SOURCE"
}

//create bank info//
export const bankAction = {
    createBank: "CREATE_BANK",
    createConnect: "CREATE_CONNECT_ACCOUNT"
};
