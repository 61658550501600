import {
  createBusinessAdmin,
  loadBusinessAdmins,
  updateStatusBusinessAdmin,
} from "../../ActionTypes/admin";

export const load_business_admins = (param) => ({
  type: loadBusinessAdmins.load,
  payload: param,
});
export const load_business_admins_success = (data) => ({
  type: loadBusinessAdmins.success,
  payload: data,
});
export const load_business_admins_error = (data) => ({
  type: loadBusinessAdmins.failure,
  payload: data,
});

export const create_business_admin = (param, dispatch) => ({
  type: createBusinessAdmin.load,
  payload: { param, dispatch },
});
export const create_business_admin_success = (data) => ({
  type: createBusinessAdmin.success,
  payload: data,
});
export const create_business_admin_error = (data) => ({
  type: createBusinessAdmin.failure,
  payload: data,
});

export const update_status_business_admin = (param) => ({
  type: updateStatusBusinessAdmin.load,
  payload: param,
});
export const update_status_business_admin_success = (data) => ({
  type: updateStatusBusinessAdmin.success,
  payload: data,
});
export const update_status_business_admin_error = (data) => ({
  type: updateStatusBusinessAdmin.failure,
  payload: data,
});
