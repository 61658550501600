import { call, put, takeEvery } from "redux-saga/effects";
import {
  requestLoadReviewDetails,
  requestSaveReviewDetails,
} from "../Requests/create-new-list";
import { loadReviewDetails, saveReviewDetails } from "../../ActionTypes";
import {
  load_review_details_error,
  load_review_details_success,
  save_review_details_error,
  save_review_details_success,
} from "../../Actions/create-new-list";
import { OWNER_PATHS } from "../../../config/Owner-And-NewList_Paths";
import { request_loader } from "../../Actions/loader";
import { constantTexts } from "../../../Constants/Common";
import { dateConverter } from "../../../helpers/CommonHelper";

export function* handleLoadReviewDetails({ payload }) {
  try {
    // yield put(request_loader({ load: true }));
    const { property_id } = payload;
    const response = yield call(requestLoadReviewDetails, property_id);
    const { data } = response?.data;
    const { leasesigners, ...rest } = data;
    const basic_property_info = rest;
    const lease_signor_info = leasesigners;
    if (basic_property_info || lease_signor_info) {
      if (
        basic_property_info?.propertyInformation?.block_out_dates.length > 0
      ) {
        basic_property_info.propertyInformation.block_out_dates = dateConverter(
          basic_property_info?.propertyInformation?.block_out_dates
        );
      }
      yield put(
        load_review_details_success({
          basicAndPropertyInfo: basic_property_info,
          leaseSignorInfo: lease_signor_info,
        })
      );
      //yield put(request_loader({ load: false }));
    } else {
      yield put(load_review_details_error(data));
    }
  } catch (error) {
    yield put(load_review_details_error(error));
  }
}

export function* watchLoadReviewDetails() {
  yield takeEvery(loadReviewDetails.load, handleLoadReviewDetails);
}

export function* handleSaveReviewDetails({ payload }) {
  const { property_id, nav } = payload;
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(requestSaveReviewDetails, property_id);
    const { data } = response;
    if (data) {
      yield put(save_review_details_success(data));
      localStorage.removeItem(constantTexts.P_ID);
      localStorage.removeItem(constantTexts.STATE);
      localStorage.setItem(constantTexts.SAVED, constantTexts.SAVED);
      nav(OWNER_PATHS.REDIRECT);
      yield put(request_loader({ load: true }));
    } else {
      yield put(save_review_details_error(data));
    }
  } catch (error) {
    yield put(save_review_details_error(error));
  }
}

export function* watchSaveReviewDetails() {
  yield takeEvery(saveReviewDetails.load, handleSaveReviewDetails);
}
