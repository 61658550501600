import { call, put, takeEvery } from "redux-saga/effects";
import {
  evictionDisplay,
  getTerminationDetails,
  setEvictionDetails,
  setTerminationDetails,
} from "../../Actions/Eviction";
import { request_loader } from "../../Actions/loader";
import { evictionActions, terminationActions } from "../../ActionTypes";
import {
  evictionList,
  getEvictionAdminDetails,
  getEvictionDetails,
  getTerminationDetailsRequest,
} from "../Requests/evictionRequest";

export function* handleEvictionDetail({ payload }) {
  try {
    const { id } = payload;
    yield put(request_loader({ load: true }));
    const response = yield call(getEvictionDetails, id);
    yield put(request_loader({ load: false }));
    if (response) {
      const { status, data } = response;
      if (status === window.code["200"].code) {
        yield put(setEvictionDetails(data.data));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchEvictionDetail() {
  yield takeEvery(evictionActions.fetch, handleEvictionDetail);
}

export function* handleEvictionList({ payload }) {
  try {
    const {
      initial,
      fetchPage,
      page,
      lists,
      promo_status,
      promo_search,
      type,
      first_name,
      last_name,
    } = payload;
    let req = {};
    if (page) req.page = page;
    if (promo_status) req.status = promo_status;
    if (promo_search) req.search = promo_search;
    if (type) req.type = type;
    if (first_name) req.first_name = first_name;
    if (last_name) req.last_name = last_name;
    console.log(req);
    const response = yield call(evictionList, req);
    if (response) {
      const { status } = response;
      if (status === window.code["200"].code) {
        const { data, options } = response.data;
        const params = {
          initial: false,
          lists: lists ? lists.concat(data.docs) : data.docs,
        };
        if (promo_status) params.promo_status = promo_status;
        if (promo_search) params.promo_search = promo_search;
        if (fetchPage === true) params.totalPages = options.totalDocs;
        if (options && options.nextPage) params.page = options.nextPage;
        yield put(evictionDisplay(params));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchEvictionList() {
  yield takeEvery(evictionActions.list, handleEvictionList);
}

export function* handleEvictionAdminDetail({ payload }) {
  try {
    const { id } = payload;
    yield put(request_loader({ load: true }));
    const response = yield call(getEvictionAdminDetails, id);
    yield put(request_loader({ load: false }));
    if (response) {
      const { status, data } = response;
      if (status === window.code["200"].code) {
        yield put(setEvictionDetails(data.data));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchEvictionAdminDetail() {
  yield takeEvery(evictionActions.displayDetail, handleEvictionAdminDetail);
}

// get termination exist and store it
export function* handleTerminationDetail({ payload }) {
  try {
    const { id } = payload;
    yield put(request_loader({ load: true }));
    const response = yield call(getTerminationDetailsRequest, id);
    yield put(request_loader({ load: false }));
    if (response) {
      const { status, data } = response;
      if (status === window.code["200"].code) {
        yield put(setTerminationDetails(data.data));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchTerminationDetail() {
  yield takeEvery(terminationActions.fetch, handleTerminationDetail);
}
