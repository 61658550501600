export const listAction = {
  loadProp: "LOAD_PROPERTY_ADMIN_LIST",
  loadUser: "LOAD_USER_ADMIN_LIST",
  displayProp: "DISPLAY_PROPERTY_ADMIN_LIST",
  updatePropStatus: "UPDATE_CURRENT_BOOKING_STATUS",
  displayUser: "DISPLAY_USER_ADMIN_LIST",
};

export const userAction = {
  updateStatus: "UPDATE_USER_STATUS",
  listOnlyUpdateUnique: "UPDATE_RECORD_USER_STATUS",
  publishApproveProp: "PUBLISH_APPROVE_PROPERTY"
};

export const propAction = {
  propApr: "APPROVE_PROPERTY_STATUS_ADMIN",
  propRej: "REJECT_PROPERTY_STATUS_ADMIN",
};

export const settings = {
  getAllSetting: "GET_ALL_SETTING",
  storeAllSetting: "STORE_ALL_SETTING",
  updateFeesSetting: "UPDATE_FEES_SETTING",
  updateProcessingFeesSetting: "UPDATE_PROCESSING_FEES_SETTING",
  updateNotificationSetting: "UPDATE_NOTIFICATION_SETTING",
  createNewCleaningFee:"CREATE_NEW_CLEANING_FEE",
  createNewCleaningFeeSuccess:"CREATE_NEW_CLEANING_FEE_SUCCESS",
  deleteCleaningFee:"DELETE_CLEANING_FEE",
  deleteCleaningFeeSuccess:"DELETE_CLEANING_FEE_SUCCESS",
  updateBackgroundSetting: "UPDATE_BACKGROUND_SETTING",

};

export const loadListingRequest = {
  load: "LOAD_LISTING_REQUEST",
  success: "LOAD_LISTING_REQUEST_SUCCESS",
  failure: "LOAD_LISTING_REQUEST_ERROR",
};

export const deleteListingRequest = {
  load: "DELETE_LISTING_REQUEST",
  success: "DELETE_LISTING_REQUEST_SUCCESS",
  failure: "DELETE_LISTING_REQUEST_ERROR",
};

export const listingRequestDetails = {
  load: "LISTING_REQUEST_DETAILS",
  success: "LISTING_REQUEST_DETAILS_SUCCESS",
  failure: "LISTING_REQUEST_DETAILS_ERROR",
};
// save safety rating
export const SaveRatingDetails = {
  load: "SAVE_SAFETY_RATING_DETAILS",
  success: "SAVE_RATING_DETAILS_SUCCESS",
  failure: "SAVE_RATING_DETAILS_ERROR",
};
//business admins
export const loadBusinessAdmins = {
  load: "LOAD_BUSINESS_ADMINS",
  success: "LOAD_BUSINESS_ADMINS_SUCCESS",
  failure: "LOAD_BUSINESS_ADMINS_ERROR",
};

export const createBusinessAdmin = {
  load: "CREATE_BUSINESS_ADMIN",
  success: "CREATE_BUSINESS_ADMIN_SUCCESS",
  failure: "CREATE_BUSINESS_ADMIN_ERROR",
};

export const updateStatusBusinessAdmin = {
  load: "UPDATE_STATUS_BUSINESS_ADMIN",
  success: "UPDATE_STATUS_BUSINESS_ADMIN_SUCCESS",
  failure: "UPDATE_STATUS_BUSINESS_ADMIN_ERROR",
};

export const listFeaturedHistory = {
  load: "LOAD_FEATURED_HISTORY",
  success: "LOAD_FEATURED_HISTORY_SUCCESS"
}

//fetch messages for admin
export const fetchAdminThreadList = {
  load: "FETCH_ADMIN_THREAD_LIST",
  success: "FETCH_ADMIN_THREAD_LIST_SUCCESS",
  failure: "FETCH_ADMIN_THREAD_LIST_ERROR",
};

export const fetchAdminDashboardReports = {
  load: "FETCH_ADMIN_DASHBOARD_REPORTS",
  success: "FETCH_ADMIN_DASHBOARD_REPORTS_SUCCESS",
  failure: "FETCH_ADMIN_DASHBOARD_REPORTS_ERROR",
};

export const fetchBackgroundCheckList = {
  load: "FETCH_BACKGROUND_CHECK_LIST",
  success: "FETCH_BACKGROUND_CHECK_LIST_SUCCESS",
  failure: "FETCH_BACKGROUND_CHECK_LIST_ERROR",
};

export const fetchRenterInfo = {
  load: "FETCH_RENTER_INFO",
  success: "FETCH_RENTER_INFO_SUCCESS",
  failure: "FETCH_RENTER_INFO_ERROR",
};