import {
    createLeavingReview
  } from "../../ActionTypes";
  //Create Leaving review
  export const create_leaving_review = (param) => ({
    type: createLeavingReview.load,
    payload: param,
  });
  export const create_leaving_review_success = (data) => ({
    type: createLeavingReview.success,
    payload: data,
  });
  export const create_leaving_review_error = (data) => ({
    type: createLeavingReview.failure,
    payload: data,
  });