import axios from "axios";
import { apiConstants } from "../../../../Constants/api_constants";

export const requestLoadOwnerListing = (param) => {
  return axios.get(apiConstants.FETCH_PROPERTY_LIST, {
    params: { ...param },
  });
};
export const requestDeleteOwnerListing = (id, isPublished, contactData) => {
  //  published to unpublished
  if (isPublished) {
    return axios({
      method: "DELETE",
      url: `${apiConstants.DELETE_LIVE_LISTING}`,
      data: { property_id: id },
      validateStatus: () => true,
    });
  } else if (!isPublished && contactData?.contactDetails) {
    // unpublished and no of bookings zero with reasons
    return axios({
      method: "DELETE",
      url: `${apiConstants.DELETE_LIVE_LISTING}`,
      data: contactData?.contactDetails?.reason?{ property_id: id, ...contactData?.contactDetails }:{ property_id: id },
      validateStatus: () => true,
    });
  } else {
    return axios.delete(`${apiConstants.DELETE_OWNER_LISTING}${id}`);
  }
};

export const requestPropertyFeaturedAmount = (data) => {
  return axios({
    method: "POST",
    url: apiConstants.PROPERTY_FEATURED_AMOUNT,
    data: { ...data },
    validateStatus: () => true,
  });
};

export const requestPayPropertyFeaturedAmount = (data) => {
  return axios({
    method: "POST",
    url: apiConstants.PAY_PROPERTY_FEATURED_AMOUNT,
    data: { ...data },
    validateStatus: () => true,
  });
};
