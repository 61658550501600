import { evictionActions } from "../ActionTypes";
import { initial_state } from "../../Constants/Common";

const data = {
  evictionDetails: {}
}
const Eviction = (state = data, action) => {
  switch (action.type) {
    case evictionActions.store:
      return { ...state, evictionDetails: action.payload }
    case evictionActions.display:
      return { ...state, ...action.payload }
    default:
      return { ...state };
  }
};
export default Eviction;
