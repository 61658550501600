import axios from "axios";
import { apiConstants } from "../../../../Constants/api_constants";

export const requestPayPenalty = (data) => {
    return axios({
      method: "POST",
      url: apiConstants.PAY_PENALTY,
      data: { ...data },
      validateStatus: () => true,
    });
  };