import { listFeaturedHistory } from "../../ActionTypes/admin";

const data = {
  loading: true,
  featuredHistory: [],
  error: "",
};
const featured_history = (state = data, action) => {
  switch (action.type) {
    case listFeaturedHistory.success:
      return { ...state, loading: false, featuredHistory: action.payload };
    default:
      return { ...state };
  }
};

export default featured_history;
