import { call, put, takeEvery } from "redux-saga/effects";
import { settings } from "../../../ActionTypes/admin";
import {
  requestGetAllSetting,
  requestUpdateFeeSetting,
  requestUpdateNotificationSetting,
  requestUpdateProcessingFeeSetting,
  requestUpdateBackgroundSetting,
} from "../../Requests/admin/setting";
import { request_loader } from "../../../Actions/loader";
import { set_message } from "../../../Actions/message";
import {
  create_new_cleaning_fee_success,
  delete_cleaning_fee_success,
  store_all_settings,
} from "../../../Actions/admin/settings";

export function* handleGetAllSetting({payload}) {
  try {
    if(payload && payload.type === undefined)yield put(request_loader({ load: true }));
    const response = yield call(requestGetAllSetting);
    yield put(request_loader({ load: false }));
    if (response) {
      const {
        data: { data: allSettings },
      } = response[0];
      const {
        data: { data: allCleaningFee },
      } = response[1];

      yield put(store_all_settings(allSettings[0], allCleaningFee.docs));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchGetAllSetting() {
  yield takeEvery(settings.getAllSetting, handleGetAllSetting);
}

export function* handleUpdateFeeSetting({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(requestUpdateFeeSetting, payload.values);
    yield put(request_loader({ load: false }));
    if (response) {
      const { status } = response;
      if (status === window.code["200"].code) {
        yield put(
          set_message({
            error_code: window.msgs["fee_update_success"],
            code: "success",
            heading: payload.status,
          })
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateBackgroundSetting({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(requestUpdateBackgroundSetting, payload.values);
    yield put(request_loader({ load: false }));
    if (response) {
      const { status } = response;
      if (status === window.code["200"].code) {
        yield put(
          set_message({
            error_code: window.msgs["background_update_success"],
            code: "success",
            heading: payload.status,
          })
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchUpdateFeeSetting() {
  yield takeEvery(settings.updateFeesSetting, handleUpdateFeeSetting);
}

export function* watchUpdateBackgroundSetting() {
  yield takeEvery(settings.updateBackgroundSetting, handleUpdateBackgroundSetting);
}

export function* handleUpdateProcessingFeeSetting({ payload }) {
  const {
    values: {
      processingfeeSetting: { cleaning_service_rate_city_state, ...rest },
    },
  } = payload;
  cleaning_service_rate_city_state?.map((el) => delete el.country);
  let processingfeeSetting = { ...rest };
  processingfeeSetting.cleaning_service_rate =
    +processingfeeSetting?.cleaning_service_rate;
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(
      requestUpdateProcessingFeeSetting,
      processingfeeSetting,
      cleaning_service_rate_city_state
    );
    yield put(request_loader({ load: false }));
    if (response) {
      const { status } = response[0];
      if (status === window.code["200"].code) {
        yield put(
          set_message({
            error_code: window.msgs["processing_fee_update_success"],
            code: "success",
            heading: payload.status,
          })
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchUpdateProcessingFeeSetting() {
  yield takeEvery(
    settings.updateProcessingFeesSetting,
    handleUpdateProcessingFeeSetting
  );
}

export function* handleUpdateNotificationSetting({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(
      requestUpdateNotificationSetting,
      payload.values
    );
    yield put(request_loader({ load: false }));
    if (response) {
      const { status } = response;
      if (status === window.code["200"].code) {
        yield put(
          set_message({
            error_code: window.msgs["notification_update_success"],
            code: "success",
            heading: payload.status,
          })
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchUpdateNotificationSetting() {
  yield takeEvery(
    settings.updateNotificationSetting,
    handleUpdateNotificationSetting
  );
}

function* handleCreateNewCleaningFee({ payload }) {
  payload.price = +payload.price;
  yield put(create_new_cleaning_fee_success(payload));
}

export function* watchCreateNewCleaningFee() {
  yield takeEvery(settings.createNewCleaningFee, handleCreateNewCleaningFee);
}

function* handleDeleteCleaningFee({ payload }) {
  yield put(delete_cleaning_fee_success(payload));
}

export function* watchDeleteCleaningFee() {
  yield takeEvery(settings.deleteCleaningFee, handleDeleteCleaningFee);
}
