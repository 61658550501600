import { uploadAction, uploadMedia, uploadDoc } from "../ActionTypes";
const data = {
  error: "",
  message: "",
  file_arr: [],
  doc_arr: [],
  data: null,
};
const UploadActn = (state = data, action) => {
  switch (action.type) {
    case uploadAction.upload_reset:
      return { error: "", message: "", file_arr: [], doc_arr: [], data: null };
    case uploadAction.setMessage:
      return { ...action.payload };
    case uploadMedia.store:
      let file_arr1 = state.file_arr.concat(action.payload.data);
      return { ...state, file_arr: file_arr1 };
    case uploadMedia.delete:
      state.file_arr.splice(parseInt(action.payload), 1);
      return { ...state };
    case uploadDoc.store:
      let doc_arr1 = state.doc_arr.concat(action.payload.data);
      return { ...state, doc_arr: doc_arr1 };
    case uploadDoc.delete:
      state.doc_arr.splice(parseInt(action.payload), 1);
      return { ...state };
    default:
      return { ...state };
  }
};
export default UploadActn;
