import { promocodeActions } from "../ActionTypes";

export const promocodeCreate = (data) => ({
    type: promocodeActions.create,
    payload: data,
  });

  export const promocodeList = (data) => ({
    type: promocodeActions.list,
    payload: data,
  });

  export const promocodeDisplay = (data) => ({
    type: promocodeActions.display,
    payload: data,
  });

  export const promocodeUpdate = (data) => ({
    type: promocodeActions.update,
    payload: data,
  });

  export const promocodeStatusUpdate = (data) => ({
    type: promocodeActions.statusupdate,
    payload: data,
  });

  export const promocodeDelete = (data) => ({
    type: promocodeActions.delete,
    payload: data,
  });
  export const promocodeListUpdateOnly = (data) =>({
    type: promocodeActions.updateOnly,
    payload: data,
  })

  export const listingApplyPayoutPromocode = (data) => ({
    type: promocodeActions.apply_listing_payout,
    payload: data
  })

  export const listingApplyHotelPromocode = (data) => ({
    type: promocodeActions.apply_listing_hotel,
    payload: data
  })

  export const listingPromocodeApplied = (data) => ({
    type: promocodeActions.listing_promocode_applied,
    payload: data
  })
  export const listingPromocodeDelete = (data) => ({
    type: promocodeActions.remove_listing_promocode,
    payload: data,
  });
  