import { execute_filter } from "../../../Redux/Actions/filter";
import {
  user_logout,
  getBackGroundInfoReset,
} from "../../../Redux/Actions/auth";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import LogoutModel from "../../../components/Common/Modals/LogoutModel";
import { userRoles } from "../../../Constants/Common";
import { useDispatch, useSelector } from "react-redux";
import { set_message } from "../../../Redux/Actions/message";
import error_messages from "../../../Constants/error-messages";
import ToastMessage from "../../../components/Common/ToastMessage";
import { apiConstants } from "../../../Constants/api_constants";

import {
  Logo,
  ColoredLogo,
  CloseWhiteIcon,
  ProfileError,
  SelectDropIcon,
  DetailCheckIcon,
  MenuLeftICon,
  menuToggleIcon,
} from "../../../assets/img/imagesh";
import {
  COMMON_PATHS,
  CREATE_NEW_LIST_PATHS,
  REDIRECTIONS,
  RENTER_PATHS,
} from "../../../config/Owner-And-NewList_Paths";
import { React, useState, useEffect } from "react";
import { returnBackgroundUserRecord } from "../../../helpers/CommonHelper";
import { advanceFilter, filterAction } from "../../../Redux/ActionTypes";
import { reset_search_page } from "../../../Redux/Actions/create-new-list";

const Header = (props) => {
  const [showLogoutPop, showLogoutPopUp] = useState(false);
  const LogoutPopClose = () => showLogoutPopUp(false);
  const LogoutPopShow = () => showLogoutPopUp(true);
  const [isActionActive, setActionActive] = useState(false);
  const [isOriginActive, setOriginActive] = useState(false);
  const [isActive, setActive] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [nameCount, setName] = useState("");
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const role = localStorage.getItem("role");
  const message = useSelector((state) => state.message);
  const checker = useSelector((state) => state.auth);

  const performLogout = () => {
    dispatch(user_logout(navigate));
    setActionActive(false);
    LogoutPopClose();
  };
  const actionToggle = () => {
    setActionActive(!isActionActive);
    dispatch({ type: filterAction.resetAddress });
  };
  const cleanFilter = () => {
    dispatch({ type: advanceFilter.resetFilter });
  };

  const chooseOrigin = () => {
    setOriginActive(!isOriginActive);
  };

  const menuToggle = () => {
    setActive(!isActive);
  };
  const setCountry = (name, code) => {
    setName(name);
    chooseOrigin();
    window.location.href = "?country=" + code;
  };
  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  useEffect(() => {
    if (location.pathname !== "/home") {
      dispatch(getBackGroundInfoReset());
      if (
        localStorage.getItem("role") &&
        ["renter", "owner"].includes(localStorage.getItem("role"))
      ) {
        returnBackgroundUserRecord(
          apiConstants.USER_BACKGROUND_INFO,
          "GET",
          dispatch
        );
      }
    }
  }, []);

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector(".main-header");
    const scrollTop = window.scrollY;
    scrollTop >= 10
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };

  const userDetails = JSON.parse(
    sessionStorage.getItem("user") || localStorage.getItem("all_user_data")
  );
  const getButtonLinkText = () => {
    if (
      role === userRoles._renter &&
      localStorage.getItem("accessToken") !== ""
    )
      return "Bookings";
    else {
      return "List your property";
    }
  };

  const getButtonLink = () => {
    if (role === userRoles._owner) {
      return `${CREATE_NEW_LIST_PATHS.REDIRECT}`;
    } else if (role === userRoles._renter) return `${RENTER_PATHS.REDIRECT}`;
    else {
      return `${COMMON_PATHS.SIGNUP + "?owner=1"}`;
    }
  };

  return (
    <>
      {message.error_code && (
        <ToastMessage
          error={message.error_code}
          bg={message.code}
          head={message.heading}
        />
      )}
      <LogoutModel
        show={showLogoutPop}
        onHide={LogoutPopClose}
        onCloseClick={LogoutPopClose}
        onClick={() => performLogout()}
      />
      <header className="main-header">
        <Container>
          <Row>
            <Col md={3} xs={8} onClick={cleanFilter}>
              <Link to={COMMON_PATHS.HOME} onClick={() => dispatch(reset_search_page())}>
                <img className="home-logo" src={Logo} alt="Nurses bnb Logo" />
                <img
                  className="inner-pg-logo"
                  src={ColoredLogo}
                  alt="Nurses bnb Logo"
                />
              </Link>
            </Col>
            <Col md={9} xs={4}>
              <div className="right-navbar">
                {/* Mobile Nav Menu  */}
                <div className="mobile-menu show-in-mobile">
                  <span className="toggle-menu" onClick={menuToggle}>
                    {" "}
                    <img src={menuToggleIcon} alt="Toggle Icon" />{" "}
                  </span>
                  <div
                    // {...(isActive==='active')?{className:'mobile-nav-wrp active'}:{className:'mobile-nav-wrp'}}
                    className={
                      "mobile-nav-wrp" + " " + (isActive ? "active" : " ")
                    }
                  >
                    <nav className="mobile-nav home-pg">
                      <strong> Account </strong>
                      <ul className="mr-b-30">
                        <li className="arrow-left">
                          <Link
                            className="nav-link"
                            to={
                              token
                                ? REDIRECTIONS[role + "_redirect"]
                                : COMMON_PATHS.LOGIN
                            }
                          >
                            {" "}
                            {token ? "Account" : "Sign In"}{" "}
                            <img src={MenuLeftICon} alt="Arrow Icon" />
                          </Link>
                        </li>
                        <li className="arrow-left">
                          <Link
                            className="nav-link"
                            to={token ? "" : COMMON_PATHS.SIGNUP}
                            {...(token ? { onClick: LogoutPopShow } : {})}
                          >
                            {" "}
                            {token ? "Sign out" : "Create an account"}{" "}
                            <img src={MenuLeftICon} alt="Arrow Icon" />
                          </Link>
                        </li>
                      </ul>
                      <strong> Account </strong>
                      <ul className="mr-b-20">
                        {props.lists?.map((value, index) => {
                          return (
                            <li
                              key={index}
                              className={
                                props.name === value.name
                                  ? "active-country"
                                  : " "
                              }
                            >
                              <a
                                className="nav-link"
                                onClick={() =>
                                  setCountry(value.name, value.isoCode)
                                }
                              >
                                <span className="flag-space">{value.flag}</span>{" "}
                                {value.name}
                                {props.name === value.name && (
                                  <img src={DetailCheckIcon} alt="Check Icon" />
                                )}
                              </a>
                            </li>
                          );
                        })}
                      </ul>{" "}
                      <Link to={getButtonLink()}>
                        <Button className="button" variant="light">
                          {getButtonLinkText()}
                        </Button>
                      </Link>{" "}
                    </nav>
                    <span className="close-nav" onClick={menuToggle}>
                      {" "}
                      <img src={CloseWhiteIcon} alt="Close Icon" />{" "}
                    </span>
                  </div>
                </div>

                {/* Web Nav Menu */}
                <ul className="nav-links">
                  <li>
                    <a onClick={actionToggle}> Account </a>
                    <div
                      onClick={actionToggle}
                      className={
                        "overlay" + " " + (isActionActive ? "active" : null)
                      }
                    >
                      {" "}
                    </div>
                    <div
                      className={
                        "filter-dropdown" +
                        " " +
                        (isActionActive ? "active" : null)
                      }
                    >
                      <ul>
                        <li>
                          <Link
                            to={
                              token
                                ? REDIRECTIONS[role + "_redirect"]
                                : COMMON_PATHS.LOGIN
                            }
                          >
                            {" "}
                            {token ? "Account" : "Sign In"}{" "}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={token ? "" : COMMON_PATHS.SIGNUP}
                            {...(token ? { onClick: LogoutPopShow } : {})}
                          >
                            {token ? "Sign out" : "Create an account"}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    {" "}
                    <Link
                      to={getButtonLink()}
                    >
                      {/* <Button className="button" variant="light"> */}
                      {getButtonLinkText()}
                      {/* </Button> */}
                    </Link>{" "}
                  </li>
                  <li>
                    {props.lists && props.lists.length > 0 && (
                      <a
                        className="drop-icon"
                        onClick={chooseOrigin}
                        attr={nameCount}
                      >
                        <span className="flag-space">{props.flag}</span>{" "}
                        {props.name}
                      </a>
                    )}
                    <div
                      onClick={chooseOrigin}
                      className={
                        "overlay" + " " + (isOriginActive ? "active" : null)
                      }
                    >
                      {" "}
                    </div>
                    <div
                      className={
                        "filter-dropdown" +
                        " " +
                        (isOriginActive ? "active" : null)
                      }
                    >
                      <ul>
                        {props?.lists?.map((value, index) => {
                          return (
                            <li
                              key={index}
                              className={
                                props.name === value.name
                                  ? "active-country"
                                  : " "
                              }
                            >
                              <a
                                onClick={() =>
                                  setCountry(value.name, value.isoCode)
                                }
                              >
                                <span className="flag-space">{value.flag}</span>{" "}
                                {value.name}
                                {props.name === value.name && (
                                  <img src={DetailCheckIcon} alt="Check Icon" />
                                )}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </li>
                </ul>

                {/* User Menu Widget */}
                <div className="user-info">
                  <div className="user" onClick={actionToggle}>
                    <div className="user-thumb">
                      <img
                        src={userDetails?.profile_image || ProfileError}
                        width="50"
                        height="50"
                        // alt="User thumb"
                      />
                    </div>
                    <div className="user-txt">
                      <h5>
                        {userDetails?.first_name} {userDetails?.last_name}
                      </h5>
                      <p> {userDetails?.mobile}</p>
                    </div>
                    <div className="user-action">
                      <img src={SelectDropIcon} alt="User thumb" />
                    </div>
                  </div>
                  <div
                    onClick={actionToggle}
                    className={
                      "overlay" + " " + (isActionActive ? "active" : null)
                    }
                  >
                    {" "}
                  </div>
                  <div
                    className={
                      "filter-dropdown" +
                      " " +
                      (isActionActive ? "active" : null)
                    }
                  >
                    <ul>
                      <li>
                        <Link
                          to={
                            token
                              ? REDIRECTIONS[role + "_redirect"]
                              : COMMON_PATHS.LOGIN
                          }
                        >
                          {" "}
                          {token ? "Account" : "Sign In"}{" "}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={token ? "" : COMMON_PATHS.SIGNUP}
                          {...(token ? { onClick: LogoutPopShow } : {})}
                        >
                          {token ? "Sign out" : "Create an account"}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </header>
    </>
  );
};
export default Header;
