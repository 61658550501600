import {
  createBasicInfo,
  createLeaseSignor,
  createPropertyInfo,
  createRvProperty,
  deleteLeaseSignor,
  fetchExistLeaseSignors,
  loadCities,
  loadCountries,
  loadCountriesWithFlag,
  loadHomePage,
  loadPropertyInfo,
  loadReviewDetails,
  loadStates,
  payHotelFee,
  saveReviewDetails,
} from "../ActionTypes";

// basic info //
export const create_basic_info = (basicInfo, navigate, status) => ({
  type: createBasicInfo.load,
  payload: { basicInfo, navigate, status },
});
export const create_basic_info_success = (data) => ({
  type: createBasicInfo.success,
  payload: data,
});
export const create_basic_info_error = (data) => ({
  type: createBasicInfo.failure,
  payload: data,
});

// Load property info //
export const load_property_info = () => ({
  type: loadPropertyInfo.load,
});
export const load_property_info_success = (data) => ({
  type: loadPropertyInfo.success,
  payload: data,
});
export const load_property_info_error = (data) => ({
  type: loadPropertyInfo.failure,
  payload: data,
});

// property info //
export const create_property_info = (propertyInfo, propertyId, navigate) => ({
  type: createPropertyInfo.load,
  payload: { propertyInfo, propertyId, navigate },
});
export const create_property_info_success = (data) => ({
  type: createPropertyInfo.success,
  payload: data,
});
export const create_property_info_error = (data) => ({
  type: createPropertyInfo.failure,
  payload: data,
});

// lease signor //
export const create_lease_signor = (leaseSignor, navigate) => ({
  type: createLeaseSignor.load,
  payload: { leaseSignor, navigate },
});
export const create_lease_signor_success = (data) => ({
  type: createLeaseSignor.success,
  payload: data,
});
export const create_lease_signor_error = (data) => ({
  type: createLeaseSignor.failure,
  payload: data,
});

// delete lease signor //
export const delete_lease_signor = (id) => ({
  type: deleteLeaseSignor.load,
  payload: { id },
});
export const delete_lease_signor_success = (data) => ({
  type: deleteLeaseSignor.success,
  payload: data,
});
export const delete_lease_signor_error = (data) => ({
  type: deleteLeaseSignor.failure,
  payload: data,
});

// get review list //
export const load_review_details = (property_id) => ({
  type: loadReviewDetails.load,
  payload: { property_id },
});

export const load_review_details_success = (data) => ({
  type: loadReviewDetails.success,
  payload: data,
});
export const load_review_details_error = (data) => ({
  type: loadReviewDetails.failure,
  payload: data,
});

// load home page //
export const load_home_page = (id) => ({
  type: loadHomePage.load,
  payload: id,
});

export const load_search_page = (id) => ({
  type: loadHomePage.searchLoad,
  payload: id,
});

export const reset_search_page = () => ({
  type: loadHomePage.searchLoadReset,
});

export const load_search_page_success = (id) => ({
  type: loadHomePage.searchLoadSuccess,
  payload: id,
});

export const load_home_page_success = (data) => ({
  type: loadHomePage.success,
  payload: data,
});
export const load_home_page_error = (data) => ({
  type: loadHomePage.failure,
  payload: data,
});

//save review details

export const save_review_details = (id) => ({
  type: saveReviewDetails.load,
  payload: id,
});
export const save_review_details_success = (data) => ({
  type: saveReviewDetails.success,
  payload: data,
});
export const save_review_details_error = (data) => ({
  type: saveReviewDetails.failure,
  payload: data,
});

//load countries

export const load_countries = () => ({
  type: loadCountries.load,
});
export const load_countries_success = (data) => ({
  type: loadCountries.success,
  payload: data,
});
export const load_countries_error = (data) => ({
  type: loadCountries.failure,
  payload: data,
});
//load states

export const load_states = (id) => ({
  type: loadStates.load,
  payload: id,
});
export const load_states_success = (data) => ({
  type: loadStates.success,
  payload: data,
});
export const load_states_error = (data) => ({
  type: loadStates.failure,
  payload: data,
});
//load cities

export const load_cities = (c_id, s_id) => ({
  type: loadCities.load,
  payload: { c_id, s_id },
});
export const load_cities_success = (data) => ({
  type: loadCities.success,
  payload: data,
});
export const load_cities_error = (data) => ({
  type: loadCities.failure,
  payload: data,
});

//load countries with flag

export const load_countries_with_flag = () => ({
  type: loadCountriesWithFlag.load,
});
export const load_countries_with_flag_success = (data) => ({
  type: loadCountriesWithFlag.success,
  payload: data,
});
export const load_countries_with_flag_error = (data) => ({
  type: loadCountriesWithFlag.failure,
  payload: data,
});

export const search_request_list = (data) => ({
  type: loadPropertyInfo.listRequest,
  payload: data,
});

//pay hotel fees
export const payHotelFeeAmount = (data) => ({
  type: payHotelFee.load,
  payload: data,
});

//Fetch exist leaseSignors

export const fetch_exist_lease_signors = (_id) => ({
  type: fetchExistLeaseSignors.load,
  payload: _id,
});
export const fetch_exist_lease_signors_success = (data) => ({
  type: fetchExistLeaseSignors.success,
  payload: data,
});
export const fetch_exist_lease_signors_error = (data) => ({
  type: fetchExistLeaseSignors.failure,
  payload: data,
});

// Create RV property info //
export const create_rv_property = (propertyInfo, propertyId, navigate) => ({
  type: createRvProperty.load,
  payload: { propertyInfo, propertyId, navigate },
});
export const create_rv_property_success = (data) => ({
  type: createRvProperty.success,
  payload: data,
});
export const create_rv_property_error = (data) => ({
  type: createRvProperty.failure,
  payload: data,
});
