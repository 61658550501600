import { evictionActions, terminationActions } from "../ActionTypes";

export const getEvictionDetails = (data) => ({
    type: evictionActions.fetch,
    payload: data,
});

export const setEvictionDetails = (data) => ({
    type: evictionActions.store,
    payload: data,
})

export const evictionList = (data) => ({
    type: evictionActions.list,
    payload: data,
});

export const evictionDisplay = (data) => ({
    type: evictionActions.display,
    payload: data,
});

export const getEvictionAdminDetails = (data) => ({
    type: evictionActions.displayDetail,
    payload: data,
});


export const getTerminationDetails = (data) => ({
    type: terminationActions.fetch,
    payload: data,
});

export const setTerminationDetails = (data) => ({
    type: terminationActions.store,
    payload: data,
})