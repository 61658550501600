import { call, put, takeEvery } from "redux-saga/effects";
import {
  requestUserLogin,
  requestForgetPassOtp,
  requestResetPassword,
  requestEmailVarify,
  requestLeaseDocSign,
} from "../Requests/auth";
import { authAction } from "../../ActionTypes";
import { request_loader } from "../../Actions/loader";
import { login_success } from "../../Actions/auth";
import { set_message } from "../../Actions/message";
import {
  REDIRECTIONS,
  COMMON_PATHS,
} from "../../../config/Owner-And-NewList_Paths";
import { verify, forgetPasswordLabel } from "../../../Constants/Common";

export function* handleUserLogin({ payload }) {
  try {
    const { request, navigate } = payload;
    yield put(request_loader({ load: true }));
    const response = yield call(requestUserLogin, {
      email: request.email,
      password: request.password,
      recaptcha_token: request.recaptcha_token,
    });
    yield put(request_loader({ load: false }));
    if (response) {
      let { data, status } = response;
      if (status === window.code["200"].code) {
        localStorage.removeItem("user_login");
        if (request.remember === true) {
          localStorage.setItem("user_login", request.email);
        }
        let da = data.data;
        let role = da.role[0];
        localStorage.setItem("accessToken", da.accessToken);
        localStorage.setItem("user_id", da._id);
        localStorage.setItem("role", role);
        localStorage.setItem("all_user_data", JSON.stringify(da));
        localStorage.setItem("loginTimer", Date.now());
        yield put(login_success({ role: role, user: da }));
        let link = localStorage.getItem("redirect_path")
          ? localStorage.getItem("redirect_path")
          : REDIRECTIONS[role + "_redirect"];
        let redirection = localStorage.getItem("history_url")
          ? localStorage.getItem("history_url")
          : link;
        if (redirection === '/verify' || redirection === '/home') {
          redirection = REDIRECTIONS[role + "_redirect"];
        }
        localStorage.removeItem("redirect_path");
        localStorage.removeItem("history_url");
        navigate(redirection);
      } else {
        yield put(request_loader({ load: "auth_error" }));
      }
    }
  } catch (error) {
    yield put(request_loader({ load: false }));
    yield put(
      set_message({
        error_code: "Incorrect user or password",
        code: "warning",
        heading: "Invalid Value",
      })
    );
  }
}
export function* handleUserLogout({ payload }) {
  const user_login = localStorage.getItem("user_login");
  localStorage.clear();
  sessionStorage.clear();
  if (user_login) {
    localStorage.setItem("user_login", user_login);
  }
  yield put(request_loader({ load: false }));
  payload(`${REDIRECTIONS.default_redirect}`);
}
export function* handleRequestOtp({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const {
      country_code,
      recaptcha_token,
      type,
      value,
      label,
      navigate,
      req,
      delete_cookie,
    } = payload;
    let param = {
      type: type,
      mobile: value,
      recaptcha_token,
    };
    if (type === forgetPasswordLabel.mobile.key) {
      param.iso_code = country_code;
    }
    const response = yield call(requestForgetPassOtp, param);
    yield put(request_loader({ load: false }));
    if (response) {
      const { data, status } = response;
      if (status === window.code["200"].code) {
        if (type === forgetPasswordLabel.mobile.key) {
          window.parent.setToken({
            user_phone: value,
            token: data.data,
            countryCode: country_code,
            countryCodeLabel: label,
          });
          delete_cookie(req + "_is_disable");
          delete_cookie(req + "_is_disabled_for");
          delete_cookie(req + "_hit_counter");
          let module = {
            error_code: data.msg,
            code: "success",
            heading: "Passcode sent successfully.",
          };
          yield put(set_message(module));
          navigate(`${REDIRECTIONS.lost_pass_redirect + "/" + req}`);
        }
        if (type === forgetPasswordLabel.email.key) {
          navigate(`${COMMON_PATHS.EMAIL_LINK_SEND}`);
        }
      } else {
        yield put(request_loader({ load: "auth_error" }));
      }
    }
  } catch (error) {
    yield put(request_loader({ load: "auth_error" }));
    console.log(error);
  }
}

export function* handleResetPassword({ payload }) {
  try {
    const { request, navigate, token } = payload;
    if (token === "") {
      yield put(
        set_message({
          error_code: "Invalid value token",
          code: "warning",
          heading: "Invalid Value",
        })
      );
      navigate(`${COMMON_PATHS.FORGOT_PASSWORD}`);
    }
    let req = {
      token: token,
      password: request.password,
    };
    yield put(request_loader({ load: true }));
    const response = yield call(requestResetPassword, req);
    yield put(request_loader({ load: false }));
    if (response) {
      const { status } = response;
      if (status === window.code["200"].code) {
        localStorage.removeItem("token");
        localStorage.removeItem("user_phone");
        localStorage.removeItem("code");
        localStorage.removeItem("countryCodeLabel");
        localStorage.removeItem("countryCode");
        navigate(`${COMMON_PATHS.FORGOT_PASSWORD_SUCCESS}`);
      }
      if (status === window.code["400"].code) {
        yield put(
          set_message({
            error_code: "request_time_out",
            code: "warning",
            heading: "Invalid Value",
          })
        );
        navigate(`${COMMON_PATHS.FORGOT_PASSWORD}`);
      }
    }
  } catch (error) {
    yield put(request_loader({ load: false }));
    let module = {
      error_code: "server_error",
      code: "warning",
      heading: "Warning",
    };
    yield put(set_message(module));
  }
}

export function* handleEmailVarify({ payload }) {
  try {
    const { navigate, actn, token } = payload;
    const response = yield call(requestEmailVarify, token, actn);
    if (response) {
      const { data, status } = response;
      if (status === window.code["200"].code) {
        let module = {
          error_code: data.msg,
          code: "success",
          heading: "Success",
        };
        let link =
          REDIRECTIONS[actn + "_success_redirect"] + "?token=" + data.data;
        if (actn === verify.leasesignor || actn === verify.leasedocument) {
          link = REDIRECTIONS[actn + "_success_redirect"];
          localStorage.setItem("leasesignor", JSON.stringify(data.data));
        }
        if (actn === verify.leasesignor) {
          yield put(set_message(module));
        }
        if (actn === verify.newuser) {
          link = COMMON_PATHS.VERIFY_USER_EMAIL_SUCCESS;
        }
        if (actn === verify.newBusinessAdmin) {
          link = COMMON_PATHS.COMPLETE_PROFILE;
          localStorage.setItem("new_bus_admin", JSON.stringify(data.data));
          localStorage.setItem("token", token);
        }

        navigate(link);
      } else {
        let module = {
          error_code: "Unauthorised request. Try again",
          code: "warning",
          heading: "Warning",
        };
        yield put(set_message(module));
        navigate(REDIRECTIONS[actn + "_faild_redirect"]);
      }
    }
  } catch (error) {
    const { navigate, actn } = payload;
    let module = {
      error_code: "Invalid value token",
      code: "warning",
      heading: "Warning",
    };
    yield put(set_message(module));
    navigate(REDIRECTIONS[actn + "_faild_redirect"]);
  }
}

export function* handleLeaseSignDoc({ payload }) {
  try {
    const { data, navigate } = payload;
    yield put(request_loader({ load: true }));
    const response = yield call(requestLeaseDocSign, data);

    yield put(request_loader({ load: false }));
    if (response) {
      const { status } = response;
      if (status === window.code["200"].code) {
        navigate(COMMON_PATHS.LEASE_SIGNOR_DOCUMENT_SIGNES);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* watchUserLogin() {
  yield takeEvery(authAction.load, handleUserLogin);
  yield takeEvery(authAction.logout, handleUserLogout);
  yield takeEvery(authAction.otpRequest, handleRequestOtp);
  yield takeEvery(authAction.passwordReset, handleResetPassword);
  yield takeEvery(authAction.emailVerify, handleEmailVarify);
  yield takeEvery(authAction.leaseSignDoc, handleLeaseSignDoc);
}
