import { payPenalty } from "../../ActionTypes/Renter";

export const pay_penalty = (values, id, navigate) => ({
  type: payPenalty.load,
  payload: { values, id, navigate },
});

export const pay_penalty_success = (data) => ({
  type: payPenalty.success,
  payload: data,
});

export const pay_penalty_error = (data) => ({
  type: payPenalty.failure,
  payload: data,
});
