import {
  fetchBackgroundCheckList,
} from "../../ActionTypes/admin";
const data = {
  loading: true,
  backgroundCheck: [],
  error: "",
};
const background_check = (state = data, action) => {
  switch (action.type) {
    case fetchBackgroundCheckList.load:
      return { ...state, loading: true };
    case fetchBackgroundCheckList.success:
      return { ...state, loading: false, ...action.payload };
    case fetchBackgroundCheckList.failure:
      return { ...state, loading: false, error: action.payload };
    default:
      return { ...state };
  }
};

export default background_check;
