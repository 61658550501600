import { listAction } from '../../Redux/ActionTypes/admin/index';

const data = {
  filterValue:""
};
const fetchFilterReducer = (state = data, action) => {

  switch (action.type) {
    case listAction.loadProp:
      return {
        ...state,
        filterValue: action.payload,
      };
    default:
      return { ...state };
  }
};

export default fetchFilterReducer;