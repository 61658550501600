import { createFaq, deleteFaq, loadFaq, updateFaq } from "../ActionTypes";
const data = {
  loading: true,
  faq: [],
  error: "",
};
const getItemIndex = (faqs, updatedFaq) => {
  return faqs.findIndex((d) => d._id === updatedFaq._id);
};
const faq = (state, action) => {
  switch (action.type) {
    case loadFaq.success:
      return { ...state, loading: false, faq: action.payload };

    case createFaq.load:
      return { ...state, loading: true };
    case createFaq.success:
      return {
        ...state,
        loading: false,
        faq: [action.payload, ...state.faq],
      };
    case createFaq.failure:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case updateFaq.load:
      return { ...state, loading: true };
    case updateFaq.success:
      const index = getItemIndex(state.faq, action.payload);
      state.faq.splice(index, 1, action.payload);
      return {
        ...state,
        loading: false,
        faq: [...state.faq],
      };
    case deleteFaq.load:
      return { ...state, loading: true };
    case deleteFaq.success:
      const ind = getItemIndex(state.faq, { _id: action.payload._id });
      state.faq.splice(ind, 1);
      return {
        ...state,
        loading: false,
        faq: [...state.faq],
      };
    default:
      return { ...state };
  }
};
export default faq;
