import { call, put, takeEvery } from "redux-saga/effects";
import { constantTexts } from "../../../Constants/Common";
import {
  create_faq_success,
  delete_faq_success,
  StoreFaq,
  update_faq_success,
} from "../../Actions/Cms";
import { request_loader } from "../../Actions/loader";
import { set_message } from "../../Actions/message";
import { createFaq, deleteFaq, loadFaq, updateFaq } from "../../ActionTypes";
import {
  requestCreateFaq,
  requestDeleteFaq,
  requestFetchFaq,
  requestUpdateFaq,
} from "../Requests/Cms";
import {
  fetchFaq,
} from "../../../Redux/Actions/Cms";
import { take } from "lodash";

export function* handleFetchFaq({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(requestFetchFaq);
    yield put(request_loader({ load: false }));
    if (response) {
      const { data, status } = response;
      if (status === window.code["200"].code) {
        yield put(StoreFaq(data.data));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchFetchFaq() {
  yield takeEvery(loadFaq.load, handleFetchFaq);
}

//Add Faq

export function* handleCreateFaq({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(requestCreateFaq, payload?.data);
    yield put(request_loader({ load: false }));
    if (response) {
      const { data, status } = response;
      if (status === 201) { 
        yield take(create_faq_success(data.data));
        payload?.dispatch(fetchFaq())    
      }
      yield put(
        set_message({
          error_code: data?.msg,
          code: constantTexts.SUCCESS,
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchCreateFaq() {
  yield takeEvery(createFaq.load, handleCreateFaq);
}

//update Faq

export function* handleUpdateFaq({ payload }) {
  const { _id, updatedata } = payload;
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(requestUpdateFaq, _id, updatedata);
    yield put(request_loader({ load: false }));
    const { data, status } = response;
    if (data) {
      if (status === 200) { 
        yield take(update_faq_success(data));
        payload?.dispatch(fetchFaq()) 
      }
      yield put(
        set_message({
          error_code: data?.msg,
          code: constantTexts.SUCCESS,
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchUpdateFaq() {
  yield takeEvery(updateFaq.load, handleUpdateFaq);
}

//delete Faq

export function* handleDeleteFaq({ payload }) {
  const {id,dispatch} = payload
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(requestDeleteFaq, id);
    yield put(request_loader({ load: false }));
    if (response) {
      const { data, status } = response;
      if (status === 200) { 
        yield take(delete_faq_success(data.msg));
        dispatch(fetchFaq());  
      }
      yield put(
        set_message({
          error_code: data?.msg,
          code: constantTexts.SUCCESS,
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}
export function* watchDeleteFaq() {
  yield takeEvery(deleteFaq.load, handleDeleteFaq);
}
