import { createLeavingReview } from "../../ActionTypes";
const data = {
  loading: true,
  leavingReview: [],
  error: "",
};

const leavingReview = (state = data, action) => {
  switch (action.type) {
    case createLeavingReview.load:
      return { ...state, loading: true, createdReview: false };
    case createLeavingReview.success:
      return {
        ...state,
        loading: false,
        ...action.payload,
        createdReview: true,
      };
    case createLeavingReview.failure:
      return {
        ...state,
        loading: false,
        error: action.payload,
        createdReview: false,
      };
    default:
      return { ...state };
  }
};

export default leavingReview;
