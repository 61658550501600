import { call, put, takeEvery } from "redux-saga/effects";
import { loadLeaseSignorDetails } from "../../../ActionTypes";
import {
  load_lease_signor_details_error,
  load_lease_signor_details_success,
} from "../../../Actions/Owner/owner-listing";
import { requestLoadLeaseSignorDetailsById } from "../../Requests/owner/LeaseSignorDetails";

export function* handleLoadLeaseSignorDetails({ payload }) {
  try {
    const response = yield call(requestLoadLeaseSignorDetailsById, payload);
    const { data } = response;
    if (data) {
      yield put(load_lease_signor_details_success(data.data));
    } else yield put(load_lease_signor_details_error(data));
  } catch (error) {
    yield put(load_lease_signor_details_error(error));
  }
}

export default function* watchLoadLeaseSignorDetails() {
  yield takeEvery(loadLeaseSignorDetails.load, handleLoadLeaseSignorDetails);
}
