import { call, put, takeEvery } from "redux-saga/effects";
import { fetchBackgroundCheckList } from "../../../ActionTypes/admin";
import { request_loader } from "../../../Actions/loader";
import { load_backgroundCheck_success } from "../../../Actions/admin/BackgroundCheck";
import { requestLoadBackgroundCheck } from "../../Requests/admin/BackgroundCheck";

export function* handleLoadBackgroundCheckList({ payload }) {
  try {

    const { search, fetchPage, page,lists,initial,
      type,
      search_by,status,
      iso_code } = payload;
      yield put(request_loader({ load: initial }));
      let req = {};
      if(iso_code&&req.search_by==='mobile')req.iso_code = iso_code;
      if(status)req.status = status;
      if (page) req.page = page;
      if(search_by&&search)req[search_by] = search;
    

    const response = yield call(requestLoadBackgroundCheck,req);

    if (response) {
      const { status } = response;
      if (status === window.code["200"].code) {
        const { data, options } = response.data;
        const params = {
          initial: false,
          lists: lists ? lists.concat(data) : data,
        };
        
        if(type)params.type = type;
        if(search_by)params.search_by = search_by;
        if(search)params.search = search;
        if(search_by==='mobile')params.iso_code=iso_code;
        if (fetchPage === true) params.totalPages = options.totalDocs;
        if (options && options.nextPage) params.page = options.nextPage;
  
        yield put(load_backgroundCheck_success(params));
      }
      yield put(request_loader({ load: false }));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchLoadBackgroundCheckList() {
  yield takeEvery(fetchBackgroundCheckList.load, handleLoadBackgroundCheckList);
}
