
import { uploadAction } from "../../ActionTypes";
import { initial_state_lease } from "../../../Constants/Common";

const LeaseDocAdmin = (state = initial_state_lease, action) => {
  switch (action.type) {
    case uploadAction.displayLeaseDoc:
     return {...state,...action.payload}
    default:
      return { ...state };
  }
};
export default LeaseDocAdmin;
