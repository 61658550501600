import { sendMessage } from "../../ActionTypes/Renter";

export const send_message = (msg, type, navigate, isNavigation) => ({
  type: sendMessage.load,
  payload: { msg, type, navigate, isNavigation },
});

export const send_message_success = (data) => ({
  type: sendMessage.success,
  payload: data,
});

export const send_message_error = (data) => ({
  type: sendMessage.failure,
  payload: data,
});
