import {
  loadListingRequest,
  deleteListingRequest,
  listingRequestDetails,SaveRatingDetails
} from "../../ActionTypes/admin";

export const load_listing_request = (param) => ({
  type: loadListingRequest.load,
  payload: param,
});
export const load_listing_request_success = (data) => ({
  type: loadListingRequest.success,
  payload: data,
});
export const load_listing_request_error = (data) => ({
  type: loadListingRequest.failure,
  payload: data,
});

export const delete_listing_request = (param) => ({
  type: deleteListingRequest.load,
  payload: param,
});
export const delete_listing_request_success = (data) => ({
  type: deleteListingRequest.success,
  payload: data,
});
export const delete_listing_request_error = (data) => ({
  type: deleteListingRequest.failure,
  payload: data,
});

export const listing_request_details = (param) => ({
  type: listingRequestDetails.load,
  payload: param,
});
export const listing_request_details_success = (data) => ({
  type: listingRequestDetails.success,
  payload: data,
});
export const listing_request_details_error = (data) => ({
  type: listingRequestDetails.failure,
  payload: data,
});

//save saftey rating
export const save_rating_request_details = (param) => ({
  type: SaveRatingDetails.load,
  payload: param,
});
export const save_rating_details_success = (data) => ({
  type: SaveRatingDetails.success,
  payload: data,
});
export const save_rating_details_error = (data) => ({
  type: SaveRatingDetails.failure,
  payload: data,
});