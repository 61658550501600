import { listAction, userAction } from "../../ActionTypes/admin";
import { initial_state } from "../../../Constants/Common";

const userlistadmin = (state = initial_state, action) => {
  switch (action.type) {
    case listAction.displayUser:
      return { ...state, ...action.payload };
    case userAction.listOnlyUpdateUnique:
      return { ...state, lists: action.payload };
    default:
      return { ...state };
  }
};
export default userlistadmin;
