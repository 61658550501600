import * as Yup from "yup";
import error_messages from "./error-messages";
import { testBlockDate, testRentalPeriod, setMinCheckout } from "../helpers/CommonHelper";
import {
  alphaRegex,
  numberOnly,
  passwordRegex,
  phoneRegExp,
  positiveValue,
  emailRegex2,
  usCodeRegex,
  canadaCodeRegex2,
  blankSpaceregex,
  countryCodes,
  onlyAlphabets,
  alphaWithFewChars,
  alphaNumeric,
  charsRegex,
  removeWhiteSpace,
  aplhaSpecial,
  urlValid,
  emailRegex3,
  decimalUpToTwo,
} from "./Common";

export const giveValue = (val) => val;

export const setCleaningFee = {
  val: "",
};
export const basicInfo_validationSchema = Yup.object().shape({
  basicInformation: Yup.object().shape({
    property_title: Yup.string()
      .required("Listing title required.")
      .nullable()
      .test("value-name", "Please remove empty space.", (val) =>
        val && val?.startsWith(" ") ? false : true
      )
      .max(75, "Value should not be greater than 75 characters."),
    property_type: Yup.string().required("Property type required."),
    no_of_guest: Yup.string()
      .required("Number of guests required.")
      .nullable()
      .matches(blankSpaceregex, "Please remove the blank space.")
      .matches(numberOnly, "Number of guests should be a number.")
      .matches(
        positiveValue,
        "Enter correct number of guests.",
        (value) => +value > -1
      )
      .test("is-decimal", "Enter proper value", (val) =>
        val?.includes(".") ? false : true
      ),
    fulladdress: Yup.object().shape({
      address1: Yup.string().nullable().required("Address 1 required."),
      country: Yup.string().nullable().required("Country required."),
      city: Yup.string().nullable().required("City required."),
      postal_code: Yup.string()
        .when("country", {
          is: (val) => val === countryCodes[1],
          then: Yup.string()
            .required("Enter postal code for Canada.")
            .nullable()
            // .matches(canadaCodeRegex2, "Invalid code entered for Canada."),
        })
        .when("country", {
          is: (val) => val === countryCodes[0],
          then: Yup.string()
            .required("Enter postal code for US")
            .nullable()
            .matches(usCodeRegex, "Invalid code entered for US."),
        })
        .required("Postal code required.")
        .nullable(),
      state: Yup.string().nullable().required("State required."),
    }),
    property_description: Yup.string()
      .nullable()
      .required("Listing description required.")
      .min(10, "Value should be more than 10 characters.")
      .max(1500, "Value should not be greater than 1500 characters."),
  }),
});

export const propertyInfo_validationSchema = Yup.object().shape({
  propertyInformation: Yup.object().shape({
    weeklyrent: Yup.number()
      .required("Weekly rent required.")
      .nullable()
      .typeError("Weekly rent must be a number.")
      .positive("Weekly rent must be greater than zero.")
      .min(100, "Value should not be less than $100.")
      .test("decimalAlowed", "Enter a value upto 2 decimal places.", (value) =>
        /^(?:\d*\.\d{1,2}|\d+)$/.test(value) ? true : false
      ),

    montlyrate: Yup.number()
      .required("Monthly rent required.")
      .nullable()
      .typeError("Monthly rent must be a number.")
      .positive("Monthly rent must be greater than zero.")
      .moreThan(Yup.ref("weeklyrent"), "Must be greater than weekly rent.")
      .test("decimalAlowed", "Enter a value upto 2 decimal places.", (value) =>
        /^(?:\d*\.\d{1,2}|\d+)$/.test(value) ? true : false
      ),
    house_rules: Yup.string()
      .required("House rules required.")
      .nullable()
      .min(10, "Value should be more than 10 characters.")
      .trim("The house rules required leading and trailing spaces.")
      .max(1450, "Value should not be greater than 1500 characters."),
    rental_space: Yup.number()
      .required("Rental space required.")
      .nullable()
      .typeError("Rental space must be a number.")
      .positive("Rental space must be greater than zero.")
      .test("decimalAlowed", "Enter a value upto 2 decimal places.", (value) =>
        /^(?:\d*\.\d{1,2}|\d+)$/.test(value) ? true : false
      ),
    is_pet_allowed: Yup.boolean().required("Pets required"),
    is_parking_allowed: Yup.boolean().required("Parking required"),
    security_fee: Yup.number()
    .nullable()
    .typeError("Security deposit must be a number.")
    .moreThan(-1, "Security deposit must be a proper value.")
    .max(Yup.ref("montlyrate"), "Must not be greater than monthly rent")
    .test("decimalAlowed", "Enter a value upto 2 decimal places.", (value) => {
      return !value || /^(?:\d*\.\d{1,2}|\d+)$/.test(value);
    }),
    cleaning_fee: Yup.number()
      .typeError("Cleaning fee must be a number.")
      .moreThan(-1, "Cleaning fee must be a proper value.")
      .test("decimalAlowed", "Enter a value upto 2 decimal places.", (value) =>
        !value || /^(?:\d*\.\d{1,2}|\d+)$/.test(value) ? true : false),
    pet: Yup.array().when("is_pet_allowed", {
      is: true,
      then: Yup.array().required("Pets required").min(1, "Pets Required"),
    }),
    others: Yup.string().when("pet", {
      is: (val) => val?.includes("other"),
      then: Yup.string()
        .nullable()
        .matches(removeWhiteSpace, "Please remove blank space.")
        .required("Others Required."),
    }),

    utilites: Yup.array()
      .nullable()
      .test(
        "check-default-value",
        "Water and Electricity is mandatory",
        (val) =>
          val ? val.includes("electricity") && val.includes("water") : false
      )
      .required("Utilites required")
      .min(1, "Utilites required"),
    rental_period: Yup.array()
      .required("Rental period required.")
      .min(1, "Please select atleast 1 period."),
    bedroom: Yup.object().shape({
      privacy: Yup.string().nullable().required("Privacy required."),
      no_of_room: Yup.number()
        .required("Room required")
        .nullable()
        .typeError("Room must be a number.")
        // .integer("Room must be a number.")
        .positive("Room must be greater than zero.")
        .test(
          "decimalAlowed",
          "Enter a value upto 2 decimal places.",
          (value) => (/^(?:\d*\.\d{1,2}|\d+)$/.test(value) ? true : false)
        ),
    }),
    bathroom: Yup.object().shape({
      privacy: Yup.string().nullable().required("Privacy required."),
      no_of_room: Yup.number()
        .required("Room required")
        .nullable()
        .typeError("Room must be a number.")
        //.integer("Room must be a number.")
        .positive("Room must be greater than zero.")
        .test(
          "decimalAlowed",
          "Enter a value upto 2 decimal places.",
          (value) => (/^(?:\d*\.\d{1,2}|\d+)$/.test(value) ? true : false)
        ),
    }),
    kitchen: Yup.object().shape({
      no_of_room: Yup.number()
        .required("Room required")
        .nullable()
        .typeError("Room must be a number.")
        .integer("Room must be a number.")
        .positive("Rooms must be greater than zero."),
      privacy: Yup.string().nullable().required("Privacy required."),
    }),
    closet: Yup.object().shape({
      no_of_room: Yup.number()
        .required("Room required")
        .nullable()
        .typeError("Rooms must be a number.")
        .integer("Room must be a number.")
        .positive("Rooms must be greater than zero."),
      privacy: Yup.string().nullable().required("Privacy required."),
    }),
    entrance: Yup.string().nullable().required("Privacy required."),

    parking_type: Yup.object().when("is_parking_allowed", {
      is: true,
      then: Yup.object().shape({
        parking_type: Yup.array()
          .required("Parking Type required")
          .min(1, "Parking Type required"),
        parking_fee: Yup.number()
          .required("Parking Fee required")
          .nullable()
          .typeError("Parking Fee must be a number.")
          .moreThan(-1, "Parking Fee must be a proper value.")
          .test(
            "decimalAlowed",
            "Enter a value upto 2 decimal places.",
            (value) => (/^(?:\d*\.\d{1,2}|\d+)$/.test(value) ? true : false)
          ),
      }),
    }),
  }),
});

export const leaseSignor_validationSchema = Yup.object().shape({
  leasesignors: Yup.array().of(
    Yup.object().shape({
      first_name: Yup.string()
        //  .matches(blankSpaceregex, "Please remove blank space.")
        //   .matches(onlyAlphabets, "Numbers are not allowed.")
        //   .nullable()
        //   .required("First Name required."),
        .matches(removeWhiteSpace, "Spaces are not allowed.")
        .matches(aplhaSpecial, "Numbers are not allowed.")
        .required("First Name required.")
        .trim("The first name required leading and trailing spaces."),
      last_name: Yup.string()
        // .matches(blankSpaceregex, "Please remove blank space.")
        // .matches(onlyAlphabets, "Numbers are not allowed.")
        // .nullable()
        // .required("Last Name required."),
        .matches(removeWhiteSpace, "Spaces are not allowed.")
        .matches(aplhaSpecial, "Numbers are not allowed.")
        .required("Last Name required.")
        .trim("The last name required leading and trailing spaces."),
      iso_code: Yup.string().required("Please select country code"),
      mobile: Yup.string()
        .required("Mobile Number required.")
        .matches(
          positiveValue,
          "Enter Correct Mobile Number",
          (value) => +value > -1
        )
        .min(10, "Mobile number should be of 10 digits.")
        .max(10, "Mobile number should be of 10 digits."),
      email: Yup.string()
        .required("Email address required.")
        .email("Invalid Email address."),
    })
  ),
});

export const bankInfo_validationSchema = Yup.object().shape({
  bankInfo: Yup.object().shape({
    company_name: Yup.string(),
    account_number: Yup.number()
      .required("Account number required.")
      .typeError("Invalid Account Number."),
    routing_number: Yup.number()
      .required("Routing number required.")
      .typeError("Invalid Routing Number."),
    bank_name: Yup.string(),
    first_name: Yup.string()
      .matches(removeWhiteSpace, "Please remove blank space.")
      .required("First Name required.")
      .test("Is any Number?", "Alphabets only.", (value) =>
        value?.includes("1") ||
          value?.includes("2") ||
          value?.includes("3") ||
          value?.includes("4") ||
          value?.includes("5") ||
          value?.includes("6") ||
          value?.includes("7") ||
          value?.includes("8") ||
          value?.includes("9") ||
          value?.includes("0")
          ? false
          : true
      )
      .trim("The first name required leading and trailing spaces."),
    last_name: Yup.string()
      .matches(removeWhiteSpace, "Please remove blank space.")
      .required("Last Name required.")
      .test("Is any Number?", "Alphabets only.", (value) =>
        value?.includes("1") ||
          value?.includes("2") ||
          value?.includes("3") ||
          value?.includes("4") ||
          value?.includes("5") ||
          value?.includes("6") ||
          value?.includes("7") ||
          value?.includes("8") ||
          value?.includes("9") ||
          value?.includes("0")
          ? false
          : true
      )
      .trim("The last name required leading and trailing spaces."),
  }),
});

export const userDetails_validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    first_name: Yup.string()
      .strict(false)
      .trim()
      .required("First name is required.")
      .matches(
        aplhaSpecial,
        "First name can only contain alphabets and special characters along with space."
      ),
    last_name: Yup.string()
      .strict(false)
      .trim()
      .required("Last name is required.")
      .matches(
        aplhaSpecial,
        "Last name can only contain alphabets and special characters along with space."
      ),

    mobile: Yup.string()
      .required("Mobile Number required.")
      .matches(phoneRegExp, "Invalid Mobile Number.")
      .min(10, "Please enter a valid 10 digit Mobile Number.")
      .max(10, "Please enter a valid 10 digit Mobile Number."),
    toc_accepted: Yup.bool().oneOf(
      [true],
      "Please accept Terms & Conditions and Privacy Policy."
    ),
  }),
});
export const login_validationSchema = Yup.object().shape({
  email: Yup.string().required("Email or Mobile Number required"),
  password: Yup.string()
    .required("Password required.")
    .matches(
      /^(\S+$)/g,
      "The password cannot include leading and trailing spaces."
    )
    .strict(true),
});

export const change_password_schema = Yup.object().shape({
  old_password: Yup.string()
    .required("Password required.")
    .matches(
      /^(\S+$)/g,
      "The password cannot include leading and trailing spaces."
    )
    .strict(true),
  password: Yup.string()
    .required("Password required.")
    .matches(
      /^(\S+$)/g,
      "The password cannot include leading and trailing spaces."
    )
    .strict(true)
    .matches(
      passwordRegex,
      "Must contain one uppercase, One lowercase, One number and One special case character."
    )
    .min(8, "The password should contain atleast 6 characters"),
  confirm_password: Yup.string()
    .required("Password required.")
    .oneOf([Yup.ref("password"), null], "Password must match."),
});

export const leaseSign_validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name Is required"),
  email: Yup.string()
    .email("Enter email in proper format.")
    .required("Email is required"),
  toc_accepted: Yup.bool().oneOf(
    [true],
    "Accept Terms & Conditions is required"
  ),
});

export const profileedit_validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(removeWhiteSpace, "Spaces are not allowed.")
    .matches(aplhaSpecial, "Numbers are not allowed.")
    .required("First Name required.")
    .trim("The first name required leading and trailing spaces."),
  last_name: Yup.string()
    .matches(removeWhiteSpace, "Spaces are not allowed.")
    .matches(aplhaSpecial, "Numbers are not allowed.")
    .required("Last Name required.")
    .trim("The last name required leading and trailing spaces."),
  email: Yup.string()
    .email("Invalid Email Address.")
    .required("Email Address required."),
  mobile: Yup.string()
    .required("Mobile number required.")
    .matches(phoneRegExp, "Incorrect Mobile Number."),
});

export const otp_validationSchema = Yup.object().shape({
  otp: Yup.object().shape({
    one: Yup.number().typeError("Digits only").required(" "),
    two: Yup.number().typeError("Digits only").required(" "),
    three: Yup.number().typeError("Digits only").required(" "),
    four: Yup.number().typeError("Digits only").required(" "),
    five: Yup.number().typeError("Digits only").required(" "),
    six: Yup.number().typeError("Digits only").required(" "),
  }),
});

export const complete_profile_validationSchema = Yup.object().shape({
  email: Yup.string()

    .required("Email required.")
    .email("Please enter a valid Email Address."),
  password: Yup.string()
    .required("Password required.")
    .matches(
      /^(\S+$)/g,
      "The password cannot include leading and trailing spaces."
    )
    .min(8, "Password must be a minimum of 8 characters.")
    .matches(
      passwordRegex,
      "Must contain One uppercase, One lowercase, One number and One special case character."
    ),
  confirm_password: Yup.string()
    .required("Password required.")
    .oneOf([Yup.ref("password"), null], "Password must match."),
});

export const reset_validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required.")
    .matches(
      /^(\S+$)/g,
      "The password cannot include leading and trailing spaces."
    )
    .min(8, "Password must be a minimum of 8 characters.")
    .matches(
      passwordRegex,
      "Must contain One uppercase, One lowercase, One number and One special case character."
    ),
  confirm_password: Yup.string()
    .required("Password is required.")
    .oneOf([Yup.ref("password"), null], "Password must match."),
});

export const forget_pass_schema = (value, selector) => {
  let mobilevalid = numberOnly.test(value);
  let islength = value.length;
  let emailvalid = emailRegex3.test(value);
  if (selector === "mobile") {
    if (mobilevalid === false || islength !== 10) {
      return "Please Enter 10 Digit Valid Mobile Number.";
    }
  } else {
    if (emailvalid === false) {
      return "Please Enter A Valid Email Address.";
    }
  }
  return true;
};

export const rejection_reasons = (value, isPublished) => {
  const { reasons, comment } = value;
  const error = {};
  if (!isPublished && reasons.length < 1)
    error.reErr = "Please Select The Rejection Reason.";
  if (comment.trim() === "") error.comErr = "Please Enter The Comment.";
  if (comment.length > 1500) {
    error.comErr = "Value should not be greater than 1500 characters.";
  }

  if (Object.keys(error).length > 0) return error;
  return true;
};

export const feesetting_validationSchema = Yup.object().shape({
  feesSetting: Yup.object().shape({
    background_application_fee: Yup.number()
      .required("Background Application Fee Required.")
      .nullable()
      .typeError("Must be a number.")
      .moreThan(-1, "It must be greater than or equal to zero."),
    cancellation_fee: Yup.number()
      .required("Cancellation Fee required.")
      .nullable()
      .typeError("Must be a number.")
      .moreThan(-1, "It must be greater than or equal to zero."),
    evication_fee: Yup.number()
      .required("Eviction Fee required.")
      .nullable()
      .typeError("Must be a number.")
      .moreThan(-1, "It must be greater than or equal to zero."),
    hotel_lsiting_fee: Yup.number()
      .required("Corporate Fee required.")
      .nullable()
      .typeError("Must be a number.")
      .moreThan(-1, "It must be greater than or equal to zero."),
    late_rent_payment_fee: Yup.number()
      .required("Late Rent Payment Fee Required.")
      .nullable()
      .typeError("Must be a number.")
      .moreThan(-1, "It must be greater than or equal to zero."),

    lisiting_promotion_fee: Yup.number()
      .required("Daily Listing Promotion Fee Required.")
      .nullable()
      .typeError("Must be a number.")
      // .lessThan(Yup.ref("lisiting_promotion_fee_weekly"), "Must be less than weekly promotion fee.")
      .moreThan(-1, "It must be greater than or equal to zero."),
    lisiting_promotion_fee_weekly: Yup.number()
      .required("Weekly Listing Promotion Fee Required.")
      .nullable()
      .test("PositivenumberAlowed", "It must be greater than or equal to zero.", (value) =>
        /^\+?(0|[1-9]\d*)$/.test(value) ? true : false)
      .moreThan(Yup.ref("lisiting_promotion_fee"), "Must be greater than daily listing promotion fee.")
      .typeError("Must be a number."),
    renter_insurance_fee: Yup.number()
      .required("Renter Insurance Fee Required.")
      .nullable()
      .typeError("Must be a number.")
      .moreThan(-1, "It must be greater than or equal to zero."),
  }),
});

export const processingfeesetting_validationSchema = Yup.object().shape({
  processingfeeSetting: Yup.object().shape({
    cleaning_service_rate: Yup.number()
      .required("Weekly Cleaning Fee required.")
      .nullable()
      .typeError("Must be a number.")
      .min(0, "It must be greater than or equal to zero."),
    renter_processing_fee: Yup.number()
      .required("Renter Proccessing Fee required.")
      .nullable()
      .typeError("Must be a number.")
      .moreThan(-1, "It must be greater than or equal to zero."),
    host_platform_fee: Yup.number()
      .required("Homeowner Processing Fee required.")
      .nullable()
      .typeError("Must be a number.")
      .moreThan(-1, "It must be greater than or equal to zero.")
      .max(100, "Percentage can't be more that 100"),
    payment_processing_fee: Yup.number()
      .required("Renter Payment Processing Fee required.")
      .nullable()
      .typeError("Must be a number.")
      .moreThan(-1, "It must be greater than or equal to zero.")
      .max(100, "Percentage can't be more that 100"),
    // cleaning_service_rate_city_state: Yup.array().of(
    //   Yup.object().shape({
    //     country: Yup.string().required("Country Required").nullable(),
    //     state: Yup.string().required("State Required").nullable(),
    //     city: Yup.string().required("City Required").nullable(),
    //     cleaning_fee: Yup.number()
    //       .required("Cleaning Fee Required")
    //       .nullable()
    //       .typeError("Must be a number")
    //       .moreThan(-1, "Must be greater than equal to zero")
    //       .max(100, "Percentage can't be more that 100"),
    //   })
    // ),
  }),
});

export const home_filter_validate_Schema = Yup.object().shape({
  check_in: Yup.date().nullable(),
  check_out: Yup.date().nullable(),
});

/* Get Featured Listing End date */
export const getEndDate = (startDate, featured_period, featured_type) => {
  let addDays = featured_period;
  if (featured_type != 'days') {
    addDays = (featured_period * 7);
  }
  return setMinCheckout(startDate, addDays);
};

export const featured_validation = Yup.object().shape({
  search_criteria: Yup.bool().oneOf([true], "Feature section required"),
  start_date: Yup.date()
    .required("Start date required")
    .test('booking-not-allowed', 'Featuring of listing on some dates are not allowed.', (value, context) => {
      const isInvalidDate = testBlockDate(value, getEndDate(value, context.parent.featured_period, context.parent.featured_type), context.parent.featured_dates);
      return parseInt(isInvalidDate) > 0 ? false : true;
    })

  //featured_period: 2,
  //featured_type: 'days'

  // end_date: Yup.date()
  //   .required("End date required")
  //   .min(Yup.ref("start_date"), "End date should be greater that start date.")
  //   .test('booking-not-allowed', 'Featuring of listing on some dates are not allowed.', (value, context) => {
  //     const isInvalidDate = testBlockDate(context.parent.start_date, value, context.parent.featured_dates);
  //     return parseInt(isInvalidDate) > 0 ? false : true;
  //   })
});

export const document_validate = (doc, stateval) => {
  let error = "";
  if (!doc || doc === null) error = "Please Select The Document To Upload.";
  if (stateval === "") error = "Please Select The State To Proceed.";
  if (error !== "") return error;
  return true;
};

export const cmValidationSchema = Yup.object().shape({
  content: Yup.string().required("Required Content"),
});
export const faq_validation_schema = Yup.object().shape({
  question: Yup.string()
    .matches(removeWhiteSpace, "Please remove the blank spaces.")
    .required("Please enter the question."),
  answer: Yup.string()
    .matches(removeWhiteSpace, "Please remove the blank spaces.")
    .required("Please enter the answer."),
});
export const notificationsetting_validationSchema = Yup.object().shape({
  notificationSetting: Yup.object().shape({}),
});

export const addBusinessAdmin_validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(removeWhiteSpace, "Spaces are not allowed.")
    .required("First Name required.")
    .nullable()
    .trim("The firstname required leading and trailing spaces.")
    .typeError("Must be a First Name")
    .matches(aplhaSpecial, "Numbers are not allowed."),
  last_name: Yup.string()
    .matches(removeWhiteSpace, "Spaces are not allowed.")
    .required("Last Name required.")
    .nullable()
    .trim("The last name required leading and trailing spaces.")
    .matches(aplhaSpecial, "Numbers are not allowed.")
    .typeError("Must be a Last Name"),
  email: Yup.string()
    .required("Email required.")
    .nullable()
    .email("Please enter a valid Email Address."),
});

export const bookingValidation = (
  fromdate,
  todate,
  block_dates,
  rental_period
) => {
  const validerr = {};
  if (fromdate === "") {
    validerr.check_in = "Please select the Check in date.";
  }
  const isInvalidDate = testBlockDate(fromdate, todate, block_dates);
  if (parseInt(isInvalidDate) > 0) {
    validerr.check_in = "Booking on some dates are not allowed.";
  }
  const isValidPeriod = testRentalPeriod(fromdate, todate, rental_period);
  if (!isValidPeriod.status) {
    validerr.check_out = `Please select the rental period of minimum ${isValidPeriod.min
      } days ${isValidPeriod.max ? " and maximum " + isValidPeriod.max + " days" : ""
      } .`;
  }
  return validerr;
};

export const date_diff = (fromdate, todate) => {
  const dateone = new Date(fromdate);
  const datetwo = new Date(todate);
  const differ = datetwo - dateone;
  let intr = 0;
  if (differ > 0) {
    const diff = Math.abs(differ) / (60 * 60 * 24 * 1000);
    const intval = parseInt(diff);
    const diffr = diff - intval;
    intr = diff;
    if (diffr > 0) {
      //intr = parseInt(diff) + parseInt(1);
      intr = parseInt(diff);
    }
  }
  return intr;
};

export const occupantsValidation = (props) => {
  let error = {};
  const {
    newName,
    emername,
    emermob,
    zipcode,
    dob,
    driver_license_state,
    driver_license_number,
    country,
    state,
    city,
    documents_url,
    count,
    allowed,
    appliedforcheck,
    skipforcheck,
    first_name,
    middle_name,
    last_name,
    ssn,
    user_consent,
    hasNoMiddleName,
    ssnVisible,
    locality,
    national_id_number,
  } = props;
  if (appliedforcheck === null ) {
  if ((!zipcode || zipcode === "") && (!locality || locality === ""))
    error.zipcode = "Please enter valid zipcode.";
  // if (zipcode && zipcode.length < 5)
  //   error.zipcode = "Please enter minimum 5 digits.";
  // if (zipcode && zipcode.length > 6)
  //   error.zipcode = "Maximum 6 digits are allowed.";
  if (!dob || dob === "" || dob === "NaN-NaN-NaN")
    error.dob = "Please enter date of birth.";
  if (!driver_license_state || driver_license_state == "")
    error.driver_license_state = "Please select the Driver Licence State.";
  if (country === "US" && driver_license_number == "")
    error.driver_license_number = "Please enter State ID or Driver's License number.";
  if (!country || country === "")
    error.country = "Please select the country.";
  if (!state || state === "") error.state = "Please select the state.";
  if (!city || city === "") error.city = "Please select the city.";
  if (!documents_url || documents_url.length === 0)
    error.documents_url = "Please upload atleast one file.";
  if (!first_name || first_name === "")
    error.first_name = "Please enter First Name.";
  if (!hasNoMiddleName && (!middle_name || middle_name === ""))
    error.middle_name = "Please enter Middle Name.";
  if (!last_name || last_name === "")
    error.last_name = "Please enter Last Name.";
  if (ssnVisible && (!ssn || ssn === ""))
    error.ssn = "Please enter SSN.";
  if (!ssnVisible && (!national_id_number || national_id_number === ""))
    error.ssn = "Please enter National ID Number.";
  if ( !skipforcheck && (!user_consent || user_consent === ""))
    error.user_consent = "Please consent to proceed.";
  }
  if (newName === false) error.field = error_messages.booking_occupants_error;
  if (parseInt(count) > parseInt(allowed))
    error.field = "You have exceeded the maximum occupants limit.";

  if (emername === "")
    error.emername = error_messages.booking_emergancy_name_err;
  if (newName && newName.includes(emername.toLowerCase()))
    error.emername = error_messages.booking_occupants_duplicate;
  if (!phoneRegExp.test(emermob))
    error.emermob = error_messages.booking_emergancy_mobile_err;

  return Object.keys(error).length > 0 ? error : true;
};

export const setCleaningFeeSchema = Yup.object().shape({
  stateCountryCityCleaningFee: Yup.object().shape({
    country: Yup.string().required("Country required.").nullable(),
    state: Yup.string().required("State required.").nullable(),
    city: Yup.string().required("City required.").nullable(),
    price: Yup.string().required("Cleaning fee required.").nullable(),
  }),
});
export const contactDetailsForHomeOwnetsSchema = Yup.object().shape({
  contactDetails: Yup.object().shape({
    firstName: Yup.string()
      .required("First Name required.")
      .nullable()
      .matches(blankSpaceregex, "Please remove blank space."),
    lastName: Yup.string()
      .required("Last Name required.")
      .nullable()
      .matches(blankSpaceregex, "Please remove blank space."),
    company: Yup.string()
      .required("Company required")
      .nullable()
      .matches(blankSpaceregex, "Please remove blank space."),
    emailAddress: Yup.string()
      .required("Email address required.")
      .nullable()
      .email("Enter correct email address."),
    mobileNumber: Yup.string()
      .required("Mobile number required.")
      .nullable()
      .matches(blankSpaceregex, "Please remove blank space.")
      .matches(numberOnly, "Number of guests should be a number"),
  }),
});

export const create_promocode_validation_schema = Yup.object().shape({
  promo_code_name: Yup.string()
    .required("Please enter the promo code name.")
    .matches(removeWhiteSpace, "Please remove blank space."),
  start_date: Yup.date().required("Please enter the start date."),
  end_date: Yup.date()
    .min(
      Yup.ref("start_date"),
      "End date should be greater than equal to start date."
    )
    .required("Please enter the end date."),
  percentage: Yup.number()
    .required("Please enter the percentage.")
    .test(
      "Is positive?",
      "The number must be between 0 and 100.",
      (value) => value > 0 && value < 100
    ),
  description: Yup.string()
    .matches(removeWhiteSpace, "Please remove blank space.")
    .required("Please enter the promocode description."),
});

export const new_message_validation_schema = Yup.object().shape({
  subject: Yup.string().nullable().required("Subject type required."),
  messageText: Yup.string()
    .required("Message should not be blank.")
    .max(250, "Value should not be greater than 250 characters.")
    .trim("The message test required leading and trailing spaces.")
    .test("is-link", "Please remove the link.", (value) =>
      value?.includes(".com") ||
        value?.includes("www.") ||
        value?.includes(".in") ||
        value?.includes(".net") ||
        value?.includes(".org")
        ? false
        : true
    )
    .test(
      "special-char",
      "Numbers and special characters not allowed, except (< , > . ? ' !)",
      (value) => (/[^ < . ? ! >,' \n a-zA-Z ]/.test(value) ? false : true)
    )
});

export const new_message_only_validation_schema = Yup.object().shape({
  messageText: Yup.string()
    .required("Message should not be blank.")
    .max(250, "Value should not be greater than 250 characters.")
    .trim("The message test required leading and trailing spaces.")
    .test("is-link", "Please remove the link.", (value) =>
      value?.includes(".com") ||
        value?.includes("www.") ||
        value?.includes(".in") ||
        value?.includes(".net") ||
        value?.includes(".org")
        ? false
        : true
    )
    .test(
      "special-char",
      "Numbers and special characters not allowed, except (< , > . ? ' !)",
      (value) => (/[^< . ? ! >,'\n a-zA-Z ]/.test(value) ? false : true)
    )

});
export const businesstype_individual_validate_Schema = Yup.object().shape({
  dob: Yup.date().required("Please enter the date of birth."),
  address_line1: Yup.string().required("Please enter the Local Address"),
  postal_code: Yup.string()
    .required("Please enter the postal code")
    .min(5, "Please enter minimum 5 digit number.")
    .max(6, "Please enter maximum 6 digit number.")
    .matches(alphaNumeric, "Only accepts alphanumeric values"),
  ssn_last_4: Yup.string()
    .required("Please enter the ssn number.")
    .max(4, "Provided number should be 4 digits")
    .min(4, "Provided number should be 4 digits"),

  phone: Yup.string()
    .required("Mobile number required.")
    .matches(phoneRegExp, "Incorrect Mobile Number.")
    .min(10, "Please enter a valid 10 digit Mobile Number.")
    .max(10, "Please enter a valid 10 digit Mobile Number."),
});

export const businesstype_company_validate_Schema = Yup.object().shape({
  business_profile_name: Yup.string().required("Please enter the company name"),
  company_tax_id: Yup.string().required("Please enter the txn id"),
  business_profile_url: Yup.string().required(
    "Please enter the company website"
  ),
  address_line1: Yup.string().required("Please enter the Local Address"),
  postal_code: Yup.string()
    .required("Please enter the postal code")
    .min(5, "Please enter minimum 5 digit number.")
    .max(6, "Please enter maximum 6 digit number.")
    .matches(alphaNumeric, "Only accepts alphanumeric values"),
  phone: Yup.string()
    .required("Mobile number required.")
    .matches(phoneRegExp, "Incorrect Mobile Number.")
    .min(10, "Please enter a valid 10 digit Mobile Number.")
    .max(10, "Please enter a valid 10 digit Mobile Number."),
});

export const valid_location = (days, country, state, city) => {
  const error = {};
  if (parseInt(days / 365) < 14)
    error.birthyear = "You should be atleast 14 years old.";
  if (country === "") error.country = "Please select the country";
  if (state === "") error.state = "Please select the state";
  if (city === "") error.city = "Please select the city";
  return error;
};

export const representative_add_validate = Yup.object().shape({
  first_name: Yup.string().required("Please enter the first name"),
  last_name: Yup.string().required("Please enter the last name"),
  ssn_last_4: Yup.string()
    .required("Please enter the ssn number.")
    .max(4, "Provided number should be 4 digits")
    .min(4, "Provided number should be 4 digits"),
  dob: Yup.date().required("Please enter the date of birth."),
  email: Yup.string()
    .required("Email address required.")
    .nullable()
    .email("Enter correct email address."),
  address_line1: Yup.string().required("Please enter the Local Address"),
  postal_code: Yup.string()
    .required("Please enter the postal code")
    .min(5, "Please enter minimum 5 digit number.")
    .max(6, "Please enter maximum 6 digit number.")
    .matches(alphaNumeric, "Only accepts alphanumeric values"),

  phone: Yup.string()
    .required("Mobile number required.")
    .matches(phoneRegExp, "Incorrect Mobile Number.")
    .min(10, "Please Enter A Valid 10 Digit Mobile Number.")
    .max(10, "Please Enter A Valid 10 Digit Mobile Number."),
  owner_first_name: Yup.string().required("Please enter the owner first name"),
  owner_last_name: Yup.string().required("Please enter the owner last name"),

  owner_email: Yup.string()
    .required("Email address required.")
    .nullable()
    .email("Enter correct email address."),
});

export const review_booking_validation_schema = Yup.object().shape({
  review: Yup.object().shape({
    title: Yup.string()
      .nullable()
      .test("value-name", "Please remove empty space.", (val) =>
        val && val?.startsWith(" ") ? false : true
      )
      .required("Please enter review title"),
    comments: Yup.string()
      .nullable()
      .test("value-name", "Please remove empty space.", (val) =>
        val && val?.startsWith(" ") ? false : true
      )
      .max(250, "Value should not be greater than 250 characters.")
      .required("Please enter comment"),
  }),
});

export const rv_propertyInfo_validationSchema = Yup.object().shape({
  propertyInformation: Yup.object().shape({
    weeklyrent: Yup.number()
      .required("Weekly rent required.")
      .nullable()
      .typeError("Weekly rent must be a number.")
      .positive("Weekly rent must be greater than zero.")
      .min(100, "Value should not be less than $100.")
      .test("decimalAlowed", "Enter a value upto 2 decimal places.", (value) =>
        /^(?:\d*\.\d{1,2}|\d+)$/.test(value) ? true : false
      ),
    montlyrate: Yup.number()
      .required("Monthly rent required.")
      .nullable()
      .typeError("Weekly rent must be a number.")
      .positive("Weekly rent must be greater than zero.")
      .moreThan(Yup.ref("weeklyrent"), "Must be greater than weekly rent.")
      .test("decimalAlowed", "Enter a value upto 2 decimal places.", (value) =>
        /^(?:\d*\.\d{1,2}|\d+)$/.test(value) ? true : false
      ),
      security_fee: Yup.number()
      .nullable()
      .typeError("Security deposit must be a number.")
      .moreThan(-1, "Security deposit must be a proper value.")
      .max(Yup.ref("montlyrate"), "Must not be greater than monthly rent")
      .test("decimalAlowed", "Enter a value upto 2 decimal places.", (value) => {
        return !value || /^(?:\d*\.\d{1,2}|\d+)$/.test(value);
      }),
    rv_parking_lot_size: Yup.number()
      .required("RV parking lot size required.")
      .nullable()
      .typeError("RV parking lot size must be a number.")
      .positive("RV parking lot size must be greater than zero.")
      .moreThan(0, "RV parking lot size must be a proper value.")
      .test("decimalAlowed", "Enter a value upto 2 decimal places.", (value) =>
        !value || /^(?:\d*\.\d{1,2}|\d+)$/.test(value) ? true : false
      ),

    electricity: Yup.array().required("Electricity required.").nullable(),
    others: Yup.string().when("electricity", {
      is: (val) => val?.includes("Other"),
      then: Yup.string()
        .matches(removeWhiteSpace, "Please remove blank space.")
        .nullable()
        .required("Others required."),
    }),
    rental_period: Yup.array()
      .required("Rental period required.")
      .min(1, "Please select atleast 1 period."),
    full_concrete_parking: Yup.boolean().required(
      "Full concrete parking required"
    ),
  }),
});

export const createConnectAccountValidate = (props) => {
  const {
    first_name,
    last_name,
    business_profile_url,
    confirm_ssn_last_4,
    ssn_last_4,
    dob,
    address_line1,
    country,
    state,
    city,
    postal_code,
  } = props;
  let error = {};
  let ssnType = 9;
  if (first_name === "") error.first_name = "Please enter first name.";
  if (last_name === "") error.last_name = "Please enter last name.";
  if (business_profile_url === "")
    error.business_profile_url = "Please enter your business profile url.";
  if (!urlValid.test(business_profile_url))
    error.business_profile_url = "Invalid URL format.";
  // if(country==="CA") ssnType = 9
  if (ssn_last_4 === "" || ssn_last_4.length < ssnType)
    error.ssn_last_4 = `Please enter last ${ssnType} digit of your SSN / SIN.`;
  if (ssn_last_4 !== confirm_ssn_last_4)
    error.confirm_ssn_last_4 = `  SSN / SIN number and confirm  SSN / SIN must match.  `;
  if (dob === "") error.dob = "Please enter your date of birth.";
  if (address_line1 === "")
    error.address_line1 = "Please enter address line 1.";
  if (country === "" || country === undefined) error.country = "Please select the country.";
  if (state === "" || state === undefined) error.state = "Please select state.";
  if (city === "" || city === undefined) error.city = "Please select the city.";
  if (postal_code === "" || postal_code === undefined) error.postal_code = "Please enter the postal code.";
  // if (postal_code?.length < 5)
  //   error.postal_code = "Please enter minimum 5 digit.";
  return Object.keys(error).length > 0 ? error : true;
};

export const createConnectAccountForCanadaValidate = (props) => {
  const {
    transit_number,
    institution_number,
    account_number,
    confirm_account_number
  } = props;
  let error = {};
  if (transit_number === "") error.transit_number = "Please enter transit number.";
  if (institution_number === "") error.institution_number = "Please enter institution number.";
  if (account_number === "" || account_number === undefined) error.account_number = "Please enter Account Number"
  if (confirm_account_number === "" || confirm_account_number === undefined) error.confirm_account_number = "Please enter confirm account number.";
  if ((account_number && confirm_account_number) && (account_number !== confirm_account_number)) error.confirm_account_number = "Account number and confirm account number are not same.";
  return error;
};


export const canadabankInfo_validationSchema = Yup.object().shape({
  transit_number: Yup.number()
    .required('Please enter transit number.'),
  institution_number: Yup.number()
    .required('Please enter institution number.'),
  account_number: Yup.number()
    .required('Please enter Account Number'),
  confirm_account_number: Yup.number()
    .required('Please enter confirm account number.'),
});

export const valid_contac_us = ({ name, email, mobile, description }) => {
  let error = {};
  let mobilevalid = numberOnly.test(mobile);
  let islength = mobile.length;
  let emailvalid = emailRegex3.test(email);

  if (name === "") error.name = "Name required";

  if (email === "") error.email = "Email required";
  else if (emailvalid === false)
    error.email = "Please Enter A Valid Email Address.";

  if (mobile.length > 0 && (mobilevalid === false || islength !== 10))
    error.mobile = "Please Enter 10 Digit Valid Mobile Number.";

  if (description === "") error.description = "Description required";

  return error;
};

