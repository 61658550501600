import { settings } from "../../ActionTypes/admin";

export const get_all_settings = (data) => ({
  type: settings.getAllSetting,
  payload: data,
});

export const store_all_settings = (allSettings, allCleaningFee) => ({
  type: settings.storeAllSetting,
  payload: { allSettings, allCleaningFee },
});

export const update_fees_setting = (data) => ({
  type: settings.updateFeesSetting,
  payload: data,
});

export const update_processing_fees_setting = (data) => ({
  type: settings.updateProcessingFeesSetting,
  payload: data,
});

export const update_notification_setting = (data) => ({
  type: settings.updateNotificationSetting,
  payload: data,
});

export const create_new_cleaning_fee = (data) => ({
  type: settings.createNewCleaningFee,
  payload: data,
});
export const create_new_cleaning_fee_success = (data) => ({
  type: settings.createNewCleaningFeeSuccess,
  payload: data,
});

export const delete_cleaning_fee = (data) => ({
  type: settings.deleteCleaningFee,
  payload: data,
});
export const delete_cleaning_fee_success = (data) => ({
  type: settings.deleteCleaningFeeSuccess,
  payload: data,
});

export const update_background_setting = (data) => ({
  type: settings.updateBackgroundSetting,
  payload: data,
});
