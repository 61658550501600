import axios from "axios";
import { apiConstants } from "../../../Constants/api_constants";

export function bookingInitiateRequest(data) {
  return axios({
    method: "POST",
    url: apiConstants.CREATE_BOOKING + apiConstants.INITIATE,
    data: data,
    validateStatus: () => true,
  });
}

export function bookingAddOccupants(data, id) {
  return axios({
    method: "POST",
    url: apiConstants.CREATE_BOOKING + id + apiConstants.OCCUPANTS,
    data: data,
    validateStatus: () => true,
  });
}

export function bookProperty(data) {
  return axios({
    method: "POST",
    url: apiConstants.BOOK_PROPERTY,
    data: data,
    validateStatus: () => true,
  });
}

export function bookingAdminList(data) {
  return axios({
    method: 'get',
    url: apiConstants.LIST_BOOKING_ADMIN,
    params: {...data},
  });
}

export function bookingOwnerList(data) {
  return axios({
    method: "GET",
    url: apiConstants.LIST_BOOKING_OWNER,
    params:{...data},
    validateStatus: () => true,
  });
}

export function bookingOwnerStatusUpdate(id, status, comment) {
  let req = { status: status };
  if (comment) {
    req.comment = comment;
  }

  return axios({
    method: "PUT",
    url: apiConstants.CREATE_BOOKING + id + apiConstants.STATUS,
    data: req,
    validateStatus: () => true,
  });
}

export function bookingCancelRenter(id, status, comment) {
  return axios({
    method: "PUT",
    url: apiConstants.CREATE_BOOKING + id + "/" + status,
    data: { comment: comment },
    validateStatus: () => true,
  });
}

export function bookingDetail(id) {
  return axios({
    method: "GET",
    url: apiConstants.CREATE_BOOKING + id,
    validateStatus: () => true,
  });
}

export function applyPromocodeRequest(promocode) {
  return axios ({
    method:"POST",
    url:apiConstants.APPLY_PROMOCODE,
    data: {promocode},
    validateStatus: () => true,
  })
}

export function payOwnerCancellatonCharge(request) {
  return axios ({
    method:"POST",
    url:apiConstants.OWNER_CANCELLATION_CHARGE,
    data: request,
    validateStatus: () => true,
  })
}

export function cancelOwnerBooking(booking_id,comment,token) {
  return axios ({
    method:"PUT",
    url:apiConstants.CREATE_BOOKING+booking_id+'/cancel_owner',
    data: {comment:comment,payment_token:token},
    validateStatus: () => true,
  })
}


export function applyListingPayoutPromocodeRequest(promocode, property_id) {
  return axios ({
    method:"POST",
    url:apiConstants.APPLY_LISTING_PAYOUT_PROMOCODE,
    data: {promocode, property_id},
    validateStatus: () => true,
  })
}

export function applyListingHotelPromocodeRequest(promocode, property_id) {
  return axios ({
    method:"POST",
    url:apiConstants.APPLY_LISTING_HOTEL_PROMOCODE,
    data: {promocode, property_id},
    validateStatus: () => true,
  })
}


export function deleteListingPromocodeRequest(property_id) {
  return axios ({
    method:"POST",
    url:apiConstants.DELETE_LISTING_PROMOCODE,
    data: {property_id},
    validateStatus: () => true,
  })
}