import axios from "axios";
import { apiConstants } from "../../../Constants/api_constants";

export async function requestBasicInfoUploadImages(image) {
  const d = await axios.post(apiConstants.UPLOAD_PROPERTY_IMAGES, image);
  return d;
}

export function requestDeletePropertyImage() {
  return { data: true };
}
