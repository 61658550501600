import axios from "axios";
import { apiConstants } from "../../../Constants/api_constants";

export function requestCreateUserDetails(data) {
  return axios({
    method: "POST",
    url: apiConstants.CREATE_USER_DETAILS,
    data: data,
    validateStatus: () => true,
  });
}

export function requestOtpCheck(data, action) {
  return axios({
    method: "POST",
    url: apiConstants[action],
    data: data,
    validateStatus: () => true,
  });
}

export function requestOtpResend(mobile, token, country_code) {
  const req = {
    mobile: mobile,
    token: token,
    iso_code: country_code,
  };
  return axios({
    method: "POST",
    url: apiConstants.RESEND_OTP,
    data: req,
    validateStatus: () => true,
  });
}

export function requestCompleteProfile(data, isBusAdmin, token) {
  if (isBusAdmin) {
    return axios({
      method: "POST",
      url: `${apiConstants.BUSINESS_ADMIN_CREATE_PASSWORD}/${token}`,
      data: data,
      validateStatus: () => true,
    });
  } else
    return axios({
      method: "PUT",
      url: apiConstants.USER_UPDATE,
      data: data,
      validateStatus: () => true,
    });
}

export function requestChangePassword(data) {
  return axios({
    method: "PUT",
    url: apiConstants.UPDATE_PASSWORD,
    data: data,
    validateStatus: () => true,
  });
}

export function requestOtpLeaseSignorSend(data) {
  return axios({
    method: "POST",
    url: apiConstants.LEASESIGNOR_SENDOTP,
    data: data,
    validateStatus: () => true,
  });
}

export function requestFetchUserDetails(userId) {
  if (userId)
    return axios.get(apiConstants.FETCH_USER_DETAIL, {
      params: { user_id: userId },
    });
  else return axios.get(apiConstants.FETCH_USER_DETAIL);
}

export function requestUpdateUserDetails(data) {
  return axios({
    method: "PUT",
    url: apiConstants.FETCH_USER_DETAIL,
    data: data,
    validateStatus: () => true,
  });
}

export function requestUpdateUserProfileImage(image) {
  return axios.post(apiConstants.FETCH_USER_DETAIL, image);
}

export function requestSwitchRole(data) {
  return axios.put(apiConstants.SWITCH_ROLE, { ...data });
}
