import {
  createUserDetails,
  otpAction,
  completeProfileAction,
} from "../ActionTypes";
export const create_user_details = (data) => ({
  type: createUserDetails.load,
  payload: data,
});
export const create_user_detailsSuccess = (data) => ({
  type: createUserDetails.success,
  payload: data,
});

export const check_otp = (data) => ({
  type: otpAction.load,
  payload: data,
});

export const check_otp_error = (data) => ({
  type: otpAction.failure,
  payload: data,
});

export const check_otp_success = (data) => ({
  type: otpAction.success,
  payload: data,
});

export const resend_otp = (data) => ({
  type: otpAction.resend,
  payload: data,
});

export const leasesignor_mobile_verify = (data) => ({
  type: otpAction.otpSignorSend,
  payload: data,
});

export const complete_profile = (data, isBusAdmin) => ({
  type: completeProfileAction.load,
  payload: { data, isBusAdmin },
});

export const complete_profile_error = (data) => ({
  type: completeProfileAction.failure,
  payload: data,
});

export const complete_profile_success = (data) => ({
  type: completeProfileAction.success,
  payload: data,
});

export const fetch_user_detail = (data) => ({
  type: createUserDetails.fetchRequest,
  payload: data,
});

export const fetch_user_detail_success = (data) => ({
  type: createUserDetails.fetchSuccess,
  payload: data,
});

export const update_user_profile = (data) => ({
  type: createUserDetails.update,
  payload: data,
});

export const update_user_profile_success = (data) => ({
  type: createUserDetails.updateSuccess,
  payload: data,
});

export const create_user_profile_image = (data) => ({
  type: createUserDetails.updateImage,
  payload: data,
});

export const change_password = (data) => ({
  type: createUserDetails.changePass,
  payload: data,
});

export const create_user_profile_image_success = (data) => ({
  type: createUserDetails.updateImageSuccess,
  payload: data,
});

export const switch_role = (data) => ({
  type: createUserDetails.switchRole,
  payload: data,
});

export const verify_connect = (data) => ({
  type: createUserDetails.stripeVarify,
  payload: data,
});