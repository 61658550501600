import { Col, Container, Row } from "react-bootstrap";
import { BlogBg } from "../../assets/img/imagesh";
import AccommodationSection from "../Components/Accommodation-sec";
import Footer from "../Home/Footer/Footer";
import Header from "../Home/Header/Header";

const BlogDetail3 = () => {
  return (
    <>
      <div className="page gray-bg">
        {/* Header */}
        <Header />

        {/* Page Banner */}
        {/* Page Banner */}
        <section className="page-banner contact-us">
          <Container>
            <div
              className="banner-image mr-b-30"
              style={{ backgroundImage: `url(${BlogBg})` }}
            >
               <h1> 5 Things To Know Before You Sign That Travel Nurse Contract </h1>
            </div>
          </Container>
        </section>
        {/* Content Block */}
        <div className="content-block">
          <Container>
            <Row>
              <Col>
                <p> Travel nurse contracts go very fast, especially the good ones. If you are not careful you can get caught up in the face value of everything,  without knowing the fine details. God is in the details and we are here to make it a bit easier for you with these five tips.  </p>
                <h3> Recruiters  </h3>
                <p> A recruiter can make or break your contract so make sure you find a great one who is on your side from the beginning and wants you to succeed. A travel nurse recruiter should be willing to break down the contract pricing for you before you even have the contract in your hand.  Giving you a big number without a breakdown is risky because not all that glitter is gold and tax may eat away most of your earned money.</p>
                <p>Stipend, overtime pay, and hourly pay are some of the breakdowns that should be explained. Don't be afraid to ask questions, and negotiate a higher hourly rate for yourself.  Some recruiters will lowball you while others will be fair right out of the gate.  You may need to work with multiple recruiters to compare contracts and then take the best one for you.  Recruiters will tell you about the 50-mile rule to qualify for a housing stipend, but the IRS did not specify the distance. Do your research.</p>
                <h3> Savings.</h3>
                <p> Make sure to have money saved or a credit card with a good limit before you start your contract. If you are a single person with no family, you can wing it to some extent and will require less savings than a person with a family. You should duplicate all your expenses in your budget for two weeks and ensure you have that in your savings to cover upfront payments before starting your assignment.  This money will be used to secure housing, take care of the family, nanny, and any tests that may be required for the job.  Some recruiters will pay for tests, exams, certifications, and scrubs while others will ask you to pay and then reimburse you on your first check. </p>
                <p> Housing will take a big chunk of your savings, luckily with Nursesbnb, we help to lessen that burden by prorating your first month's rent if your check-in date is the middle of the month.  For a tax-free stipend, The IRS requires you to duplicate all expenses for the length of your contract and not just the days you work. Speak with a CPA about this. The one I would recommend is Travel tax because they specialize in taxes for healthcare travelers. </p>
                <h3> Housing </h3>
                <p> Housing is the other deal breaker. Several contracts have been declined for this reason. It is the most challenging part of your contract prior to starting the contract. Even if you drive an RV you will need safe parking with all the hookups for your RV. Nursesbnb provides housing and RV Parking in the USA and Canada. One of their specialty is sourcing housing in rural areas. We also have several partnerships with some elite hotels that are not yet posted on the website, and can only be accessed by directly contacting us. We partner with only the best, some of which offer continental breakfast and dinner plus 3 beverages per day (including spirits). Be sure to check us out.</p>
                <h3> On the job </h3>
                <p> Even though your recruiting agency will have you take a bunch of online exams, depending on your specialty, you may still be required to take an on-site EKG written exam. This mostly applies to ICU, ER, and PCU specialties. If you are not a good example taker or just not great with rhythms, I would advise you to take an assignment in a different specialty than those three, even if that's your specialty.  The past mark is usually 80-90%, and there's no re-take, if you fail you will have to pack your bags and go home and start seeking another contract immediately.   </p>
                <p> When on the job as a travel nurse, be prepared for very little training. You will be thrown into the deep end,  no lie.  Your skills should be sharp and ready to go. Your training is just to orientate you to how things are done per that institution, policy/procedure, and where to find items to make your job easy. You will be fumbling for the first few weeks after orientation. Be prepared to get the highest acuities. Some hospitals are not even travelers friendly and some do not have black or brown nurses so be aware of that. Please read their policy manuals and ask questions and most of all observe. You will get homesick, if you have kids you will get homesick a lot more. Group your schedule as much as possible so you can run back home as often as you can. Travelers usually work six days on eight days off so that they can get to go back home to spend a good chunk of time with their families. If you cannot do six days, do what works for you.</p>
                <h3> Insurance</h3>
                <p> Shop around for insurance. Some recruiters offer insurance but most nurses do not accept it, because every time they change recruiter they have to change insurance. Some recruiting agencies provide health coverage from day one on the job while others take thirty days. When you are on a Ninety-day contract, you want your a$$ covered from day one. Covid has taught us a lot of things. There are many good, and reasonably priced insurance out there that you can get independent of your contract, you should ask around, we at Nursesbnb know a few. </p>
                <p> Speak with a good financial advisor about retirement and investments. A wise person once said the poor spend what they have then invest what's left but the rich invest what they have and then spend what's left.  </p>
                <p> We hope you were able to pick up some Nuggets to make your travel nurse job easier. If there's a topic you would like to hear more about let us know in the chat. </p>
              </Col>
            </Row>
          </Container>
        </div>
        {/* Experience Accommodations Section */}
        <AccommodationSection />

        {/* Footer */}
        <Footer />
      </div>
    </>
  );
};
export default BlogDetail3;
