import axios from "axios";
import { apiConstantsAdmin } from "../../../../Constants/api_constant_admin";

export const requestLoadBackgroundCheck = (param) => {
  return axios.get(apiConstantsAdmin.BACKGROUND_CHECK_LIST,
    {
      params: { ...param },
      }
      );
};
