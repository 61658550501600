import { messageCount } from "../ActionTypes";
const initialMessageCount = {
  data: {},
};
const messageAllcount = (state = initialMessageCount, action) => {
  switch (action.type) {
    case messageCount.reset:
      return { data: {} };
    case messageCount.success:
      return { ...action.payload };
    default:
      return { ...state };
  }
};
export default messageAllcount;
