import { loadHomePage } from '../ActionTypes';
import { initial_state } from '../../Constants/Common';
const data = {
  loading: '',
  homePage: [],
  error: '',
  ...initial_state,
};
const home_page = (state = data, action) => {
  switch (action.type) {
    case loadHomePage.load:
      return { ...state, loading: true };
    case loadHomePage.success:
      return { ...state, loading: false, homePage: action.payload };
    case loadHomePage.failure:
      return { ...state, loading: false, error: action.payload };
    case loadHomePage.searchLoadSuccess:
      return { ...state, ...action.payload };
    case loadHomePage.searchLoadReset:
      return { ...state };
    default:
      return { ...state };
  }
};

export default home_page;
