export const apiEndPoint =
  process.env.REACT_APP_API_URL || "http://localhost:3000";
export const apiEndPointSettingService =
  process.env.REACT_APP_API_URL || "http://localhost:3005/";
export const apiEndPointPropertyService =
  process.env.REACT_APP_API_URL || "http://localhost:3201/";
export const apiEndPointUserService =
  process.env.REACT_APP_API_URL || "http://localhost:3002/";
export const apiEndPointPaymentService =
process.env.REACT_APP_API_URL ||  "http://localhost:3004/" ;
  
export const apiConstantsAdmin = {
  LOAD_PROPERTY_ADMIN: `${apiEndPointPropertyService}property/admin/index`,
  LOAD_USER_ADMIN: `${apiEndPointUserService}user/admin/index`,
  UPDATE_USER_STATUS_ADMIN: `${apiEndPointUserService}user/admin/update_status`,
  APPROVE_PROP_ADMIN: `${apiEndPointPropertyService}property/admin/approve_property`,
  REJECT_PROP_ADMIN: `${apiEndPointPropertyService}property/admin/rejectproperty`,
  GET_ALL_SETTING: `${apiEndPointSettingService}setting/fess/get_all_setting`,
  UPDATE_FEE_SETTING: `${apiEndPointSettingService}setting/fess/update`,
  UPDATE_BACKGROUND_SETTING: `${apiEndPointSettingService}setting/background/update`,
  GET_BACKGROUND_SETTING: `${apiEndPointSettingService}setting/background/check`,
  UPDATE_PROCESSING_FEE_SETTING: `${apiEndPointSettingService}setting/processingfee/update`,
  UPDATE_NOTIFICATION_FEE_SETTING: `${apiEndPointSettingService}setting/notification/update`,
  LISTING_REQUEST: `${apiEndPointPropertyService}property/admin/listing_request`,
  LOAD_BUSINESS_ADMINS: `${apiEndPointPropertyService}user/admin/get_business_admin`,
  CREATE_BUSINESS_ADMINS: `${apiEndPointPropertyService}user/admin/business_admin_save`,
  UPDATE_STATUS_BUSINESS_ADMINS: `${apiEndPointPropertyService}user/admin/update_status`,
  SAVE_CLEANING_FEE: `${apiEndPointPropertyService}setting/processingfee/save_cleaning`,
  GET_FEATURED_LIST: `${apiEndPointPropertyService}property/featured`,
  GET_CLEANING_FEE: `${apiEndPointSettingService}setting/processingfee/get_cleaning_fee`,
  GET_ADMIN_THREADS: `${apiEndPointSettingService}messages/threads/admin/list`,
  SAFETY_RATING: `${apiEndPointPropertyService}property/admin/save_safety_rating`,
  CREATE_LINK_TOKEN:`${apiEndPointPaymentService}payment/accounts/create_link_token`,
  CREATE_PLAID_STRIPE_BANK_TOKEN:`${apiEndPointPaymentService}payment/accounts/create_stripe_bank_token`,
  PUBLISH_APPROVED_PROPERTY:`${apiEndPointPropertyService}property/admin/publish_approved_property`,
  
  // ADMIN DASHBOARD
  DASHBOARD_GET_REVENUE:`${apiEndPointPaymentService}payment/getRevenue/dashboard`,
  DASHBOARD_GET_PAYOUT:`${apiEndPointPaymentService}payment/getPayout/dashboard`,
  DASHBOARD_GET_PROFIT:`${apiEndPointPaymentService}payment/getProfit/dashboard`,
  DASHBOARD_GET_SECURITY_DEPOSIT:`${apiEndPointPropertyService}booking/get_security_deposit/dashboard`,
  DASHBOARD_GET_CANCELATION:`${apiEndPointPropertyService}payment/get_cancelation_record/dashboard`,
  DASHBOARD_REPORTS: `${apiEndPointPropertyService}property/admin/save_safety_rating`,
  BACKGROUND_CHECK_LIST: `${apiEndPointPropertyService}user/admin/get_backgroundcheck_list`,
  BACKGROUND_CHECK_LIST_DETAILS: `${apiEndPointPropertyService}user/admin/get_background_checkr_details/`,
  DASHBOARD_TOTAL_SEARCHES: `${apiEndPointPropertyService}property/total_searches/dashboard`,
  LOAD_PROPERTY_CURRENT_RENTER: `${apiEndPointPropertyService}user/profile?user_id=`,
};
