import { put, takeEvery } from "redux-saga/effects";
import { headerLanguage } from "../../ActionTypes";
import { set_header_language } from '../../Actions/header-language';


export function* handleHeaderLanguage({ payload }) {
  try {
    const { data, country } = payload;
    const lists = data;
    let code;
    let flag;
    let name;
    let only_Us = {}
    if (data?.length > 0) {
      code = lists[1]?.isoCode;
      flag = lists[1]?.flag;
      name = lists[1]?.name;
      only_Us = lists[1]
      for (var key in lists) {
        if (lists[key].isoCode === country) {
          code = lists[key].isoCode;
          flag = lists[key].flag;
          name = lists[key].name;
        }
      }
    }
    const obj = {
      code, flag, name, lists
      // code, flag, name, lists: [only_Us]
    };
    yield put(set_header_language(obj));

  }

  catch (error) {
    console.log(error);
  }
}

export default function* watchHeaderLanguage() {
  yield takeEvery(headerLanguage.load, handleHeaderLanguage);
}
