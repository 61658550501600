import { call, put, takeEvery } from "redux-saga/effects";
import { requestAddBankInfo,requestAccountVerify,requestRepUpdate } from "../Requests/create-bank-info";
import { addSingleBankInfo } from "../../ActionTypes";
import {
  add_single_bankError,
  add_single_bankSuccess,
} from "../../Actions/stripe/bank-info";
import { CREATE_NEW_LIST_PATHS } from "../../../config/Owner-And-NewList_Paths";
import { set_message } from "../../Actions/message";
import { request_loader } from "../../Actions/loader";
import { constantTexts } from "../../../Constants/Common";
import { verify_connect } from "../../Actions/create-user-details";

export function* handleAddSingleBankInfo({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const { bank, p_id, navigate } = payload;
    console.log(bank);
    const response = yield call(requestAddBankInfo, bank, p_id);
    const { data } = response;
    if (data) {
      yield put(add_single_bankSuccess(data.data));
      navigate(
        `${CREATE_NEW_LIST_PATHS.LAYOUT_PATH}${CREATE_NEW_LIST_PATHS.REVIEW_DETAILS_ONLY}${p_id}`
      );
      yield put(
        set_message({
          error_code: data?.msg,
          code: constantTexts.SUCCESS,
        })
      );
      yield put(request_loader({ load: false }));
    } else {
      yield put(add_single_bankError(data));
    }
  } catch (error) {
    yield put(add_single_bankError(error));
  }
}

export function* watchAddSingleBankInfo() {
  yield takeEvery(addSingleBankInfo.load, handleAddSingleBankInfo);
}

export function* handleVerifyBankInfo({payload}){
  try{
    const {request} = payload;
    const response = yield call(requestAccountVerify,request);
    if(response){
      const { status,data } = response;
      if(status===window.code['200'].code){
          yield put(verify_connect('in_progress'));
        let module = {
          error_code: data.data.business_type==='individual'?data.msg:'',
          code: "success",
          heading: data.data.business_type,
        };
       
        yield put(set_message(module));
      }
    }
  }catch(error){
    console.log(error);
  }
}

export function* watchVerifyBankInfo() {
  yield takeEvery(addSingleBankInfo.verifyAccount, handleVerifyBankInfo);
}


export function* handleUpdateRepresentative({payload}){
  try{
    yield put(request_loader({ load: true }));
    const response = yield call(requestRepUpdate,payload);
  
    yield put(request_loader({ load: false }));
    if(response){
      const { status,data } = response;
      
      if(status===window.code['200'].code){
        yield put(verify_connect('in_progress'));
        let module = {
          error_code: data.msg,
          code: "success",
          heading: '',
        };
        yield put(set_message(module));
      }
    }
  }catch(error){
    console.log(error);
  }
}

export function* watchUpdateRepresentative() {
  yield takeEvery(addSingleBankInfo.updateRepresentative, handleUpdateRepresentative);
}
