import { headerLanguage } from "../ActionTypes";
const data = {
  isloaded:'',
  lists:[],
  code:'',
  flag:'',
  name:'',
};
const headlang = (state = data, action) => {
  switch (action.type) {
    case headerLanguage.success:
      return {...action.payload };
    default:
      return { ...state };
  }
};
export default headlang;
