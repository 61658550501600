import { call, put, takeEvery } from "redux-saga/effects";
import { request_loader } from "../../../Actions/loader";
import { storeAllLeaseSignors } from "../../../Actions/Owner/Profile";
import { allLeaseSignors } from "../../../ActionTypes";
import { requestFetchAllLeaseSignors } from "../../Requests/owner/Profile";

export function* handleFetchAllLeaseSignors({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(requestFetchAllLeaseSignors);
    yield put(request_loader({ load: false }));
    if (response) {
      const { data, status } = response;
      if (status === window.code["200"].code) {
        yield put(storeAllLeaseSignors(data.data));
      }
    }
  } catch (error) {
    yield put(request_loader({ load: false }));
    console.log(error);
  }
}

export function* watchFetchAllLeaseSignors() {
  yield takeEvery(allLeaseSignors.load, handleFetchAllLeaseSignors);
}
