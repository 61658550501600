import { uploadAction, uploadDoc, uploadMedia } from "../ActionTypes";

export const admin_upload_doc = (data) => ({
    type: uploadAction.uploadDoc ,
    payload: data,
  });

  export const list_lease_docs = (data) =>({
    type: uploadAction.listDoc ,
    payload: data,
  });

  export const display_leasedoc_list = (data) => ({
    type: uploadAction.displayLeaseDoc,
    payload: data,
  });

  export const delete_lease_docs = (data) =>({
    type: uploadAction.deleteDoc,
    payload: data,
  });

  export const upload_media = (data) => ({
    type: uploadMedia.load,
    payload: data
  })


  export const store_upload_media = (data) => ({
    type: uploadMedia.store,
    payload: data
  })

  export const delete_upload_media = (data) => ({
    type: uploadMedia.delete,
    payload: data
  })

  
  export const upload_doc = (data) => ({
    type: uploadDoc.load,
    payload: data
  })


  export const store_upload_doc = (data) => ({
    type: uploadDoc.store,
    payload: data
  })

  export const delete_upload_doc = (data) => ({
    type: uploadDoc.delete,
    payload: data
  })

