import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from "react-router-dom";
import { constantTexts, routeTypes } from '../Constants/Common';
import trackPathForAnalytics from '../Constants/TrackPageForAnalytics';

const MetaTag = () => {
    // let title = constantTexts.TITLE
    // let currentPath = useLocation().pathname;
    // const urlHash = useLocation().hash
    // routeTypes.forEach(element => {
    //     if (currentPath.includes(element.key)) {
    //         title = element.value
    //         const data = { title, path: currentPath, search: "" }
    //         if (urlHash !== '#loaded') {
    //             trackPathForAnalytics(data);
    //         }
    //     }
    // });


    let title = constantTexts.TITLE;
    let currentPath = useLocation().pathname;
    const urlHash = useLocation().hash;
    let currentPathAfterSplit = currentPath.split('/');
    let currentPathLastValue = currentPathAfterSplit[currentPathAfterSplit.length - 1];
    if (!isNaN(currentPathLastValue)) {
        currentPathLastValue = currentPathAfterSplit[currentPathAfterSplit.length - 2];
    }
    if (currentPath.includes('lease-signor')) {
        currentPathLastValue = currentPathAfterSplit[currentPathAfterSplit.length - 3];
    }
    const historyPath = localStorage.getItem('currentPath');
    if (historyPath !== currentPath) {
        routeTypes.forEach(element => {
            let elementKeyAfterSplit = element.key.split('/');
            let elementKeyLastValue = elementKeyAfterSplit[elementKeyAfterSplit.length - 1];
            if (currentPathLastValue === elementKeyLastValue) {
                console.log('coming inside', elementKeyLastValue);
                title = element.value
                const data = { title, path: currentPath, search: "" }
                if (urlHash !== '#loaded') {
                    trackPathForAnalytics(data);
                }
            }
        });
    } else {
        routeTypes.forEach(element => {
            if (currentPath.includes(element.key)) {
                title = element.value
            }
        });
    }
    localStorage.setItem("currentPath", currentPath);
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
        </Helmet>
    )
}

export default MetaTag;