import { ga4react } from './Common';
const trackPathForAnalytics = (data) => {
  const { path, search, title } = data;
  ga4react
    .then((ga) => {
      ga.pageview(path, search, title);
    })
    .catch((err) => console.error(`Analytics failed: ${err}`));
};

export default trackPathForAnalytics;
