import { call, put, takeEvery } from "redux-saga/effects";
import {
  deleteListingRequest,
  listingRequestDetails,
  loadListingRequest,
  SaveRatingDetails,
} from "../../../ActionTypes/admin";
import {
  delete_listing_request_error,
  delete_listing_request_success,
  listing_request_details_error,
  listing_request_details_success,
  load_listing_request,
  load_listing_request_success,
  save_rating_details_success,
} from "../../../Actions/admin/listing-request";
import {
  requestDeleteListingRequest,
  requestListingRequestDetails,
  requestLoadListingRequest,
  requestSaveRatingRequest,
} from "../../Requests/admin/listing-request";
import { request_loader } from "../../../Actions/loader";
import { set_message } from "../../../Actions/message";
import { DEFAULTFILTER } from "../../../../Constants/Common";

export function* handleLoadListingRequest({ payload }) {
  try {
    const { search, select, initial, fetchPage, page, order_by, order } =
      payload;
    const len = select.length;
    let req = {
      page: parseInt(page),
      order_by,
      order,
      search,
    };
    if (len > 0) {
      for (const value of select.entries()) {
        req[value] = 1;
      }
    }
    yield put(request_loader({ load: true }));
    const response = yield call(requestLoadListingRequest);
    yield put(request_loader({ load: false }));
    if (response) {
      const { data, status } = response;
      if (status === window.code["200"].code) {
        const params = {
          search,
          select,
          initial,
          page,
          lists: data.data.docs,
          order_by,
          order,
        };

        if (fetchPage === true) params.totalPages = data.data.totalPages;
        yield put(load_listing_request_success(params));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchLoadListingRequest() {
  yield takeEvery(loadListingRequest.load, handleLoadListingRequest);
}

//Save safety rating
export function* handleSaveRating(data) {
  const { payload } = data;
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(requestSaveRatingRequest, payload);
    yield put(request_loader({ load: false }));
    if (response) {
      const { data, msg, status } = response;
      if (status === window.code["200"].code) {
        yield put(save_rating_details_success(data.data));
        yield put(
          set_message({
            error_code: response.data.msg,
          })
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
}
export function* watchSaveRatingRequest() {
  yield takeEvery(SaveRatingDetails.load, handleSaveRating);
}

//delete owner listing
export function* handleDeleteListingRequest({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(requestDeleteListingRequest, id);
    const { data } = response;
    if (data) {
      yield put(delete_listing_request_success(id));
      yield put(
        load_listing_request({
          ...DEFAULTFILTER,
          fetchPage: true,
          initial: true,
        })
      );
    } else {
      yield put(delete_listing_request_error(data));
    }
  } catch (error) {
    yield put(delete_listing_request_error(error));
  }
}

export function* watchDeleteListingRequest() {
  yield takeEvery(deleteListingRequest.load, handleDeleteListingRequest);
}

export function* handleListingRequestDetails({ payload }) {
  const { id } = payload;
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(requestListingRequestDetails, id);
    const { data } = response;
    if (data) {
      yield put(request_loader({ load: false }));
      yield put(listing_request_details_success(data.data));
    } else {
      yield put(listing_request_details_error(data));
    }
  } catch (error) {
    yield put(listing_request_details_error(error));
  }
}

export function* watchListingRequestDetails() {
  yield takeEvery(listingRequestDetails.load, handleListingRequestDetails);
}
