import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import reducers from "../Reducers";
import rootSaga from "../Sagas";

 const sagaMiddleware = createSagaMiddleware();
 const middlewares = [sagaMiddleware];
 const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  export const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(...middlewares))
  );
  sagaMiddleware.run(rootSaga);
  if (module.hot) {
    module.hot.accept("../Reducers", () => {
      const nextRootReducer = require("../Reducers");
      store.replaceReducer(nextRootReducer);
    });
  }
