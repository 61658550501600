import { takeEvery, call, put } from "redux-saga/effects";
import {
  uploadAction,
  uploadCheckinMedia,
  uploadDoc,
  uploadMedia,
} from "../../ActionTypes";
import {
  requestUploadAction,
  requestListSignorDocs,
  requestDeleteSignorDocs,
  requestUploadMedia,
} from "../Requests/RequestUploadActn";
import { request_loader } from "../../Actions/loader";
import {
  display_leasedoc_list,
  store_upload_doc,
  store_upload_media,
} from "../../Actions/Upload";
import { set_message } from "../../Actions/message";

export function* handleLeaseSignorDocs({ payload }) {
  yield put(request_loader({ load: true }));
  const { form, order_by, order, segment, dispatch, actn } = payload;
  const response = yield call(requestUploadAction, form);
  yield put(request_loader({ load: false }));
  if (response) {
    const { status, data } = response;
    if (status === window.code["200"].code) {
      dispatch(
        actn({
          order_by,
          order,
          initial: "loading",
          displayStatus: "success",
          displayMessage: data.msg,
          segment,
        })
      );
    }
  }
}

export function* watchLeaseSignorDocs() {
  yield takeEvery(uploadAction.uploadDoc, handleLeaseSignorDocs);
}

export function* handleListSignorDocs({ payload }) {
  try {
    const { order_by, order, initial, segment, displayStatus, displayMessage } =
      payload;
    const req = { order_by, order };
    yield put(request_loader({ load: true }));
    yield put(display_leasedoc_list({ initial: initial }));
    const response = yield call(requestListSignorDocs, req, segment);
    yield put(request_loader({ load: false }));
    if (response) {
      const { data, status } = response;
      if (status === window.code["200"].code) {
        const params = {
          lists: data.data,
          initial: true,
          order_by,
          order,
        };
        yield put(display_leasedoc_list(params));
      }
    }
    if (displayStatus) {
      yield put(
        set_message({
          error_code: displayMessage,
          code: displayStatus,
          heading: "Success",
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchListSignorDocs() {
  yield takeEvery(uploadAction.listDoc, handleListSignorDocs);
}

export function* handleDeleteLeaseDoc({ payload }) {
  try {
    const { order_by, order, id, actn, dispatch, segment } = payload;
    const response = yield call(requestDeleteSignorDocs, id);
    if (response) {
      const { data, status } = response;
      if (status === window.code["200"].code) {
        dispatch(
          actn({
            order_by,
            order,
            initial: "loading",
            displayStatus: "success",
            displayMessage: data.msg,
            segment,
          })
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchDeleteLeaseDoc() {
  yield takeEvery(uploadAction.deleteDoc, handleDeleteLeaseDoc);
}

export function* handleUploadMedia({ payload }) {
  try {
    const { formData, uploadUrl } = payload;

    const response = yield call(requestUploadMedia, formData, uploadUrl);

    if (response) {
      const { data, status } = response;
      if (status === window.code["200"].code) {
        yield put(store_upload_media(data));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchUploadMedia() {
  yield takeEvery(uploadMedia.load, handleUploadMedia);
}

export function* handleUploadDoc({ payload }) {
  try {
    const { formData, uploadUrl } = payload;

    const response = yield call(requestUploadMedia, formData, uploadUrl);

    if (response) {
      const { data, status } = response;
      if (status === window.code["200"].code) {
        yield put(store_upload_doc(data));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchUploadDoc() {
  yield takeEvery(uploadDoc.load, handleUploadDoc);
}
