import { filterAction } from "../ActionTypes";
const data = {
  address:[]
};
const SearchAutoFill = (state = data, action) => {
  switch (action.type) {
    case filterAction.resetAddress:
      return { address:[] };
    case filterAction.setAddress:
      return { address:action.payload};
    default:
      return { ...state };
  }
};
export default SearchAutoFill;