import {
  createPropertyInfo,
  loadCities,
  loadCountries,
  loadPropertyInfo,
  loadStates,
} from "../ActionTypes";
const data = {
  loading: true,
  propertyInfo: [],
  error: "",
  propertyInfoFinished: false,
};
const property_info = (state = data, action) => {
  switch (action.type) {
    case loadPropertyInfo.load:
      return { ...state, loading: true };
    case loadPropertyInfo.success:
      return { ...state, loading: false, loadPropertyInfo: action.payload };
    case loadPropertyInfo.failure:
      return { ...state, loading: false, error: action.payload };
    //countries
    case loadCountries.load:
      return { ...state, loading: true };
    case loadCountries.success:
      return { ...state, loading: false, countries: action.payload };
    case loadCountries.failure:
      return { ...state, loading: false, error: action.payload };
    //states
    case loadStates.load:
      return { ...state, loading: true };
    case loadStates.success:
      return { ...state, loading: false, states: action.payload };
    case loadStates.failure:
      return { ...state, loading: false, error: action.payload };
    //cities
    case loadCities.load:
      return { ...state, loading: true };
    case loadCities.success:
      return { ...state, loading: false, cities: action.payload };
    case loadCities.failure:
      return { ...state, loading: false, error: action.payload };

    case createPropertyInfo.load:
      return { ...state, propertyInfoFinished: false, loading: true };
    case createPropertyInfo.success:
      return {
        ...state,
        loading: false,
        propertyInfoFinished: true,
        propertyInfo: action.payload,
      };
    case createPropertyInfo.failure:
      return {
        ...state,
        loading: false,
        propertyInfoFinished: false,
        error: action.payload,
      };
    default:
      return { ...state };
  }
};

export default property_info;
