import {filterAction} from '../ActionTypes';
export const execute_filter = (data) =>({
  type: filterAction.executeFilter,
  payload: data,
});

export const setAddress = (data) =>({
  type:filterAction.setAddress,
  payload: data,
})

export const resetAddress = () =>({
  type:filterAction.resetAddress,
})