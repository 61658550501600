import { call, put, takeEvery } from "redux-saga/effects";
import { requestCreateRvPropertyInfo } from "../Requests/create-new-list";
import { createRvProperty } from "../../ActionTypes";
import {
  create_rv_property_success,
  create_rv_property_error,
} from "../../Actions/create-new-list";
import { CREATE_NEW_LIST_PATHS } from "../../../config/Owner-And-NewList_Paths";
import { constantTexts, electricity } from "../../../Constants/Common";
import { request_loader } from "../../Actions/loader";
import { set_message } from "../../Actions/message";

//Create Property Info
export function* handleRvCreateProperyInfo({ payload }) {
  const { propertyInfo, propertyId, navigate } = payload;
  if (
    propertyInfo?.electricity?.includes(electricity[2])
  ) {
    const pp = propertyInfo?.electricity;
    propertyInfo.electricity = [];
    if (pp.includes(electricity[0]))
      propertyInfo?.electricity.push(electricity[0]);
    if (pp.includes(electricity[1]))
      propertyInfo?.electricity.push(electricity[1]);
    propertyInfo.electricity.push(
      propertyInfo.others,
      electricity[2]
    );
    delete propertyInfo.others;
  }
  if (
    !propertyInfo?.electricity?.includes(electricity[2]) &&
    !propertyInfo?.electricity?.includes(electricity[0]) &&
    !propertyInfo?.electricity?.includes(electricity[1])
  ) {
    propertyInfo.electricity = [];
  }
  propertyInfo.weeklyrent =
    +propertyInfo.weeklyrent;
  propertyInfo.rv_parking_lot_size =
    +propertyInfo.rv_parking_lot_size;
  propertyInfo.montlyrate =
    +propertyInfo.montlyrate;
  propertyInfo.security_fee =
    +propertyInfo.security_fee;
  propertyInfo.cleaning_fee =
    +propertyInfo.cleaning_fee;
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(
      requestCreateRvPropertyInfo,
      propertyInfo,
      propertyId
    );
    const { data } = response;
    if (data) {
      yield put(create_rv_property_success(data.data));
      navigate(
        `${CREATE_NEW_LIST_PATHS.LAYOUT_PATH}${CREATE_NEW_LIST_PATHS.BANK_INFO_ONLY}${data.data.property_id}`
      );
      yield put(
        set_message({
          error_code: data?.msg,
          code: constantTexts.SUCCESS,
        })
      );
      yield put(request_loader({ load: false }));
    } else yield put(create_rv_property_error(data));
  } catch (error) {
    yield put(create_rv_property_error(error));
  }
}

export default function* watchRvCreatePropertyInfo() {
  yield takeEvery(createRvProperty.load, handleRvCreateProperyInfo);
}
