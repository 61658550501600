import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Toast from "react-bootstrap/Toast";
import { ErrorIcon, TickIcon } from "../../assets/img/imagesh";
import { displayMessage } from "../../Redux/ActionTypes";

const ToastMessage = (props) => {
  const [show, setShow] = useState(true);
  let { error, bg, delay } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    const timer = setTimeout(
      () => {
        dispatch({ type: displayMessage.reset });
      },
      delay ? delay : 5000
    );
    return () => clearTimeout(timer);
  }, [dispatch]);
  return (
    <>
      <Toast
        className="toast-customize"
        bg={bg}
        onClose={() => {setShow(false);dispatch({ type: displayMessage.reset })}}
        show={show}
        delay={delay ? delay : 5000}
      >
        <Toast.Header closeButton={true}></Toast.Header>
        <Toast.Body>
          <span className="icon" bg={bg}>
            {" "}
            <img
              src={bg === "warning" ? ErrorIcon : TickIcon}
              alt="Toast Icon"
            />{" "}
          </span>
          {error}
        </Toast.Body>
      </Toast>
    </>
  );
};
export default ToastMessage;
