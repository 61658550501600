import axios from "axios";
import { apiConstants } from "../../../../Constants/api_constants";

export const requestFetchNotifications = () => {
  return axios.get(apiConstants.GET_NOTIFICATIONS);
};

export const requestDeleteNotifications = () => {
    return axios.delete(apiConstants.CLEAR_NOTIFICATIONS);
  };

  export const requestIcalLink = (id) => {
   return axios.get(`${apiConstants.ICAL_LINK}${id}`);
  };