import axios from "axios";
import { apiConstantsAdmin } from "../../../../Constants/api_constant_admin";


export const requestfetchDashboardRevenue = (param) => {
    return axios.get(apiConstantsAdmin.DASHBOARD_GET_REVENUE, {
      params: { ...param },
    });
  };

  export const requestfetchDashboardPayout = (param) => {
    return axios.get(apiConstantsAdmin.DASHBOARD_GET_PAYOUT, {
      params: { ...param },
    });
  };

  export const requestfetchDashboardProfit = (param) => {
    return axios.get(apiConstantsAdmin.DASHBOARD_GET_PROFIT, {
      params: { ...param },
    });
  };
  export const requestfetchDashboardDeposit = (param) => {
    return axios.get(apiConstantsAdmin.DASHBOARD_GET_SECURITY_DEPOSIT, {
      params: { ...param },
    });
  };

  export const requestfetchDashboardCancelation = (param) => {
    return axios.get(apiConstantsAdmin.DASHBOARD_GET_CANCELATION, {
      params: { ...param },
    });
  };

  export const requestFetchDashboardTotalSearches = (param) => {
    return axios.get(apiConstantsAdmin.DASHBOARD_TOTAL_SEARCHES, {
      params: { ...param },
    });
  };