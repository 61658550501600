import {
  watchUserListAdmin,
  watchUserUpdateStatusAdmin,
  watchTogetCurentRenterAdmin,
  watchTogetPublishApprovePropAdmin
} from "../Handlers/Admin/UserList";
import {
  watchPropListAdmin,
  watchPropApproveAdmin,
  watchPropRejectAdmin,
  watchFeaturedHistory,
} from "../Handlers/Admin/Proplist";
import {
  watchCreateNewCleaningFee,
  watchDeleteCleaningFee,
  watchGetAllSetting,
  watchUpdateFeeSetting,
  watchUpdateNotificationSetting,
  watchUpdateProcessingFeeSetting,
  watchUpdateBackgroundSetting,
} from "../Handlers/Admin/Setting";

import { 
  watchPromocodeCreate,
  watchPromocodeList,
  watchPromocodeUpdate,
  watchPromocodeStatusUpdate,
  watchPromocodeDelete
 } from "../Handlers/PromocodeHandle";
import { watchEvictionAdminDetail, watchEvictionList } from "../Handlers/EvictionHandle";

const AdminSaga = [
  watchUserListAdmin(),
  watchUserUpdateStatusAdmin(),
  watchTogetPublishApprovePropAdmin(),
  watchPropListAdmin(),
  watchPropApproveAdmin(),
  watchPropRejectAdmin(),
  watchGetAllSetting(),
  watchUpdateFeeSetting(),
  watchUpdateProcessingFeeSetting(),
  watchUpdateNotificationSetting(),
  watchFeaturedHistory(),
  watchCreateNewCleaningFee(),
  watchDeleteCleaningFee(),
  watchPromocodeCreate(),
  watchPromocodeList(),
  watchPromocodeUpdate(),
  watchPromocodeStatusUpdate(),
  watchPromocodeDelete(),
  watchEvictionList(),
  watchEvictionAdminDetail(),
  watchTogetCurentRenterAdmin(),
  watchUpdateBackgroundSetting(),
];

export default AdminSaga;
