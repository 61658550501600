import { call, put, takeEvery } from "redux-saga/effects";
import { promocodeActions } from "../../ActionTypes";
import {
  promocodeCreate,
  promocodeList,
  promocodeUpdate,
  promocodeStatusUpdate,
  promocodeDelete,
} from "../Requests/promocodeRequest";
import { promocodeDisplay } from "../../Actions/promocode";
import { request_loader } from "../../Actions/loader";
import { set_message } from "../../Actions/message";
import error_messages from "../../../Constants/error-messages";

export function* handlePromocodeCreate({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const { request, lists } = payload;
    const response = yield call(promocodeCreate, request);
    if (response) {
      const { status, data } = response;
      if (
        status === window.code["200"].code ||
        status === window.code["201"].code
      ) {
        const records = data?.data;
        const newList = lists.length > 0 ? [records, ...lists] : records;
        yield put(promocodeDisplay({ lists: newList }));
        yield put(
          set_message({
            error_code: error_messages.promo_code_created_successfully,
            code: "success",
            heading: "success",
          })
        );
      }
    }
    yield put(request_loader({ load: false }));
  } catch (error) {
    console.log(error);
  }
}

export function* watchPromocodeCreate() {
  yield takeEvery(promocodeActions.create, handlePromocodeCreate);
}

export function* handlePromocodeList({ payload }) {
  try {
    const { initial, fetchPage, page, lists, promo_status, promo_search } =
      payload;
    let req = {};
    if (page) req.page = page;
    if (promo_status) req.status = promo_status;
    if (promo_search) req.search = promo_search;
    const response = yield call(promocodeList, req);
    if (response) {
      const { status } = response;
      if (status === window.code["200"].code) {
        const { data, options } = response.data;
        const params = {
          initial: false,
          lists: lists ? lists.concat(data) : data,
        };
        if (promo_status) params.promo_status = promo_status;
        if (promo_search) params.promo_search = promo_search;
        if (fetchPage === true) params.totalPages = options.totalDocs;
        if (options && options.nextPage) params.page = options.nextPage;
        yield put(promocodeDisplay(params));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchPromocodeList() {
  yield takeEvery(promocodeActions.list, handlePromocodeList);
}

export function* handlePromocodeUpdate({ payload }) {
  try {
    const { request, id, lists } = payload;
    yield put(request_loader({ load: true }));
    const response = yield call(promocodeUpdate, request, id);
    if (response) {
      const { status } = response;
      if (
        status === window.code["200"].code ||
        status === window.code["201"].code
      ) {
        const ind = lists.findIndex((element) => {
          if (element.id === id) {
            return true;
          }
        });
        const subobj = {
          ...lists[ind],
        };
        subobj.description = request.description;
        subobj.promo_code_name = request.promo_code_name;
        subobj.end_date = request.end_date;
        subobj.one_time = request.one_time;
        subobj.percentage = request.percentage;
        subobj.promo_type = request.promo_type;
        subobj.start_date = request.start_date;
        lists[ind] = subobj;

        yield put(
          set_message({
            error_code: error_messages.promo_code_updated_successfully,
            code: "success",
            heading: "success",
          })
        );
        yield put(promocodeDisplay({ lists: lists }));
      }
    }
    yield put(request_loader({ load: false }));
  } catch (error) {
    console.log(error);
  }
}

export function* watchPromocodeUpdate() {
  yield takeEvery(promocodeActions.update, handlePromocodeUpdate);
}

export function* handlePromocodeStatusUpdate({ payload }) {
  try {
    const { id, promo_status } = payload;
    yield put(request_loader({ load: true }));
    const response = yield call(promocodeStatusUpdate, id, promo_status);
    if (response) {
      const { status } = response;
      if (
        status === window.code["200"].code ||
        status === window.code["201"].code
      ) {
        yield put(
          set_message({
            error_code: error_messages.promo_code_status_updated_successfully,
            code: "success",
            heading: "success",
          })
        );
      }
    }
    yield put(request_loader({ load: false }));
  } catch (error) {
    console.log(error);
  }
}

export function* watchPromocodeStatusUpdate() {
  yield takeEvery(promocodeActions.statusupdate, handlePromocodeStatusUpdate);
}

export function* handlePromocodeDelete({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(promocodeDelete, payload);

    if (response) {
      const { status } = response;
      if (
        status === window.code["200"].code ||
        status === window.code["201"].code
      ) {
        yield put(
          set_message({
            error_code: error_messages.promo_code_deleted_successfully,
            code: "success",
            heading: "success",
          })
        );
      }
    }
    yield put(request_loader({ load: false }));
  } catch (error) {
    console.log(error);
  }
}

export function* watchPromocodeDelete() {
  yield takeEvery(promocodeActions.delete, handlePromocodeDelete);
}
