import { statuscode } from "../../../Constants/Common";
import { fetchOwnerListing, deleteOwnerListing } from "../../ActionTypes";
const data = {
  loading: true,
  ownerListing: [],
  error: "",
};
const getItemIndex = (array, currentFileId) => {
  return array.findIndex((d) => d.id === currentFileId);
};
const owner_listing = (state = data, action) => {
  switch (action.type) {
    case fetchOwnerListing.load:
      return { ...state, loading: true };
    case fetchOwnerListing.success:
      return { ...state, loading: false, ...action.payload };
    case fetchOwnerListing.failure:
      return { ...state, loading: false, error: action.payload };
    case deleteOwnerListing.load:
      return { ...state, loading: true, ownerListingDeleted: false };
    case deleteOwnerListing.success:
      if (!localStorage.getItem("f_l_details")) {
        let index = getItemIndex(state.lists, action.payload.id);
        if (action.payload?.changedSts === statuscode.published.class) {
          if (state.lists?.[index]?.property_edit_history) {
            state.lists[index].status = action.payload.changedSts;
            //  state.lists?.[index]?.property_edit_history?.status;
          } else {
            state.lists[index].status = action.payload.changedSts;
          }
        } else if (
          action.payload?.changedSts === statuscode.unpublished.class
        ) {
          state.lists[index].status = action.payload.changedSts;
        } else state?.lists?.splice(index, 1);
      } 
      return {
        ...state,
        loading: false,
        ownerListingDeleted: true,
        changedSts: action.payload.changedSts,
      };
    case deleteOwnerListing.failure:
      return {
        ...state,
        loading: false,
        error: action.payload,
        ownerListingDeleted: false,
      };
    default:
      return { ...state };
  }
};

export default owner_listing;
