import axios from "axios";
import { apiConstants } from "../../../Constants/api_constants";

export function requestCreateBasicInfo(data, status) {
  if (data.property_id || status?.isPublished) {
    data.id = data.basicInformation.id;
    return axios.put(apiConstants.UPDATE_BASIC_INFO, { ...data });
  } else return axios.post(apiConstants.CREATE_BASIC_INFO, { ...data });
}

//Create Property Info
export function requestCreatePropertyInfo(data, propertyId) {
  const d = { propertyInformation: data, property_id: propertyId };
  return axios.put(apiConstants.CREATE_PROPERTY_INFO, d);
}
//Create Rv Property Info
export function requestCreateRvPropertyInfo(data, propertyId) {
  const d = { propertyInformation: data, property_id: propertyId };
  return axios.put(apiConstants.CREATE_PROPERTY_INFO, d);
}
//Load Property Info
export const requestLoadPropertyInfo = () => {
  return axios.get(apiConstants.LOAD_PROPERTY_INFO);
};
//Load Countries
export const requestLoadCountries = () => {
  return axios.get(apiConstants.LOAD_COUNTRIES);
};
//Load States
export const requestLoadStates = (c_id) => {
  return axios.get(`${apiConstants.LOAD_STATES}/${c_id}`);
};
//Load Cities
export const requestLoadCities = (c_id, s_id) => {
  return axios.get(`${apiConstants.LOAD_CITIES}/${c_id}/${s_id}`);
};

//Create Lease Signor
export const requestCreateLeaseSignor = (data) => {
  return axios({
    method: "POST",
    url: apiConstants.CREATE_LEASE_SIGNOR,
    data: data,
    validateStatus: (status) => {
      return status === 200 || status === 422;
    },
  });
};

//Delete Lease Signor
export const requestDeleteLeaseSignor = (data) => {
  return axios.delete(apiConstants.DELETE_LEASE_SIGNOR, { data });
};

//Load Review Details
export const requestLoadReviewDetails = async (property_id) => {
  return axios.get(`${apiConstants.LOAD_PREVIEW_LIST}${property_id}`);
};

//save review details
export const requestSaveReviewDetails = (id) => {
  return axios.put(`${apiConstants.SAVE_REVIEW_DETAILS}`, { property_id: id });
};

//Load Home Page
export const requestLoadHomePage = () => {
  return axios.get(`${apiConstants.LOAD_HOME_PAGE}`);
};

export const requestLoadSearchPage = (data) => {
  return axios({
    method: "GET",
    url: apiConstants.FETCH_LIST_FRONTED,
    params: { ...data },
    validateStatus: (status) => {
      return status === 200 || status === 422;
    },
  });
};

//load country with flag
export const requestLoadCountriesWithflag = () => {
  return axios.get(`${apiConstants.GET_ISO_CODES}`);
};

export function requestSearchRequestLisitng(data) {
  return axios({
    method: "POST",
    url: apiConstants.LISTING_REQUEST,
    data: data,
    validateStatus: () => true,
  });
}

export const requestPayHotelFee = (data) => {
  return axios.post(apiConstants.PAY_HOTEL_FEE_AMOUNT, data);
};

export const requestResendInvite = (data) => {
  return axios.post(apiConstants.LEASE_SIGNOR_RESEND_INVITE, data);
};
//Fetch Exist Lease signors
export const requestFetchLss = (_id) => {
  return axios.get(apiConstants.GET_EXIST_LEASE_SIGNOR + _id);
};

//Fetch messagesCount
export const getAllMessagesCount = () => {
  return axios.get(apiConstants.COUNT_MESSAGES);
};
export const getThreadCount = (thriedId) => {
  return axios.post(apiConstants.GET_EXIST_LEASE_SIGNOR + thriedId);
};
