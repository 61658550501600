import { call, put, takeEvery } from "redux-saga/effects";
import error_messages from "../../../../Constants/error-messages";
import { request_loader } from "../../../Actions/loader";
import { set_message } from "../../../Actions/message";
import {
  create_link_token_success,
  create_stripe_bank_token_success,
  create_link_token_error,
  create_stripe_bank_token_error,
} from "../../../Actions/Owner/owner-listing";
import { fetch_stripe_payment_methods } from "../../../Actions/stripe/bank-info";
import { fetchLinkToken, fetchStripeBankToken } from "../../../ActionTypes";
import {
  requestCreateLinkToken,
  requestCreateStripeBankToken,
} from "../../Requests/owner/PlaidRequest";
import {
  requestCreateBankPMStripe,
  requestCreateBankStripe,
} from "../../Requests/stripe/bank";

export function* handleCreateLinkToken({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(requestCreateLinkToken, payload);
    if (response) {
      yield put(request_loader({ load: false }));
      const { data, status } = response;
      if (status === window.code["200"].code) {
        localStorage.setItem("link_token", data.data.link_token);
        yield put(create_link_token_success(data.data));
      } else {
        yield put(create_link_token_error(data.data));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchOwnerCreateLinkToken() {
  yield takeEvery(fetchLinkToken.load, handleCreateLinkToken);
}

export function* handleCreateStripeBankToken({ payload }) {
  const { param, dispatch } = payload;
  console.log(param);
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(requestCreateStripeBankToken, param);
    if (response) {
      yield put(request_loader({ load: false }));
      const { data, status, msg } = response;
      if (status === window.code["200"].code) {
        // call create bank
        if (!param.is_payment_method) {
          let userLocalStorage = JSON.parse(
            localStorage.getItem("all_user_data")
          );
          const response1 = yield call(requestCreateBankStripe, {
            bank_token: data.data.bank_token,
            stripe_connect_account: userLocalStorage["stripe_connect_account"],
          });
          yield put(request_loader({ load: false }));
          if (response1) {
            let { status } = response1;
            if (status === window.code["200"].code) {
              yield put(
                set_message({
                  error_code: error_messages["bank_added_successfully"],
                  code: "success",
                  heading: window.code["200"].header,
                })
              );
              dispatch(fetch_stripe_payment_methods());
            }
          } else {
            yield put(
              set_message({
                error_code: "",
                code: "warning",
                heading: "Invalid Value",
              })
            );
          }
        } else {
          const response1 = yield call(requestCreateBankPMStripe, {
            bank_token: data.data.bank_token,
          });
          yield put(request_loader({ load: false }));
          if (response1) {
            let { status } = response1;
            if (status === window.code["200"].code) {
              yield put(
                set_message({
                  error_code: error_messages["bank_added_successfully"],
                  code: "success",
                  heading: window.code["200"].header,
                })
              );
              dispatch(fetch_stripe_payment_methods());
            } else {
              set_message({
                error_code: response1.data.message,
                code: "warning",
                heading: "Invalid Value",
              });
            }
          } else {
            yield put(
              set_message({
                error_code: "",
                code: "warning",
                heading: "Invalid Value",
              })
            );
          }
          yield put(request_loader({ load: false }));
        }

        // yield put(create_stripe_bank_token_success(data.msg));
      } else {
        yield put(create_stripe_bank_token_error(data.data));
      }
    }
  } catch (error) {
    yield put(
      set_message({
        error_code: error_messages["server_error"],
        code: "success",
        heading: window.code["200"].header,
      })
    );
  }
}

export function* watchOwnerCreateStripeBankToken() {
  yield takeEvery(fetchStripeBankToken.load, handleCreateStripeBankToken);
}
