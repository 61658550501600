import axios from "axios";
import { apiConstants } from "../../../Constants/api_constants";

export function requestCreateBankInfo(bankInfo) {
  return axios.post(apiConstants.CREATE_BANK_INFO, bankInfo);
}

export function requestAddBankInfo(bank, p_id) {
  return axios.put(apiConstants.ADD_SINGLE_BANK_INFO, {
    property_id: p_id,
    bankAccount: bank.id,
  });
}

export function requestAccountVerify(data) {
  const url = apiConstants.ACCOUNT_VERIFICATION_START;
  return axios({
    method: "POST",
    url: url,
    data:data,
    validateStatus: () => true,
  });
}


export function requestRepUpdate(data) {
  const url = apiConstants.UPDATE_REP_OWNER;
  return axios({
    method: "POST",
    url: url,
    data:data,
    validateStatus: () => true,
  });
}