import React from "react";
import RoutesIndex from "./Routes";
import "../src/assets/scss/_style.scss";
import 'react-quill/dist/quill.snow.css';
import "./helpers/axios-middleware";



const App = () => {
  return (
    <div className="App">
      <RoutesIndex />
    </div>
  );
};

export default App;
