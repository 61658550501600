import { call, put, takeEvery } from "redux-saga/effects";
import {
  requestLoadPropertyInfo,
  requestCreatePropertyInfo,
  requestLoadCountries,
  requestLoadStates,
  requestLoadCities,
  requestSearchRequestLisitng,
} from "../Requests/create-new-list";
import {
  createPropertyInfo,
  loadCities,
  loadCountries,
  loadPropertyInfo,
  loadStates,
} from "../../ActionTypes";
import {
  load_property_info_error,
  load_property_info_success,
  create_property_info_success,
  create_property_info_error,
  load_countries_success,
  load_countries_error,
  load_states_success,
  load_states_error,
  load_cities_success,
  load_cities_error,
} from "../../Actions/create-new-list";
import { CREATE_NEW_LIST_PATHS } from "../../../config/Owner-And-NewList_Paths";
import { constantTexts, pets } from "../../../Constants/Common";
import { request_loader } from "../../Actions/loader";
import { set_message } from "../../Actions/message";

//Load Property Info
export function* handleLoadProperyInfo() {
  try {
    const response = yield call(requestLoadPropertyInfo);
    const { data } = response;
    if (data) {
      yield put(load_property_info_success(data.data));
    } else {
      yield put(load_property_info_error(data));
    }
  } catch (error) {
    yield put(load_property_info_error(error));
  }
}

export function* watchLoadPropertyInfo() {
  yield takeEvery(loadPropertyInfo.load, handleLoadProperyInfo);
}

//Load Countries
export function* handleLoadCountries() {
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(requestLoadCountries);
    const { data } = response;
    if (data) {
      yield put(load_countries_success(data.data));
      yield put(request_loader({ load: false }));
    } else {
      yield put(load_countries_error(data));
      yield put(request_loader({ load: false }));
    }
  } catch (error) {
    yield put(request_loader({ load: false }));
    yield put(
      set_message({
        error_code: "Cannot fetch countries",
        code: constantTexts.WARNING,
      })
    );

    yield put(load_countries_error(error));
  }
}

export function* watchLoadCountries() {
  yield takeEvery(loadCountries.load, handleLoadCountries);
}

//Load States
export function* handleLoadStates({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(requestLoadStates, payload);
    const { data } = response;
    if (data) {
      yield put(load_states_success(data.data));
      yield put(request_loader({ load: false }));
    } else {
      yield put(request_loader({ load: false }));
      yield put(load_states_error(data));
    }
  } catch (error) {
    yield put(request_loader({ load: false }));
    yield put(
      set_message({
        error_code: "Cannot fetch states",
        code: constantTexts.WARNING,
      })
    );
    yield put(load_states_error(error));
  }
}

export function* watchLoadStates() {
  yield takeEvery(loadStates.load, handleLoadStates);
}
//Load Cities
export function* handleLoadCities({ payload }) {
  const { c_id, s_id } = payload;
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(requestLoadCities, c_id, s_id);
    const { data } = response;
    if (data) {
      yield put(load_cities_success(data.data));
      yield put(request_loader({ load: false }));
    } else {
      yield put(request_loader({ load: false }));
      yield put(load_cities_error(data));
    }
  } catch (error) {
    yield put(request_loader({ load: false }));
    yield put(
      set_message({
        error_code: "Cannot fetch cities",
        code: constantTexts.WARNING,
      })
    );
    yield put(load_cities_error(error));
  }
}

export function* watchLoadCities() {
  yield takeEvery(loadCities.load, handleLoadCities);
}

//Create Property Info
export function* handleCreateProperyInfo({ payload }) {
  const { d, propertyId, navigate,dateFormat } = payload.propertyInfo;
  const blocker = [];
  d.block_out_dates.map((date)=>{
    if(date.startDate !== constantTexts.EMPTY_STRING && date.endDate !== null ) {
      blocker.push({startDate:dateFormat(date.startDate),endDate:dateFormat(date.endDate)})
    }
  });
  d.block_out_dates = blocker;
  

  if (!d?.is_parking_allowed) delete d.parking_type;
  else
  d.parking_type.parking_fee =
      +d.parking_type.parking_fee;

  if (!d?.is_pet_allowed) d.pet = [];

  if (d?.pet?.includes(pets[2])) {
    const pp = d?.pet;
    d.pet = [];
    if (pp.includes(pets[0])) d?.pet.push(pets[0]);
    if (pp.includes(pets[1])) d?.pet.push(pets[1]);
    d.pet.push(d.others, pets[2]);
    delete d.others;
  }
  if (
    d?.is_pet_allowed &&
    !d?.pet?.includes(pets[2]) &&
    !d?.pet?.includes(pets[0]) &&
    !d?.pet?.includes(pets[1])
  ) {
    d.is_pet_allowed = false;
    d.pet = [];
  }
  d.weeklyrent = +d.weeklyrent;
  d.rental_space = +d.rental_space;
  d.montlyrate = +d.montlyrate;
  d.security_fee = +d.security_fee;
  d.cleaning_fee = +d.cleaning_fee;
  d.bathroom.no_of_room = +d.bathroom.no_of_room;
  d.bedroom.no_of_room = +d.bedroom.no_of_room;
  d.closet.no_of_room = +d.closet.no_of_room;
  d.kitchen.no_of_room = +d.kitchen.no_of_room;
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(
      requestCreatePropertyInfo,
      d,
      propertyId
    );
    const { data } = response;
    if (data) {
      yield put(create_property_info_success(data.data));
      navigate(
        `${CREATE_NEW_LIST_PATHS.LAYOUT_PATH}${
          CREATE_NEW_LIST_PATHS.LEASE_SIGNOR_ONLY
        }${localStorage.getItem(constantTexts.STATE)}/${data.data.property_id}`
      );
      yield put(
        set_message({
          error_code: data?.msg,
          code: constantTexts.SUCCESS,
        })
      );
      yield put(request_loader({ load: false }));
    } else yield put(create_property_info_error(data));
  } catch (error) {
    yield put(create_property_info_error(error));
  }
}

export function* watchCreatePropertyInfo() {
  yield takeEvery(createPropertyInfo.load, handleCreateProperyInfo);
}

//Search Request Listing
export function* handleSearchRequestLisitng({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(requestSearchRequestLisitng, payload);
    yield put(request_loader({ load: false }));
    if (response) {
      const { data, status } = response;
      if (status === window.code["200"].code) {
        yield put(
          set_message({
            error_code: data.msg,
            code: "success",
          })
        );
      }
    } else {
      yield put(
        set_message({
          error_code: "Bad Request. Try Again.",
          code: "warning",
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchSearchRequestLisitng() {
  yield takeEvery(loadPropertyInfo.listRequest, handleSearchRequestLisitng);
}
