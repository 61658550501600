import axios from "axios";
import { apiConstantsAdmin } from "../../../../Constants/api_constant_admin";

export const requestLoadListingRequest = (param) => {
  return axios.get(apiConstantsAdmin.LISTING_REQUEST, {
    params: { ...param },
  });
};

export const requestDeleteListingRequest = (id) => {
  return axios.delete(`${apiConstantsAdmin.LISTING_REQUEST}/${id}`);
};

export const requestListingRequestDetails = (id) => {
  return axios.get(`${apiConstantsAdmin.LISTING_REQUEST}/${id}`);
};
export const requestSaveRatingRequest = (payload) => {
  return axios.put(`${apiConstantsAdmin.SAFETY_RATING}`,payload);
};

