import { createBasicInfo } from "../ActionTypes";
const data = {
  loading: true,
  basicInfo: [],
  error: "",
  basicInfoFinished: false,
};
const basic_info = (state = data, action) => {
  switch (action.type) {
    case createBasicInfo.load:
      return { ...state, loading: true, basicInfoFinished: false };
    case createBasicInfo.success:
      return {
        ...state,
        loading: false,
        basicInfoFinished: true,
        basicInfo: action.payload,
      };
    case createBasicInfo.failure:
      return {
        ...state,
        loading: false,
        basicInfoFinished: false,
        error: action.payload,
      };
    default:
      return { ...state };
  }
};

export default basic_info;
