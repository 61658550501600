import { modalActionTypes, reviewCountTypes } from '../ActionTypes';

const initial_values = {
  modalStatus: false,
  img: '',
};

const modalReducer = (state = initial_values, action) => {
  switch (action.type) {
    case modalActionTypes.OPEN:
      return { ...state, ...action.payload };

    case modalActionTypes.RESET:
      return {
        modalStatus: false,
        img: '',
      };
    default:
      return state;
  }
};

export default modalReducer;
