import {headerLanguage} from '../ActionTypes';

export const get_header_language = (data) =>({
  type: headerLanguage.load ,
  payload: data,
});

export const set_header_language = (data) =>({
  type: headerLanguage.success ,
  payload: data,
});
