import axios from "axios";
import { apiConstants } from "../../../Constants/api_constants";

export function paymentListHomeowner(data) {
    return axios({
      method: 'get',
      url: apiConstants.PAYOUTS,
      params: {...data},
    });
  }

  export function paymentListRenter(data) {
    return axios({
      method: 'get',
      url: apiConstants.PAYOUT_RENTER,
      params: {...data},
    });
  }