import { React} from "react";
import {useNavigate} from 'react-router-dom';
import {Container, Row, Col, Button} from "react-bootstrap";
import { COMMON_PATHS } from "../../config/Owner-And-NewList_Paths";
const AccommodationSection = props => {
  const navigate = useNavigate();
  const openPage = () =>{
    if(props.path) {
      navigate(props.path);
    } else {
      navigate(COMMON_PATHS.SIGNUP)
    }
  }
  return(
    <>
    <section className="exp-acco-section">
        <Container>
          <Row>
            <Col md={9}>
              <div className="text">
                <h4> Experience the best accommodations. </h4>
                <p>
                  {" "}
                  We are not just another booking platform. We have pulled out
                  all the stops to ensure a safe journey for healthcare
                  professionals. From RV parking to the best hosts who
                  understand your needs. They are flexible with check-ins and
                  will go that extra mile if required.
                </p>
              </div>
            </Col>
            <Col md={3}>
              <div className="align-right" {...(localStorage.getItem('accessToken'))?{style:{display:'none'}}:{}}>
                <Button className="white-btn" onClick={()=>openPage()}> Sign Up Now </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
export default AccommodationSection;
