import { call, put, takeEvery } from "redux-saga/effects";
import {
  requestLoadOwnerListing,
  requestDeleteOwnerListing,
} from "../../Requests/owner/owner-listing";
import { deleteOwnerListing, fetchOwnerListing } from "../../../ActionTypes";
import {
  fetch_owner_listing_success,
  delete_owner_listing_success,
  delete_owner_listing_error,
} from "../../../Actions/Owner/owner-listing";
import { request_loader } from "../../../Actions/loader";
import { set_message } from "../../../Actions/message";
import { constantTexts, statuscode } from "../../../../Constants/Common";

export function* handleFetchOwnerListing({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const { search, select, initial, lists, fetchPage, page, order_by, order } =
      payload;
    yield put(request_loader({ load: initial }));
   let req = {}
    if (page) req.page = page;

    if (select) {
      if(select!='all' &&  select != 'deleted'){
        if(select == 'featured'){
          req.fetaured = '1'
        }else {
          req.status = select;
        }
      }else if (select == 'deleted'){
        req.deleted = '1'
      }
    }
  
    const response = yield call(requestLoadOwnerListing,req);
    yield put(request_loader({ load: false }));
    if (response) {
      const { status } = response;
      if (status === window.code["200"].code) {
        const { data, options } = response.data;
        const params = {
          search,
          select,
          initial:false,
          page,
          lists: lists ? lists.concat(data) : data,
          order_by,
          order,
        };
        if (fetchPage === true) params.totalPages = options.totalDocs;
        if (options && options.nextPage) params.page = options.nextPage;
        yield put(fetch_owner_listing_success(params));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchFetchOwnerListing() {
  yield takeEvery(fetchOwnerListing.load, handleFetchOwnerListing);
}

//delete owner listing
export function* handleDeleteOwnerListing({ payload }) {
  const { id, isPublished, contactData, s_type } = payload;
  let changedSts;
  if (s_type && s_type === statuscode.published.class) {
    changedSts = statuscode.unpublished.class;
  }
  if (s_type && s_type === statuscode.unpublished.class)
    changedSts = statuscode.published.class;
  try {
    const response = yield call(
      requestDeleteOwnerListing,
      id,
      isPublished,
      contactData
    );
    const { data } = response;
    if (data) {
      yield put(
        delete_owner_listing_success({
          id,
          isPublished,
          changedSts,
          contactData,
        })
      );
      yield put(
        set_message({
          error_code: data?.msg,
          code: constantTexts.SUCCESS,
        })
      );
    } else {
      yield put(delete_owner_listing_error(data));
    }
  } catch (error) {
    yield put(delete_owner_listing_error(error));
  }
}

export function* watchDeleteOwnerListing() {
  yield takeEvery(deleteOwnerListing.load, handleDeleteOwnerListing);
}
