import { allLeaseSignors, leaseSignorAction } from "../../ActionTypes";

export const fetchLeaseSignors = (data) => ({
    type : allLeaseSignors.load,
    payload : data
})

export const storeAllLeaseSignors = (data) => ({
    type : allLeaseSignors.success,
    payload : data
})

export const signorResendInvite = (data) => ({
    type : leaseSignorAction.resendInvite,
    payload: data
})
