export const ADMINPATHS = {
  APPPATH: "/app",
  REDIRECT: "/app/admin/listing/?select=pending",
  CALLBACK: "*",
  DEFAULT: "/admin",
  LISTING: "/admin/listing/",
  ADMIN_BOOKINGS: "/admin/bookings",
  LISTINGDEFAULT: "/admin/listing/?select=pending",
  USER_LISTING: "/admin/user-listing/",
  LISTINGREQUESTS: "/admin/listing/listing-requests",
  LISTINGREQUESTSDETAILONLY: "/admin/listing/listing-requests/detail",
  LISTINGREQUESTSDETAIL: "/admin/listing/listing-requests/detail/:request_id",
  LISTINGDETAILONLY: "/admin/listing/detail/",
  LISTINGDETAIL: "/admin/listing/detail/:property_id",
  DASHBOARD: "/admin/dashboard/",
  PROPDETAIL: "/admin/listing/detail/",
  SETTINGS: "/admin/dashboard/setting",
  USERMANAGEMENT: "/admin/user-management",
  USERMANAGEMENTbkp: "/admin/user-management-bkp",
  USERMANAGEMENTDEFAULT: "/admin/user-management?type=owner",
  USERMANAGEMENT_DETAILS_ONLY: "/admin/user-management/details/",
  USERMANAGEMENT_DETAILS: "/admin/user-management/details/:user_role/:user_id",
  BOOKINGS: "/admin/bookings",
  BOOKINGSDETAILS: '/admin/bookings/detail/:id',
  BOOKINGSDETAIL: '/app/admin/bookings/detail/',
  BUSINESSADMINS: '/admin/business-admin',
  BACKGROUNDCHECK: '/admin/background-check',
  BACKGROUNDCHECKDEFAULT: '/admin/background-check?type=pending',
  BACKGROUNDCHECKDETAIL: '/admin/background-check/detail/:id',
  BACKGROUNDCHECKDETAILONLY: '/admin/background-check/detail/',
  PROMO_CODE_MANAGEMENT: "/admin/promo-code-management",
  EVICTIONREQUESTS: '/admin/eviction-request',
  EVICTIONREQUESTSBK: '/admin/eviction-request-bk',
  EVICTIONREQUESTSDERAIL: '/admin/eviction-request/detail/:eviction_id',
  NOTIFICATION: '/admin/notification' ,
  MESSAGES: '/admin/messages',
  MANAGEDOCUMENT: '/admin/lease-documents',
  MANAGE_DOCUMENT_DETAIL: '/admin/lease-document/:country',
  MANAGEDOCUMENTDETAIL: '/admin/lease-document/',
  EDIT_CM_CONTENT: "/admin/edit-content/:c_id",
  EDIT_CM_CONTENT_ONLY: "/admin/edit-content/",
  CONTENT_MANAGEMENT: "/admin/content-management",
  ADMIN_MESSAGES_DETAIL: "/messages-detail/:type/:id",
  ADMIN_MESSAGES_DETAIL_ONLY: "/messages-detail/"
};
