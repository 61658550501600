import queryString from "query-string";
import { filterAction } from "../Redux/ActionTypes";
import {
  DEFAULT_NO_RECORD,
  statuscode,
  rentalPeriods,
  userRoles,
  changeCase,
  incudedContriesCode2l,
  magicNumbers,
} from "../Constants/Common";
import { date_diff } from "../Constants/form-validation-schemas";
import axios from "axios";
import { getBackGroundInfo } from "../Redux/Actions/auth";
import { format } from 'date-fns';
import moment from "moment";

export const custom_assigners = (data, action) => {
  const req = {
    actn: action,
    select: data.select,
    page: data.page,
    order_by: data.order_by,
    order: data.order,
    initial: data.initial,
    totalPages: data.totalPages,
    count: data.count,
    type: data.type,
  };
  if (data.search_by && data.search) req[data.search_by] = data.search;
  if (data.month) req.month = data.month;
  if (data.year) req.year = data.year;
  if (data.promo_status) req.promo_status = data.promo_status;
  if (data.promo_search) req.promo_search = data.promo_search;
  if (data.user_id) req.user_id = data.user_id;
  if (data.type) req.type = data.type;
  if (data.first_name) req.first_name = data.first_name;
  if (data.last_name) req.last_name = data.last_name;
  if (data.search && !data.search_by) req.search = data.search;
  if (data.bathroom) req.bathroom = data.bathroom;
  if (data.bedroom) req.bedroom = data.bedroom;
  if (data.checkin) req.checkin = data.checkin;
  if (data.checkout) req.checkout = data.checkout;
  if (data.lat) req.lat = data.lat;
  if (data.lng) req.lng = data.lng;
  if (data.country) req.country = data.country;

  return req;
};

export const custom_assigner_search = (data) => {
  return {
    checkin: data.checkin,
    checkout: data.checkout,
    guests: data.guests,
    search: data.search,
    country: data.country,
    state: data.state,
    lat: data.lat,
    lng: data.lng,
    bedroom: data.bedroom,
    bathroom: data.bathroom,
    property_type: data.property_type,
    is_pet_allowed: data.is_pet_allowed,
  };
};

export const custom_assigner_segmented = (data, action, segment) => {
  return {
    actn: action,
    search: data.search,
    select: data.select,
    page: data.page,
    order_by: data.order_by,
    order: data.order,
    initial: data.initial,
    totalPages: data.totalPages,
    segment,
  };
};

export const switch_assigner = (data) => {
  return {
    lists: data.lists,
    code: data.code,
    flag: data.flag,
    name: data.name,
  };
};

export const advance_query_redirector = (locate, navigate, dest, obj) => {
  const path = locate.search;
  let parser = queryString.parse(path);
  for (let item of Object.keys(obj)) {
    if (obj[item] !== "") parser[item] = obj[item];
    if (obj[item] === "" || !obj[item]) {
      delete parser[item];
    }
  }
  let query = "";
  for (let item of Object.keys(parser)) {
    query += item + "=" + parser[item] + "&";
  }
  let final = query.slice(0, query.length - 1);
  if (final !== "") navigate(dest + "?" + final);
  else navigate(dest);
};

export const set_check_selection = (checkArr, action, value) => {
  let arr = checkArr;
  var index = arr.indexOf(value);
  if (action === false && index > -1) arr.splice(index, 1);
  if (action === true && index < 0) arr.push(value);
  return arr;
};
// return Y-m-d format
export const dateFormat = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const dateFormatObj = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return new Date(year, month, day);
};
//return Jan 11, 2022 format
// export const dateFormatAdv = (date) => {
//   let dateObj = new Date(date);
//   let month = dateObj.getMonth(); //months from 1-12
//   let day = dateObj.getUTCDate();
//   let year = dateObj.getUTCFullYear();
//   const months = [
//     "Jan",
//     "Feb",
//     "Mar",
//     "Apr",
//     "May",
//     "Jun",
//     "Jul",
//     "Aug",
//     "Sep",
//     "Oct",
//     "Nov",
//     "Dec",
//   ];
//   let days = day;

//   if (day < 10) {
//     days = "0" + day;
//   }
//   return months[month] + " " + days + ", " + year;
// };

export const dateFormatAdv = (date) => {
  return format(new Date(date), 'MMM dd, yyyy');
}  
export const dateFormatFilter = (date) => {
  let dateObj = new Date(date);
  let month = dateObj.getMonth(); //months from 1-12
  let day = dateObj.getDate();
  let year = dateObj.getFullYear();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let days = day;
  if (days < 10) {
    days = "0" + days;
  }

  year = (String(year)).split("-");
  if (year.length !== 1) {
    year = year[1]
  }

  return months[month] + " " + days + ", " + year;
};

//return Jan 11, 2022 format
export const getTimeAdv = (date) => {
  var dateObj = new Date(date);
  return dateObj.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
};

export const divider = (date) => {
  var dateObj = new Date(date);
  var month = dateObj.getMonth(); //months from 1-12
  var year = dateObj.getUTCFullYear();
  const full = [1, 3, 5, 7, 8, 10, 12];
  const partial = [4, 6, 9, 11];
  let divider = year % 4 === 0 ? 29 : 28;
  month = parseInt(month) + parseInt(1);
  if (full.includes(month)) {
    divider = 31;
  }
  if (partial.includes(month)) {
    divider = 30;
  }
  return divider;
};

export const monthDaysCount = (date, index) => {
  const day = new Date(date);
  const month30 = ["4", "6", "9", "11"];
  const month31 = ["1", "3", "5", "7", "8", "10", "12"];
  const currentMonth =
    day.getMonth() + index > 12 ? "1" : (day.getMonth() + index).toString();
  const currentYear = day.getFullYear();
  let days = 28;
  if (currentYear % 4 === 0) days = 29;
  if (month30.includes(currentMonth)) days = 30;
  if (month31.includes(currentMonth)) days = 31;
  return days;
};

export const differ = (date) => {
  var dateObj = new Date(date);
  var day = dateObj.getDate();
  return monthDaysCount(date, 1) - day;
};

/* Added by neeraj*/
export function date_diffNew(first, second) {
  return Math.round((second - first) / (1000 * 60 * 60 * 24));
}
/* Added by neeraj*/
//return Jan, 2022 format
export const dateFormatCard = (month, year) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[parseInt(month - 1)] + ", " + year;
};

export const setCoordinate = ({ records, dispatch }) => {
  let data = [];

  records && records.forEach((record) => {
    if (record) {
      const lat = record.lat;
      const lng = record.lng;
      const weeklyrent = record?.weekly_rent || 0;
      data.push({
        lat: Number(lat),
        lng: Number(lng),
        address: weeklyrent,
        prop_id: record?.property_id,
        city: record?.city,
        state: record?.state,
        image: record?.image,
      });
    }
  });
  dispatch({
    type: filterAction.setCoordinate,
    payload: { data: data, status: "loaded" },
  });
};

export const getQueryStringValue = (query, setDefaultCountry = true) => {
  const search = window.location.search;
  let code = "";
  if (setDefaultCountry) {
    code = query === "country" ? "US" : "";
  }
  if (search) {
    let parser = queryString.parse(search);
    if (query === "search") {
      parser = queryString.parse(search, { decode: false });
    }
    code = parser[query];
  }
  return code;
};

export const requestBuilder = (locate) => {
  const path = locate.search;
  return queryString.parse(path);
};

export const getContentBySlug = (allStaticContents, slug) => {
  let contentData = "";
  allStaticContents.forEach((content) => {
    if (content.slug === slug) {
      contentData = content.content;
    }
  });
  return contentData;
};
export const uploadErrMsg = (data) => {
  let i = 0;
  let error = "";
  for (; i < data.length; i++) {
    error += data[i] + " ";
  }
  return error;
};

//function to get the bank details from bank Id
export const getBankDetails = (allBankDetails, bankId) => {
  let contentData = [];
  allBankDetails &&
    allBankDetails.forEach((content) => {
      if (content.id === bankId) {
        contentData.push(content);
      }
    });
  return contentData;
};

export const generateRequestObject = (data) => {
  const req = {
    going_to: data.search || DEFAULT_NO_RECORD,
    guest: data.guest || DEFAULT_NO_RECORD,
    bedroom: data.bedroom || DEFAULT_NO_RECORD,
    bathroom: data.bathroom || DEFAULT_NO_RECORD,
  };
  if (data.check_in) {
    req.check_in = data.check_in;
  }
  if (data.check_out) {
    req.check_out = data.check_out;
  }
  return req;
};

export const priceDesigner = (price) => {
  return price.toLocaleString();
};

export const month_diff = (fromdate, todate) => {
  const dateone = new Date(fromdate);
  const datetwo = new Date(todate);
  return datetwo.getMonth() - dateone.getMonth();
};

export const bookingStatus = (checkin, checkout) => {
  const now = new Date(Date.now());
  const start = new Date(checkin);
  const end = new Date(checkout);
  const start_diff = now - start;
  const end_diff = end - now;
  let status = "";
  if ((start_diff >= 0 && end_diff > 0) || (end_diff <= 0)) {
    status = statuscode.ongoing_booking.key;
  }
  if (start_diff < 0 && end_diff > 0) {
    status = statuscode.upcoming_booking.key;
  }
  // if (start_diff > 0 && end_diff <= 0) {
  //   status = statuscode.completed_booking.key;
  // }
  return status;
};

export const currencyFormat = (amount, curr = "$") => {
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const numericAmount = isNaN(amount) ? '0':  Number(amount);

  const formattedAmount = numericAmount.toLocaleString("en-US", options);
  return curr + formattedAmount;
};

export const currencyFormatwithoutComma = (amount, curr = "$") => {
  const num = currencyFormat(amount, curr);
  return num.split(",").join("");
};

export const numberFormat = (amount, curr = "$") => {
  return Number(amount).toLocaleString("en");
};

export const getParkingCleaningFees = (fees, total_jobs) => {
  return (fees * total_jobs).toFixed(2);
};

export const getTotal = (arr) => {
  return arr
    .reduce((partialSum, a) => parseFloat(partialSum) + parseFloat(a), 0)
    .toFixed(2);
};

export const generateFullAddress = (address, addrParams) => {
  let addrString = [];
  addrParams.forEach((value) => {
    if (address[value]) {
      addrString.push(address[value]);
    }
  });
  return addrString.join(", ");
};

export const numberOfWeeks = (
  weeks,
  service_per_week,
  adjustCleningParking
) => {
  const weeks_rounded = parseInt(weeks);
  const diff = weeks - weeks_rounded;
  let addon_week = 0;
  if (diff > 0 && diff <= 0.5) {
    addon_week = 1;
  }
  if (diff > 0.5) {
    addon_week = service_per_week;
  }
  if (adjustCleningParking) {
    addon_week = addon_week - 1;
  }
  return parseInt(weeks_rounded * service_per_week) + parseInt(addon_week);
};

export const dateInUTC = (date) => {
  const dateTime = new Date(date);
  const dateEst = dateTime.toLocaleString("en-US", {
    timeZone: "America/New_York",
  });
  return dateFormat(dateEst);
};

export const blockDates = (block_dates) => {
  let block = [];
  if (block_dates && block_dates.length > 0) {
    block_dates.forEach((value) => {
      block.push({
        start: dateReducerFN(value.startDate),
        end: dateReducerFN(value.endDate),
      });
    });
  }
  return block;
};

export const blockedFeaturedDates = (featured_dates) => {
  let featured = [];
  if (featured_dates && featured_dates.length > 0) {
    featured_dates.forEach((value) => {
      featured.push({
        start: dateReducerFN(value.start_date),
        end: dateReducerFN(value.end_date),
      });
    });
  }
  return featured;
};

export const testBlockDate = (fromdate, todate, block_dates) => {
  let incremental = 0;
  const dateFrom = new Date(fromdate);
  const fromTime = dateFrom.getTime();
  const dateTo = new Date(todate);
  const toTime = dateTo.getTime();
  block_dates.forEach((element) => {
    let start = element.start;
    let end = element.end;
    let startDate = new Date(start);
    let startTime = startDate.getTime();
    let endDate = new Date(end);
    let endTime = endDate.getTime();
    if (
      (startTime >= fromTime && startTime <= toTime) ||
      (endTime >= fromTime && endTime <= toTime)
    ) {
      incremental++;
    }
  });
  return incremental;
};

export const testRentalPeriodOld = (fromdate, todate, rental_period) => {
  let i = 0;
  const dateDifference = date_diff(fromdate, todate);
  if (rental_period.length <= 0) {
    return i;
  }
  let j = 0;
  for (; j < rental_period.length; j++) {
    let min = rentalPeriods[rental_period[j]].min;
    let max = rentalPeriods[rental_period[j]].max;
    if (max === "") {
      if (parseInt(dateDifference) >= parseInt(min)) i++;
    } else {
      if (
        parseInt(dateDifference) >= parseInt(min) &&
        parseInt(dateDifference) <= parseInt(max)
      )
        i++;
    }
  }
  return i;
};

export const testRentalPeriod = (fromdate, todate, rental_period) => {
  const dateDifference = date_diff(fromdate, todate);
  const testResult = setCheckoutDays(rental_period);
  if (
    parseInt(dateDifference) <= testResult.max &&
    parseInt(dateDifference) >= testResult.min
  ) {
    testResult.status = true;
  }
  return testResult;
};

export const setCheckoutDays = (rental_period) => {
  const daysResult = { status: false, min: 0, max: 0 };
  if (rental_period.length < 1) {
    return daysResult;
  }
  Object.keys(rentalPeriods).forEach((key) => {
    if (rental_period.includes(key)) {
      if (daysResult.min === 0) {
        daysResult.min = rentalPeriods[key].min;
      }
      daysResult.max = rentalPeriods[key].max;
    }
  });
  return daysResult;
};

export const getOrderedRentalPeriod = (rental_period, change_case) => {
  let rentalString = [];
  if (!Array.isArray(rental_period) || rental_period.length < 1) {
    return rentalString;
  }
  Object.keys(rentalPeriods).forEach((key) => {
    let value = "";
    if (rental_period.includes(key)) {
      if (change_case && change_case === true) {
        value = `${" "}${changeCase(key, true)}`;
      } else {
        value = `${" "}${key}`;
      }
      rentalString.push(value);
    }
  });
  return rentalString.join(",");
};

export const toCamelCase = (text) => {
  text = text?.replace(/[-_\s.]+(.)?/g, (_, c) =>
    c ? " " + c?.toUpperCase() : " "
  );
  return text?.substr(0, 1).toUpperCase() + text?.substr(1);
};

export const differenceBetweenTwoDates = (fromdate, todate) => {
  const dateone = new Date(fromdate);
  const datetwo = new Date(todate);
  const differ = datetwo - dateone;
  const diff = differ / (60 * 60 * 24 * 1000);
  return parseInt(diff);
};

export const todayDate = () => {
  return new Date().toISOString().split("T")[0];
};

export const returnImiditiateResponse = (url, method, data = {}) => {
  const req = {
    method,
    url,
    validateStatus: () => true,
  };
  if (Object.keys(data).length === 0) req.data = data;
  return axios(req);
};

export const getExtension = (filename) => {
  if (filename.indexOf("?") > 0) {
    let ext = filename.split("?")[0].split(".").pop();
    if (ext.indexOf("?") > 0) {
      let ext2 = ext.split("?");
      return ext2[0];
    } else {
      return ext;
    }
  } else if (filename.indexOf("%3D") > 0) {
    let ext = filename.split("%3D")[0].split(".").pop();
    if (ext.indexOf("?") > 0) {
      let ext2 = ext.split("?");
      return ext2[0];
    } else {
      return ext;
    }
  } else {
    return filename.split(".").pop();
  }
};

export const timeInMinutesandSeconds = (time) => {
  const secs = time - Date.now();
  const minutes = Math.floor(secs / (60 * 1000));
  const seconds = secs / 1000 - minutes * 60;
  let str = "";
  if (minutes > 0) str += minutes + " mins & ";
  str += Math.ceil(seconds) + " secs";
  return { str: str, rem: secs };
};

/* depreciated on the Nov12 for fixing timezone issue */
export const setMinCheckoutOld = (checkin, adder) => {
  const check = dateFormat(checkin);
  const monD = monthDaysCount(check, +1);
  const spl = check.split("-");
  const da = spl[2];
  const mon = spl[1];
  const year = spl[0];
  const days =
    parseInt(da) + adder > monD ? adder - (monD - da) : parseInt(da) + adder;
  const months =
    parseInt(da) + adder > monD ? parseInt(mon) : parseInt(mon) - 1;
  const mont = parseInt(months) > 12 ? 1 : months;
  let years =
    parseInt(months) > 12 && parseInt(da) + adder > monD
      ? parseInt(year) + 1
      : year;
  return new Date(years, mont, days);
};

/* Fixed the timezone issue by neeraj 12 nov*/
export const setMinCheckout = (checkin, adder) => {
  var currentDate = new Date(checkin);
  currentDate.addDays(adder);
  return currentDate;
};

/* Add days to date */
Date.prototype.addDays = function (days) {
  this.setDate(this.getDate() + parseInt(days));
  return this;
};



export const returnBackgroundUserRecord = async (url, method, dispatch) => {
  const req = {
    method,
    url,
    validateStatus: () => true,
  };
  const res = await axios(req);
  if (res && res.status === 200) {
    dispatch(getBackGroundInfo(res.data.data));
  }
};

export const getReadableRole = (role) => {
  let readableRole = "";
  switch (role) {
    case userRoles._renter:
      readableRole = userRoles.renter;
      break;
    case userRoles._owner:
      readableRole = userRoles.owner;
      break;
    case userRoles._admin:
      readableRole = userRoles.admin;
      break;
    case userRoles._bus_admin:
      readableRole = userRoles.admin;
      break;

    default:
      break;
  }
  return readableRole;
};

export const dateConverter = (comingDateData) => {
  const element = [];
  if (comingDateData.length > 0) {
    comingDateData.forEach((ele) => {
      element.push({
        startDate: dateReducerFN(ele.startDate),
        endDate: dateReducerFN(ele.endDate),
      });
    });
  } else {
    element.push({
      startDate: "",
      endDate: "",
    });
  }
  return element;
};

export const dateReducerFN = (comingDate) => {
  const dt = new Date(comingDate);
  dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
  return dt;
};

export const US_DATE = new Date().toLocaleString("en-US", {
  timeZone: "America/New_York",
});

export const setGoogleMapPlaceData = (place) => {
  let isAdministrativeAddressPresent = false;
  let adminIndex = magicNumbers.ZERO;
  let addressComponents = [];
  let adminstrativeObj = {};
  if (place?.address_components?.length > 0) {
    place.address_components.forEach((places, index) => {
      addressComponents[index] = places;
      if (
        places.types &&
        places.types.includes("country") &&
        incudedContriesCode2l.includes(places.short_name.toLowerCase())
      ) {
        adminstrativeObj = {
          long_name: places.long_name,
          short_name: places.short_name,
          types: ["administrative_area_level_1", "political"],
        };
        addressComponents[index] = {
          long_name: "United States",
          short_name: "US",
          types: ["country", "political"],
        };
        place.formatted_address = `${place.formatted_address}, USA`;
      }
      if (
        places.types &&
        places.types.includes("administrative_area_level_1")
      ) {
        adminIndex = index;
        isAdministrativeAddressPresent = true;
      }
    });
    if (Object.keys(adminstrativeObj).length !== magicNumbers.ZERO) {
      if (isAdministrativeAddressPresent) {
        addressComponents[adminIndex] = adminstrativeObj;
      } else {
        addressComponents.push(adminstrativeObj);
      }
    }
  }
  return { ...place, ...{ address_components: addressComponents } };
};


export const getDateTime = (date) => {
  const dateObj = new Date(date);
  return dateObj.toLocaleDateString([], { month: 'short', day: '2-digit', year: 'numeric' });
};

// Date format function without handling timezone.
export const getFormattedDate = (date) => {
  return moment(date).format('MMM D, YYYY')
}
