import axios from "axios";
import { apiConstantsStripe } from "../../../../Constants/api_constant_stripe";


export function requestCreateCardStripe(data) {
    return axios({
        method: 'POST',
        url: apiConstantsStripe.CREATE_CARD,
        data:{...data},
        validateStatus: () => true
    });
}

export function getPaymentMethods() {
    return axios.get(apiConstantsStripe.GET_ACCOUNTS);
}

export function requestSetDefaultCard(data) {
    return axios({
        method: 'PUT',
        url: apiConstantsStripe.SET_DEFAULT_CARD,
        data:{...data},
        validateStatus: () => true
    });
}


export function requestDeleteSource(data) {
    return axios({
        method: 'DELETE',
        url: apiConstantsStripe.DELETE_SOURCE+data.source_type+'/'+data.source_id,
        validateStatus: () => true
    });
}
