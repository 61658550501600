import axios from "axios";
import { apiConstants } from "../../../../Constants/api_constants";
import { apiConstantsStripe } from "../../../../Constants/api_constant_stripe";


export function requestCreateBankStripe(data) {
    return axios({
        method: 'POST',
        url: apiConstantsStripe.CREATE_BANK_ACCOUNT,
        data:{...data},
        validateStatus: () => true
    });
}

export function requestCreateBankPMStripe(data) {
    return axios({
        method: 'POST',
        url: apiConstantsStripe.CREATE_BANK_PAYMENT_METHOD,
        data:{...data},
        validateStatus: () => true
    });
}

export function requestCreateConnectAccount(data) {
    return axios({
        method: 'POST',
        url: apiConstants.CREATE_CONNECT_ACCOUNT,
        data:{...data},
        validateStatus: () => true
    });
}

export function requestSaveBankAccount(data) {
    return axios({
        method: 'POST',
        url: apiConstants.SAVE_BANK_ACCOUNT,
        data:{...data},
        validateStatus: () => true
    });
}
