import { cardAction } from "../../ActionTypes/stripe";
const data = {
    loading: true,
    card_data : [],
    error: "",
  };

const cardData = (state = data, action) => {
    switch (action.type) {
        case cardAction.createCard:
            return {...state, ...action.payload}
        default:
            return {...state}
    }
};
export default cardData;