import { listAction,userAction,fetchRenterInfo } from '../../ActionTypes/admin';

export const get_user_list = (data) => ({
  type: listAction.loadUser,
  payload: data,
});

export const display_user_list = (data) => ({
  type: listAction.displayUser,
  payload: data,
});

export const update_user_status = (data) => ({
  type: userAction.updateStatus,
  payload: data,
});
export const publish_approve_prop = (data) => ({
  type: userAction.publishApproveProp,
  payload: data,
});


export const userUpdateUnique = (data) => ({
  type: userAction.listOnlyUpdateUnique,
  payload: data,
});
// to get current renterInfo
export const  getCurrentRenter = (id) => ({
  type: fetchRenterInfo.load,
  payload:  id,
});

export const getCurrentRenter_success = (data) => ({
  type: fetchRenterInfo.success,
  payload: data,
});

export const getCurrentRenter_error = (data) => ({
  type: fetchRenterInfo.failure,
  payload: data,
});
