import { call, put, takeEvery } from "redux-saga/effects";
import {
  requestCreateUserDetails,
  requestFetchUserDetails,
  requestUpdateUserDetails,
  requestUpdateUserProfileImage,
  requestChangePassword,
  requestSwitchRole,
} from "../Requests/create-user-details";
import { createUserDetails } from "../../ActionTypes";
import { fetch_user_detail_success } from "../../Actions/create-user-details";
import { set_message } from "../../Actions/message";

import {
  COMMON_PATHS,
  REDIRECTIONS,
} from "../../../config/Owner-And-NewList_Paths";
import { request_loader } from "../../Actions/loader";
import error_messages from "../../../Constants/error-messages";

export function* handleCreateUserDetails({ payload }) {
  try {
    const { values, navigate, actn, delete_cookie } = payload;
    console.log(values);
    let role = localStorage.getItem("role") || "";
    if (role === "") {
      navigate(`${COMMON_PATHS.SIGNUP}`);
    }
    values.user.role = role;
    yield put(request_loader({ load: true }));
    const response = yield call(requestCreateUserDetails, values.user);
    yield put(request_loader({ load: false }));
    if (response) {
      const { data, status } = response;
      if (status === window.code["200"].code) {
        yield put(
          set_message({
            error_code: data.msg,
            code: "success",
            heading: window.code["200"].header,
          })
        );
        window.parent.setToken({
          token: data.data.token,
          first_name: values.user.first_name,
          last_name: values.user.last_name,
          user_phone: values.user.mobile,
          countryCode: values.user.iso_code,
          countryCodeLabel: values.user.countryCodeLabel,
        });
        delete_cookie(actn + "_is_disable");
        delete_cookie(actn + "_is_disabled_for");
        delete_cookie(actn + "_hit_counter");
        navigate(`${COMMON_PATHS.OTP + "/" + actn}`);
      } else {
        yield put(request_loader({ load: "auth_error" }));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchCreateUserDetails() {
  yield takeEvery(createUserDetails.load, handleCreateUserDetails);
}

export function* handleFetchUserDetails({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(
      requestFetchUserDetails,
      payload?.user_id ? payload?.user_id : ""
    );

    const { data } = response;
    if (data) {
      const da = {
        loading: false,
        user: data.data,
        error: "",
      };
      sessionStorage.setItem("user", JSON.stringify(data.data));
      sessionStorage.setItem("profile_image", data.data.profile_image);
      yield put(fetch_user_detail_success(da));
      yield put(request_loader({ load: false }));
    }
  } catch (error) {
    yield put(request_loader({ load: false }));
    console.log(error);
  }
}

export function* watchFetchUserDetails() {
  yield takeEvery(createUserDetails.fetchRequest, handleFetchUserDetails);
}

export function* handleUpdateUserDetails({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    let { values, navigate, role } = payload;
    let { first_name, last_name, profession, profession_other, email } = values;
    if (profession != "Others") {
      profession_other = null;
    }
    let record = JSON.parse(sessionStorage.getItem("user"));
    let request = {
      first_name: first_name,
      last_name: last_name,
      profession: profession,
      profession_other: profession_other,
    };
    if (email !== record.email) request.email = email;
    const response = yield call(requestUpdateUserDetails, request);
    yield put(request_loader({ load: false }));
    if (response) {
      const { data, status } = response;
      if (status === window.code["200"].code) {
        if (data.data.token) {
          localStorage.setItem("accessToken", data.data.token);
        }
        navigate(`${COMMON_PATHS.APP1 + "/" + role + "-profile"}`);
        yield put(
          set_message({
            error_code:
              email !== record.email
                ? data.msg
                : error_messages.profile_updated_successfully,
            code: "success",
            heading: "Success",
          })
        );

        record.first_name = first_name;
        record.last_name = last_name;
        if (email !== record.email) record.email = email;
        sessionStorage.setItem("user", JSON.stringify(record));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchUpdateUserDetails() {
  yield takeEvery(createUserDetails.update, handleUpdateUserDetails);
}

export function* handleUpdateUserProfileImage({ payload }) {
  try {
    const { record, user } = payload;
    yield put(request_loader({ load: true }));
    const response = yield call(requestUpdateUserProfileImage, record);
    yield put(request_loader({ load: false }));
    if (response.status === 200) {
      const { data } = response;
      let image = data.data.publicUrl;
      user.profile_image = image;
      yield put(
        set_message({
          error_code: "Image " + data.msg,
          code: "success",
          heading: "Success",
        })
      );
      yield put(
        fetch_user_detail_success({ loading: false, user: user, error: "" })
      );
      sessionStorage.setItem("profile_image", image);
      if (data.data.token) {
        localStorage.setItem("accessToken", data.data.token);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchUpdateUserProfileImage() {
  yield takeEvery(createUserDetails.updateImage, handleUpdateUserProfileImage);
}

export function* handleChangePassword({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    const response = yield call(requestChangePassword, payload);
    yield put(request_loader({ load: false }));
    if (response) {
      const { status, data } = response;
      if (status === window.code["200"].code) {
        yield put(
          set_message({
            error_code: "Password " + data.data,
            code: "success",
            heading: window.code["200"].header,
          })
        );
      }
    }
  } catch (error) {
    console.log(error);
    yield put(request_loader({ load: false }));
    // yield put(
    //   set_message({
    //     error_code: "old_pass_does_not_match",
    //     code: "warning",
    //     heading: "Invalid Value",
    //   })
    // );
  }
}

export function* watchChangePassword() {
  yield takeEvery(createUserDetails.changePass, handleChangePassword);
}

export function* handleSwitchRole({ payload }) {
  try {
    yield put(request_loader({ load: true }));
    let { role } = payload;
    let request = { role: role };
    const response = yield call(requestSwitchRole, request);
    if (response.status === 200) {
      yield put(request_loader({ load: false }));
      yield put(
        set_message({
          error_code: response?.data?.msg,
          code: "success",
          heading: "Success",
        })
      );
      localStorage.setItem("accessToken", response?.data?.data?.token);
      localStorage.setItem("role", response?.data?.data?.role);
      let link = localStorage.getItem("redirect_path")
        ? localStorage.getItem("redirect_path")
        : REDIRECTIONS[role + "_redirect"];
      let redirection = localStorage.getItem("history_url")
        ? localStorage.getItem("history_url")
        : link;
      localStorage.removeItem("redirect_path");
      localStorage.removeItem("history_url");
      localStorage.setItem("history_switch_url", redirection);
      window.location.reload();
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchSwitchRole() {
  yield takeEvery(createUserDetails.switchRole, handleSwitchRole);
}
