import profileData from "./ProfileData";
import owner_listing from "./owner-listing";
import lease_signor_details from "./LeaseSignorDetails";
import PaymentManagement from "../PaymentManagement";
import leaving_review from "./LeavingReview";

const ownerReducer = {
  profileData,
  owner_listing,
  lease_signor_details,
  PaymentManagement,
  leaving_review,
};

export default ownerReducer;
