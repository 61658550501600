import { fetchIcalLink } from "../ActionTypes/index";
const data = {
  loading: true,
  icalLinkInfo: [],
  error: "",
  icalLinkInfoFinished: false,
};
const ical_info = (state = data, action) => {

  switch (action.type) {
    case fetchIcalLink.success:
      return {
        ...state,
        loading: false,
        icalLinkInfo: action.payload,
      };
    case fetchIcalLink.failure:
      return {
        ...state,
        loading: false,
        icalLinkInfoFinished: false,
        error: action.payload,
      };
    default:
      return { ...state };
  }
};

export default ical_info;