
  import { advanceFilter } from "../ActionTypes";
  const data = {
    bedroom:new URLSearchParams(window.location.search).get('bedroom')||'',
    bathroom:new URLSearchParams(window.location.search).get('bathroom')||''
  }
  const AdvanceFilter = (state=data, action) => {
    switch (action.type) {
      case advanceFilter.updateBed:
        return { ...state,bedroom:action.payload };
     case advanceFilter.updateBath:
        return {...state,bathroom:action.payload };
     case advanceFilter.resetFilter:
      return{
        bedroom:"",
        bathroom:""
      }
      default:
        return state;
    }
  };
  export default AdvanceFilter;