import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route, BrowserRouter } from "react-router-dom";
import Loading from "../components/Common/Loading";
import MetaTag from "../Containers/metaTag";
import commonRoutes from "./CommonRoutes";
import ScrollToTop from "./ScrollToTop";

const RoutesIndex = () => {
  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
        {<ScrollToTop />}
        <MetaTag />
        <Routes>
          {commonRoutes.map((route, ky) => {
            return <Route key={ky} {...route} />;
          })}
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default RoutesIndex;
