import { loadStaticContent, updateTac } from "../ActionTypes";
const data = {
  loading: true,
  staticContent: [],
  error: "",
};
const getItemIndex = (staticContents, updateContent) => {
    return staticContents.findIndex((d) => d._id === updateContent._id);
  };

const staticContent = (state, action) => {
  switch (action.type) {
    case loadStaticContent.success:
      return { ...state, loading: false, staticContent: action.payload };
    case updateTac.load:
      return { ...state, loading: true };
    case updateTac.success:
      const index = getItemIndex(state.staticContent, action.payload);
      state.staticContent.splice(index, 1, action.payload);
      return {
        ...state,
        loading: false,
        staticContent: [...state.staticContent],
      };
    default:
      return { ...state };
  }
};
export default staticContent;
