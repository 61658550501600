import { call, put, takeEvery } from "redux-saga/effects";
import { requestCompleteProfile } from "../Requests/create-user-details";
import { completeProfileAction } from "../../ActionTypes";
import { check_otp_error } from "../../Actions/create-user-details";
import { login_success } from "../../Actions/auth";
import { COMMON_PATHS,REDIRECTIONS } from "../../../config/Owner-And-NewList_Paths";
import { set_loader } from "../../Actions/loader";
import { set_message } from "../../Actions/message";
import error_messages from "../../../Constants/error-messages";

export function* handleCompleteProfile({ payload }) {
  try {
    const { isBusAdmin, request, navigate } = payload.data;
    const token = localStorage.getItem("token") || "";

    request.token = token;
    if (isBusAdmin) {
      delete request.token;
      delete request._id;
      delete request.email;
      delete request.confirm_password;
    }
    yield put(set_loader({ load: true }));
    const response = yield call(
      requestCompleteProfile,
      request,
      isBusAdmin,
      token
    );
    yield put(set_loader({ load: false }));
    if (response) {
      const { data, status } = response;

      if (status === window.code["200"].code) {
        let res = {
          user: !isBusAdmin ? data.data.user_deatils : data.data.id,
          role: !isBusAdmin ? data.data.user_deatils.role[0] : data.data.role[0],
        };
        yield put(login_success(res));
        if (!isBusAdmin){
          window.parent.setToken({
            "accessToken": data.data.accessToken,
            "user_id":data.data.user_deatils.id,
            "user":data.data.user_deatils,
            "role":data.data.user_deatils.role[0],
            "all_user_data":JSON.stringify(data.data.user_deatils),
            "loginTimer":  Date.now()
          },["token","first_name","last_name","role_name","nba"]);
          let redirection = localStorage.getItem("history_url")
          ? localStorage.getItem("history_url")
          : `${COMMON_PATHS.SIGNUP_SUCCESS_MESSAGE}`;
          navigate(redirection);
        }
        else{
          localStorage.clear();
          yield put(
            set_message({
              error_code: error_messages.business_admin_success,
              code: "success",
              heading: "Try Later",
            })
          );
          navigate(`${REDIRECTIONS[data.data.role[0]+ "_success_redirect"]}`);
        }
      }
      if (status === window.code["400"].code) {
        yield put(
          set_message({
            error_code: "Invalid value token",
            code: "warning",
            heading: "Try Later",
          })
        );
        navigate(`${COMMON_PATHS.SIGNUP}`);
      }
    }
  } catch (error) {
    yield put(check_otp_error(error));
  }
}

export default function* watchCompleteProfile() {
  yield takeEvery(completeProfileAction.load, handleCompleteProfile);
}
