import {
  addSingleBankInfo,
  // createBankAccountInfo,
  stripePaymentMethods,
} from "../../ActionTypes";

export const fetch_stripe_payment_methods = (data) => ({
  type: stripePaymentMethods.load,
  payload: data
});

export const fetch_stripe_payment_methods_success = (data) => ({
  type: stripePaymentMethods.success,
  payload: data,
});

//add Bank in to review list
export const add_single_bank = (bank, p_id, navigate) => ({
  type: addSingleBankInfo.load,
  payload: { bank, p_id, navigate },
});
export const add_single_bankSuccess = (data) => ({
  type: addSingleBankInfo.success,
  payload: data,
});
export const add_single_bankError = (data) => ({
  type: addSingleBankInfo.failure,
  payload: data,
});

export const verify_bank_individual = (data) => ({
  type: addSingleBankInfo.verifyAccount,
  payload: data,
});

export const update_represntative_details = (data) => ({
  type: addSingleBankInfo.updateRepresentative,
  payload: data,
});