import { call, put, takeEvery } from "redux-saga/effects";
import { paymentActn } from "../../ActionTypes";
import { request_loader } from "../../Actions/loader";
import { homeownerPaymentDisplay } from "../../Actions/paymentManagement";
import {
  paymentListHomeowner,
  paymentListRenter,
} from "../Requests/paymentRequest";

export function* handlePaymentListOwner({ payload }) {
  try {
    const { initial, fetchPage, page, month, year, lists } = payload;
    yield put(request_loader({ load: initial }));
    let req = {};
    if (page) req.page = page;
    if (month) req.month = month;
    if (year) req.year = year;
    const response = yield call(paymentListHomeowner, req);
    yield put(request_loader({ load: false }));
    if (response) {
      const { status } = response;
      if (status === window.code["200"].code) {
        const { data, options } = response.data;
        const params = {
          initial: false,
          lists: lists ? lists.concat(data) : data,
          options,
        };
        if (fetchPage === true) params.totalPages = options.totalDocs;
        if (options && options.nextPage) params.page = options.nextPage;
        if (month) params.month = month;
        if (year) params.year = year;
        yield put(homeownerPaymentDisplay(params));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchPaymentListOwner() {
  yield takeEvery(paymentActn.load, handlePaymentListOwner);
}

export function* handlePaymentListRenter({ payload }) {
  try {
    const { initial, fetchPage, page, month, year, lists } = payload;
    yield put(request_loader({ load: initial }));
    let req = {};
    if (page) req.page = page;
    if (month) req.month = month;
    if (year) req.year = year;
    const response = yield call(paymentListRenter, req);
    yield put(request_loader({ load: false }));
    if (response) {
      const { status } = response;
      if (status === window.code["200"].code) {
        const { data, options } = response.data;
        const params = {
          initial: false,
          lists: lists ? lists.concat(data) : data,
          options,
        };
        if (fetchPage === true) params.totalPages = options.totalDocs;
        if (options && options.nextPage) params.page = options.nextPage;
        if (month) params.month = month;
        if (year) params.year = year;
        yield put(homeownerPaymentDisplay(params));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchPaymentListRenter() {
  yield takeEvery(paymentActn.loadRenter, handlePaymentListRenter);
}
