import { Col, Container, Row } from "react-bootstrap";
import { BlogBg } from "../../assets/img/imagesh";
import AccommodationSection from "../Components/Accommodation-sec";
import Footer from "../Home/Footer/Footer";
import Header from "../Home/Header/Header";

const BlogDetail4 = () => {
  return (
    <>
      <div className="page gray-bg">
        {/* Header */}
        <Header />

        {/* Page Banner */}
        {/* Page Banner */}
        <section className="page-banner contact-us">
          <Container>
            <div
              className="banner-image mr-b-30"
              style={{ backgroundImage: `url(${BlogBg})` }}
            >
               <h1>When to say no to a homeowner | Travel Nurse Rental Guide </h1>
            </div>
          </Container>
        </section>
        {/* Content Block */}
        <div className="content-block">
          <Container>
            <Row>
              <Col>
                <p> Travel Nurse contracts go very fast, and no one wants to miss a good contract because of housing. However, we know that housing is the most challenging part of the travel nursing experience. We want to set you up for success when renting, so you can avoid many pitfalls that are ahead.  </p>
                <p> Staying in a stranger’s home may give you the jitters, but don’t worry, many of us have done it safely.  Sometimes you will be staying alone, another time you may be sharing a house. There are many precautions to take to ensure you are doing this part of the puzzle correctly. Here are some things to consider when renting.</p>
                <h3> Price  </h3>
                <p> Is this rental property the right price for my budget, or is this rental property worth the asking price? As a travel nurse you will be more than likely duplicating expenses. This means that your budget should be able to take care of your permanent home expenses and your new home expenses. Do not get caught up in the hype about stipend, the truth is stipend must be spent wisely because it helps to balance out the taxed portion of your salary.  Look at photos of the place you are about to rent to get a good feel for how the place will be. Ask your prospective landlord questions about the property and surroundings. On NursesBnB’s website you can ask homeowners questions via the chat on their listed property, then check your inbox on your dashboard for a response or watch your email for those notifications. It is customary for our healthcare professionals to ask homeowners for discounts on the listed property. Homeowners usually respond positively to this. As a renter you also must take into consideration processing fees when booking. Some websites charge hefty processing fees which can quickly put you over your monthly rental budget. With NursesBnB our fees are low, transparent, and easy to understand right from the start. </p>
                <h3> Location </h3>
                <p> Location is a big factor when renting. Being close to the hospital that you will work at can be a big advantage in saving on gas and time. Going to a new neighborhood always requires taking safety precautions to protect your life and property. Our neighborhood safety rating gives a fair idea of what a neighborhood is like, but nothing beats an in-person view. Most neighborhoods have good and bad areas. Our goal is to have you in safe locations to start and complete your assignments. Location in this sense also includes where you go to book a property. There are many websites but not all have your best interest at heart or even understand your interest. Ensuring that your booking takes place online and no part of your transaction is done offline is very important. This protects you from scams and unforeseen problems with rental deposits down the road. Avoid landlords who want to collect 50% or more of your 90-day booking upfront.</p>
                <h3> Pre rental information </h3>
                <p> At no time should a homeowner request sensitive information during the rental process. A renter should not give a prospective landlord, paystub, social security number or any other type of information that classifies as personal information that can result in your identity stolen.  While a homeowner may take offense to this, it’s in your best interest to keep this information private. Renting on a website that allows homeowners to collect your personal information is high risk and reckless. With NursesBnB the homeowner is privy to information to help them make a safe informed decision without exposing personal information that has been given to us. Our system was created with the safety of both homeowner and renter in mind. Our property owners will not ask you to pay for background checks, these checks will be done prior to booking. With NursesBnB, one background check gives you unlimited booking for one year.  This saves you from unnecessary expense each time you book a property with us. If you have questions on the rental process, do not hesitate to contact us. Happy Traveling.  We adhere to the fair housing act. We have built in anti-discriminatory features to prevent both parties from making decisions base on discriminatory profiling. If you have questions on the rental process, do not hesitate to contact us. Happy Traveling.</p>
              </Col>
            </Row>
          </Container>
        </div>
        {/* Experience Accommodations Section */}
        <AccommodationSection />

        {/* Footer */}
        <Footer />
      </div>
    </>
  );
};
export default BlogDetail4;
