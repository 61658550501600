export const apiEndPointPaymentService =
process.env.REACT_APP_API_URL || "http://localhost:3004/";

export const apiConstantsStripe = {
  CREATE_CARD: `${apiEndPointPaymentService}payment/accounts/create_card`,
  CREATE_BANK_PAYMENT_METHOD: `${apiEndPointPaymentService}payment/accounts/create_bank_payment_method`,
  CREATE_BANK_ACCOUNT: `${apiEndPointPaymentService}payment/accounts/create_bank_account`,
  GET_ACCOUNTS:  `${apiEndPointPaymentService}payment/accounts/index`,
  SET_DEFAULT_CARD:  `${apiEndPointPaymentService}payment/accounts/set_default_card`,
  DELETE_SOURCE:  `${apiEndPointPaymentService}payment/accounts/delete/`,
};
