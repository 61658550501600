import axios from "axios";
import { apiConstants } from "../../../Constants/api_constants";

export function getCheckinDetails(booking_id) {
  return axios({
    method: "GET",
    url: apiConstants.GET_CHECKIN_DETAILS + booking_id,
    validateStatus: () => true,
  });
}
