import {
  createFaq,
  deleteFaq,
  loadFaq,
  loadStaticContent,
  updateFaq,
  updateTac,
} from "../ActionTypes";

export const fetchStaticContent = (data) => ({
  type: loadStaticContent.load,
  payload: data,
});

export const storeStaticContent = (data) => ({
  type: loadStaticContent.success,
  payload: data,
});

export const fetchFaq = (data) => ({
  type: loadFaq.load,
  payload: data,
});

export const StoreFaq = (data) => ({
  type: loadFaq.success,
  payload: data,
});

//Update TAC
export const update_cm = (u_cm, navigate) => ({
  type: updateTac.load,
  payload: { u_cm, navigate },
});
export const update_cm_success = (data) => ({
  type: updateTac.success,
  payload: data,
});

//Create Faq
export const create_faq = (data,dispatch) => ({
  type: createFaq.load,
  payload: {data, dispatch}
});
export const create_faq_success = (data) => ({
  type: createFaq.success,
  payload: data,
});

//Update Faq
export const update_faq = (updatedata, _id, dispatch) => ({
  type: updateFaq.load,
  payload: { updatedata, _id, dispatch },
});

export const update_faq_success = (data) => ({
  type: updateFaq.success,
  payload: data,
});

//Delete Faq
export const delete_faq = (id,dispatch) => ({
  type: deleteFaq.load,
  payload: {id, dispatch},
});
export const delete_faq_success = (data) => ({
  type: deleteFaq.success,
  payload: data,
});
